import Vue from 'vue'
import VueApollo from 'vue-apollo'
import {
  createApolloClient,
  restartWebsockets,
} from 'vue-cli-plugin-apollo/graphql-client'
import { setContext } from 'apollo-link-context'

import { Operation, NextLink, fromPromise, ApolloLink, from } from 'apollo-link'
import { onError } from 'apollo-link-error'
import VueJwtDecode from 'vue-jwt-decode'
import gql from 'graphql-tag'
import { URL } from '@/constants/URLConstant.js'

// Install the vue plugin
Vue.use(VueApollo)

// Name of the localStorage item
const AUTH_TOKEN = 'access_token'

// Http endpoint

const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || URL + '/graphql'
// const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || 'https://contesaur.app/graphql'

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem('access_token')

  if (token === 'undefined' || !token) {
    window.location = '/login'

    return
  }

  return {
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
      'Access-Control-Allow-Headers':
        'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
    },
  }
})

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    const token = localStorage.getItem('access_token')
    if (token === 'undefined' || !token) {
      window.location = '/login'
      return
    }

    if (networkError) {
      if (networkError.statusCode === 401) {
        const refresh_token = localStorage.getItem('refresh_token')
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            token: refresh_token,
          }),
        }

        return fromPromise(
          fetch(URL + '/api/login/refresh', requestOptions)
            .then((response) => response.json())
            .then((data) => {
              localStorage.setItem('access_token', data.access_token)
              localStorage.setItem('refresh_token', data.refresh_token)
            })
            .catch((error) => {
              localStorage.removeItem('access_token')
              localStorage.removeItem('refresh_token')

              window.location = '/login'
            })
        ).flatMap(() => {
          return forward(operation)
        })
      }
    }
  }
)

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)

  // Use `null` to disable subscriptions
  // wsEndpoint: 'wss://94.130.216.37:8080/subscriptions',
  wsEndpoint: null,

  // LocalStorage token
  tokenName: AUTH_TOKEN,

  link: from([errorLink, authLink]),
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  // link: myLink

  // Override default cache
  // cache: myCache

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
}

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options,
  })
  apolloClient.wsClient = wsClient

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: "cache-and-network",
        fetchPolicy: 'no-cache',
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log(
        '%cError',
        'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
        error.message
      )
    },
  })

  return apolloProvider
}

/*
// Manually call this when user log in
export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== "undefined" && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (login)", "color: orange;", e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem(AUTH_TOKEN);
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (logout)", "color: orange;", e.message);
  }
}
*/
