function filterContent(contents, contentFilter, contentOwner) {
  let filteredContents = contents

  contentFilter.forEach((filterItem) => {
    if (filterItem.values.length > 0) {
      let newFilteredContents = []

      filteredContents.forEach((content) => {
        let contentItemList = []
        const contentVal = content[filterItem.type]

        if (!Array.isArray(contentVal)) {
          contentItemList.push(contentVal)
        } else {
          contentItemList = contentVal
        }

        if (filterItem.type === 'name') {
          if (content.name.toUpperCase().includes(filterItem.values[0].toUpperCase())) {
            newFilteredContents.push(content)
          }
        } else if (filterItem.type === 'users') {
          if (content.owner) {
            if (filterItem.values.includes(content.owner.name)) {
              newFilteredContents.push(content)
            }
          }
        } else if (filterItem.type === 'channels') {
          if (content.channel && content.channel.hasOwnProperty('name')) {
            if (filterItem.values.includes(content.channel.name)) {
              newFilteredContents.push(content)
            }
          }
        } else if (filterItem.type === 'rating') {
          if (content.review?.value) {
            if (filterItem.values.includes(content.review.value / 2)) {
              newFilteredContents.push(content)
            }

            /*
            if (filterItem.values.includes(content.review?.value)) {
              newFilteredContents.push(content)
            }
             */
          }

          if (filterItem.values.includes(null)) {
            if (content.review === undefined || content.review === null) {
              newFilteredContents.push(content)
            }

            if (content.review?.value === null || content.review?.value === 0) {
              newFilteredContents.push(content)
            }
          }
        } else if (filterItem.type === 'state' || filterItem.type === 'stdcs') {
          if (
            contentItemList.some((item) => filterItem.values.includes(item))
          ) {
            newFilteredContents.push(content)
          }
        } else {
          if (contentVal != null) {
            if (
              contentItemList.some((item) =>
                filterItem.values.includes(item.name)
              )
            ) {
              newFilteredContents.push(content)
            }
          }
        }
      })

      filteredContents = newFilteredContents
    }
  })

  if (contentOwner === 'all') {
    return filteredContents
  }

  // Filter Me / All
  // Test user id
  const test_user_id = contentOwner

  let ownerContentList = []
  filteredContents.forEach((content) => {
    if (content.owner.id === test_user_id) {
      ownerContentList.push(content)
    }

    content.checklist.forEach((item) => {
      if (item.done === false && item.assignee != null) {
        if (item.assignee.id === test_user_id) {
          ownerContentList.push(content)
        }
      }
    })
  })

  return ownerContentList
}

function addNameList(name, nameList) {
  const result = nameList.filter((item) => item.value === name)
  // console.log("filter_result", result);

  if (result.length === 0) {
    nameList.push({
      label: name,
      value: name,
    })
  }

  return nameList
}

export const FilterService = {
  filterContent,
  addNameList,
}
