<template>
    <div class="accordion-item">
        <div 
            class="accordion-item__header"
            :class="{'is-open': isOpen}"
            @click="toggleAccordion()"
            >
            {{ title }}
            <div class="accordion-item__svg">
                <IcoDown />
            </div>
        </div>
        <div 
            v-if="isOpen"
            class="accordion__body"
            :class="{'is-open': isOpen}"
            >
            <div class="accordion__body__wrapper">
                <slot></slot>
            </div>            
        </div>
    </div>
</template>

<script>
import IcoDown from '@/components/svg/IcoDown.vue';

export default {
    components: {
        IcoDown
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        open: {
            type: Boolean,
            default: false
        }     
    },
    mounted() {
        this.isOpen = this.open;
    },
    data() {
        return {
            isOpen: false
        }
    },
    methods: {
        toggleAccordion() {
            this.isOpen = !this.isOpen;
        }
    },
    watch: {
        open() {
            console.log("accordion item changed", this.open);
            if(this.open) {
                this.isOpen = true;
            }
        }
    }
}
</script>

<style scoped>
.accordion-item__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px; 
    /* margin-top: 40px; */
    margin-top: 5px;
    background: #E7E9E9;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #17252A;
    cursor: pointer;
}

.accordion-item__body{
    padding: 0 30px;
    padding-bottom: 30px;
    /* overflow: hidden; */
    /* max-height: 0; */
    transition-duration: 0.2s;
    transition-property: transform;    
}

.accordion__body__wrapper{
    padding-bottom: 30px;
}

.accordion-item__body.is-open{
    /* max-height: 500px; */
}

.accordion-item__svg{
    transition-duration: 0.2s;
    transition-property: transform;
}

.accordion-item__header.is-open .accordion-item__svg{
    transform: rotate(180deg);    
}
</style>