<template>
  <div @click="editContent()" class="suggestion" :class="[size, color]">
    <div class="suggestion-title">{{ content.name }}</div>
    <div class="suggestion-button" type="button">
      <IcoAdd />
      <span v-if="size !== 'small'">{{ $t('8_3_2_suggestion_btn_name') }}</span>
    </div>
  </div>
</template>

<script>
import IcoAdd from '@/components/svg/IcoAdd.vue'
import gql from 'graphql-tag'
import { DateTime } from 'luxon'

export default {
  components: {
    IcoAdd,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: 'small',
    },
    color: {
      type: String,
      default: 'light',
    },
  },
  computed: {
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
    user() {
      return this.$store.getters.getMe
    },
  },
  methods: {
    editContent() {
      this.$store.commit('setCreateContentDefaultValue', {
        date: this.content.date,
        name: this.content.name,
        eod: true,
      })

      this.$store.commit('setCreateContentModalStatus', true)
    },
    createContent() {
      // Disable button resubmit
      this.disabledCreateButton = true

      const content = this.content
      let date

      // Set owner to suggested content
      content.owner = this.user

      // State is TODO
      content.state = 'TODO'

      const _date = DateTime.fromISO(this.content.date).setZone(
        this.selectedCompany.zoneId
      )

      content.date = DateTime.fromISO(this.content.date)
        .setZone(this.selectedCompany.zoneId)
        .set({
          year: _date.year,
          month: _date.month,
          day: _date.day,
          hour: 11,
          minute: 59,
          second: 59,
          millisecond: 0,
        })
        .toUTC()
        .set({
          hour: 11,
          minute: 59,
          second: 59,
        })
        .toISO()

      try {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($content: ContentInput) {
                createContent(content: $content) {
                  id
                  name
                }
              }
            `,
            variables: {
              content: {
                companyId: this.selectedCompany.id,
                name: content.name,
                ownerId: this.content.owner.id,
              },
            },
            update: (store, { data: { createContent } }) => {
              content.id = createContent.id

              try {
                this.$apollo
                  .mutate({
                    mutation: gql`
                      mutation (
                        $id: ID!
                        $name: String
                        $state: State!
                        $date: Date
                        $ownerId: ID
                        $eod: Boolean!
                      ) {
                        updateContent(
                          id: $id
                          name: $name
                          state: $state
                          date: $date
                          ownerId: $ownerId
                          eod: $eod
                        ) {
                          id
                          state
                          description
                          eod
                          date
                        }
                      }
                    `,
                    variables: {
                      id: content.id,
                      name: content.name,
                      state: content.state,
                      date: content.date,
                      ownerId: content.owner.id,
                      eod: content.eod,
                    },
                    update: (store, { data: { updateContent } }) => {
                      this.$store.commit('setRefreshToken')

                      this.$notify({
                        title: this.$t(
                          '9_1_toaster_success_title_content_created'
                        ),
                        text: this.$t(
                          '9_1_toaster_success_description_content_created'
                        ),
                        type: 'success',
                        duration: 5000,
                      })
                    },
                  })
                  .then((data) => {
                    this.disabledCreateButton = false
                  })
                  .catch((error) => {
                    this.$notify({
                      title: this.$t('9_1_toaster_error_title_content_created'),
                      text: this.$t('global_toaster_error_description'),
                      type: 'error',
                      duration: 5000,
                    })

                    this.disabledCreateButton = false
                  })
              } catch (e) {
                // this.$store.commit('setRefreshToken')

                this.$notify({
                  title: this.$t('9_1_toaster_error_title_content_created'),
                  text: this.$t('global_toaster_error_description'),
                  type: 'error',
                  duration: 5000,
                })

                this.disabledCreateButton = false
              }
            },
          })
          .catch((error) => {
            this.disabledCreateButton = false

            this.$notify({
              title: this.$t('9_1_toaster_error_title_content_created'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.disabledCreateButton = false

        this.$notify({
          title: this.$t('9_1_toaster_error_title_content_created'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
  },
}
</script>

<style scoped>
.suggestion {
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin-top: 5px;
  margin-right: 5px;
  border: 1.5px dashed #aeb3b5;
  border-radius: 6px;
  padding: 7px 10px;
  width: 99%;
}

.suggestion.large {
  flex-direction: column;
  gap: 7px;
  margin-top: 10px;
  padding: 7px;
}

.suggestion-title {
  position: absolute;
  top: 12px;
  right: 32px;
  left: 10px;
  overflow: hidden;
  color: #888f91;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  font-family: 'Nunito';
  text-overflow: ellipsis;
  white-space: nowrap;
}

.suggestion.large .suggestion-title {
  display: block;
  position: static;
  width: 100%;
  overflow: visible;
  text-align: left;
  white-space: normal;
}

.suggestion-button {
  display: inline-flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #e7e9e9;
  width: 20px;
  height: 20px;
}

.suggestion.dark .suggestion-button {
  background-color: #d1d4d4;
}

.suggestion.large .suggestion-button {
  display: flex;
  position: relative;
  gap: 4px;
  width: 100%;
}

.suggestion.large .suggestion-button svg {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
}

.suggestion.large .suggestion-button span {
  color: #000000;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  font-family: 'Nunito';
}
</style>
