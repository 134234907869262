
const states = [
    {
        id: 'TODO',
        title: 'To Do',
        color: '#888F91',
        textColor: '#FFFFFF'
    },
    {
        id: 'IN_PROGRESS',
        title: 'In Progress',
        color: '#17252A',
        textColor: '#FFFFFF'
    },
    {
        id: 'IN_REVIEW',
        title: 'In Review',
        color: '#F7DC6F',
        textColor: '#9A7D0A'
    },
    {
        id: 'APPROVED',
        title: 'Approved',
        color: '#2CE0C1',
        textColor: '#FFFFFF'
    },
    {
        id: 'PUBLISHED',
        title: 'Published',
        color: '#DEFEF8',
        textColor: '#2ACEB1'
    },  
];

const statesFull = states;
statesFull.unshift(
    {
        id: 'IDEA',
        title: 'Idea',
        color: '#E7E9E9',
        textColor: '#888F91'
    }
);

const statesReadonly = [
    {
        id: 'IN_PROGRESS',
        title: 'In Progress',
        color: '#17252A',
        textColor: '#FFFFFF'
    },
    {
        id: 'IN_REVIEW',
        title: 'In Review',
        color: '#F7DC6F',
        textColor: '#9A7D0A'
    },
    {
        id: 'APPROVED',
        title: 'Approved',
        color: '#2CE0C1',
        textColor: '#FFFFFF'
    }  
];

export { states, statesFull, statesReadonly };