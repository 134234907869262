


const escapeRegExp = (text) => {          
    return text.replaceAll(/[-[\]{}()*+?.,\\^$#\s]/g, '\\$&');
}



const highlightUsers = (newComment, users) => {
    const replaced = newComment
        ?.replaceAll("&", "&amp;")
        ?.replaceAll(">", "&gt;")
        ?.replaceAll("<", "&lt;") + "\n\n";

    let reg = users
        .slice()
        .sort((a, b) => b.value?.length - a.value?.length)
        .map((user) => user.value)
        .join("|")
  
    let search = new RegExp(escapeRegExp(reg), "g");
  
    return replaced.replace(search, (match, offset) => {
        return `<span class="mention-str">${match}</span>`;
    });
}



export const CommentService = {
    highlightUsers
};