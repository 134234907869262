const short = 500
const long = 10000
const comment = 3000
const companyName = 50
const companyDescription = 100
const userName = 50
const userJob = 100
const attributes = 50

export default {
  short,
  long,
  companyName,
  companyDescription,
  userName,
  userJob,
  attributes,
  comment,
}
