var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"sticky-header",class:{ expanded: _vm.ideasContainerStatus }},[_c('Menu'),(!_vm.billingOverdue)?_c('div',{staticClass:"wrapper wrapper--calendar-fixed"},[_c('CalendarHeader',{attrs:{"selectedDate":_vm.formattedCurrentDate,"type":'calendarMonthView',"nameList":_vm.nameList},on:{"selectToday":_vm.selectToady,"datePrev":_vm.datePrev,"dateNext":_vm.dateNext,"dropToContainer":_vm.dropToContainer}})],1):_vm._e()],1),(!_vm.billingOverdue)?_c('div',{staticClass:"wrapper wrapper--calendar wrapper--top page",class:{
      expanded: _vm.ideasContainerStatus,
      'days-to-expiration': _vm.selectedCompany.showExpirationWarning,
    }},[_c('GlobalNotification'),_c('v-calendar',{ref:"calendar",staticClass:"custom-calendar max-w-full",attrs:{"first-day-of-week":2,"masks":_vm.masks,"locale":_vm.$i18n.locale,"attributes":_vm.calendarData,"disable-page-swipe":"","is-expanded":"","model-config":_vm.modelConfig},scopedSlots:_vm._u([{key:"day-content",fn:function(ref){
    var day = ref.day;
    var attributes = ref.attributes;
return [_c('div',{staticClass:"flex flex-col h-full z-10 overflow-hidden day-data",class:{
            'is-selected': day === _vm.currentDate,
            'is-active': _vm.globalDragStatus,
            'is-dropped': _vm.activeDropzone === 'day-' + day.id,
          },attrs:{"id":'day-' + day.id},on:{"click":function($event){return _vm.selectDay(day)},"dragover":function($event){$event.preventDefault();return _vm.showActiveDrop('day-' + day.id)},"drop":function($event){$event.preventDefault();return _vm.drop($event, day)},"mouseleave":_vm.hideExtra,"mouseover":function($event){return _vm.showExtra(day.id)}}},[_c('div',{staticClass:"day-data__drop-area",on:{"dragover":function($event){$event.preventDefault();}}},[_c('span',[_vm._v(_vm._s(_vm.$t('8_1_drop_zone')))])]),_c('div',{staticClass:"day-data__labels",on:{"dragover":function($event){$event.preventDefault();}}},[_c('span',{staticClass:"day-label day-label--day text-sm text-gray-900"},[_vm._v(" "+_vm._s(day.day)+". ")]),_c('span',{staticClass:"day-data__labels__data"},[(attributes && _vm.getDaySuggestionCount(attributes))?_c('span',{staticClass:"day-data__label-suggestion"},[_c('IcoSuggestion')],1):_vm._e(),(
                  attributes && _vm.getDayContentCount(attributes) > 0 && _vm.loaded
                )?_c('span',{staticClass:"\n                  content-counter content-counter--small\n                  day-label day-label--content\n                  text-sm text-gray-900\n                "},[_c('IcoContent'),_vm._v(" "+_vm._s(_vm.getDayContentCount(attributes))+" ")],1):_vm._e()]),(!_vm.loaded)?_c('span',{staticClass:"counter-loader"}):_vm._e()]),_c('div',{staticClass:"day-data__ideas",on:{"dragover":function($event){$event.preventDefault();return _vm.showActiveDrop('day-' + day.id)},"mouseleave":_vm.hideExtra,"mouseover":function($event){return _vm.showExtra(day.id)}}},[_c('vuescroll',{attrs:{"ops":_vm.ops}},[(!_vm.loaded)?_c('div',_vm._l((_vm.generateRandomNumber(day.day, 3)),function(item,index){return _c('IdeaPreloader',{key:index})}),1):_vm._e(),(_vm.loaded)?[_vm._l((attributes),function(item){return [(item.customData.type === 'content')?_c('Idea',{key:item.key,attrs:{"item":item.customData,"draggable":_vm.role !== 'READ_ONLY',"id":item.key},on:{"deleteContent":_vm.deleteContent}}):_vm._e(),(
                      item.customData.type === 'suggestion' &&
                      _vm.ideasContainerStatus
                    )?_c('Suggestion',{key:item.key,attrs:{"content":item.customData}}):_vm._e()]})]:_vm._e(),(_vm.role !== 'READ_ONLY')?_c('div',{staticClass:"day-data__add-content"},[(_vm.dayShowExtra === day.id)?_c('button',{staticClass:"day-data__add-content__button",on:{"click":function($event){return _vm.addContent(day.date)}}},[_c('IcoPlus'),_vm._v(" "+_vm._s(_vm.$t('8_1_add_content'))+" ")],1):_vm._e()]):_vm._e()],2)],1)])]}}],null,false,1432887854)})],1):_c('ExpiredCompany',{attrs:{"company":_vm.selectedCompany}}),(_vm.cannotOpenContent)?_c('Modal',{on:{"closeModal":function($event){_vm.cannotOpenContent = false}}},[_c('div',{staticClass:"logout"},[_c('h2',{staticClass:"logout__title",domProps:{"innerHTML":_vm._s(_vm.$t('10_1_title_content_not_found'))}}),_c('div',{staticClass:"modal-description"},[_c('p',[_vm._v(_vm._s(_vm.$t('10_1_description_content_not_found')))])]),_c('div',{staticClass:"logout__options"},[_c('Button',{staticStyle:{"width":"100%"},attrs:{"type":"delete"},on:{"click":function($event){_vm.cannotOpenContent = false}}},[_vm._v(" "+_vm._s(_vm.$t('10_1_btn_close'))+" ")])],1)])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }