<template>
    <Modal @closeModal="closeModal">
        <div class="logout" style="width: 320px;">
            <h2 class="logout__title">{{ $t('8_1_4_modal_delete_title') }} {{ contentToDelete.name }}?</h2>

            <div class="modal-description">
                <p>{{ $t('8_1_4_modal_delete_description') }}</p>
            </div>        

            <div class="modal-options">
                <ul>                    
                    <li>
                        <Button style="width: 100%;" @click="closeModal()" :type="'secondary'">{{ $t('8_1_4_modal_btn_go_back') }}</Button>
                    </li>
                    <li>
                        <Button style="width: 100%;" @click="deleteContent()" :type="'delete'">{{ $t("8_1_4_modal_btn_delete") }}</Button>
                    </li>
                </ul>
            </div>

        </div>
    </Modal>
</template>

<script>
import CONTENT_DELETE from "@/graphql/ContentDelete.gql";

import Modal from '@/components/Modal.vue';
import Button from '@/components/button/Button.vue';
import ShowError from '@/components/ShowError.vue';


export default {
    components: {
        Modal,
        Button,
        ShowError
    },
    computed: {        
        contentToDelete() {
            return this.$store.getters.getContentToDelete;
        }
    },
    data() {
        return {
            disabledDeleteAction: true,
            error_form: [],
            password: ''
        }
    },
    methods: {
        closeModal() {
            this.$emit("closeModal");
            this.$store.commit("setContentToDelete", {});
        },
        deleteContent() {
            try {
                this.$apollo.mutate({
                    mutation: CONTENT_DELETE,
                    variables: {
                        id: this.contentToDelete.id,
                    },                
                    update: (store, { data: { deleteContent } }) => {
                        
                        if(!deleteContent) {
                            this.$notify({
                                title: this.$t('8_1_4_toaster_error_title_delete_content'),
                                text: this.$t('8_1_4_toaster_error_description_delete_content'),
                                type: 'error',
                                duration: 5000
                            });
                            return;
                        }

                        this.$store.commit("setRefreshToken");
                        this.$store.commit("setEditContentModalStatus", {});
                        this.$store.commit("setContentToDelete", {});

                        this.$notify({
                            title: this.$t('8_1_4_toaster_success_title_deleted_content'),
                            text: this.$t('8_1_4_toaster_success_description_deleted_content'),
                            type: 'success',
                            duration: 5000
                        });
                    }
                })
            } catch(e) {

                this.$notify({
                    title: this.$t('8_1_4_toaster_error_title_delete_content'),
                    text: this.$t('global_toaster_error_description'), 
                    type: 'error',
                    duration: 5000
                });
            }
        }
    }
}
</script>
