<template>
  <div
    class="tag-input"
    :class="{
      slim: slim,
      'show-data': showData && type != 'create',
      compact: !showData,
    }"
    v-click-outside="closeData"
  >
    <div @click="openData" class="tag-input__selected clearfix">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          min-height: 42px;
        "
      >
        <div v-if="data_items.length > 0">
          <div
            class="tag-input__tag tag-input__tag--channel"
            v-for="(tag, index) in data_items"
            :key="index"
          >
            <span :style="{ color: getColor(tag.name) }">{{ tag.name }}</span>
          </div>
        </div>

        <div v-else>
          <div
            class="
              tag-input__tag tag-input__tag--channel tag-input__tag--placeholder
            "
          >
            <span>{{ $t('9_1_placeholder_channel') }}</span>
          </div>
        </div>

        <span
          v-if="data_items.length === 0"
          class="ico"
          :class="{ rotate: showData }"
          style="position: relative; top: 4px"
        >
          <IcoDown />
        </span>

        <span
          v-if="data_items.length > 0 && !disabled"
          @click.stop="clear"
          class="ico ico--black"
          style="position: relative; top: 4px"
        >
          <IcoClose />
        </span>
      </div>
    </div>

    <div v-if="showData" class="tag-input__data">
      <div class="tag-input__data__header">
        <div>{{ name }} ({{ searchItems.length }})</div>
        <div>
          <button v-if="editable" @click.stop.prevent="closeEdit" class="back">
            <IcoBack /> {{ $t('9_2_back') }}
          </button>

          <button v-else @click.stop.prevent="editable = true" class="edit">
            <IcoSettings /> {{ $t('9_2_edit') }}
          </button>

          <button class="simple-button" @click="closeData()">
            <IcoClose />
          </button>
        </div>
      </div>
      <div class="tag-input__data__search">
        <div style="width: 100%">
          <input
            type="text"
            ref="search_items"
            :placeholder="$t('9_2_search')"
            v-model="search"
            :maxlength="length.attributes"
          />
          <IcoSearch />
        </div>
        <div v-if="editable" class="tag-input__data__add">
          <button :disabled="isCreateDisabled" @click="createItem()">
            <IcoPlus />
          </button>
        </div>
      </div>
      <div class="tag-input__data__content">
        <TagInputItem
          :editable="editable"
          v-for="(item, index) in searchItems"
          :key="index"
          :item="item"
          :color="getColor(item.value)"
          @selectItem="selectItem"
          @deleteItem="deleteItem"
          @updateItem="updateItem"
          readonly
        />

        <div class="tag-input__no-result" v-if="searchItems.length == 0">
          <p>{{ $t('9_2_item_search_no_result_title') }}</p>
          <p v-if="!editable">
            {{ $t('9_2_item_search_no_result_desc') }}
            <a @click.stop.prevent="openEdit()">{{
              $t('9_2_link_edit_mode')
            }}</a
            >.
          </p>
          <p v-else>{{ $t('9_2_item_search_no_result_else') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CHANNEL_DELETE from '@/graphql/ChannelDelete.gql'
import CHANNEL_CREATE from '@/graphql/ChannelCreate.gql'
import CHANNEL_UPDATE from '@/graphql/ChannelUpdate.gql'

import textLength from '@/constants/TextLengthConstant.js'
import { ColorService } from '@/services/ColorService.js'

import TagInputItem from '@/components/TagInputItem.vue'
import IcoSettings from '@/components/svg/IcoSettings.vue'

import ClickOutside from 'vue-click-outside'

import IcoBack from '@/components/svg/IcoBack.vue'
import IcoSearch from '@/components/svg/IcoSearch.vue'
import IcoPlus from '@/components/svg/IcoPlus.vue'
import IcoDown from '@/components/svg/IcoDown.vue'
import IcoCross from '@/components/svg/small/IcoCross.vue'
import IcoClose from '@/components/svg/IcoClose.vue'

export default {
  components: {
    TagInputItem,
    IcoBack,
    IcoSearch,
    IcoPlus,
    IcoSettings,
    IcoDown,
    IcoCross,
    IcoClose,
  },
  directives: {
    ClickOutside,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default() {
        return []
      },
    },
    placeholder: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default() {
        return []
      },
    },
    name: {
      type: String,
      default: '',
    },
    max: {
      type: Number,
      default: 0,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'casual',
    },
    slim: {
      type: Boolean,
      default: false,
    },
    colorize: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (Array.isArray(this.value)) {
      this.data_items = this.value
    }

    this.uid = this._uid
  },
  computed: {
    length() {
      return textLength
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
    searchItems() {
      let filteredItems = this.itemsList

      filteredItems = filteredItems.filter((item) => {
        return item.label.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
      })

      if (this.editable) {
        filteredItems.sort(function(a, b) {
          return a.label.localeCompare(b.label);
        });

        return filteredItems
      }

      if (this.data_items) {
        filteredItems = this.getDifference(filteredItems, this.data_items)
      }

      filteredItems.sort(function(a, b) {
        return a.label.localeCompare(b.label);
      });

      return filteredItems
    },
    activeUID() {
      return this.$store.getters.getActiveUID
    },
  },
  created() {
    if (!this.slim) {
      this.showInput = true
    }

    this.itemsList = this.items
  },
  data() {
    return {
      isCreateDisabled: false,
      textColor: '#888F91',
      backgroundColor: '#E7E9E9',
      search: '',
      showData: false,
      editable: false,
      content: '',
      data_items: [],
      canClose: true,
      showInput: false,
      uid: '',
      newTag: '',
      itemsList: [],
    }
  },
  methods: {
    getDifference(array1, array2) {
      return array1.filter((object1) => {
        return !array2.some((object2) => {
          return object1.id === object2.id
        })
      })
    },
    createItem() {
      if (this.search === '') {
        return
      }

      this.isCreateDisabled = true

      try {
        this.$apollo
          .mutate({
            mutation: CHANNEL_CREATE,
            variables: {
              id: this.selectedCompany.id,
              name: this.search,
            },
            update: (store, { data: { createChannel } }) => {
              this.$notify({
                title: this.$t('9_2_toaster_success_title_channel_created'),
                text: this.$t(
                  '9_2_toaster_success_description_channel_created'
                ),
                type: 'success',
                duration: 5000,
              })

              console.log('createChannel.channel', createChannel)

              this.itemsList = this.processDataFromServer(
                createChannel.channels
              )
              this.search = ''

              this.isCreateDisabled = false
            },
          })
          .then((data) => {
            this.$store.commit('setRefreshToken')

            this.isCreateDisabled = false
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('9_2_toaster_error_title_channel_create'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })

            this.isCreateDisabled = false
          })
      } catch (e) {
        console.error(e)

        this.$notify({
          title: this.$t('9_2_toaster_error_title_channel_create'),
          text: this.$t('9_2_toaster_error_description_channel_create'),
          type: 'error',
          duration: 5000,
        })

        this.isCreateDisabled = false
      }
    },
    deleteItem(item) {
      const index = this.data_items.findIndex(
        (deletedItem) => deletedItem.id === item.id
      )
      this.data_items.splice(index, 1)

      const indexList = this.itemsList.findIndex(
        (deletedItem) => deletedItem.id === item.id
      )
      this.itemsList.splice(indexList, 1)

      try {
        this.$apollo
          .mutate({
            mutation: CHANNEL_DELETE,
            variables: {
              id: this.selectedCompany.id,
              channelId: item.id,
            },
            update: (store, { data: { removeChannel } }) => {
              this.$notify({
                title: this.$t('9_2_toaster_success_title_channel_deleted'),
                text: this.$t(
                  '9_2_toaster_success_description_channel_deleted'
                ),
                type: 'success',
                duration: 5000,
              })

              this.$store.commit('setRefreshToken')
            },
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('9_2_toaster_error_title_channel_delete'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        console.error(e)

        this.$notify({
          title: this.$t('9_2_toaster_error_title_channel_delete'),
          text: this.$t('9_2_toaster_error_description_channel_delete'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    updateItem(item) {
      const index = this.data_items.findIndex(
        (deletedItem) => deletedItem.id === item.id
      )
      this.data_items.splice(index, 1)

      const indexList = this.itemsList.findIndex(
        (deletedItem) => deletedItem.id === item.id
      )
      this.itemsList.splice(indexList, 1)

      try {
        this.$apollo
          .mutate({
            mutation: CHANNEL_UPDATE,
            variables: {
              id: item.id,
              name: item.label,
            },
            update: (store, { data: { updateChannel } }) => {
              /* this.$notify({
                title: this.$t("9_2_toaster_success_title_channel_updated"),
                text: this.$t(
                  "9_2_toaster_success_description_channel_updated"
                ),
                type: "success",
                duration: 5000,
              }); */
            },
          })
          .then((data) => {
            this.$store.commit('setRefreshToken')
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('9_2_toaster_error_title_channel_updated'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        console.error(e)

        this.$notify({
          title: this.$t('9_2_toaster_error_title_channel_updated'),
          text: this.$t('9_2_toaster_error_description_channel_updated'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    processDataFromServer(items) {
      const result = []
      items.forEach((item) => {
        result.push({
          id: item.id,
          label: item.name,
          value: item.name,
        })
      })

      return result
    },
    getBackgroundColor(string) {
      if (this.colorize) {
        return ColorService.computedColor(string)
      } else {
        return this.backgroundColor
      }
    },
    getTextColor(string) {
      if (this.colorize) {
        return ColorService.computedColor(string)
      } else {
        return this.textColor
      }
    },
    getColor(name) {
      return ColorService.computedColor(name)
    },
    closeData() {
      this.showData = false
    },
    openData() {
      if (this.disabled) {
        this.showData = false
        return
      }

      this.itemsList = this.items
      this.$store.commit('setActiveUID', this._uid)

      this.showData = true
    },
    openEdit() {
      this.canClose = false
      this.editable = true

      setTimeout(() => (this.canClose = true), 100)
    },
    selectItem(item) {
      const filteredItems = this.data_items.filter((exist_item) => {
        return exist_item.id === item.id
      })

      if (filteredItems.length > 0) {
        return
      }

      this.canClose = false
      this.data_items = [
        {
          name: item.label,
          id: item.id,
        },
      ]

      this.$emit('save_changed_value', this.data_items)

      setTimeout(() => (this.canClose = true), 100)
    },
    closeEdit() {
      this.editable = false
    },
    addTag(event) {
      event.preventDefault()

      var val = event.target.value.trim()
      if (val.length > 0) {
        this.data_items.push(val)
        this.content = ''
      }

      this.$emit('input', this.data_items)
    },
    removeTag(index) {
      this.data_items.splice(index, 1)
      this.$emit('input', this.data_items)
      this.$emit('save_changed_value', this.data_items)
    },
    removeLastTag(event) {
      if (event.target.value.length === 0) {
        this.removeTag(this.data_items.length - 1)
      }

      // Update model
      this.$emit('input', this.data_items)
      this.$emit('save_changed_value', this.data_items)
    },
    clear() {
      this.data_items = []
      this.$emit('input', this.data_items)
      this.$emit('save_changed_value', this.data_items)
    },
  },
  watch: {
    showData() {
      if (this.showData) {
        this.$emit('focus')

        this.editable = false
        this.search = ''

        setTimeout(() => {
          this.$refs.search_items.focus()
        }, 200)
      } else {
        this.$emit('blur')
      }
    },
    editable() {
      if (this.editable) {
        setTimeout(() => {
          this.$refs.search_items.focus()
        }, 200)
      }
    },
  },
}
</script>

<style scoped>
.clearfix {
  overflow: auto;
}

.clearfix::after {
  display: table;
  clear: both;
  content: '';
}
.tag-input {
  position: relative;
  box-sizing: border-box;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 0 10px;
  padding-bottom: 10px;
  width: 100%;
  font-size: 0.9em;
  font-size: 14px;
  /* overflow: hidden; */
}

.tag-input.slim {
  border-color: #fff;
  padding: 0;
}

.tag-input.show-data {
  border-color: #2ce0c1;
}

.tag-input__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.3;
  z-index: 1;
  border-radius: 3px;
}

.tag-input__selected {
  position: relative;
  padding-right: 3px;
  min-height: 40px;
  overflow: hidden;
}

.tag-input__selected span {
  transform: rotate(0deg);
  transition-duration: 0.2s;
  transition-property: transform;
}

.tag-input__selected span.rotate {
  transform: rotate(180deg);
  transition-duration: 0.2s;
  transition-property: transform;
}

.tag-input__selected .tag-input__empty-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  padding-left: 4px;
  color: #888f91;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  font-family: Nunito;
}

.tag-input__selected-text {
  position: relative;
  z-index: 2;
  word-break: break-all;
}

.tag-input__no-result {
  padding: 0 15px 15px 15px;
}

.tag-input__no-result p {
  margin-bottom: 6px;
  color: #aeb3b5;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  font-family: Nunito;
}

.tag-input__no-result p a {
  color: #2ce0c1;
  text-decoration: underline;
}

.tag-input__tag {
  position: relative;
  float: left;
  border-radius: 3px;
  background-color: #eee;
  background: #e7e9e9;
  padding: 0 0 0 10px;
  color: #888f91;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 30px;
  font-family: Nunito;
}

.tag-input__tag--channel {
  background: transparent;
  padding-top: 11px;
  padding-right: 0 !important;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tag-input__tag--placeholder {
  color: #c5c5c5;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  font-family: 'Nunito';
}

.content-edit__channel .tag-input__selected .tag-input__tag--placeholder span {
  display: flex;
  padding-top: 0;
  font-size: 14px !important;
  line-height: 14px !important;
}

.tag-input.slim.compact .tag-input__tag {
  margin-top: 0;
  background: transparent;
  padding: 0;
  height: auto;
  color: #17252a;
  line-height: 1.4;
}

.tag-input.slim.compact .ico {
  display: none;
}

.tag-input.slim .tag-input__tag {
  margin-top: 3px;
  margin-right: 0;
  margin-left: 3px;
}

.tag-input.slim {
  padding-bottom: 3px;
}

.tag-input__tag span.tag-input__remove {
  position: absolute;
  top: 5px;
  right: 6px;
  z-index: 92;
  cursor: pointer;
  font-weight: 400;
  font-size: 18px;
  line-height: 1;
}

.tag-input__text {
  display: inline-block;
  margin-top: 10px;
  outline: none;
  border: none;
  background: none;
  width: auto;
  height: 30px;
  color: #17252a;
  font-style: normal;

  font-weight: bold;
  font-size: 0.9em;
  font-size: 12px;
  line-height: 30px;
  font-family: Nunito;
}

.tag-input.slim .tag-input__text {
  margin-top: 1px;
  color: #888f91;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  font-family: 'Nunito';
  letter-spacing: -0.2px;
}

.tag-input__data {
  position: absolute;
  top: 100%;
  right: 14px;
  left: 14px;
  z-index: 9993;
  box-shadow: 15px 15px 60px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background: #ffffff;
  min-width: 240px;
}

.tag-input__data__header {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.tag-input__data__header div {
  display: flex;
  align-items: center;
  mix-blend-mode: normal;
  color: #aeb3b5;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  font-family: Nunito;
  text-transform: uppercase;
}

.tag-input__data__header button {
  cursor: pointer;
  border: none;
  border-radius: 3px;
  background: #2ce0c1;
  height: 20px;
  color: #ffffff;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 10px;
  font-family: Nunito;
}

.tag-input__data__header button.edit {
  background: #e7e9e9;
  color: #17252a;
}

.tag-input__data__header button.back {
  background: #2ce0c1;
  color: #ffffff;
}

.tag-input__data__search {
  display: flex;
  gap: 10px;
  padding: 0 15px 5px 15px;
}

.tag-input__data__search div {
  position: relative;
  mix-blend-mode: normal;
  border-radius: 5px;
  background: #f6f6f7;
  width: 100%;
}

.tag-input__data__search input {
  border: none;
  background: transparent;
  padding: 0 10px;
  padding-right: 30px;
  width: 100%;
  height: 30px;

  color: #17252a;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  font-family: Nunito;
}

.tag-input__data__search svg {
  position: absolute;
  top: 7px;
  right: 10px;
}

.tag-input__data__content {
  padding-bottom: 15px;
}

.tag-input__data__add {
  width: 30px;
  min-width: 30px;
  max-width: 30px;
}

.tag-input__data__add input {
  border: none;
  border-radius: 4px;
  background: #f6f6f7;
  padding: 0 10px;
  width: 100%;
  height: 30px;
  color: #17252a;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 30px;
  line-height: 18px;
  font-family: Nunito;
}

.tag-input__data__add button {
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background: #2ce0c1;
  width: 30px;
  height: 30px;
  color: #fff;
  line-height: 1;
}

.tag-input.slim.show-data {
  padding-right: 10px;
}

.tag-input__data__add svg {
  position: static;
  min-width: 10px;
  min-height: 10px;
}

.tag-input__data__add button {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style>
.modal-content .tag-input__tag--channel {
  max-width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

body .modal-content .tag-input.compact .ico.ico--black {
  top: 6px !important;
}

body .content-edit .tag-input.compact .ico.ico--black {
  top: 4px !important;
}
</style>
