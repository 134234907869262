<template>
    <Modal @closeModal="closeModal">
        <div class="logout">
            <h2 class="logout__title" v-html="$t('5_3_1_modal_title')"></h2>

            <div class="logout__options">
                <ul>                    
                    <li>
                        <Button class="logout__cancel" @click="closeModal">{{ $t("5_3_1_back") }}</Button>
                    </li>
                    <li>
                        <Button :type="'delete'" @click="logout">{{ $t("5_3_1_logout") }}</Button>
                    </li>
                </ul>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import Button from '@/components/button/Button.vue';

export default {
    components: {
        Modal,
        Button,
    },
    methods: {
        closeModal() {
            this.$emit("closeModal");
        },
        logout() {
            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token')
            
            // window.location = '/login'
            this.$router.replace('/login')

            this.$emit("closeModal");
        }
    }
}
</script>
