<template>
  <div>
    <div class="sticky-header">
      <Menu />

      <div class="wrapper wrapper--calendar-fixed">
        <DashboardHeader
          :selectedDate="formattedRange"
          :type="'listView'"
          :nameList="[]"
          @selectToday="selectToday"
          @datePrev="datePrev"
          @dateNext="dateNext"
        />
      </div>
    </div>

    <div
      class="wrapper wrapper--calendar wrapper--top wrapper--list"
      :class="{
        'days-to-expiration': this.selectedCompany && this.selectedCompany.showExpirationWarning,
      }"
    >
      <GlobalNotification />

      <div class="print-only">
        <div class="c-flex">
          <LogoContesaurBlack />

          <div>
            <div>
              <strong v-if="selectedCompany">{{ selectedCompany.name }}</strong>
            </div>
            <strong>{{ formattedFullRange }}</strong>
          </div>
        </div>
      </div>

      <div class="grid grid--full">
        <div class="grid-col grid-col--3">
          <div
            class="dashboard-card"
            :class="{ 'dashboard-card--loading': loadingStatistics }"
          >
            <div class="dashboard-card__data">
              <h2>{{ $t('13_title_state') }}</h2>
              <p>{{ $t('13_description_state') }}</p>
              <div v-if="statesData" class="dashboard-card__legend">
                <ul>
                  <li
                    v-for="(legendItem, index) in statesData.legend"
                    :key="index"
                  >
                    <span
                      class="percentage"
                      :style="{
                        'background-color':
                          statesData.datasets[0].backgroundColor[index],
                      }"
                    >
                      {{ legendItem.percentage }}
                    </span>
                    <span class="name">
                      {{ legendItem.label }}
                    </span>
                    <span class="count">
                      {{ legendItem.count }}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="statesData" class="dashboard-card__graph">
              <DoughnutChart
                :width="200"
                :height="200"
                ref="state_chart"
                :chart-data="statesData"
                :options="options"
              >
              </DoughnutChart>

              <div v-if="statesData" class="dashboard-card__graph-center">
                <strong>{{ statesSum }}</strong
                >{{ $t('13_title_state') }}
              </div>
            </div>
          </div>
        </div>

        <div class="grid-col grid-col--3">
          <div
            class="dashboard-card"
            :class="{ 'dashboard-card--loading': loadingStatistics }"
          >
            <div class="dashboard-card__data">
              <h2>{{ $t('13_title_channels') }}</h2>
              <p>{{ $t('13_description_channels') }}</p>
              <div v-if="channelsData" class="dashboard-card__legend">
                <ul>
                  <li
                    v-for="(legendItem, index) in channelsData.legend"
                    :key="index"
                  >
                    <span
                      class="percentage"
                      :style="{
                        'background-color':
                          channelsData.datasets[0].backgroundColor[index],
                      }"
                    >
                      {{ legendItem.percentage }}
                    </span>
                    <span class="name">
                      {{ legendItem.label }}
                    </span>
                    <span class="count">
                      {{ legendItem.count }}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="channelsData" class="dashboard-card__graph">
              <DoughnutChart
                :width="200"
                :height="200"
                ref="channel_chart"
                :chart-data="channelsData"
                :options="options"
              >
              </DoughnutChart>

              <div v-if="channelsData" class="dashboard-card__graph-center">
                <strong>{{ channelsCount }}</strong
                >{{ $t('13_title_channels') }}
              </div>
            </div>
          </div>
        </div>
        <div class="grid-col grid-col--3">
          <div
            class="dashboard-card"
            :class="{ 'dashboard-card--loading': loadingStatistics }"
          >
            <div class="dashboard-card__data">
              <h2>{{ $t('13_title_products') }}</h2>
              <p>{{ $t('13_description_products') }}</p>

              <div v-if="productsData" class="dashboard-card__legend">
                <ul>
                  <li
                    v-for="(legendItem, index) in productsData.legend"
                    :key="index"
                  >
                    <span
                      class="percentage"
                      :style="{
                        'background-color':
                          productsData.datasets[0].backgroundColor[index],
                      }"
                    >
                      {{ legendItem.percentage }}
                    </span>
                    <span class="name">
                      {{ legendItem.label }}
                    </span>
                    <span class="count">
                      {{ legendItem.count }}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="productsData" class="dashboard-card__graph">
              <DoughnutChart
                :width="200"
                :height="200"
                ref="skills_chart"
                :chart-data="productsData"
                :options="options"
              >
              </DoughnutChart>

              <div class="dashboard-card__graph-center">
                <strong>{{ productsCount }}</strong
                >{{ $t('13_title_products') }}
              </div>
            </div>
          </div>
        </div>

        <div class="grid-col grid-col--3">
          <div
            class="dashboard-card"
            :class="{ 'dashboard-card--loading': loadingStatistics }"
          >
            <div class="dashboard-card__data">
              <h2>{{ $t('13_title_personas') }}</h2>
              <p>{{ $t('13_description_personas') }}</p>

              <div v-if="personasData" class="dashboard-card__legend">
                <ul>
                  <li
                    v-for="(legendItem, index) in personasData.legend"
                    :key="index"
                  >
                    <span
                      class="percentage"
                      :style="{
                        'background-color':
                          personasData.datasets[0].backgroundColor[index],
                      }"
                    >
                      {{ legendItem.percentage }}
                    </span>
                    <span class="name">
                      {{ legendItem.label }}
                    </span>
                    <span class="count">
                      {{ legendItem.count }}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="personasData" class="dashboard-card__graph">
              <DoughnutChart
                :width="200"
                :height="200"
                ref="skills_chart"
                :chart-data="personasData"
                :options="options"
              >
              </DoughnutChart>

              <div v-if="personasData" class="dashboard-card__graph-center">
                <strong>{{ personsCount }}</strong
                >{{ $t('13_title_personas') }}
              </div>
            </div>
          </div>
        </div>
        <div class="grid-col grid-col--3">
          <div
            class="dashboard-card"
            :class="{ 'dashboard-card--loading': loadingStatistics }"
          >
            <div class="dashboard-card__data">
              <h2>{{ $t('13_title_stdc') }}</h2>
              <p>{{ $t('13_description_stdc') }}</p>

              <div
                v-if="stdcData"
                class="
                  dashboard-card__legend dashboard-card__legend--capitalize
                "
              >
                <ul>
                  <li
                    v-for="(legendItem, index) in stdcData.legend"
                    :key="index"
                  >
                    <span
                      class="percentage"
                      :style="{
                        'background-color':
                          stdcData.datasets[0].backgroundColor[index],
                      }"
                    >
                      {{ legendItem.percentage }}
                    </span>
                    <span class="name">
                      {{ legendItem.label }}
                    </span>
                    <span class="count">
                      {{ legendItem.count }}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="dashboard-card__graph">
              <DoughnutChart
                :width="200"
                :height="200"
                ref="skills_chart"
                :chart-data="stdcData"
                :options="options"
              >
              </DoughnutChart>

              <div v-if="stdcData" class="dashboard-card__graph-center">
                <strong>{{ stdcCount }}</strong
                >{{ $t('13_title_stdc') }}
              </div>
            </div>
          </div>
        </div>

        <div class="grid-col grid-col--3">
          <div
            class="dashboard-card"
            :class="{ 'dashboard-card--loading': loadingStatistics }"
          >
            <div class="dashboard-card__data">
              <h2>{{ $t('13_title_tags') }}</h2>
              <p>{{ $t('13_description_tags') }}</p>

              <div v-if="tagsData" class="dashboard-card__legend">
                <ul>
                  <li
                    v-for="(legendItem, index) in tagsData.legend"
                    :key="index"
                  >
                    <span
                      class="percentage"
                      :style="{
                        'background-color':
                          tagsData.datasets[0].backgroundColor[index],
                      }"
                    >
                      {{ legendItem.percentage }}
                    </span>
                    <span class="name">
                      {{ legendItem.label }}
                    </span>
                    <span class="count">
                      {{ legendItem.count }}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="dashboard-card__graph">
              <DoughnutChart
                :width="200"
                :height="200"
                ref="tags_chart"
                :chart-data="tagsData"
                :options="options"
              >
              </DoughnutChart>

              <div v-if="tagsData" class="dashboard-card__graph-center">
                <strong>{{ tagsCount }}</strong
                >{{ $t('13_title_tags') }}
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="
            this.statistic &&
            this.statistic.tasksPerPerson[0] &&
            this.statistic.tasksPerPerson[0]._id !== 'Disabled'
          "
          class="grid-col grid-col--3"
        >
          <div
            class="dashboard-card"
            :class="{ 'dashboard-card--loading': loadingStatistics }"
          >
            <div class="dashboard-card__data">
              <h2>{{ $t('13_title_tasks') }}</h2>
              <p>{{ $t('13_description_tasks') }}</p>

              <div v-if="usersData" class="dashboard-card__legend">
                <ul>
                  <li
                    v-for="(legendItem, index) in usersData.legend"
                    :key="index"
                  >
                    <span
                      class="percentage"
                      :style="{
                        'background-color':
                          usersData.datasets[0].backgroundColor[index],
                      }"
                    >
                      {{ legendItem.percentage }}
                    </span>
                    <span class="name">
                      {{ legendItem.label }}
                    </span>
                    <span class="count">
                      {{ legendItem.count }}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="dashboard-card__graph">
              <DoughnutChart
                :width="200"
                :height="200"
                ref="skills_chart"
                :chart-data="usersData"
                :options="options"
              >
              </DoughnutChart>

              <div v-if="usersData" class="dashboard-card__graph-center">
                <strong>{{ tasksSum }}</strong>
                {{ $t('13_title_tasks') }}
              </div>
            </div>
          </div>
        </div>

        <div class="grid-col grid-col--3">
          <div
            class="dashboard-card"
            :class="{ 'dashboard-card--loading': loadingStatistics }"
          >
            <div class="dashboard-card__data">
              <h2>{{ $t('13_title_review') }}</h2>
              <p>{{ $t('13_description_review') }}</p>

              <div
                v-if="reviewData"
                class="
                  dashboard-card__legend dashboard-card__legend--capitalize
                "
              >
                <ul>
                  <li
                    v-for="(legendItem, index) in reviewData.legend"
                    :key="index"
                  >
                    <span
                      class="percentage"
                      :style="{
                        'background-color':
                          reviewData.datasets[0].backgroundColor[index],
                      }"
                    >
                      {{ legendItem.percentage }}
                    </span>
                    <span class="name">
                      <star-rating
                        v-if="legendItem.value"
                        :star-size="20"
                        :increment="0.5"
                        :read-only="true"
                        v-model="legendItem.value"
                      ></star-rating>
                      <span v-else>{{ legendItem.label }}</span>
                    </span>
                    <span class="count">
                      {{ legendItem.count }}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="dashboard-card__graph">
              <DoughnutChart
                :width="200"
                :height="200"
                ref="skills_chart"
                :chart-data="reviewData"
                :options="options"
              >
              </DoughnutChart>

              <div v-if="reviewData" class="dashboard-card__graph-center">
                <strong>{{ reviewsCount }}</strong
                >{{ $t('13_title_review') }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="role !== 'READ_ONLY'" class="grid-col grid-col--3">
          <div class="dashboard-tips">
            <div class="dashboard-tips__background">
              <DashboardTips />
            </div>
            <div class="dashboard-tips__wrapper">
              <div class="dashboard-tips__data">
                <div class="dashboard-tips__title">
                  <h2>{{ $t('13_title_tips') }}</h2>
                </div>
                <div class="dashboard-tips__description">
                  <p>{{ $t('13_description_tips') }}</p>
                </div>
                <div class="dashboard-tips__control">
                  <button @click="addContent" class="menu__create__btn">
                    <IcoPlus /> {{ $t('5_1_menu_add_content') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="grid-col grid-col--3"></div>
      </div>
    </div>
  </div>
</template>

<script>
import STATISTIC from '@/graphql/Statistic.gql'

import { DateService } from '@/services/DateService.js'
import { ColorService } from '@/services/ColorService.js'
import { StateService } from '@/services/StateService.js'

import moment from 'moment'
import DashboardHeader from '@/components/DashboardHeader.vue'
import Menu from '@/components/Menu.vue'
import ButtonAddContent from '@/components/button/ButtonAddContent.vue'
import DoughnutChart from '@/components/dashboard/DoughnutChart.vue'
import IcoPlus from '@/components/svg/IcoPlus.vue'
import DashboardTips from '@/components/svg/DashboardTips.vue'
import { obj_clone } from '../services/helpers/clone'
import GlobalNotification from '@/components/GlobalNotification.vue'
import StarRating from 'vue-star-rating'
import LogoContesaurBlack from '@/components/svg/LogoContesaurBlack.vue'

const options = {
  with: '200px',
  height: '200px',
  cutoutPercentage: 70,
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    animateRotate: true,
  },
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,
    custom: function (tooltipModel) {
      // console.log('tooltipModel', tooltipModel)
      let tooltipEl = document.getElementById('chartjs-tooltip')

      // Create element on first render
      if (!tooltipEl) {
        tooltipEl = document.createElement('div')
        tooltipEl.id = 'chartjs-tooltip'
        document.body.appendChild(tooltipEl)
      }

      // Hide if no tooltip
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0
        return
      }

      // Set caret Position
      tooltipEl.classList.remove('above', 'below', 'no-transform')
      if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign)
      } else {
        tooltipEl.classList.add('no-transform')
      }

      function getBody(bodyItem) {
        return bodyItem.lines
      }

      // Set Text
      if (tooltipModel.body) {
        var bodyLines = tooltipModel.body.map(getBody)
        tooltipEl.innerHTML = `<div style="display: flex; flex-direction: row; align-items: center; background-color: #fff; border-radius: 8px; box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.05); padding: 4px; color: #888F91 !important">${bodyLines[0]}</div>`
      }

      var position = this._chart.canvas.getBoundingClientRect()

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1
      tooltipEl.style.zIndex = 99999
      tooltipEl.style.position = 'absolute'
      tooltipEl.style.left = position.left + tooltipModel.caretX / 2 + 'px'
      tooltipEl.style.top =
        position.top + window.pageYOffset + tooltipModel.caretY / 2 + 'px'
      tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily
      tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px'
      tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle
      tooltipEl.style.padding =
        tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px'
      tooltipEl.style.pointerEvents = 'none'
    },
  },
}

const colors = [
  '#2CE0C1',
  '#40D2F2',
  '#FFE500',
  '#9795FF',
  '#F45050',

  '#28CAAE',
  '#3ABDDA',
  '#E6CE00',
  '#8886E6',
  '#FF6A88',

  '#23B39A',
  '#33A8C2',
  '#CCB700',
  '#7977CC',

  '#1F9D87',
  '#2D93A9',
  '#B3A000',
  '#6A68B3',

  '#1A8674',
  '#267E91',
  '#998900',
  '#5B5999',

  '#167061',
  '#206979',
  '#807300',
  '#4C4B80',
]

export default {
  components: {
    LogoContesaurBlack,
    Menu,
    DoughnutChart,
    ButtonAddContent,
    IcoPlus,
    DashboardTips,
    DashboardHeader,
    GlobalNotification,
    StarRating,
  },
  apollo: {
    statistic: {
      query: STATISTIC,
      update: (data) => data.companies[0].statistics,
      variables() {
        return {
          from: DateService.dateToUTC(this.dateRangeFrom),
          to: DateService.dateToUTC(this.dateRangeTo),
          id: this.selectedCompany.id,
        }
      },
      skip() {
        return !this.selectedCompany
      },
    },
  },
  computed: {
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    codelist() {
      return this.$store.getters.getCodelist
    },
    dateRangeFrom() {
      return this.$store.getters.getDateRangeFrom
    },
    dateRangeTo() {
      return this.$store.getters.getDateRangeTo
    },
    formattedRange() {
      return this.$store.getters.getFormattedRange
    },
    formattedFullRange() {
      return this.$store.getters.getFullFormattedRange
    },
    diffDays() {
      return this.$store.getters.getDateRangeDiff
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
    loadingStatistics() {
      return this.$apollo.queries.statistic.loading
    },
    loadingData() {
      this.$apollo.loading
    },
  },
  data() {
    const d = this.$store.getters.getSelectedDate
    d.setDate(1)
    d.setHours(0, 0, 0, 0)

    return {
      statesSum: 0,
      tasksSum: 0,
      channelsCount: 0,
      channelsSum: 0,
      productsCount: 0,
      productsSum: 0,
      personsCount: 0,
      personsSum: 0,
      stdcCount: 0,
      stdcSum: 0,
      tagsCount: 0,
      tagsSum: 0,
      reviewsCount: 0,
      reviewsSum: 0,

      loaded: false,
      statesData: {
        tooltips: false,
        labels: [],
        datasets: [
          {
            backgroundColor: colors,
            borderWidth: 2,
            data: [],
          },
        ],
      },
      channelsData: {
        tooltips: false,
        labels: [],
        datasets: [
          {
            backgroundColor: colors,
            borderWidth: 2,
            data: [],
          },
        ],
      },
      tagsData: {
        tooltips: false,
        labels: [],
        datasets: [
          {
            backgroundColor: colors,
            borderWidth: 2,
            data: [],
          },
        ],
      },
      stdcData: {
        tooltips: false,
        labels: [],
        datasets: [
          {
            backgroundColor: colors,
            borderWidth: 2,
            data: [],
          },
        ],
      },
      productsData: {
        tooltips: false,
        labels: [],
        datasets: [
          {
            backgroundColor: colors,
            borderWidth: 2,
            data: [],
          },
        ],
      },
      personasData: {
        tooltips: false,
        labels: [],
        datasets: [
          {
            backgroundColor: colors,
            borderWidth: 2,
            data: [],
          },
        ],
      },
      usersData: {
        tooltips: false,
        labels: [],
        datasets: [
          {
            backgroundColor: colors,
            borderWidth: 2,
            data: [],
          },
        ],
      },
      taskData: {
        tooltips: false,
        labels: [],
        datasets: [
          {
            backgroundColor: colors,
            borderWidth: 2,
            data: [],
          },
        ],
      },
      reviewData: {
        tooltips: false,
        labels: [],
        datasets: [
          {
            backgroundColor: colors,
            borderWidth: 2,
            data: [],
          },
        ],
      },
      statistic: null,
      formattedCurrentDate: '',
      currentDate: d,
      colors: colors,
      options,
    }
  },
  mounted() {
    this.$apollo.queries.statistic.refetch()
    this.getFormattedCurrentDay(this.dateRangeFrom)
    this.initData()
  },
  created() {
    this.loaded = false
  },
  methods: {
    generateColors(items) {
      const colors = []
      items.forEach((item) => {
        colors.push(ColorService.computedColor(item.label))
      })
      return colors
    },
    selectToday() {
      let _dateFrom = new Date(new Date().setHours(0, 0, 0, 0))
      this.$store.commit('setDateRangeFrom', _dateFrom)

      const _dateTo = new Date(new Date().setHours(23, 59, 59, 0))
      this.$store.commit('setDateRangeTo', _dateTo)
    },
    datePrev() {
      this.loaded = false
      let days = this.diffDays * -1
      if (days === 0) {
        days = -1
      }
      this.changeDate(days)
    },
    dateNext() {
      let days = this.diffDays
      if (days === 0) {
        days = 1
      }
      this.changeDate(days)
    },
    changeDate(day) {
      if (
        DateService.checkIfDateRangeIsMonth(
          this.dateRangeFrom,
          this.dateRangeTo
        )
      ) {
        let duration = DateService.getMonthsDuration(
          this.dateRangeFrom,
          this.dateRangeTo
        )

        if (day <= 0) {
          duration *= -1
        }

        const _dateFrom = DateService.getMonthShiftStartDate(
          this.dateRangeFrom,
          duration
        )
        const _dateTo = DateService.getMonthShiftEndDate(
          this.dateRangeTo,
          duration
        )

        this.$store.commit('setDateRangeFrom', _dateFrom)
        this.$store.commit('setDate', _dateFrom)
        this.$store.commit('setDateRangeTo', _dateTo)

        return
      }

      this.loaded = false
      const _dateFrom = new Date(this.dateRangeFrom)
      _dateFrom.setDate(_dateFrom.getDate() + day)
      this.$store.commit('setDateRangeFrom', _dateFrom)

      const _dateTo = new Date(this.dateRangeTo)
      _dateTo.setDate(_dateTo.getDate() + day)
      _dateTo.setHours(23, 59, 59, 0)
      this.$store.commit('setDateRangeTo', _dateTo)
    },
    getFormattedCurrentDay(selectedDate) {
      this.formattedCurrentDate = moment(selectedDate).format('MMMM YYYY')
    },
    addContent() {
      this.$store.commit('setCreateContentDefaultValue', {})
      this.$store.commit('setCreateContentModalStatus', true)
    },
    initData() {
      if (this.statistic === null) {
        return
      }

      this.statesData = {
        tooltips: false,
        labels: [],
        datasets: [
          {
            backgroundColor: colors,
            borderWidth: 2,
            data: [],
          },
        ],
      }

      this.tagsData = {
        tooltips: false,
        labels: [],
        datasets: [
          {
            backgroundColor: colors,
            borderWidth: 2,
            data: [],
          },
        ],
      }

      this.stdcData = {
        tooltips: false,
        labels: [],
        datasets: [
          {
            backgroundColor: colors,
            borderWidth: 2,
            data: [],
          },
        ],
      }

      this.productsData = {
        tooltips: false,
        labels: [],
        datasets: [
          {
            backgroundColor: colors,
            borderWidth: 2,
            data: [],
          },
        ],
      }

      this.personasData = {
        tooltips: false,
        labels: [],
        datasets: [
          {
            backgroundColor: colors,
            borderWidth: 2,
            data: [],
          },
        ],
      }

      this.usersData = {
        tooltips: false,
        labels: [],
        datasets: [
          {
            backgroundColor: colors,
            borderWidth: 2,
            data: [],
          },
        ],
      }

      this.taskData = {
        tooltips: false,
        labels: [],
        datasets: [
          {
            backgroundColor: colors,
            borderWidth: 2,
            data: [],
          },
        ],
      }

      this.reviewData = {
        tooltips: false,
        labels: [],
        datasets: [
          {
            backgroundColor: colors,
            borderWidth: 2,
            data: [],
          },
        ],
      }

      this.statesSum = 0
      this.tasksSum = 0
      this.channelsCount = 0
      this.channelsSum = 0
      this.productsCount = 0
      this.productsSum = 0
      this.personsCount = 0
      this.personsSum = 0
      this.stdcCount = 0
      this.stdcSum = 0
      this.tagsCount = 0
      this.tagsSum = 0
      this.reviewsCount = 0
      this.reviewsSum = 0

      let states = []
      let tags = []
      let stdc = []
      let users = []
      let channels = []
      let products = []
      let personas = []

      try {
        this.statistic.stateUsage.map((item) => {
          let filteredState = this.codelist.state.filter(
            (state) => state.id === item._id
          )

          if (filteredState.length > 0) {
            filteredState[0]['count'] = item.count
            states = [...states, ...filteredState]
          }

          this.statesSum += parseInt(item.count)
        })
      } catch (e) {
        console.log(e)
      }

      this.statesData = {
        tooltips: false,
        labels: states.map((item, index) => {
          return `<span class="label-percentage" style="background-color: ${
            colors[index]
          };">${this.round(
            (item.count / this.statesSum) * 100,
            1
          )}%</span><span class="label-name">${item.label.substring(
            0,
            24
          )}</span>${item.label.length > 24 ? '...' : ''}`
        }),
        legend: states.map((item) => {
          return {
            label: this.$t(item.value),
            percentage: `${this.round(
              (item.count / this.statesSum) * 100,
              1
            )}%`,
            count: item.count,
          }
        }),
        datasets: [
          {
            backgroundColor: colors,
            borderWidth: 2,
            data: states.map((item) => item.count),
          },
        ],
      }

      try {
        this.statistic.tagsUsage.map((item) => {
          let filteredTag = this.codelist.tags.filter(
            (tag) => tag.id === item._id
          )

          if (filteredTag.length > 0) {
            filteredTag[0]['count'] = item.count
            tags = [...tags, ...filteredTag]
          }

          if (item._id === null) {
            tags.push({
              label: this.$t('13_none'),
              count: item.count,
              value: item._id,
            })
          }

          this.tagsCount += 1
          this.tagsSum += parseInt(item.count)
        })
      } catch (e) {
        console.log(e)
      }

      this.tagsData = {
        tooltips: false,
        labels: tags.map((item, index) => {
          return `<span class="label-percentage" style="background-color: ${
            colors[index]
          };">${this.round(
            (item.count / this.tagsSum) * 100,
            1
          )}%</span><span class="label-name">${item.label.substring(
            0,
            24
          )}</span>${item.label.length > 24 ? '...' : ''}`
        }),
        legend: tags.map((item) => {
          return {
            label: `${item.label.substring(0, 24)}${
              item.label.length > 24 ? '...' : ''
            }`,
            percentage: `${this.round((item.count / this.tagsSum) * 100, 1)}%`,
            count: item.count,
          }
        }),
        datasets: [
          {
            backgroundColor: colors,
            borderWidth: 2,
            data: tags.map((item) => item.count),
          },
        ],
      }

      try {
        // Channels
        this.statistic.channelsUsage.map((item) => {
          let filtered = this.codelist.channels.filter(
            (channel) => channel.id === item._id
          )

          if (filtered.length > 0) {
            filtered[0]['count'] = item.count
            channels = [...channels, ...filtered]
          }

          if (item._id === null) {
            channels.push({
              label: this.$t('13_none'),
              count: item.count,
              value: item._id,
            })
          }

          this.channelsCount += 1
          this.channelsSum += parseInt(item.count)
        })
      } catch (e) {
        console.log(e)
      }

      this.channelsData = {
        labels: channels.map((item, index) => {
          return `<span class="label-percentage" style="background-color: ${
            colors[index]
          };">${this.round(
            (item.count / this.channelsSum) * 100,
            1
          )}%</span><span class="label-name">${item.label.substring(
            0,
            24
          )}</span>${item.label.length > 24 ? '...' : ''}`
        }),
        legend: channels.map((item) => {
          return {
            label: `${item.label.substring(0, 24)}${
              item.label.length > 24 ? '...' : ''
            }`,
            percentage: `${this.round(
              (item.count / this.channelsSum) * 100,
              1
            )}%`,
            count: item.count,
          }
        }),
        datasets: [
          {
            backgroundColor: colors,
            borderWidth: 2,
            data: channels.map((item) => item.count),
          },
        ],
      }

      try {
        // productsData
        this.statistic.productsUsage.map((item) => {
          let filtered = this.codelist.products.filter(
            (product) => product.id === item._id
          )

          if (filtered.length > 0) {
            filtered[0]['count'] = item.count
            products = [...products, ...filtered]
          }

          if (item._id === null) {
            products.push({
              label: this.$t('13_none'),
              count: item.count,
              value: item._id,
            })
          }

          this.productsCount += 1
          this.productsSum += parseInt(item.count)
        })
      } catch (e) {
        console.log(e)
      }

      this.productsData = {
        labels: products.map((item, index) => {
          return `<span class="label-percentage" style="background-color: ${
            colors[index]
          };">${this.round(
            (item.count / this.productsSum) * 100,
            1
          )}%</span><span class="label-name">${item.label.substring(
            0,
            24
          )}</span>${item.label.length > 24 ? '...' : ''}`
        }),
        legend: products.map((item) => {
          return {
            label: `${item.label.substring(0, 24)}${
              item.label.length > 24 ? '...' : ''
            }`,
            percentage: `${this.round(
              (item.count / this.productsSum) * 100,
              1
            )}%`,
            count: item.count,
          }
        }),
        datasets: [
          {
            backgroundColor: colors,
            borderWidth: 2,
            data: products.map((item) => item.count),
          },
        ],
      }

      // personas
      this.statistic.personasUsage.map((item) => {
        let filtered = this.codelist.personas.filter(
          (persona) => persona.id === item._id
        )

        if (filtered.length > 0) {
          filtered[0]['count'] = item.count
          personas = [...personas, ...filtered]
        }

        if (item._id === null) {
          personas.push({
            label: this.$t('13_none'),
            count: item.count,
            value: item._id,
          })
        }

        this.personsCount += 1
        this.personsSum += parseInt(item.count)
      })

      // personasData
      this.personasData = {
        labels: personas.map((item, index) => {
          return `<span class="label-percentage" style="background-color: ${
            colors[index]
          };">${this.round(
            (item.count / this.personsSum) * 100,
            1
          )}%</span><span class="label-name">${item.label.substring(
            0,
            24
          )}</span>${item.label.length > 24 ? '...' : ''}`
        }),
        legend: personas.map((item) => {
          return {
            label: `${item.label.substring(0, 24)}${
              item.label.length > 24 ? '...' : ''
            }`,
            percentage: `${this.round(
              (item.count / this.personsSum) * 100,
              1
            )}%`,
            count: item.count,
          }
        }),
        datasets: [
          {
            backgroundColor: colors,
            borderWidth: 2,
            data: personas.map((item) => item.count),
          },
        ],
      }

      // taskData
      this.statistic.tasksPerPerson.map((item) => {
        if (item._id === null) {
          const _item = obj_clone(item)
          _item.label = this.$t('13_none')
          users.push(_item)
        }

        let filtered = this.codelist.users.filter(
          (user) => user.id === item._id
        )

        if (filtered.length > 0) {
          filtered[0]['count'] = item.count
          users = [...users, ...filtered]
        }

        this.tasksSum += parseInt(item.count)
      })

      this.usersData = {
        labels: users.map((item, index) => {
          {
            return `<span class="label-percentage" style="background-color: ${
              colors[index]
            };">${this.round(
              (item.count / this.tasksSum) * 100,
              1
            )}%</span><span class="label-name">${item.label.substring(
              0,
              24
            )}</span>${item.label.length > 24 ? '...' : ''}`
          }
        }),
        legend: users.map((item) => {
          return {
            label: `${item.label.substring(0, 24)}${
              item.label.length > 24 ? '...' : ''
            }`,
            percentage: `${this.round((item.count / this.tasksSum) * 100, 1)}%`,
            count: item.count,
          }
        }),
        datasets: [
          {
            backgroundColor: colors,
            borderWidth: 2,
            data: users.map((item) => item.count),
          },
        ],
      }

      // stdcData
      this.statistic.stdcsUsage.map((item) => {
        let filtered = this.codelist.stdcs.filter(
          (stdc) => stdc.id === item._id
        )

        if (filtered.length > 0) {
          filtered[0]['count'] = item.count
          stdc = [...stdc, ...filtered]
        }

        if (item._id === null) {
          stdc.push({
            label: this.$t('13_none'),
            count: item.count,
            value: item._id,
          })
        }

        this.stdcCount += 1
        this.stdcSum += parseInt(item.count)
      })

      this.stdcData = {
        labels: stdc.map((item, index) => {
          return `<span class="label-percentage" style="background-color: ${
            colors[index]
          };">${this.round(
            (item.count / this.stdcSum) * 100,
            1
          )}%</span><span class="label-name">${item.label.substring(
            0,
            24
          )}</span>${item.label.length > 24 ? '...' : ''}`
        }),
        legend: stdc.map((item) => {
          return {
            label: `${item.label.substring(0, 24)}${
              item.label.length > 24 ? '...' : ''
            }`,
            percentage: `${this.round((item.count / this.stdcSum) * 100, 1)}%`,
            count: item.count,
          }
        }),
        datasets: [
          {
            backgroundColor: colors,
            borderWidth: 2,
            data: stdc.map((item) => item.count),
          },
        ],
      }

      // reviewData
      let reviews = []
      this.statistic.reviewsUsage.forEach((item) => {
        reviews.push({
          label: item._id === null ? this.$t('13_none') : item._id / 2,
          count: item.count,
          value: item._id,
        })

        this.reviewsCount += 1
        this.reviewsSum += parseInt(item.count)
      })
      // reviews.sort((a, b) => a.value - b.value)
      // console.log('this.statistic.reviewsUsage', this.statistic.reviewsUsage)

      this.reviewData = {
        labels: reviews.map((item, index) => {
          return `<span class="label-percentage" style="background-color: ${
            colors[index]
          };">${this.round(
            (item.count / this.reviewsSum) * 100,
            1
          )}%</span><span class="label-name">${item.id}</span>${
            item.label.length > 24 ? '...' : ''
          }`
        }),
        legend: reviews.map((item) => {
          return {
            label: `${item.label}`,
            percentage: `${this.round(
              (item.count / this.reviewsSum) * 100,
              1
            )}%`,
            count: item.count,
            value: parseInt(item.value) / 2,
          }
        }),
        datasets: [
          {
            backgroundColor: colors,
            borderWidth: 2,
            data: reviews.map((item) => item.count),
          },
        ],
      }

      // console.log('this.reviewData', this.reviewData)
    },
    reloadPage() {
      if (this.$route.name === 'Dashboard') {
        this.$apollo.queries.statistic.refetch()
        this.initData()
      }
    },
    round(value, precision) {
      let multiplier = Math.pow(10, precision || 0)
      return Math.round(value * multiplier) / multiplier
    },
  },
  watch: {
    dateRangeFrom() {
      this.getFormattedCurrentDay()
    },
    statistic() {
      this.loaded = true
      this.initData()
    },
    codelist: {
      deep: true,
      handler() {
        this.initData()
      },
    },
    loadingData() {
      if (this.loadingData === false) {
        this.loaded = true
        this.initData()
      }
    },
    '$route.params': 'reloadPage',
  },
}
</script>

<style>
.chart-container {
  width: 280px;
  height: 280px;
}

.dashboard-card__legend .vue-star-rating {
  display: inline-block !important;
  position: relative;
  top: 1px;
}

.dashboard-card__legend .vue-star-rating-rating-text {
  display: none;
}
</style>
