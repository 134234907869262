<template>
    <div class="idea-status-card-preloader"></div>
</template>

<script>

export default {
    
}

</script>

<style scoped>
    .idea-status-card-preloader{
        width: 100%;
        height: 58px;
        background: linear-gradient(270deg, #D1D4D4 0%, rgba(209, 212, 212, 0.5) 100%);
        background-size: 300% 300%;
        animation: gradient 1s ease infinite;
        mix-blend-mode: normal;
        border-radius: 8px;
        margin-top: 15px;
    }

    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
</style>