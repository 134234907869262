import axios from "axios";
import { URL } from "@/constants/URLConstant.js";

const instance = axios.create({
  baseURL: URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
  },
});

export default instance;