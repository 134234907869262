const colors = [
    '#2CE0C1',
    '#40D2F2',
    '#9795FF',
    '#F45050',
    '#28CAAE',
    '#3ABDDA',
    '#8886E6',
    '#FF6A88',

    '#1A8674',
    '#267E91',
    '#998900',
    '#5B5999',

    '#167061',
    '#206979',
    '#807300',
    '#4C4B80',

    '#1F9D87',
    '#2D93A9',
    '#B3A000',
    '#6A68B3',

    '#23B39A',
    '#33A8C2',
    '#CCB700',
    '#7977CC',

    '#4C4B80',
    '#F45050',
    '#40D2F2',
    '#9795FF',
    '#2CE0C1',
    
    '#28CAAE',
    '#3ABDDA',
    '#8886E6',    
    '#FF6A88',
    
    
    '#9795FF',
    '#2CE0C1',
    '#40D2F2',    
    '#F45050',

    '#CCB700',
    '#33A8C2',
    '#7977CC',
    '#23B39A',

    '#5B5999',
    '#206979',
    '#807300',
    '#167061',

    '#B3A000',
    '#6A68B3',
    '#1F9D87',
    '#2D93A9',
    
    '#1A8674',
    '#267E91',
    '#998900',
    

    '#CCB700',
    '#23B39A',
    '#33A8C2',
    '#7977CC',

    '#5B5999',
    '#998900',
    '#1A8674',
    '#267E91',

    '#6A68B3',
    '#1F9D87',
    '#B3A000',
    '#2D93A9',
    
    '#167061',
    '#206979',
    '#807300',
    '#4C4B80',

    '#28CAAE',    
    '#8886E6',
    '#3ABDDA',
    '#FF6A88',
]

const generateRandom = (number, maxLimit = 72) => {
    let rand = number * maxLimit;
    console.log(rand); // say 99.81321410836433
    rand = Math.floor(rand); // 99
    return rand;
}

const alphaVal = (s) => s.toLowerCase().charCodeAt(0)

function computedColor(str) {
    
    if( str === 'Nepřiřazeno' || str === 'None' ) {
        return "#E7E9E9"
    }

    if( str === 0 || str === null || str === 'Nepřiřazeno' ) {
        return "#2CE0C1"
    }

    if( str === 0.5 ) {
        return "#28CAAE"
    }

    if( str === 1 ) {
        return "#23B39A"
    }

    if( str === 1.5 ) {
        return "#1F9D87"
    }

    if( str === 2 ) {
        return "#1A8674"
    }

    if( str === 2.5 ) {
        return "#167061"
    }

    if( str === 3 ) {
        return "#40D2F2"
    }

    if( str === 3.5 ) {
        return "#3ABDDA"
    }

    if( str === 4 ) {
        return "#33A8C2"
    }

    if( str === 4.5 ) {
        return "#2D93A9"
    }

    if( str === 5 ) {
        return "#267E91"
    }


    if( str === "SEE" ) {
        return "#2CE0C1"
    }

    if( str === "THINK" ) {
        return "#40D2F2"
    }

    if( str === "DO" ) {
        return "#FFE500"
    }

    if( str === "CARE" ) {
        return "#9795FF"
    }

    if (typeof str === 'string' || str instanceof String) {
        if( str.toLowerCase() === "facebook" ) {
            return "#006BE3";
        }
    }    

    var hash = 0;
    var value = 0;

    if (typeof str === 'string' || str instanceof String) {
        for (var i = 0; i < str.length; i++) {
            value += alphaVal(str[i]);
        }
    } else {
        value = 12345 + (10 * str)
    }

    hash = Math.abs(value)
    hash = hash % 72
    
    return colors[hash]
}

export const ColorService = {
    computedColor
};