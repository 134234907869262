export default {
  '1_1_title': 'Pojďme tě přihlásit',
  '1_1_description': 'Vítej v dinosauřím obsahosvětě!',
  '1_1_label_email': 'E-mail',
  '1_1_label_password': 'Heslo',
  '1_1_checkbox_remember_me': 'Neodhlašovat',
  '1_1_link_forgot_password': 'Zapomenuté heslo',
  '1_1_btn_login': 'Přihlásit se',
  '1_1_or': 'nebo',
  '1_1_btn_google_login': 'Přihlásit přes Google',
  '1_1_no_account': 'Ještě nemáš účet?',
  '1_1_sign_up': 'Nová registrace',
  '1_2_invalid_login_title': 'Přihlášení se nepovedlo',
  '1_2_invalid_login_text': 'Zkontroluj prosím své přihlašovací údaje.',
  '1_2_ok_google_complete_title': 'Dokonči nastavení profilu',
  '1_2_ok_google_complete_text':
    'Přihlašuješ se pomocí účtu Google. Dokonči nastavení profilu vytvořením hesla.',
  '2_1_title': 'Začínáme',
  '2_1_description': 'Vytvoř si účet a začni plánovat skvělý obsah!',
  '2_1_label_name': 'Tvé jméno',
  '2_1_label_email': 'E-mail',
  '2_1_label_password': 'Heslo',
  '2_1_label_confirm_password': 'Potvrď heslo',
  '2_1_btn_register': 'Registrovat',
  '2_1_or': 'nebo',
  '2_1_btn_google_login': 'Přihlásit přes Google',
  '2_1_have_account': 'Už máš účet?',
  '2_1_btn_sign_in': 'Přihlas se',
  '2_1_checkbox_terms_line_1': 'Vytvořením účtu souhlasíš s těmito dokumenty:',
  '2_1_link_terms_condition': 'Obchodní podmínky',
  '2_1_and': 'a',
  '2_1_link_privacy_policy': 'Zásady ochrany osobních údajů',
  '2_2_invalid_fields_title': 'Neplatné údaje',
  '2_2_invalid_fields_description': 'Zkontroluj prosím své údaje.',
  '3_1_go_back': 'Zpět na Přihlášení',
  '3_1_title': 'Nepamatuješ si heslo?',
  '3_1_description':
    'Zadej svůj e-mail, na který ti pošleme odkaz pro vytvoření nového hesla.',
  '3_1_label_email': 'Registrační e-mail',
  '3_1_input_email_placeholder': 'Začni psát...',
  '3_1_btn_recover': 'Obnovit heslo',
  '3_1_trouble_recover': 'Máš potíže s obnovením hesla?',
  '3_1_link_contact_us': 'Kontaktujte nás',
  '3_2_go_back': 'Zpět na Přihlášení',
  '3_2_title': 'Zkontroluj svůj e-mail',
  '3_2_description':
    'Na tvůj e-mail jsme zaslali pokyny k obnovení hesla. Zkontroluj příchozí poštu a klikni na link, který ti od nás přišel.',
  '3_2_trouble_recover': 'Máš potíže s obnovením hesla?',
  '3_2_link_contact_us': 'Kontaktuj nás',
  '3_3_title': 'Vytvoř si nové heslo',
  '3_3_description':
    'Zadej prosím unikátní heslo, které si zapamatuješ. Dinosauří tuší, že recyklace se v bezpečnosti nevyplácí.',
  '3_3_label_new_password': 'Nové heslo',
  '3_3_label_confirm_password': 'Zopakuj heslo',
  '3_3_btn_reset_password': 'Uložit nové heslo',
  '3_3_link_contact_us': 'Kontaktuj nás',
  '3_3_trouble_recover': 'Máš potíže s obnovením hesla?',
  '3_3_1_modal_title': 'Kontaktuj nás',
  '3_3_1_modal_description':
    'Něco nefunguje? Došly nápady? Dej nám vědět, pokud narazíš na nějaké potíže. Nebo nás můžeš jen pochválit – i&nbsp;to rádi slyšíme.',
  '3_3_1_label_name': 'Tvé jméno',
  '3_3_1_label_email': 'E-mail',
  '3_3_1_label_message': 'Tvá zpráva',
  '3_3_1_placeholder_email': 'superadresa@.cz',
  '3_3_1_placeholder_name': 'Jason Bourne',
  '3_3_1_placeholder_message': 'S čím ti pomůžeme?',
  '3_3_1_button_send': 'Odeslat',
  '4_1_title': 'Vytvoř novou společnost...',
  '4_1_description':
    'Společnost, projekt, klient…říkejte tomu, jak jste zvyklí. Je to prostředí, ve kterém tvoříte specifický obsah. Chcete založit nové nebo se k nějakému přidat?',
  '4_1_btn_create': 'Vytvořit společnost',
  '4_1_or': 'nebo',
  '4_1_btn_join': 'Připojit se k vybrané společnosti',
  '4_2_title': 'Vytvoř svou první společnost',
  '4_2_description':
    'Wow, zakládáš nový projekt! Chceš plánovat obsah pro sebe, svou firmu nebo nového klienta?',
  '4_2_label_name': 'Název',
  '4_2_placeholder_name': 'Úžasný název společnosti',
  '4_2_label_description': 'Popis',
  '4_2_placeholder_description':
    'Nejlepšejší firma, která přežije každý meteorit.',
  '4_2_btn_create': 'Vytvořit společnost',
  '4_2_already_have_company': 'Už máš vytvořenou společnost?',
  '4_2_link_join_company': 'Připojit se k vybrané společnosti',
  '4_2_toaster_success_title_company_create': 'Společnost vytvořena',
  '4_2_toaster_success_description_company_create':
    'Podařilo se ti vytvořit novou společnost.',
  '4_2_toaster_error_title_company_create': 'Chyba',
  '4_2_toaster_error_description_company_create':
    'Tuto společnost nemůžeš vytvořit.',
  '4_3_title': 'Připoj se ke své první společnosti',
  '4_3_description':
    'Společnost, projekt, klient…říkej tomu, jak chceš. Je to prostředí, ve kterém tvoříš specifický obsah. Chceš založit nové nebo se k nějakému přidat?',
  '4_3_label_company_id': 'ID společnosti',
  '4_3_link_get_company_id': 'Kde najdu ID společnosti?',
  '4_3_link_join_company': 'Připoj se k existující společnosti',
  '4_3_no_company': 'Ještě nemáš vytvořenou společnosti?',
  '4_3_link_create_company': 'Tak ji vytvoř',
  '5_1_menu_calendar': 'Kalendář',
  '5_1_menu_company': 'Společnost',
  '5_1_menu_ideas': 'Nápady',
  '5_1_menu_add_content': 'Přidat obsah',
  '5_2_new_company': 'Vytvořit novou / přidat se ke společnosti',
  '5_2_select_company': 'Vybrat společnost',
  '5_2_companies_count': 'Společnosti',
  '5_2_placeholder_search': 'Hledat',
  '5_2_no_result_p1': 'Tuto společnost jsme nenašli.',
  '5_2_no_result_p2': 'Zkus jiný název nebo vytvoř novou společnost.',
  '5_2_btn_show_all': 'Zobrazit všechny společnosti',
  '5_2_btn_new_company': 'Vytvořit/Přidat novou společnost',
  '5_2_link_profile': 'Můj profil',
  '5_2_link_billing': 'Platby',
  '5_2_app_language': 'Jazyk aplikace',
  '5_2_link_logout': 'Odhlásit se',
  '5_3_1_modal_title': 'Opravdu se chceš odhlásit?',
  '5_3_1_logout': 'Odhlásit se',
  '5_3_1_back': 'Zpět do práce',
  '5_3_2_lang_english': 'Angličtina',
  '5_3_2_lang_czech': 'Čeština',
  '6_1_title': 'Vytvořit/Přidat novou společnost',
  '6_1_create_subtitle': 'Vytvoř novou společnost...',
  '6_1_create_description':
    'Wow, zakládáš svůj první projekt! Je to nový klient, trh k expanzi, nebo obchodní příležitost?',
  '6_1_label_name': 'Název',
  '6_1_placeholder_name': 'Úžasný název společnosti',
  '6_1_label_description': 'Popis',
  '6_1_placeholder_description':
    'Nejlepšejší firma, která přežije každý meteorit.',
  '6_1_btn_create_company': 'Vytvoř novou společnost',
  '6_1_subtitle_join': '... nebo se k nějaké připoj',
  '6_1_description_join':
    'Super, už na tebe čeká nějaký projekt! Nenech svůj tým na ocet a připoj se.',
  '6_1_label_company_id': 'ID společnosti',
  '6_1_link_get_company_id': 'Kde najdu ID společnosti?',
  '6_1_btn_join': 'Připoj se k existující společnosti',
  '6_2_title': 'Všechny společnosti',
  '6_2_company_no': 'Počet společností',
  '6_3_subtitle_info': 'Informace o společnosti',
  '6_3_label_name': 'Název',
  '6_3_placeholder_name': 'Úžasný název společnosti',
  '6_3_label_description': 'Popis',
  '6_3_placeholder_description':
    'Nejlepšejší firma, která přežije každý meteorit.',
  '6_3_btn_save': 'Uložit změny',
  '6_3_subtitle_team': 'Lidé',
  '6_3_invite_title': 'Pozvi další členy do týmu',
  '6_3_invite_description':
    'Pošli kolegům e-mail s žádostí o připojení ke tvé společnosti.',
  '6_3_company_id': 'ID společnosti',
  '6_3_btn_delete': 'Smazat společnost',
  '6_3_label_email': 'E-mail',
  '6_3_toaster_title_copied_to_clipboard': 'Zkopírováno',
  '6_3_toaster_description_copied_to_clipboard':
    'Zkopírovali jste ID společnosti.',
  '6_3_toaster_error_title_max_users': 'Chyba',
  '6_3_toaster_error_description_max_users':
    'Můžeš zadat maximálně 10 e-mailů.',
  '6_3_1_tooltip_email':
    'Lidé, které přidáš, automaticky dostanou pozvánku. Zadej až 10 e-mailových adres a odděl je čárkou.',
  '6_3_1_placeholder_email': 'Za každý e-mail napiš čárku',
  '6_3_1_btn_send_email_join_request': 'Odeslat pozvánku e-mailem',
  '6_3_1_toaster_success_title_user_deleted': 'Uživatel odebrán',
  '6_3_1_toaster_success_description_user_deleted':
    'Podařilo se ti odebrat uživatele.',
  '6_3_2_toaster_error_title_user_delete': 'Chyba',
  '6_3_2_toaster_error_description_user_delete':
    'Tohoto uživatele nemůžeš odebrat.',
  '6_3_1_toaster_success_title_company_updated': 'Aktualizováno',
  '6_3_1_toaster_success_description_company_updated':
    'Podařilo se ti aktualizovat společnost.',
  '6_3_2_title': 'Opravdu chceš smazat',
  '6_3_2_description':
    'Tato akce smaže všechen obsah a odstraní všechny uživatele.',
  '6_3_2_content_no': 'POČET NAPLÁNOVANÉHO OBSAHU',
  '6_3_2_users_no': 'POČET UŽIVATELŮ',
  '6_3_2_btn_delete': 'Smazat',
  '6_3_2_btn_cancel': 'Zpět',
  '6_3_2_toaster_success_title_company_deleted': 'Smazána',
  '6_3_2_toaster_success_description_company_deleted':
    'Podařilo se ti smazat společnost.',
  '6_3_2_toaster_error_title_company_delete': 'Chyba',
  '6_3_2_toaster_error_description_company_delete':
    'Tuto společnost nemůžeš smazat.',
  '6_3_2_toaster_success_title_company_create': 'Vytvořena',
  '6_3_2_toaster_success_description_company_create':
    'Podařilo se ti vytvořit novou společnost.',
  '6_3_2_toaster_error_title_company_create': 'Chyba',
  '6_3_2_toaster_error_description_company_create':
    'Tuto společnost nemůžeš vytvořit.',
  '6_3_2_toaster_success_title_company_join': 'Úspěch',
  '6_3_2_toaster_success_description_company_join':
    'Podařilo se ti připojit ke společnosti.',
  '6_3_2_toaster_error_title_company_join': 'Chyba',
  '7_1_title': 'Můj profil',
  '7_1_subtitle_personal': 'Osobní údaje',
  '7_1_label_name': 'Tvé jméno',
  '7_1_placeholder_name': 'Zadej své jméno',
  '7_1_label_job': 'Pracovní pozice',
  '7_1_description_job': 'Zadej název své pracovní pozice',
  '7_1_current_email_title': 'Registrovaný e-mail',
  '7_1_current_email_info': 'Účet máš založený na e-mailovou adresu',
  '7_1_label_new_email': 'Nová e-mailová adresa',
  '7_1_linked_accounts': 'Propojené účty',
  '7_1_disconnect_linked_account': 'Odpojit',
  '7_1_connect_linked_account': 'Spojit',
  '7_1_btn_save_personal': 'Uložit změny',
  '7_1_subtitle_security': 'Bezpečnost',
  '7_1_label_current_password': 'Aktuální heslo',
  '7_1_link_forgot_password': 'Zapomenuté heslo',
  '7_1_label_new_password': 'Nové heslo',
  '7_1_label_confirm_password': 'Zopakuj heslo',
  '7_1_password_strength_weak': 'Vymysli nějaké bezpečnější heslo.',
  '7_1_password_strength_average': 'To je velice průměrné heslo.',
  '7_1_password_strength_strong': 'Tomu říkáme supersilné heslo!',
  '7_1_btn_save_security': 'Uložit změny',
  '7_1_btn_delete_account': 'Smazat účet',
  '7_1_1_title': 'Opravdu chceš smazat svůj účet?',
  '7_1_1_description':
    'Tato akce nadobro odstraní tvůj účet a ztratíš přístup ke všem společnostem i obsahu. A navíc ti dinosauři zamávají na rozloučenou.',
  '7_1_1_company_no': 'POČET SPOLEČNOSTÍ, VE KTERÝCH JSI',
  '7_1_1_cards_no': 'POČET ROZPRACOVANÝCH KARET',
  '7_1_1_label_password': 'Heslo',
  '7_1_1_link_forgot_password': 'Zapomenuté heslo',
  '7_1_1_btn_delete': 'Smazat',
  '7_1_1_btn_back': 'Zpět',
  '7_1_2_title': 'Nepamatuješ si heslo?',
  '7_1_2_description':
    'Zadej svůj e-mail, na který ti pošleme odkaz pro vytvoření nového hesla.',
  '7_1_2_btn_recover_email': 'Obnovit heslo',
  '7_1_2_info_trouble': 'Máš potíže s obnovením hesla?',
  '7_1_2_link_contact_us': 'Kontaktuj nás',
  '7_1_3_title': 'Zkontroluj svůj e-mail',
  '7_1_3_description':
    'Na e-mail jsme ti zaslali pokyny k obnovení hesla. Otevři zprávu od nás a klikni na odkaz, který v ní najdeš.',
  '7_1_3_info_trouble': 'Máš potíže s obnovením hesla?',
  '7_1_3_link_contact_us': 'Kontaktuj nás',
  '8_1_drop_zone': 'PŘESUNOUT SEM',
  '8_1_drop_contents': 'Obsah',
  '8_1_idea_btn_share': 'Sdílet',
  '8_1_idea_btn_duplicate': 'Duplikovat',
  '8_1_idea_btn_delete': 'Smazat',
  '8_1_1_all_ideas': 'Všechny nápady',
  '8_2_drop_area': 'PŘESUNOUT SEM',
  '8_2_contents': 'Obsah',
  '8_2_content_btn_share': 'Sdílet',
  '8_2_content_btn_duplicate': 'Duplikovat',
  '8_2_content_btn_delete': 'Smazat',
  '8_2_add_content': 'Přidat obsah',
  '8_3_drop_area': 'PŘESUNOUT SEM',
  '8_3_contents': 'Obsah',
  '8_3_content_btn_share': 'Sdílet',
  '8_3_content_btn_duplicate': 'Duplikovat',
  '8_3_content_btn_delete': 'Smazat',
  '8_3_add_content': 'Přidat obsah',
  '8_3_label_product': 'Produkt',
  '8_3_label_persona': 'Persona',
  '8_3_label_stdc': 'STDC',
  '8_4_control_expand': 'Rozbalit vše',
  '8_4_control_collapse': 'Sbalit vše',
  '8_4_drop_area': 'PŘESUNOUT SEM',
  '8_4_add_content': 'Přidat obsah',
  '8_4_contents': 'Obsah',
  '8_4_content_btn_share': 'Sdílet',
  '8_4_content_btn_duplicate': 'Duplikovat',
  '8_4_content_btn_delete': 'Smazat',
  '8_5_note_month':
    'Poznámka: <strong>Měsíční zobrazení</strong> ukáže v kalendáři měsíční rozsah od vybraného dne.',
  '8_5_note_week':
    'Poznámka: <strong>Týdenní zobrazení</strong> ukáže v kalendáři týdenní rozsah od vybraného dne.',
  '8_5_note_day':
    'Poznámka: <strong>Denní zobrazení</strong> ukáže vybraný den.',
  '8_5_btn_today': 'Dnes',
  '8_5_btn_this_week': 'Tento týden',
  '8_5_btn_last_week': 'Minulý týden',
  '8_5_btn_this_month': 'Tento měsíc',
  '8_5_btn_last_month': 'Minulý měsíc',
  '8_5_btn_this_year': 'Tento rok',
  '8_5_btn_last_year': 'Minulý rok',
  '8_5_btn_apply': 'Použít',
  '8_5_btn_me': 'Já',
  '8_5_tooltip_me': 'Zobrazit obsah, který vlastním, nebo kde mám zadaný úkol.',
  '8_5_btn_all': 'Vše',
  '8_5_tooltip_all': 'Zobrazit všechny karty.',
  '8_5_tooltip_ideas_show': 'Zobrazit karty nápadů.',
  '8_5_tooltip_ideas_hide': 'Skrýt karty nápadů.',
  '8_5_btn_ideas': 'Nápady',
  '8_5_btn_month': 'Měsíc',
  '8_5_btn_week': 'Týden',
  '8_5_btn_day': 'Den',
  '8_5_btn_list': 'Seznam',
  '8_5_name_filer': 'Filtr',
  '8_5_active_filters': 'AKTIVNÍ FILTRY',
  '8_5_clear_filters': 'Smazat filtry',
  '8_5_add_filter': 'Přidat filtr',
  '8_5_placeholder_search': 'Hledat',
  '8_5_filter_item_name': 'Název',
  '8_5_filter_item_state': 'Stav',
  '8_5_filter_item_channels': 'Kanál',
  '8_5_filter_item_users': 'Vlastník',
  '8_5_filter_item_tags': 'Štítek',
  '8_5_filter_item_products': 'Produkt',
  '8_5_filter_item_personas': 'Persona',
  '8_5_filter_item_stdcs': 'STDC',
  '8_5_group_by_item_state': 'Stav',
  '8_5_group_by_item_owner': 'Vlastník',
  '8_5_group_by_item_channel': 'Kanál',
  '8_5_group_by_item_date': 'Datum publikace',
  '8_5_group_by_item_tags': 'Štítek',
  '8_5_group_by_item_products': 'Produkt',
  '8_5_group_by_item_personas': 'Persona',
  '8_5_group_by_item_stdc': 'STDC',
  '8_5_group_by_title': 'Seskupit podle',
  '8_5_group_by_tooltip': 'Seskupit nápady podle',
  '8_5_group_by_default': 'výchozí',
  '9_1_title': 'Vytvořit obsah',
  '9_1_label_name': 'Název',
  '9_1_placeholder_name': 'Zadej název obsahu',
  '9_1_label_channel': 'Kanál',
  '9_1_placeholder_channel': 'Vyber kanál',
  '9_1_label_description': 'Popis',
  '9_1_placeholder_description':
    'V rychlosti popiš zadání pro tvůrce obsahu (copywritera).',
  '9_1_resources': 'Odkazy na zdroje',
  '9_1_details': 'Podrobnosti',
  '9_1_label_keywords': 'Klíčová slova',
  '9_1_placeholder_keywords': 'Zadej klíčová slova',
  '9_1_label_tags': 'Štítek (max. 5)',
  '9_1_name_tag': 'Štítek',
  '9_1_placeholder_tags': 'Vyber štítky',
  '9_1_label_product': 'Produkt (max. 5)',
  '9_1_name_product': 'Produkt',
  '9_1_placeholder_product': 'Vyber produkty',
  '9_1_label_persona': 'Persona (max. 5)',
  '9_1_name_persona': 'Persona',
  '9_1_placeholder_persona': 'Vyber persony',
  '9_1_label_stdc': 'STDC',
  '9_1_name_stdc': 'STDC',
  '9_1_placeholder_stdc': 'Vyber STDC fázi',
  '9_1_content': 'Obsah',
  '9_1_label_final_text': 'Finální text',
  '9_1_placeholder_final_text':
    'Zadej finální text (např. příspěvek na sociální sítě nebo blog).',
  '9_1_content_links': 'Odkazy na obsah',
  '9_1_content_review': 'Vyhodnocení obsahu',
  '9_1_label_review_text': 'Psané hodnocení',
  '9_1_placeholder_review_text': 'Jak si tento obsah vedl? Jaký měl výkon?',
  '9_1_check_duplicate': 'Chci vytvořit kopii po uložení.',
  '9_1_btn_save': 'Uložit obsah',
  '9_1_btn_cancel': 'Zrušit',
  '9_1_toaster_error_title_create': 'Vyplň povinná pole',
  '9_2_select_company': 'Vybrat společnost',
  '9_2_select_user': 'Vybrat uživatele',
  '9_2_back': 'Zpět',
  '9_2_edit': 'Upravit',
  '9_2_search': 'Hledat',
  '9_2_link_edit_mode': 'Režimu úprav',
  '9_2_search_no_result_else': 'Zkus jiný název nebo vytvoř nový štítek.',
  '9_2_placeholder_new_tag': 'Nový štítek',
  '10_1_btn_name_save': 'Uložit',
  '10_1_btn_name_cancel': 'Zrušit',
  '10_1_title_resources': 'Zdroje',
  '10_1_title_content': 'Obsah',
  '10_1_title_final_text': 'Finální text',
  '10_1_btn_add_text': 'Přidat text',
  '10_1_btn_final_text_save': 'Uložit',
  '10_1_btn_final_text_cancel': 'Zrušit',
  '10_1_title_content_links': 'Odkazy na obsah',
  '10_1_title_checklist': 'Seznam úkolů',
  '10_1_title_comments': 'Komentáře',
  '10_1_name_channel': 'Kanál',
  '10_1_placeholder_channel': 'Vybrat kanál',
  '10_1_title_details': 'Podrobnosti',
  '10_1_label_keywords': 'Klíčová slova',
  '10_1_placeholder_keyword': 'Zadej klíčová slova',
  '10_1_label_product': 'Produkt',
  '10_1_name_product': 'Produkt',
  '10_1_placeholder_product': 'Vyber produkt',
  '10_1_label_persona': 'Persona',
  '10_1_name_persona': 'Persona',
  '10_1_label_stdc': 'STDC',
  '10_1_name_stdc': 'STDC',
  '10_1_placeholder_stdc': 'Vyber fázi STDC',
  '10_1_label_tag': 'Štítek',
  '10_1_name_tag': 'Štítek',
  '10_1_placeholder_tag': 'Vyber štítek',
  '10_3_btn_share': 'Sdílet',
  '10_3_btn_duplicate': 'Duplikovat',
  '10_3_btn_delete': 'Smazat',
  '10_1_resource_save': 'Uložit',
  '10_1_resource_cancel': 'Zrušit',
  '10_1_resource_add': 'Přidat zdroj',
  '10_1_toaster_success_title_resources_updated': 'Aktualizováno',
  '10_1_toaster_success_description_resources_updated':
    'Podařilo se ti aktualizovat seznam zdrojů.',
  '10_1_btn_create_comment': 'Přidat komentář...',
  '10_1_btn_comment_save': 'Uložit',
  '10_1_btn_comment_cancel': 'Zrušit',
  '10_1_btn_checklist_add': 'Přidat položku',
  '10_1_toaster_success_title_comment_deleted': 'Smazáno',
  '10_1_toaster_success_description_comment_deleted':
    'Podařilo se ti přidat komentář.',
  '10_1_toaster_success_title_comment_updated': 'Aktualizováno',
  '10_1_toaster_success_description_comment_updated':
    'Podařilo se ti aktualizovat komentář.',
  '10_1_toaster_success_title_comment_create': 'Přidáno',
  '10_1_toaster_success_description_comment_create':
    'Podařilo se ti přidat komentář.',
  '9_2_toaster_success_title_tag_deleted': 'Smazáno',
  '9_2_toaster_success_description_tag_deleted':
    'Podařilo se ti smazat štítek.',
  '9_2_toaster_error_title_tag_delete': 'Chyba',
  '9_2_toaster_error_description_tag_delete': 'Tento štítek nemůžeš smazat.',
  '9_2_toaster_success_title_tag_created': 'Vytvořeno',
  '9_2_toaster_success_description_tag_created':
    'Podařilo se ti vytvořit štítek.',
  '9_2_toaster_error_title_tag_create': 'Chyba',
  '9_2_toaster_error_description_tag_create': 'Tento štítek nemůžeš vytvořit.',
  '9_2_toaster_success_title_tag_updated': 'Aktualizováno',
  '9_2_toaster_success_description_tag_updated':
    'Podařilo se ti aktualizovat štítek.',
  '9_2_toaster_error_title_tag_updated': 'Chyba',
  '9_2_toaster_error_description_tag_updated':
    'Tento štítek nemůžeš aktualizovat.',
  '9_2_toaster_success_title_product_updated': 'Aktualizováno',
  '9_2_toaster_success_description_product_updated':
    'Podařilo se ti aktualizovat produkt.',
  '9_2_toaster_error_title_product_updated': 'Chyba',
  '9_2_toaster_error_description_product_updated':
    'Tento produkt nemůžeš aktualizovat.',
  '9_2_toaster_success_title_product_deleted': 'Smazáno',
  '9_2_toaster_success_description_product_deleted':
    'Podařilo se ti smazat produkt.',
  '9_2_toaster_error_title_product_delete': 'Chyba',
  '9_2_toaster_error_description_product_delete':
    'Tento produkt nemůžeš smazat.',
  '9_2_toaster_success_title_product_created': 'Vytvořeno',
  '9_2_toaster_success_description_product_created':
    'Podařilo se ti vytvořit produkt.',
  '9_2_toaster_error_title_product_create': 'Chyba',
  '9_2_toaster_error_description_product_create':
    'Tento produkt nemůžeš vytvořit.',
  '9_2_toaster_success_title_persona_created': 'Vytvořeno',
  '9_2_toaster_success_description_persona_created':
    'Podařilo se ti vytvořit personu.',
  '9_2_toaster_error_title_persona_create': 'Chyba',
  '9_2_toaster_error_description_persona_create':
    'Tuto personu nemůžeš vytvořit.',
  '9_2_toaster_success_title_persona_deleted': 'Smazáno',
  '9_2_toaster_success_description_persona_deleted':
    'Podařilo se ti smazat personu.',
  '9_2_toaster_error_title_persona_delete': 'Chyba',
  '9_2_toaster_error_description_persona_delete':
    'Tuto personu nemůžeš smazat.',
  '9_2_toaster_success_title_persona_updated': 'Aktualizováno',
  '9_2_toaster_success_description_persona_updated':
    'Podařilo se ti aktualizovat personu.',
  '9_2_toaster_error_title_persona_updated': 'Chyba',
  '9_2_toaster_error_description_persona_updated':
    'Tuto personu nemůžeš aktualizovat.',
  '9_2_toaster_success_title_channel_created': 'Vytvořeno',
  '9_2_toaster_success_description_channel_created':
    'Podařilo se ti vytvořit kanál.',
  '9_2_toaster_error_title_channel_create': 'Chyba',
  '9_2_toaster_error_description_channel_create':
    'Tento kanál nemůžeš vytvořit.',
  '9_2_toaster_success_title_channel_deleted': 'Smazáno',
  '9_2_toaster_success_description_channel_deleted':
    'Podařilo se ti smazat kanál.',
  '9_2_toaster_error_title_channel_delete': 'Chyba',
  '9_2_toaster_error_description_channel_delete': 'Tento kanál nemůžeš smazat.',
  '9_2_toaster_success_title_channel_updated': 'Aktualizován',
  '9_2_toaster_success_description_channel_updated':
    'Podařilo se ti aktualizovat kanál.',
  '9_2_toaster_error_title_channel_updated': 'Chyba',
  '9_2_toaster_error_description_channel_updated':
    'Tento kanál nemůžeš aktualizovat.',
  '9_1_publish_date': 'Datum publikace',
  '9_1_publish_time': 'Čas publikace',
  '9_1_no_persons': 'Žádní uživatelé',
  '9_1_label_owner': 'Vlastník',
  '10_1_checklist_item_btn_save': 'Uložit',
  '10_1_checklist_item_btn_cancel': 'Zrušit',
  '10_1_checklist_item_btn_select_date': 'Termín',
  '10_1_title_description': 'Popis',
  '10_1_btn_checklist_assign': 'Přiřadit',
  '10_1_btn_edit': 'Upravit',
  '10_1_btn_delete': 'Smazat',
  '10_1_btn_save': 'Uložit',
  '10_1_view_more_comments': 'Zobrazit další komentáře',
  '6_3_placeholder_search': 'Hledat',
  '7_1_image_default_name': 'Profilová fotka',
  '1_1_placeholder_email': 'superaddress@.com',
  '2_1_placeholder_name': 'Jason Bourne',
  '2_1_placeholder_email': 'superaddress@.com',
  '3_1_info_description':
    'Vytvoření myšlenkové mapy ti pomůže uspořádat informace, vidět celý kontext a pochopit základní potřeby a požadavky nového nápadu. Napiš svůj nápad doprostřed prázdné stránky, a pak připisuj všechny asociace, související problémy, slova nebo cokoli relevantního, co tě napadne.',
  '3_4_title': 'Vyšlo to! Máš vytvořené nové heslo.',
  '3_4_description': 'Rovnou ho použij a přihlas se ke svému účtu.',
  '3_4_btn_sign_in': 'Přihlásit se',
  '10_1_title_content_review': 'Vyhodnocení obsahu',
  TODO: 'K vypracování',
  IN_PROGRESS: 'Rozpracováno',
  IN_REVIEW: 'Ke kontrole',
  APPROVED: 'Schváleno',
  PUBLISHED: 'Publikováno',
  IDEA: 'Nápad',
  '6_1_image_default_name': 'Logo společnosti',
  '6_3_image_default_name': 'Logo společnosti',
  '4_2_image_default_name': 'Logo společnosti',
  '10_1_resource_name_placeholder': 'Název zdroje',
  '6_1_btn_add_company': 'Vytvořit/Přidat novou společnost',
  '10_1_btn_cancel': 'Zrušit',
  '10_1_toaster_title_copied_to_clipboard': 'Zkopírováno',
  '10_1_toaster_description_copied_to_clipboard':
    'Podařilo se ti zkopírovat odkaz.',
  '8_1_4_modal_delete_title': 'Opravdu chceš smazat',
  '8_1_4_modal_delete_description':
    'Akci nemůžeš vrátit a všechny informace v tomto obsahu budou nenávratně pryč.',
  '8_1_4_modal_btn_delete': 'Smazat',
  '8_1_4_modal_btn_go_back': 'Zpět',
  '8_1_4_toaster_error_title_delete_content': 'Chyba',
  '8_1_4_toaster_success_title_deleted_content': 'Smazáno',
  '9_2_clear': 'Vymazat',
  '10_1_toaster_warning_title_finish_resources_edit': 'Pozor',
  '10_1_toaster_warning_description_finish_resources_edit':
    'Dokonči editaci rozpracované položky.',
  '10_1_toaster_success_title_description_updated': 'Aktualizováno',
  '10_1_toaster_success_description_description_updated':
    'Podařilo se ti aktualizovat popis.',
  '10_1_toaster_success_title_title_updated': 'Aktualizováno',
  '10_1_toaster_success_description_title_updated':
    'Podařilo se ti aktualizovat název.',
  '10_1_toaster_success_title_final_updated': 'Aktualizováno',
  '10_1_toaster_success_description_final_updated':
    'Podařilo se ti aktualizovat finální text.',
  '10_1_toaster_success_title_content_review_updated': 'Aktualizováno',
  '10_1_toaster_success_description_content_review_updated':
    'Podařilo se ti aktualizovat vyhodnocení obsahu.',
  '10_1_toaster_success_title_stdc_updated': 'Aktualizováno',
  '10_1_toaster_success_description_stdc_updated':
    'Podařilo se ti aktualizovat STDC fázi.',
  '10_1_toaster_success_title_tags_updated': 'Aktualizováno',
  '10_1_toaster_success_description_tags_updated':
    'Podařilo se ti aktualizovat štítky.',
  '10_1_toaster_success_title_state_updated': 'Aktualizováno',
  '10_1_toaster_success_description_state_updated':
    'Podařilo se ti aktualizovat stav obsahu.',
  '10_1_toaster_success_title_personas_updated': 'Aktualizováno',
  '10_1_toaster_success_description_personas_updated':
    'Podařilo se ti aktualizovat persony.',
  '10_1_toaster_success_title_user_updated': 'Aktualizováno',
  '10_1_toaster_success_description_user_updated':
    'Podařilo se ti aktualizovat profil.',
  '10_1_toaster_success_title_products_updated': 'Aktualizováno',
  '10_1_toaster_success_description_products_updated':
    'Podařilo se ti aktualizovat produkty.',
  '10_1_toaster_success_title_keywords_updated': 'Aktualizováno',
  '10_1_toaster_success_title_channel_updated': 'Aktualizováno',
  '10_1_toaster_success_description_channel_updated':
    'Podařilo se ti aktualizovat kanály.',
  '10_1_toaster_success_title_checklist_deleted': 'Smazáno',
  '10_1_toaster_success_description_checklist_deleted':
    'Smazali jste položku ze seznamu úkolů.',
  '10_1_toaster_success_title_content_links_updated': 'Aktualizováno',
  '10_1_toaster_success_description_content_links_updated':
    'Podařilo se ti aktualizovat odkazy.',
  '10_1_toaster_success_title_content_date_updated': 'Aktualizováno',
  '10_1_toaster_success_description_content_date_updated':
    'Podařilo se ti aktualizovat datum.',
  '10_1_toaster_success_title_content_time_updated': 'Aktualizováno',
  '10_1_toaster_success_description_content_time_updated':
    'Podařilo se ti aktualizovat čas.',
  '9_2_placeholder_new_product': 'Nový produkt',
  '9_2_placeholder_new_persona': 'Nová persona',
  '10_1_date_picker_not_set': 'Vyber datum',
  '10_1_time_picker_not_set': 'Vyber čas',
  share_content_title_copied_to_clipboard: 'Zkopírováno',
  share_content_description_copied_to_clipboard:
    'Podařilo se ti zkopírovat odkaz na tento obsah.',
  '8_1_3_notification_title': 'Upomínky',
  '8_1_3_notification_btn_show_this_company': 'Tato společnost',
  '8_1_3_notification_btn_show_all_companies': 'Všechny společnosti',
  '8_1_3_notification_btn_show_only_unread': 'Zobrazit pouze nepřečtené',
  '8_1_3_notification_subtitle_today': 'Dnes',
  '8_1_3_notification_btn_mark_all_as_read': 'Označit vše jako přečtené',
  '8_1_3_notification_subtitle_older': 'Starší',
  '8_1_3_mark_as_read': 'Označit jako přečtené',
  '8_1_3_mark_as_unread': 'Označit jako nepřečtené',
  '9_1_toaster_success_title_content_created_and_duplicated':
    'Podařilo se ti vytvořit obsah a udělat jeho kopii.',
  '9_1_toaster_success_title_content_created': 'Vytvořili jste obsah.',
  '10_1_toaster_success_title_final_text_copied':
    'Podařilo se ti zkopírovat finální text.',
  '10_1_toaster_error_title_final_text_copied':
    'Finální text nemůžeš zkopírovat.',
  '10_1_checklist_placeholder_new_task': 'Nový úkol',
  '10_1_title_add_description': 'Přidej popis',
  '8_5_all_ideas': 'Všechny nápady',
  '8_5_select_no_result': 'Nic nenalezeno',
  '10_1_now': 'nyní',
  '10_1_days': 'dny',
  '10_1_day': 'den',
  '10_1_toaster_error_title_content_date_updated': 'Chyba',
  '10_1_toaster_error_title_content_time_updated': 'Chyba',
  '10_1_toaster_error_title_content_links_updated': 'Chyba',
  '10_1_toaster_error_title_resources_updated': 'Chyba',
  '10_1_toaster_error_title_checklist_deleted': 'Chyba',
  '10_1_toaster_error_title_channel_updated': 'Chyba',
  '10_1_toaster_error_title_keywords_updated': 'Chyba',
  '10_1_toaster_error_title_products_updated': 'Chyba',
  '10_1_toaster_error_title_user_updated': 'Chyba',
  '10_1_toaster_error_title_personas_updated': 'Chyba',
  '10_1_toaster_error_title_state_updated': 'Chyba',
  '10_1_toaster_error_title_tags_updated': 'Chyba',
  '10_1_toaster_error_title_stdc_updated': 'Chyba',
  '10_1_toaster_error_title_content_review_updated': 'Chyba',
  '10_1_toaster_error_title_description_updated': 'Chyba',
  '10_1_toaster_error_title_final_updated': 'Chyba',
  '9_1_toaster_error_title_content_created': 'Chyba',
  '8_1_toaster_error_content_state_update': 'Chyba',
  '8_1_toaster_error_content_update': 'Chyba',
  '8_2_toaster_error_content_state_update':
    'Nemůžeš aktualizovat stav tohoto obsahu.',
  '8_2_toaster_error_content_update': 'Tento obsah nemůžeš aktualizovat.',
  '8_3_toaster_error_content_state_update':
    'Nemůžeš aktualizovat stav tohoto obsahu.',
  '8_3_toaster_error_content_update': 'Tento obsah nemůžeš aktualizovat.',
  '8_4_toaster_error_content_delete': 'Chyba',
  '8_4_toaster_error_content_state_update':
    'Nemůžeš aktualizovat stav tohoto obsahu.',
  '8_4_toaster_error_content_update': 'Tento obsah nemůžeš aktualizovat.',
  '8_2_error_title_load_contents': 'Chyba',
  '8_2_error_description_load_contents':
    'Zkontroluj zda máš vybranou společnost.',
  '8_3_error_title_load_contents': 'Chyba',
  '8_3_error_description_load_contents':
    'Zkontroluj zda máš vybranou společnost.',
  '8_4_error_title_load_contents': 'Chyba',
  '8_4_error_description_load_contents':
    'Zkontroluj zda máš vybranou společnost.',
  '8_3_idea_task_count': 'Úkoly',
  '8_3_idea_task_no_checklist': 'Žádné úkoly',
  '9_2_none': 'není',
  '10_1_subtitle_content_review': 'Psané hodnocení',
  '10_1_subtitle_checklist': 'Úkoly',
  '10_1_toaster_error_checklist_item_delete': 'Chyba',
  '10_1_toaster_success_checklist_item_delete': 'Smazáno',
  '10_1_toaster_error_checklist_item_update':
    'Tento seznam úkolů nemůžeš aktualizovat.',
  '10_1_toaster_success_checklist_item_update': 'Aktualizováno',
  '10_1_toaster_error_title_comment_create': 'Tento komentář nemůžeš přidat.',
  '10_1_toaster_error_title_comment_delete': 'Tento komentář nemůžeš smazat.',
  '10_1_toaster_error_title_comment_update':
    'Tento komentář nemůžeš aktualizovat.',
  '10_1_toaster_success_description_keywords_updated':
    'Podařilo se ti aktualizovat klíčová slova.',
  '9_2_placeholder_new_channel': 'Nový kanál',
  '9_2_toaster_error_title_resources_missing_name': 'Chyba',
  '9_2_toaster_error_description_resources_missing_name': 'Zadej název.',
  '9_2_toaster_error_title_resources_missing_link': 'Chyba',
  '9_2_toaster_error_description_resources_missing_link': 'Zadej odkaz.',
  '10_3_toaster_error_title_checklist_name_empty': 'Chyba',
  '10_3_toaster_error_description_checklist_name_empty': 'Zadej název úkolu.',
  '10_1_toaster_error_title_comment_name_empty': 'Chyba',
  '9_2_product_none': 'není',
  '9_2_persona_none': 'není',
  time_before: 'před ',
  time_after: '',
  '5_1_menu_dashboard': 'Statistiky',
  '7_1_toaster_success_title_profile_updated': 'Aktualizováno',
  '7_1_toaster_success_description_profile_updated':
    'Podařilo se ti aktualizovat profil.',
  '7_1_toaster_error_title_name_is_short': 'Chyba',
  '7_1_toaster_error_description_name_is_short':
    'Zadej delší jméno (min. 3 znaky).',
  '7_1_toaster_error_title_name_is_long': 'Chyba',
  '7_1_toaster_error_description_name_is_long':
    'Zadej kratší jméno (max. 50 znaků).',
  '7_1_toaster_error_title_profile_updated': 'Chyba',
  '7_1_toaster_error_title_current_password_required': 'Chyba',
  '7_1_toaster_error_description_current_password_required':
    'Zadej současné heslo.',
  '7_1_toaster_error_title_new_password_required': 'Chyba',
  '7_1_toaster_error_description_new_password_required': 'Zadej nové heslo.',
  '7_1_toaster_error_title_password_mismatch': 'Chyba',
  '7_1_toaster_error_description_password_mismatch': 'Hesla se neshodují.',
  '7_1_toaster_success_title_password_updated': 'Aktualizováno',
  '7_1_toaster_success_description_password_updated':
    'Podařilo se ti změnit si heslo.',
  '7_1_toaster_error_title_password_updated': 'Chyba',
  '13_title_channels': 'Kanály',
  '13_description_channels':
    'Které kanály používáš nejvíce. Je na nich tvá cílovka, nebo by stálo za to svůj obsah rozprostřít přes víc kanálů?',
  '13_title_products': 'Produkty',
  '13_description_products':
    'O těchto produktech píšeš nejčastěji. Je rozdělení v souladu s tvou strategií, nebo na některé zapomínáš a vyplatilo by se na ně více zaměřit?',
  '13_title_personas': 'Persony',
  '13_description_personas':
    'Ke komu mluvíš nejčastěji. Hodilo by se pravidelně oslovit i někoho jiného?',
  '13_title_stdc': 'STDC',
  '13_description_stdc':
    'Kterou obchodní fázi nejvíc pokrýváš. Chcš se teď se více věnovat akvizici, nebo péči o stávající zákazníky?',
  '13_title_tags': 'Štítky',
  '13_description_tags':
    'Které štítky se nejčastěji objevují u tvého obsahu. Musíš si určit, jestli rozdělení odpovídá tvé strategii.',
  '13_title_tasks': 'Úkoly',
  '13_description_tasks':
    'Kolik toho má tvůj tým na práci. Úkoly rozděluj rovnoměrně, protože jeden člověk sám všechno nezvládne.',
  '13_title_tips': 'Víš, že…',
  '13_description_tips':
    'Díky pečlivému třídění obsahu snadno identifikuješ oblasti ke zlepšení.',
  '7_1_toaster_success_title_user_deleted': 'Smazáno',
  '7_1_toaster_success_description_user_deleted':
    'Podařilo se ti smazat svůj profil.',
  '7_1_toaster_error_title_user_deleted': 'Chyba',
  '6_3_toaster_error_description_invalid_name':
    'Zadej název společnosti (min. 3, max. 50 znaků).',
  '6_3_1_toaster_success_title_user_invited': 'Odesláno',
  '6_3_1_toaster_success_description_user_invited':
    'Podařilo se ti odeslat pozvánky.',
  '6_3_2_toaster_error_title_user_invited': 'Chyba',
  '4_3_1_title': 'Kde najdu ID společnosti?',
  '4_3_1_description_step_1': 'V menu klikni na Společnost.',
  '4_3_1_description_step_2':
    'Přejdi na sekci Pozvi další členy do týmu ve spodní části stránky.',
  '4_3_1_description_step_3': 'Zkopíruj ID společnosti.',
  '4_3_1_btn_confirm': 'Rozumím',
  '6_3_label_timezone': 'Časová zóna',
  '6_3_placeholder_timezone': 'Vyber časovou zónu společnosti',
  '6_3_toaster_error_title_invalid_timezone': 'Chyba',
  '6_3_toaster_error_description_invalid_timezone': 'Vyber časovou zónu.',
  '10_1_toaster_success_description_checklist_item_update':
    'Podařilo se ti aktualizovat seznam úkolů.',
  '10_1_toaster_success_description_checklist_item_delete':
    'Podařilo se ti smazat položku ze seznamu úkolů.',
  role_admin: 'SPRÁVCE',
  role_approver: 'SCHVALOVATEL',
  '6_3_tooltip_team':
    'Správce – může zvát uživatele, vytvářet a měnit obsah, a dělat jakékoliv další změny. Schvalovatel – může změnit jen stav obsahu, nevidí komentáře ani seznamy úkolů.',
  '8_1_1_drop_here': 'PŘESUNOUT SEM',
  '7_1_2_email': 'E-mail',
  '10_1_toaster_error_description_comment_name_empty': 'Napiš komentář.',
  '10_1_toaster_error_title_title_updated': 'Aktualizováno',
  '6_3_toaster_error_description_invalid_description': 'Zadej popis.',
  '6_3_toaster_error_title_invalid_description': 'Chyba',
  '6_3_toaster_error_title_invalid_name': 'Chyba',
  '9_2_placeholder_new_stdc': 'STDC',
  January: 'Leden',
  February: 'Únor',
  March: 'Březen',
  April: 'Duben',
  May: 'Květen',
  June: 'Červen',
  July: 'Červenec',
  August: 'Srpen',
  September: 'Září',
  October: 'Říjen',
  November: 'Listopad',
  December: 'Prosinec',
  '9_1_toaster_success_description_content_created_and_duplicated':
    'Podařilo se ti vytvořit obsah a udělat jeho kopii.',
  '9_1_toaster_success_description_content_created':
    'Podařilo se ti vytvořit obsah.',
  '8_1_4_toaster_success_description_deleted_content':
    'Podařilo se ti smazat obsah.',
  '10_1_toaster_success_description_final_text_copied':
    'Podařilo se ti zkopírovat finální text.',
  '8_1_add_content': 'Přidat obsah',
  '9_1_modal_close_content_title': 'Zavřít obsah',
  '9_1_modal_close_content_description':
    'Opravdu chceš zavřít tento obsah? Všechny úpravy v těchto polích budou nenávratně ztraceny:',
  '9_1_modal_close_content_btn_close': 'Zavřít obsah',
  '9_1_modal_close_content_btn_cancel': 'Nezavírat',
  '9_1_toaster_error_description_create': 'Chyba',
  '8_1_4_toaster_error_description_delete_content':
    'Tento obsah nemůžeš smazat.',
  '9_2_toaster_error_title_maximum_items_count': 'Chyba',
  '9_2_toaster_error_description_maximum_items_count':
    'Maximální počet záznamů:',
  '8_1_toaster_error_title_delete_content': 'Chyba',
  '8_1_toaster_error_description_delete_content': 'Tento obsah nemůžeš smazat.',
  '8_1_toaster_success_title_deleted_content': 'Smazáno',
  '8_1_toaster_success_description_deleted_content':
    'Podařilo se ti smazat obsah.',
  '8_3_toaster_error_title_content_delete': 'Chyba',
  '8_3_toaster_error_description_content_delete': 'Tento obsah nemůžeš smazat.',
  '8_3_toaster_success_title_deleted_content': 'Smazáno',
  '8_3_toaster_success_description_deleted_content':
    'Podařilo se ti smazat obsah.',
  '8_2_toaster_error_title_content_delete': 'Chyba',
  '8_2_toaster_error_description_content_delete': 'Tento obsah nemůžeš smazat.',
  '8_2_toaster_success_title_deleted_content': 'Smazáno',
  '8_2_toaster_success_description_deleted_content':
    'Podařilo se ti smazat obsah.',
  '8_4_toaster_success_title_content_updated': 'Aktualizováno',
  '8_4_toaster_success_description_content_updated':
    'Podařilo se ti aktualizovat obsah.',
  '8_4_toaster_success_title_deleted_content': 'Smazáno',
  '8_4_toaster_success_description_deleted_content':
    'Podařilo se ti smazat obsah.',
  '10_1_toaster_error_title_title_is_empty': 'Chyba',
  '10_1_toaster_error_description_title_is_empty': 'Zadej název.',
  '8_5_filter_item_rating': 'Vyhodnocení',
  '13_title_review': 'Vyhodnocení',
  '13_description_review':
    'Jak se dařilo, co fungovalo a co méně? Podívej se na vyhodnocení svého publikovaného obsahu.',
  '7_1_1_label_company': 'Název společnosti',
  '7_1_1_placeholder_company': 'Pro potvrzení napiš jméno společnosti',
  '9_2_item_search_no_result_title': 'Nic jsme nenašli',
  '9_2_item_search_no_result_desc':
    'Zkus jiný název nebo vytvoř novou položku v',
  '9_2_item_search_no_result_else':
    'Zkus jiný název nebo napiš novou položku a přidej ji pluskem.',
  '3_1_info_title': 'zatímco si kontroluješ e-mail... Víš, že?',
  '9_2_toaster_error_title_resources_invalid_link': 'Chyba',
  '9_2_toaster_error_description_resources_invalid_link':
    'Zkontroluj, zda je odkaz ve správném tvaru (např. www.odkaz.cz).',
  '6_3_2_toaster_error_title_user_cannot_update_role': 'Chyba',
  '6_3_2_toaster_error_description_user_cannot_update_role':
    'Roli nelze upravit',
  '6_3_2_toaster_error_title_user_cannot_delete': 'Chyba',
  '6_3_2_toaster_error_description_user_cannot_delete':
    'Pozvi nové členy týmu, ať se můžeš z této společnosti odebrat',
  '8_1_3_notification_content_comment_mentioned': 'tě zmiňuje v komentáři',
  '8_1_3_toaster_success_title_notification_all_read': 'Aktualizováno',
  '8_1_3_toaster_success_description_notification_all_read':
    'Máš přečtené všechny upomínky.',
  '8_1_3_toaster_error_title_notification_all_read': 'Chyba',
  '8_1_3_toaster_success_title_notification_company_read': 'Aktualizováno',
  '8_1_3_toaster_success_description_notification_company_read':
    'Máš přečtené všechny upomínky pro vybranou společnost.',
  '8_1_3_toaster_error_title_notification_company_read': 'Chyba',
  '8_1_3_toaster_success_title_notification_read': 'Aktualizováno',
  '8_1_3_toaster_success_description_notification_read':
    'Upomínka je označena jako přečtená.',
  '8_1_3_toaster_success_title_notification_unread': 'Aktualizováno',
  '8_1_3_toaster_success_description_notification_unread':
    'Upomínka je označena jako nepřečtená.',
  '8_1_3_toaster_error_title_notification_read': 'Chyba',
  '8_1_3_toaster_error_title_notification_unread': 'Chyba',
  '9_2_stdc_search_no_result_title': 'Hledanou STDC fázi jsme nenašli.',
  '3_3_1_agree_submit_form':
    'Odesláním formuláře souhlasíš s těmito dokumenty:',
  '3_3_1_link_terms_condition': 'Obchodní podmínky',
  '3_3_1_and': 'a',
  '3_3_1_link_privacy_policy': 'Zásady ochrany osobních údajů',
  '10_1_link_add': 'Přidat odkaz',
  '9_1_link_add': 'Přidat odkaz',
  '1_1_4_back_to_sign_in': 'Zpět na přihlášení',
  '1_1_4_title': 'Ověř svůj e-mail',
  '1_1_4_description':
    'Na tvůj e-mail %email% jsme odeslali ověřovací odkaz. Zkontroluj svou e-mailovou schránku a klikni na odkaz pro ověření e-mailu.',
  '1_1_4_btn_resend': 'Znovu odeslat ověřovací odkaz',
  '1_1_4_trouble_verify': 'Máš potíže s ověřením e-mailu?',
  '1_1_4_link_contact_us': 'Kontaktuj nás',
  '1_1_4_toaster_success_title_resend_verification_email': 'Aktualizováno',
  '1_1_4_toaster_success_description_resend_verification_email':
    'Ověřovací kód byl odeslán na tvou e-mailovou adresu.',
  '1_1_4_toaster_error_title_resend_verification_email': 'Chyba',
  '1_2_toaster_error_title_not_confirmed': 'Error',
  '1_2_toaster_error_description_not_confirmed':
    'Tato e-mailová adresa není ověřená.',
  '1_2_toaster_error_title_account_locked': 'Chyba',
  '1_2_toaster_error_description_account_locked': 'Tento účet je uzamčený.',
  '1_2_toaster_error_title_unauthorized': 'Chyba',
  '1_2_toaster_error_description_unauthorized': 'Chyba během přihlašování.',
  '1_2_title_verify_email': 'Ověření e-mailové adresy',
  '1_2_description_verify_email': 'Ověřujeme tvou e-mailovou adresu.',
  '1_2_toaster_error_title_invalid_token': 'Chyba',
  '1_2_toaster_error_description_invalid_token': 'Vypršel čas pro tuto akci.',
  '1_2_toaster_success_title_account_verified': 'Ověřeno',
  '1_2_toaster_success_description_account_verified': 'Tvůj účet byl ověřen.',
  '1_2_toaster_error_title_account_verify': 'Chyba',
  '2_2_toaster_error_title_register_conflict': 'Chyba',
  '2_2_toaster_error_description_register_conflict':
    'Použij jiné jméno nebo e-mailovou adresu.',
  toaster_info_title_confirm_delete: 'Potvrď smazání',
  toaster_info_description_confirm_delete:
    'Klikni ještě jednou na tlačítko pro smazání.',
  '3_1_toaster_error_title_invalid_reset_password': 'Chyba',
  '3_1_toaster_error_description_invalid_reset_password':
    'Nejde odeslat žádost o obnovení hesla.',
  '1_2_toaster_error_title_account_verified': 'Chyba',
  '1_2_toaster_error_description_account_verified':
    'Vypršel čas pro tuto akci.',
  '3_3_toaster_error_title_password_mismatch': 'Chyba',
  '3_3_toaster_error_description_password_mismatch': 'Hesla se neshodují.',
  '3_3_toaster_error_title_change_password': 'Chyba',
  '3_3_toaster_error_description_change_password': 'Nejde nastavit nové heslo.',
  '3_3_toaster_success_title_change_password': 'Aktualizováno',
  '3_3_toaster_success_description_change_password': 'Nové heslo je nastaveno.',
  '4_1_link_select_all': 'Vybrat vše',
  '4_1_btn_join_selected_companies': 'Přidat se do vybrané společnosti',
  '4_1_link_how_users_work_with_companies':
    'Jak naši uživatelé pracují se Společnostmi?',
  '4_1_invited_by': 'Pozván od',
  '4_1_2_title': 'Jak naši uživatelé pracují se Společnostmi?',
  '4_1_2_examples': 'Zde je pár příkladů:',
  '4_1_2_marketing_agency_title': 'Marketingová agentura',
  '4_1_2_marketing_agency_description':
    'každý klient má své prostředí: 3 klienti = 3 Společnosti',
  '4_1_2_international_company_title': 'Nadnárodní firma',
  '4_1_2_international_company_description':
    'každý trh má své prostředí: 3 trhy / jazyky / země = 3 Společnosti',
  '4_1_2_enterpreneur_title': 'Podnikatel',
  '4_1_2_enterpreneur_description':
    'každý projekt má své prostředí: 3 projekty (např. knižní e-shop, konzultace a pronájem bytů) = 3 společnosti',
  '4_1_2_influencer_title': 'Influencer',
  '4_1_2_influencer_description': 'jejich vlastní značka: 1 Společnost',
  '4_1_2_btn_got_it': 'Chápu',
  '4_1_title_join': '... nebo se k nějaké připoj',
  '4_1_description_join':
    'Super, už na tebe čeká nějaký projekt! Nenechte svůj tým na ocet a připoj se.',
  '6_3_2_toaster_success_title_invite_deleted': 'Smazáno',
  '6_3_2_toaster_success_description_invite_deleted': 'Pozvánka je smazána.',
  '6_3_2_toaster_error_title_invite_delete': 'Chyba',
  '6_3_2_toaster_success_title_invite_resend': 'Znovu odeslat',
  '6_3_2_toaster_success_description_invite_resend':
    'Pozvánka byla znovu odeslána.',
  '6_3_2_toaster_error_title_invite_resend': 'Chyba',
  '6_1_invited_by': 'Pozvánka od',
  '6_1_invited': 'Pozván',
  '6_1_tooltip_resend_invitation': 'Znovu odeslat pozvánku',
  '4_1_link_deselect_all': 'Zrušit výběr',
  '6_1_toaster_success_title_user_accept': 'Přijato',
  '6_1_toaster_success_description_user_accept':
    'Úspěšně se ti podařilo přidat do společnosti.',
  '6_1_toaster_error_title_user_accept': 'Chyba',
  '1_2_title_invite_company': 'Přidáváme tě do společnosti',
  '1_2_description_invite_company': 'Zabere to malou chvilku.',
  '1_2_toaster_success_title_company_joined': 'Přidaná společnost',
  '1_2_toaster_success_description_company_joined':
    'Úspěšně se ti podařilo přidat do společnosti.',
  '1_2_toaster_error_title_company_join': 'Chyba',
  '6_1_subtitle_join_no_results': '... nebo se k nějaké připoj',
  '6_1_description_join_no_results_paragraph_1':
    'Zrovna teď pro tebe nemáme žádnou pozvánku.',
  '6_1_description_join_no_results_paragraph_2':
    'Pokud očekáváš účast na projektu, požádej svůj tým, aby tě pozval; udělají to snadno na stránce Společnosti. Dinosauři pro tebe pak pozvánku připraví zde!',
  '8_5_btn_open_in_list_view': 'Otevřít v Seznamu',
  '1_2_toaster_error_description_company_join':
    'Tento uživatel není příjemcem pozvánky.',
  '1_2_invalid_google_login_title': 'Chyba',
  '1_2_invalid_google_login_text': 'Nelze se přihlásit přes Google.',
  '3_1_support_mail_subject': 'Obnovení hesla',
  '7_1_2_support_mail_subject': 'Zapomenuté heslo',
  '3_3_support_mail_subject': 'Vytvořit nové heslo',
  '3_2_support_mail_subject': 'Problémy s obnovením hesla',
  '11_1_2_support_mail_subject': 'Problémy se sítí',
  '11_1_1_support_mail_subject': 'Stránka nenalezena',
  '1_1_4_support_mail_subject': 'Ověřte svůj e-mail',
  '9_1_is_copy': 'Kopie',
  '6_3_2_toaster_success_title_role_updated': 'Aktualizováno',
  '6_3_2_toaster_success_description_role_updated': 'Role je aktualizována.',
  '6_3_2_toaster_error_title_role_updated': 'Chyba',
  '8_1_3_notification_content_state_changed': 'mění stav obsahu',
  '8_1_3_notification_content_added_as_owner': 'tě přiřazuje jako majitele',
  '8_1_3_notification_content_removed_as_owner': 'tě odebírá jako majitele',
  '10_1_tooltip_review_remove':
    'Zadané hodnocení odebereš tak, že ho podruhé zaklikneš stejně (např. 4hvězdičkové hodnocení odebereš kliknutím na 4. hvězdu)',
  '10_1_review_value_none': 'Žádné',
  global_toaster_error_description: 'Akce se nezdařila.',
  '8_4_empty_title_none': 'Nepřiřazeno',
  '13_title_state': 'Obsahy',
  '13_description_state':
    'Kolik obsahu se ti podařilo vytvořit? Podívej se, v jakých stavech zrovna jsou.',
  '13_none': 'Nepřiřazeno',
  '8_5_not_rated': 'Nehodnoceno',
  '8_1_3_notification_btn_show_only_read': 'Zobrazit vše',
  '10_3_toaster_error_title_checklist_already_editing': 'Chyba',
  '10_3_toaster_error_description_checklist_already_editing':
    'Dokonči editaci předchozího tasku.',
  '10_1_after_publish_date': 'Po datu publikace',
  '9_1_toaster_error_title_owner_undefined': 'Chyba',
  '9_1_toaster_error_description_owner_undefined':
    'Nastav aktivního Vlastníka obsahu.',
  '10_1_title_content_not_found': 'Obsah nenalezen',
  '10_1_description_content_not_found': '',
  '10_1_btn_close': 'Zavřít',
  '7_1_toaster_success_title_upload_avatar': 'Nahráno',
  '7_1_toaster_success_description_upload_avatar':
    'Tvůj profilový obrázek byl nahrán.',
  '7_1_toaster_error_title_upload_avatar': 'Chyba',
  '7_1_toaster_error_description_upload_avatar':
    'Zkus to prosím znovu nebo nás kontaktuj.',
  '7_1_toaster_success_description_deleted_avatar':
    'Profilový obrázek byl smazán.',
  '7_1_toaster_success_title_deleted_avatar': 'Smazáno',
  '7_1_button_cancel_avatar': 'Zrušit',
  '7_1_button_save_avatar': 'Uložit',
  '7_1_toaster_success_title_upload_company_logo': 'Nahráno',
  '7_1_toaster_success_description_upload_company_logo':
    'Podařilo se ti nahrát logo společnosti.',
  '7_1_toaster_error_title_upload_company_logo': 'Chyba',
  '7_1_toaster_error_description_upload_company_logo':
    'Zkus to prosím znovu nebo nás kontaktuj.',
  '7_1_toaster_success_title_deleted_company_logo': 'Smazáno',
  '7_1_toaster_success_description_deleted_company_logo': 'Logo bylo smazáno.',
  '7_1_button_cancel_company_logo': 'Zrušit',
  '7_1_button_save_company_logo': 'Uložit',
  '4_2_upload_logo_image':
    'Smaž současné logo nebo nahraj nové (maximální velikost 1MB, formát jpg nebo png).',
  '4_2_upload_logo_no_image':
    'Vyber logo společnosti (maximální velikost 1MB, formát jpg nebo png).',
  '4_2_upload_profile_no_image':
    'Vyber profilový obrázek (maximální velikost 1MB, formát jpg nebo png).',
  '4_2_upload_profile_image':
    'Smaž současný profilový obrázek nebo nahraj nový (maximální velikost 1MB, formát jpg nebo png).',
  '10_1_title_communication': 'Komunikace',
  '10_1_btn_name_comments': 'Komentáře',
  '10_1_btn_name_feedback': 'Zpětná vazba',
  '10_1_btn_create_feedback': 'Přidat zpětnou vazbu...',
  '10_1_btn_feedback_save': 'Uložit',
  '10_1_btn_feedback_cancel': 'Zrušit',
  '10_1_view_more_feedbacks': 'Zobrazit další zpětnou vazbu',
  '10_1_toaster_error_title_feedback_name_empty': 'Chyba',
  '10_1_toaster_error_description_feedback_name_empty': 'Přidej zpětnou vazbu.',
  '10_1_toaster_success_title_feedback_create': 'Vytvořeno',
  '10_1_toaster_success_description_feedback_create':
    'Podařilo se ti přidat zpětnou vazbu.',
  '10_1_toaster_error_title_feedback_create':
    'Tuto zpětnou vazbu nemůžeš přidat.',
  '10_1_toaster_error_title_feedback_update':
    'Tuto zpětnou vazbu nemůžeš aktualizovat.',
  '10_1_toaster_success_title_feedback_deleted': 'Smazáno',
  '10_1_toaster_success_description_feedback_deleted':
    'Podařilo se ti smazat zpětnou vazbu.',
  '10_1_toaster_error_title_feedback_delete':
    'Tuto zpětnou vazbu nemůžeš smazat.',
  Team: 'Tým',
  '8_5_group_by_item_ideas_date': 'Datum vytvoření',
  '8_4_1_1_title': 'Vytvoř nový obsah!',
  '8_4_1_1_description':
    'Zatím nemáš žádné nápady uložené na později. Schovej si tady nějaké pro případ nouze! Jednoduše přidej obsah a nech ho ve stavu Nápad.',
  '8_4_1_1_btn_add_content': 'Přidat obsah',
  '5_3_3_title': 'Dej nám zpětnou vazbu',
  '5_3_3_report_bug': 'Chci nahlásit chybu',
  '5_3_3_report_suggestion': 'Mám návrh na zlepšení',
  '5_3_3_report_like': 'Něco se mi líbí',
  '5_3_3_report_dislike': 'Něco se mi nelíbí',
  '5_3_3_btn_back': 'Zpět',
  '5_3_3_btn_submit': 'Odeslat',
  '5_3_3_label_message': 'Tvá zpráva',
  '5_3_3_textarea_placeholder': 'Začni psát...',
  '2_1_label_first_name': 'Křestní jméno',
  '2_1_placeholder_first_name': 'T-Rex',
  '2_1_label_last_name': 'Příjmení',
  '2_1_placeholder_last_name': 'Tvořidaktylus',
  '2_1_lang_czech': 'Česky',
  '2_1_lang_english': 'Anglicky',
  '2_1_language': 'Jazyk e-mailů',
  '2_1_toaster_error_title_first_name_is_short': 'Chyba',
  '2_1_toaster_error_description_first_name_is_short':
    'Zadej delší křestní jméno (min. 3 znaky).',
  '2_1_toaster_error_title_first_name_is_long': 'Chyba',
  '2_1_toaster_error_description_first_name_is_long':
    'Zadej kratší křestní jméno (max. 50 znaků).',
  '2_1_toaster_error_title_last_name_is_short': 'Chyba',
  '2_1_toaster_error_description_last_name_is_short':
    'Zadej delší příjmení (min. 3 znaky).',
  '2_1_toaster_error_title_last_name_is_long': 'Chyba',
  '2_1_toaster_error_description_last_name_is_long':
    'Zadej kratší příjmení (max. 50 znaků).',
  '7_1_label_first_name': 'Křestní jméno',
  '7_1_placeholder_first_name': 'T-Rex',
  '7_1_label_last_name': 'Příjmení',
  '7_1_placeholder_last_name': 'Tvořidaktylus',
  '7_1_toaster_error_title_first_name_is_short': 'Chyba',
  '7_1_toaster_error_description_first_name_is_short':
    'Zadej delší křestní jméno (min. 3 znaky).',
  '7_1_toaster_error_title_first_name_is_long': 'Chyba',
  '7_1_toaster_error_description_first_name_is_long':
    'Zadej kratší křestní jméno (max. 50 znaků).',
  '7_1_toaster_error_title_last_name_is_short': 'Chyba',
  '7_1_toaster_error_description_last_name_is_short':
    'Zadej delší příjmení (min. 3 znaky).',
  '7_1_toaster_error_title_last_name_is_long': 'Chyba',
  '7_1_toaster_error_description_last_name_is_long':
    'Zadej kratší příjmení (max. 50 znaků).',
  '6_3_company_active': 'Společnost je aktivní',
  '6_3_company_active_until': 'do',
  '6_3_contact_us': 'Kontaktuj nás',
  '6_3_company_billing_info': 'Požadavek na prodloužení',
  '6_5_btn_open_billing': 'Objednat předplatné',
  '6_5_title': 'Společnost není aktivní',
  '6_5_description':
    'Chceš s touto společností dál pracovat? Uhraď předplatné na další období.',
  '6_5_information':
    'Data této společnosti zmizí za <strong>90 dní</strong> od data vypršení předplatného.',
  '5_3_3_toaster_success_title_feedback_send': 'Odesláno',
  '5_3_3_toaster_success_description_feedback_send':
    'Děkujeme za tvou zpětnou vazbu.',
  '5_3_3_toaster_error_title_feedback_send': 'Chyba',
  '5_3_3_toaster_error_description_feedback_send':
    'Bohužel, tvou zpětnou vazbu se nepodařilo odeslat. Zkus to prosím později.',
  '6_5_email_subject_request_subscription': 'Objednat předplatné',
  '6_2_select_subscription': 'Zvol předplatné',
  '6_3_invite_tooltip':
    'Správce – může zvát uživatele, vytvářet a měnit obsah, a dělat jakékoliv další změny. Schvalovatel – může změnit jen stav obsahu, nevidí komentáře ani seznamy úkolů.',
  '6_3_no_members': 'Žádní členové nenalezeni',
  '6_3_no_invitations': 'Žádné pozvánky nenalezeny',
  '6_3_roles_and_permissions': 'Role a oprávnění',
  '6_3_tab_title_members': 'Členové',
  '6_3_tab_title_invited': 'Pozvaní',
  '8_5_btn_print_page': 'Tisk',
  '8_1_3_notification_content_todo_unassigned': 'tě odebírá z úkolu',
  '8_1_3_notification_content_todo_assigned': 'ti přiřazuje úkol',
  '8_1_3_notification_content_feedback_mentoined': 'tě zmínil ve feedbacku',
  '8_1_3_notification_content_todo_done': 'Úkol splněn',
  '9_1_modal_close_content_is_open_title': 'Název',
  '9_1_modal_close_content_is_open_description': 'Popis',
  '9_1_modal_close_content_is_open_final_text': 'Finální text',
  '9_1_modal_close_content_is_open_content_review': 'Vyhodnocení obsahu',
  '9_1_modal_close_content_is_open_resources': 'Zdroje',
  '9_1_modal_close_content_is_open_content_link': 'Odkazy na obsah',
  '9_1_modal_close_content_is_open_checklist': 'Seznam úkolů',
  '9_1_modal_close_content_is_open_feedback': 'Zpětná vazba',
  '9_1_modal_close_content_is_open_comments': 'Komentáře',
  '8_3_2_suggestion_btn_name': 'Vytvořit obsah',
  '10_1_btn_generate_ai_text': 'Generuj text s AI',
  '10_1_tooltip_generate_ai_text': 'Text se generuje z názvu obsahu.',
  '10_1_toaster_title_start_generating_final_text_ai': 'Generujeme text',
  '10_1_toaster_description_start_generating_final_text_ai':
    'Tvůj text už se líhne z vajíčka umělé inteligence.',
  '10_1_toaster_title_finish_generating_final_text_ai': 'Generování hotovo',
  '10_1_toaster_description_finish_generating_final_text_ai':
    'Tvůj text se vylíhl v nástřelového dinousarka.',
  '10_1_toaster_title_error_generating_final_text_ai': 'Chyba',
  '10_1_toaster_description_error_generating_final_text_ai':
    'Při generování textu se něco pokazilo. Zkus to později.',
  resolution_warning_description:
    'Contesaurovi se na malé obrazovce nefunguje dobře. Zkus použít větší okno prohlížeče.',
  resolution_warning_continue: 'Nevadí, pokračovat',
  '5_1_company_expire_days': 'dnů',
  '5_1_company_expire_day_1': 'den',
  '5_1_company_expire_day_2': 'dny',
  '5_1_company_expire_day_3': 'dny',
  '5_1_company_expire_day_4': 'dny',
  '5_1_company_subscription_will_expire_in': 'Předplatné vyprší za',
  '5_1_renew_subscription': 'Obnovit předplatné',
}
