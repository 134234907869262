<template>
  <div class="small-select-person">
    <VDropdown
      popperClass="popper-modal"
      :triggers="[]"
      :shown="showData"
      :eagerMount="autoHide"
      :auto-hide="autoHide"
      @hide="showData = false"
    >
      <div @click="toggle" class="small-select-person__selected">
        <Avatar
          v-if="selected.id"
          :name="selected.name"
          :user="selected"
          :size="'extrasmall'"
        />

        <Button v-else :active="showData" :size="'slim'">
          <IcoUser />
          <span v-if="showPlaceholder">
            {{ $t('10_1_btn_checklist_assign') }}
          </span>
        </Button>
      </div>

      <template #popper>
        <div class="small-select-person__data">
          <div
            class="small-select-person__item"
            v-for="(item, index) in items"
            :key="index"
            :class="{ 'is-active': isActive(item) }"
          >
            <SelectPersonItem
              :user="item"
              :index="index"
              :selected="selected"
              @selectPerson="selectPerson"
              @unsetUser="unsetUser"
            />
          </div>
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<script>
import SelectPersonItem from '@/components/SelectPersonItem.vue'
import Button from '@/components/button/Button.vue'
import Avatar from '@/components/Avatar.vue'
import IcoUser from '@/components/svg/small/IcoUser.vue'
import ClickOutside from 'vue-click-outside'

export default {
  components: {
    SelectPersonItem,
    Button,
    IcoUser,
    Avatar,
  },
  directives: {
    ClickOutside,
  },
  props: {
    user: {
      type: Object,
      default() {
        return {
          id: null,
        }
      },
    },
    showPlaceholder: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    codelist() {
      return this.$store.getters.getCodelist
    },
  },
  mounted() {
    this.getUsersList()

    if (this.user) {
      if (this.user.id) {
        this.selected = this.user
      }
    }
  },
  data() {
    return {
      autoHide: true,
      canBeClosed: false,
      showData: false,
      selected: {},
      items: [],
      userList: [],
    }
  },
  methods: {
    getUsersList() {
      let list = []
      this.codelist.users
        .filter((user) => user.role !== 'APPROVER')
        .forEach((item) => {
          list.push({
            id: item.id,
            name: item.label,
          })
        })

      this.items = list
    },
    isActive(item) {
      return item.id === this.selected.id
    },
    selectPerson(person) {
      this.selected = person
      this.showData = false

      this.$emit('changeUser', person)
    },
    hide() {
      this.showData = false
    },
    toggle() {
      this.canBeClosed = false
      this.showData = !this.showData

      if (this.showData) {
        this.autoHide = false
        setTimeout(() => (this.autoHide = true), 300)
      }
    },
    unsetUser() {
      this.selected = {}
      this.showData = false

      this.$emit('changeUser', null)
    },
  },
}
</script>

<style scoped>
.small-select-person {
  position: relative;
  cursor: pointer;
}

.small-select-person__selected {
  display: flex;
  align-items: center;
}

.small-select-person__placeholder {
  height: 30px;
  color: #c5c5c5;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  font-family: Nunito;
}

.small-select-person__data {
  top: 100%;
  box-shadow: 15px 15px 60px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background: #ffffff;
  padding: 0;
  width: 185px;
}

.small-select-person__item {
  /* margin-top: 14px; */
  cursor: pointer;
  padding: 7px 15px;
}

.small-select-person__item:hover {
  background-color: #f6f6f7;
}

.small-select-person__item:first-child {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.small-select-person__item:last-child {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.small-select-person__item.is-active {
  background-color: #f6f6f7;
}
</style>
