<template>
  <div class="checklist-item">
    <div v-if="!canEdit" class="checklist-item__read">
      <div @click="editItem" class="checklist-item__data">
        <div class="checklist-item__wrapper">
          <div class="checklist-item__title" :class="{ 'is-done': item.done }">
            <div @click.stop="toggleDone()">
              <Checkbox :readonly="true" v-model="item.done" />
            </div>
            <div
              class="checklist-item__title__read"
              :class="{ 'is-done': item.done }"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="checklist-item__controll" :class="{ 'is-done': item.done }">
        <SmallDatePicker
          :publishDate="publishDate"
          v-model="item.date"
          v-slot="slotProps"
          @changeTime="changeTime"
        >
          <Button
            :red="isChecklistAfterDueDate"
            :active="slotProps.show"
            :size="'slim'"
          >
            <IcoCalendar />
            <span v-if="item.date !== null">{{ date }}</span>
          </Button>
        </SmallDatePicker>

        <div class="checklist-item__controll__avatar">
          <SmallSelectPerson
            @changeUser="changeUser"
            :user="item.assignee"
            :showPlaceholder="false"
          />
        </div>

        <Button v-if="!confirmDelete" @click="deleteItem()" :size="'slim'"
          ><IcoBin
        /></Button>
        <Button
          v-if="confirmDelete"
          v-click-outside="cancelDeleteItem"
          @click="confirmedDeleteItem()"
          :type="'delete'"
          :size="'slim'"
          ><IcoBin
        /></Button>
      </div>
    </div>

    <div v-if="canEdit" class="checklist-item__edit">
      <div class="checklist-item__edit__text">
        <div
          class="checklist-item__edit__name"
          :class="{ 'has-error': invalidName }"
        >
          <div @click.stop="toggleDone()">
            <Checkbox :readonly="true" v-model="item.done" />
          </div>

          <textarea-autosize
            @input="invalidName = false"
            :placeholder="$t('10_1_checklist_placeholder_new_task')"
            ref="checklist_edit_name"
            v-model="item.name"
            :maxlength="length.short"
          ></textarea-autosize>
        </div>
      </div>

      <div class="checklist-item__edit__controll">
        <div class="checklist-item__edit__buttons">
          <Button @click="saveItem()" :size="'small'" :type="'primary'">{{
            $t('10_1_checklist_item_btn_save')
          }}</Button>
          <Button @click="cancelItem()" :size="'small'">{{
            $t('10_1_checklist_item_btn_cancel')
          }}</Button>
        </div>

        <div class="checklist-item__edit__buttons">
          <SmallDatePicker
            :publishDate="publishDate"
            v-model="item.date"
            v-slot="slotProps"
          >
            <Button
              :red="isChecklistAfterDueDate"
              :active="slotProps.show"
              :size="'slim'"
            >
              <IcoCalendar />
              <span v-if="item.date === null">{{
                $t('10_1_checklist_item_btn_select_date')
              }}</span>
              <span v-else>{{ date }}</span>
            </Button>
          </SmallDatePicker>

          <SmallSelectPerson
            :user="item.assignee"
            @changeUser="editChangeUser"
          />

          <Button v-if="!confirmDelete" @click="deleteItem()" :size="'slim'"
            ><IcoBin
          /></Button>
          <Button
            v-if="confirmDelete"
            v-click-outside="cancelDeleteItem"
            @click="confirmedDeleteItem()"
            :type="'delete'"
            :size="'slim'"
            class="button-confirm-delete"
          >
            <IcoBin />
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import textLength from '@/constants/TextLengthConstant.js'
import { obj_clone } from '@/services/helpers/clone.js'
import ClickOutside from 'vue-click-outside'
import moment from 'moment'

import Button from '@/components/button/Button.vue'
import SmallSelectPerson from '@/components/SmallSelectPerson.vue'
import SmallDatePicker from '@/components/date/SmallDatePicker.vue'
import Checkbox from '@/components/Checkbox.vue'

import IcoBin from '@/components/svg/small/IcoBin.vue'
import IcoCalendar from '@/components/svg/small/IcoCalendar.vue'
import IcoUser from '@/components/svg/small/IcoUser.vue'
import IcoChecked from '@/components/svg/checklist/IcoChecked.vue'
import IcoUnchecked from '@/components/svg/checklist/IcoUnchecked.vue'

export default {
  components: {
    Button,
    IcoBin,
    IcoCalendar,
    IcoUser,
    IcoUnchecked,
    IcoChecked,
    SmallSelectPerson,
    SmallDatePicker,
    Checkbox,
  },
  directives: {
    ClickOutside,
  },
  props: {
    itemData: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    publishDate: {
      type: String | Date,
      required: true,
    },
    updatingItem: {
      type: Boolean,
      required: true,
    },
    cancelStates: {
      type: Number,
      default: 1,
    },
  },
  created() {
    this.item = this.itemData
    this.itemBeforeEdit = this.itemData
  },
  computed: {
    length() {
      return textLength
    },
    date() {
      return moment(this.item.date).format('D MMM')
    },
    dateFormatted() {
      return moment(this.checklist.date).format('d.m.Y')
    },
    isChecklistAfterDueDate() {
      if (this.item.date === null) {
        return false
      }

      return (
        new Date(this.item.date).getTime() < new Date().getTime() &&
        !this.item.done
      )
    },
  },
  data() {
    return {
      invalidName: false,
      item_default: {
        name: '',
        date: null,
        assignee: {},
        done: false,
      },
      confirmDelete: false,
      canEdit: false,
      checklist: {
        name: '',
        date: null,
        assignee: {},
        done: false,
      },
      itemBeforeEdit: {
        name: '',
        date: null,
        assignee: {},
        done: false,
      },
      item: {},
    }
  },
  methods: {
    changeUser(user) {
      this.item.assignee = user
      this.$emit('updateChecklist', this.index, this.item)
    },
    editChangeUser(user) {
      this.item.assignee = user
    },
    changeTime() {
      this.$emit('updateChecklist', this.index, this.item)
    },
    toggleDone() {
      this.item.done = !this.item.done
      this.$emit('updateChecklist', this.index, this.item)
    },
    silentToggleDone() {
      this.item.done = !this.item.done
    },
    saveItem() {
      this.invalidName = false

      if (this.item.name === '') {
        this.invalidName = true

        this.$notify({
          title: this.$t('10_3_toaster_error_title_checklist_name_empty'),
          text: this.$t('10_3_toaster_error_description_checklist_name_empty'),
          type: 'error',
          duration: 5000,
        })

        return
      }

      this.item.type = 'saved'
      this.canEdit = false
      this.item_default = obj_clone(this.item)
      this.$emit('updateChecklist', this.index, this.item)
      this.$emit('setUpdatingItem', false)
    },
    cancelItem() {
      this.canEdit = false
      this.item = obj_clone(this.itemBeforeEdit)
      this.$emit('setUpdatingItem', false)
    },
    editItem() {
      if (this.updatingItem) {
        this.$notify({
          title: this.$t('10_3_toaster_error_title_checklist_already_editing'),
          text: this.$t(
            '10_3_toaster_error_description_checklist_already_editing'
          ),
          type: 'error',
          duration: 5000,
        })

        return
      }

      this.itemBeforeEdit = obj_clone(this.item)
      this.canEdit = true

      this.$nextTick(() => {
        this.$refs.checklist_edit_name.$el.focus()
      })

      this.$emit('setUpdatingItem', true)
    },
    changeDate() {
      console.log('changeitem')
    },
    changeAssignee() {
      console.log('changeAssignee')
    },
    deleteItem() {
      this.confirmDelete = true

      this.$notify({
        title: this.$t('toaster_info_title_confirm_delete'),
        text: this.$t('toaster_info_description_confirm_delete'),
        type: 'info',
        duration: 5000,
      })

      setTimeout(() => {
        this.confirmDelete = false
      }, 2000)
    },
    confirmedDeleteItem() {
      this.confirmDelete = false

      if (this.item.type === 'new') {
        this.$emit('silentDeleteCheckbox')
        this.canEdit = false
        return
      }

      this.$emit('deleteCheckbox')
    },
    cancelDeleteItem() {
      this.confirmDelete = false

      this.$emit('setUpdatingItem', false)
    },
  },
  watch: {
    itemData() {
      this.item = this.itemData
    },
    cancelStates() {
      // this.cancelItem();
    },
  },
}
</script>

<style scoped>
.is-done {
  opacity: 0.45 !important;
}

.checklist-item {
  display: flex;
  position: relative;
  justify-content: space-between;
  margin-top: 16px;
  width: 100%;
}

.checklist-item.is-done {
  opacity: 0.45;
}

.checklist-item__data {
  display: block;
  padding-right: 180px;
  width: 100%;
}

.checklist-item__wrapper {
  display: flex;
  gap: 20px;
  width: 100%;
}

.checklist-item__no,
.checklist-item__title {
  display: flex;
  gap: 8px;
  color: #17252a;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  font-family: Nunito;
  letter-spacing: -0.2px;
}

.checklist-item__title__edit {
}

.checklist-item__title__read {
  max-width: 350px;
  overflow-wrap: break-word;
}

.checklist-item__title__read.is-done {
  text-decoration: line-through;
  /* color: #888F91; */
}

.checklist-item__title__read--placeholder {
  color: #888f91;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  font-family: Nunito;
}

.checklist-item__title__edit textarea {
  box-sizing: border-box;
  border: 1px solid #2ce0c1;
  border-radius: 4px;
  width: 100%;
}

.checklist-item__controll {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  align-items: center;
  gap: 6px;
}

.checklist-item__controll__avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
}

.checklist-item__edit {
  width: 100%;
}

.checklist-item__edit__text {
  display: flex;
}

.checklist-item__edit__no {
  width: 20px;
  color: #17252a;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  font-family: Nunito;
  letter-spacing: -0.2px;
}

.checklist-item__edit__name {
  display: flex;
  gap: 8px;
  width: 100%;
}

.checklist-item__edit__name textarea {
  box-sizing: border-box;
  border: 1px solid #2ce0c1;
  border-radius: 4px;
  padding: 7px 10px;
  width: 100%;

  color: #17252a;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  font-family: Nunito;
  letter-spacing: -0.2px;
}

.checklist-item__edit__name.has-error textarea {
  border: 1px solid #f45050;
}

.checklist-item__edit__controll {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  padding-left: 24px;
}

.checklist-item__edit__buttons {
  display: flex;
  gap: 8px;
}
</style>
