<template>
    <div 
        class="menu-notification"
        v-prevent-parent-scroll
        v-click-outside="hide"
    > 
        <button @click="toggle" class="menu-notification__ico">
            <IcoNotification />
            <span v-if="allUnreadNotifications.length > 0" class="menu-notification__ico__badge"><span v-if="allUnreadNotifications.length < 10">{{ allUnreadNotifications.length }}</span></span>
        </button>
        <div v-if="show" class="menu-notification__data">        
            <div class="menu-notification__wrapper">
                <div class="menu-notification__header">
                    <div class="menu-notification__header__items">
                        <div class="menu-notification__header__title">{{ $t('8_1_3_notification_title') }}</div>                        
                    </div>
                    <div class="menu-notification__header-control">
                        <div class="menu-notification__control__switch">
                            <span v-if="notificationToShow === 'unread'">{{ $t('8_1_3_notification_btn_show_only_unread') }}</span>
                            <span v-if="notificationToShow !== 'unread'">{{ $t('8_1_3_notification_btn_show_only_read') }}</span>
                            <button class="swiper" :class="{'only-unread': notificationToShow === 'unread'}" @click="toggleOnlyUnread()"></button>
                        </div>
                        <div class="menu-notification__header__close">
                            <button @click="hide" :size="'slim'"><IcoClose /></button>
                        </div>
                    </div>                    
                </div>

                <div class="menu-notification__control">
                    <div class="menu-notification__control__menu">
                        <ul>
                            <li><button @click.stop="showDataForCurrentCompany()" :class="{'is-active': thisCompany}">{{ $t('8_1_3_notification_btn_show_this_company') }}<span v-if="selectedCompanyUnreadNotifications.length !== 0">: {{ selectedCompanyUnreadNotifications.length }}</span></button></li>
                            <li><button @click.stop="showDataForAllCompanies()" :class="{'is-active': !thisCompany}">{{ $t('8_1_3_notification_btn_show_all_companies') }}<span v-if="allUnreadNotifications.length !== 0">: {{ allUnreadNotifications.length }}</span></button></li>
                        </ul>
                    </div>                    
                </div>

                <div class="menu-notification__body">
                    <vuescroll :ops="ops">

                        <template v-if="todaysNotifications.length > 0">
                            <div class="menu-notification__meta">
                                <span class="menu-notification__meta__date">{{ $t('8_1_3_notification_subtitle_today') }}</span>
                                <button @click.stop="handleAllAsRead()" class="menu-notification__meta__btn">{{ $t('8_1_3_notification_btn_mark_all_as_read') }}</button>
                            </div>

                            <div class="menu-notification__notifications">
                                <NotificationItem v-for="item in todaysNotifications" :key="item.id" :item="item" type="new" @toggleNotification="toggleNotification" />
                            </div>
                        </template>

                        <template v-if="olderNotifications.length > 0">
                            <div class="menu-notification__meta menu-notification__meta--top">
                                <span class="menu-notification__meta__date">{{ $t('8_1_3_notification_subtitle_older') }}</span>
                                <button v-if="todaysNotifications.length === 0" @click.stop="handleAllAsRead()" class="menu-notification__meta__btn">{{ $t('8_1_3_notification_btn_mark_all_as_read') }}</button>
                            </div>

                            <div class="menu-notification__notifications">
                                <NotificationItem v-for="item in olderNotifications" :key="item.id" :item="item" type="old" @toggleNotification="toggleNotification" />
                            </div>
                        </template>

                    </vuescroll>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import ALL_NOTIFICATIONS from "@/graphql/AllNotifications.gql";
import TOGGLE_NOTIFICATIONS from "@/graphql/ToggleNotifications.gql";
import NOTIFICATION_ALL_READ from "@/graphql/NotificationAllRead.gql";
import NOTIFICATION_COMPANY_READ from "@/graphql/NotificationCompanyRead.gql";
import vuescroll from 'vuescroll';
import ClickOutside from 'vue-click-outside';
import IcoNotification from '@/components/svg/IcoNotification.vue';
import IcoClose from '@/components/svg/IcoClose.vue';
import Button from '@/components/button/Button.vue';
import moment from 'moment';

import NotificationItem from '@/components/NotificationItem.vue';

export default {
    components: {
        vuescroll,
        IcoNotification,  
        NotificationItem,
        IcoClose,
        Button  
    },
    directives: {
        ClickOutside
    },
    apollo: {        
        allNotifications: {
            query: ALL_NOTIFICATIONS,
            update: data => data.notifications,
        },
    },
    computed: {
        refreshContent() {
            return this.$store.getters.getRefreshContent;
        },
        notifications() {
            let _notifications = [];

            if( this.thisCompany && this.selectedCompany ) {
                _notifications = this.allNotifications.filter(
                    _notification => _notification.company.id === this.selectedCompany.id
                );
            } else {
                _notifications = this.allNotifications
            }

            if( this.notificationToShow === 'unread' ) {
                _notifications =  _notifications.filter(
                    _notification => !_notification.read
                );
            }

            return _notifications
        },
        selectedCompany() {            
            return this.$store.getters.getSelectedCompany;
        },
        todaysNotifications() {
            const today = moment()  
            let _notifications = this.notifications;
            
            return _notifications.filter(
                _notifications =>
                    today.isSame(_notifications.date, 'day')
            );
        },
        olderNotifications() {
            const today = moment()  
            let _notifications = this.notifications;            
            
            return _notifications.filter(
                _notifications => !today.isSame(_notifications.date, 'day')
            );
        },
        allUnreadNotifications() {
            return this.allNotifications.filter(
                _notification => !_notification.read
            );
        },
        selectedCompanyUnreadNotifications() {
            return this.allNotifications.filter(
                _notification => !_notification.read && this.selectedCompany.id === _notification.company.id
            );
        }
    },
    created() {
        this.notificationToShow = window.localStorage.getItem("notificationToShow") ?? "unread"

        this.refreshInterval = setInterval(() => {
            this.$apollo.queries.allNotifications.refetch();
        }, 60000)
    },
    destroyed() {
        clearInterval(this.refreshInterval);
    },
    data() {
        return {
            refreshInterval: {},
            allNotifications: [],
            thisCompany: true,
            show: false,
            notificationToShow: "unread",
            ops: {
                vuescroll: {},
                scrollPanel: {
                    scrollingX: false,
                    scrollingY: true,
                    initialScrollY: false,
                    initialScrollX: false,
                },
                rail: {},
                bar: {
                    onlyShowBarOnScroll: true,
                    keepShow: false,
                    minSize: 0,
                }
            },
        }
    },
    methods: {
        showDataForCurrentCompany() {
            this.thisCompany = true
        },
        showDataForAllCompanies() {
            this.thisCompany = false
        },
        hide() {
            this.show = false;
        },
        toggle() {
            this.show = !this.show;
        },
        handleAllAsRead(){
            if(this.thisCompany) {
                this.setAllCompanyNotificationsAsRead()
                return                
            }

            this.setAllNotificationsAsRead()
        },
        setAllNotificationsAsRead(){
            try {
                this.$apollo
                .mutate({
                    mutation: NOTIFICATION_ALL_READ,                    
                    update: (store, { data: { toggleNotifications } }) => {
                        
                        this.$notify({
                            title: this.$t("8_1_3_toaster_success_title_notification_all_read"),
                            text: this.$t("8_1_3_toaster_success_description_notification_all_read"),
                            type: "success",
                            duration: 5000,
                        });
                    },
                })
                .then((data) => {
                    this.$apollo.queries.allNotifications.refetch();
                })
                .catch((error) => {
                    this.$notify({
                        title: this.$t("8_1_3_toaster_error_title_notification_all_read"),
                        text: this.$t("global_toaster_error_description"),
                        type: "error",
                        duration: 5000,
                    });
                });
            } catch (e) {
                this.$notify({
                    title: this.$t("8_1_3_toaster_error_title_notification_all_read"),
                    text: this.$t("global_toaster_error_description"),
                    type: "error",
                    duration: 5000,
                });
            }
        },
        setAllCompanyNotificationsAsRead() {
            try {
                this.$apollo
                .mutate({
                    mutation: NOTIFICATION_COMPANY_READ,
                    variables: {
                        companyId: this.selectedCompany.id,
                    },
                    update: (store, { data: { toggleNotifications } }) => {
                        
                        this.$notify({
                            title: this.$t("8_1_3_toaster_success_title_notification_company_read"),
                            text: this.$t("8_1_3_toaster_success_description_notification_company_read"),
                            type: "success",
                            duration: 5000,
                        });
                    },
                })
                .then((data) => {
                    this.$apollo.queries.allNotifications.refetch();
                })
                .catch((error) => {
                    this.$notify({
                        title: this.$t("8_1_3_toaster_error_title_notification_company_read"),
                        text: this.$t("global_toaster_error_description"),
                        type: "error",
                        duration: 5000,
                    });
                });
            } catch (e) {
                this.$notify({
                    title: this.$t("8_1_3_toaster_error_title_notification_company_read"),
                    text: this.$t("global_toaster_error_description"),
                    type: "error",
                    duration: 5000,
                });
            }
        },
        toggleNotification(notificationId, read) {
            try {
                this.$apollo
                .mutate({
                    mutation: TOGGLE_NOTIFICATIONS,
                    variables: {
                        notificationId: notificationId,    
                        read: read,                
                    },
                    update: (store, { data: { toggleNotifications } }) => {

                        if( read ) {
                            this.$notify({
                                title: this.$t("8_1_3_toaster_success_title_notification_read"),
                                text: this.$t("8_1_3_toaster_success_description_notification_read"),
                                type: "success",
                                duration: 5000,
                            });
                        } else {
                            this.$notify({
                                title: this.$t("8_1_3_toaster_success_title_notification_unread"),
                                text: this.$t("8_1_3_toaster_success_description_notification_unread"),
                                type: "success",
                                duration: 5000,
                            });
                        }
                        
                        this.$apollo.queries.allNotifications.refetch();
                    },
                })
                .catch((error) => {

                    if( read ) {
                        this.$notify({
                            title: this.$t("8_1_3_toaster_error_title_notification_read"),
                            text: this.$t("global_toaster_error_description"),
                            type: "error",
                            duration: 5000,
                        });
                    } else {
                        this.$notify({
                            title: this.$t("8_1_3_toaster_error_title_notification_unread"),
                            text: this.$t("global_toaster_error_description"),
                            type: "error",
                            duration: 5000,
                        });
                    }
                    
                });
            } catch (e) {
                if( read ) {
                    this.$notify({
                        title: this.$t("8_1_3_toaster_error_title_notification_read"),
                        text: this.$t("global_toaster_error_description"),
                        type: "error",
                        duration: 5000,
                    });
                } else {
                    this.$notify({
                        title: this.$t("8_1_3_toaster_error_title_notification_unread"),
                        text: this.$t("global_toaster_error_description"),
                        type: "error",
                        duration: 5000,
                    });
                }
            }
        },
        toggleOnlyUnread() {

            if( this.notificationToShow === "all" ) {
                this.notificationToShow = "unread"
            } else {
                this.notificationToShow = "all"
            }

            window.localStorage.setItem("notificationToShow", this.notificationToShow)
        }
    },
    watch:{
        refreshContent() {
            this.$apollo.queries.allNotifications.refetch();
        },
    }
}
</script>

<style scoped>
    .menu-notification{
        position: relative;
    }

    .menu-notification__ico{
        position: relative;
        top: 3px;
        border: none;
        padding: 0;
        background: none;
        margin: 0;
        cursor: pointer;
    }

    .menu-notification__ico__badge{
        position: absolute;
        top: -3px;
        right: -7px;
        display: inline-block;
        width: 17px;
        height: 17px;
        border-radius: 50%;
        background-color: #F45050;
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.2px;
        color: #FFFFFF;
        border: 1px solid #fff;
    }

    .menu-notification__header__close button{
        padding: 0;
        background: transparent;
        border: none;
        cursor: pointer;
    }

    .menu-notification__data{
        position: absolute;
        z-index: 99991;
        top: 100%;
        right: 0;
        left: auto;
        width: 445px;
        background: #FFFFFF;
        box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.06);
    }

    .menu-notification__header{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 17px 20px 2px 20px;
    }

    .menu-notification__header__items{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 7px;
    }

    .menu-notification__header__title{
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 18px;
        letter-spacing: -0.2px;
        color: #17252A;
        padding-left: 9px;
    }

    .menu-notification__header-control{
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .menu-notification__badge{
        display: inline-block;
        width: 17px;
        height: 17px;
        line-height: 17px;
        text-align: center;
        border-radius: 50%;
        background: #F45050;

        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.2px;
        color: #FFFFFF;
    }

    .menu-notification__control{
        width: 100%;
        display: flex;        
        justify-content: space-between;
        border-bottom: 1px solid #E7E9EF;
        margin-top: 13px;
    }

    .menu-notification__control__menu{
        
    }

    .menu-notification__control__menu ul{
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
    }

    .menu-notification__control__menu ul li{
        display: inline-block;
    }

    .menu-notification__control__menu ul li button{
        position: relative;
        border: none;
        background: none;
        padding: 9px 20px;
        cursor: pointer;
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: #888F91;
    }

    .menu-notification__control__menu ul li button.is-active{
        color: #2CE0C1;
    }

    .menu-notification__control__menu ul li button:after{
        opacity: 0;
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #2CE0C1;
        height: 3px;
        border-top-left-radius: 62px;
        border-top-right-radius: 62px;
    }

    .menu-notification__control__menu ul li button.is-active:after{
        opacity: 1;
    }

    .menu-notification__control__switch{
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        color: #888F91;
        display: flex;
        align-items: center;
        gap: 5px;
    }



    .menu-notification__body{
        height: 450px;
        overflow: hidden;
    }

    .menu-notification__meta{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
    }

    .menu-notification__meta--top{
        border-top: 1px solid #E7E9EF;
    }

    .menu-notification__meta__date{
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 140%;
        color: #5F687A;
        text-transform: uppercase;
    }

    .menu-notification__meta__btn{
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 140%;
        color: #888F91;
        border: none;
        padding: 0;
        background: transparent;
        cursor: pointer;
    }

    .menu-notification__meta__btn:hover{
        text-decoration: underline;
    }
</style>