<template>
    <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path :style="{'stroke': textColor}" d="M1.43652 1.74158L6.43652 6.74158L11.4365 1.74158" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>

export default {
    props: {
        textColor: {
            type: String,
            default: '#17252A'
        }
    }
}
</script>
