<template>
    <div 
        @click="editContent()"
        class="idea-day"
        :class="{
            'is-completed': item.state === 'PUBLISHED' && item.luxonDate.toMillis() < todayTimeStamp,
            'is-overdue': item.state != 'PUBLISHED' && item.luxonDate.toMillis() < todayTimeStamp,
            'read-only': role === 'READ_ONLY'
        }" 
        :draggable="role === 'READ_ONLY' ? false : true"
        @dragstart="dragStart" 
        @dragend="dragEnd"
        @dragover.prevent
        >
        <div class="idea-day__header">
            <div 
                class="idea-day__label"
                :style="{ color: getColor( computedChannelName ) }"
                >
                {{ computedChannelName }}
            </div>
            <div v-if="!item.eod" class="idea-day__time">
               <IcoTime /> {{ timeToShow }}
            </div>

            <div class="idea-day__controll" v-click-outside="hideControll">
                <VDropdown 
                    :skidding="-42" 
                    :placements="'bottom-end'" 
                    :eagerMount="autoHide" 
                    :auto-hide="autoHide" 
                    :triggers="[]" 
                    :shown="showControll"
                    @hide="closeData" 
                    >
                    <button class="idea-day__controll__button" @click.stop="toggleControll"><IcoKebabLarge /></button>
                    <template #popper>  
                        <div class="idea-day__controll__items">
                            <ul>
                                <li><button @click.stop="shareContent()"><IcoShare /> {{ $t('8_3_content_btn_share') }}</button></li>
                                <li v-if="role !== 'READ_ONLY'"><button @click.stop="duplicateContent()"><IcoDuplicate /> {{ $t('8_3_content_btn_duplicate') }}</button></li>
                                <li v-if="role !== 'READ_ONLY'"><button @click.stop="deleteContent()"><IcoBin /> {{ $t('8_3_content_btn_delete') }}</button></li>
                            </ul>
                        </div>
                    </template>                    
                </VDropdown>
            </div>
        </div>

        <div class="idea-day__title">
            {{ item.name }}
        </div>

        <div class="idea-day__data">
            <div class="idea-day__data__line">
                <div class="idea-day__data__label">
                    {{ $t('8_3_label_stdc') }}:
                </div>
                <div class="idea-day__data__value idea-day__data__value--capitalize">
                    {{ item.stdcs.join(", ").toLowerCase() }}
                </div>    
            </div>
            <div class="idea-day__data__line">
                <div class="idea-day__data__label">
                    {{ $t('8_3_label_persona') }}:
                </div>
                <div class="idea-day__data__value">
                    <span v-for="(persona, index) in item.personas" :key="index">{{ persona.name }}<span v-if="index < item.personas.length - 1">, </span></span>
                </div>    
            </div>
            <div class="idea-day__data__line">
                <div class="idea-day__data__label">
                    {{ $t('8_3_label_product') }}:
                </div>
                <div class="idea-day__data__value">
                    <span v-for="(product, index) in item.products" :key="index">{{ product.name }}<span v-if="index < item.products.length - 1">, </span></span>
                </div>    
            </div>
        </div>

        <div class="idea-day__tags">
            <Tag v-for="(item, index) in item.tags" :key="index" :item="item" />
        </div>

        <div class="idea-day__footer">
            <IdeaTaskCompact :items="item.checklist" />

            <Avatar 
                :size="'small'"
                :name="item.owner.name"
                :user="item.owner"
                />
        </div>
    </div>
</template>

<script>
import { ColorService } from "@/services/ColorService.js";
import { DateService } from "@/services/DateService.js";
import { DateTime } from 'luxon'

import moment from 'moment';
import Tag from '@/components/Tag.vue';
import IdeaTaskCompact from '@/components/IdeaTaskCompact.vue';
import Avatar from '@/components/Avatar.vue';

import IcoKebabLarge from '@/components/svg/IcoKebabLarge.vue';
import IcoShare from '@/components/svg/IcoShare.vue';
import IcoDuplicate from '@/components/svg/IcoDuplicate.vue';
import IcoBin from '@/components/svg/IcoBin.vue';
import IcoTime from "@/components/svg/IcoTime.vue";

import ClickOutside from 'vue-click-outside';

export default({
    components: {
        Avatar,
        Tag,
        IcoKebabLarge,
        IcoShare,
        IcoDuplicate,
        IcoBin,
        IdeaTaskCompact,
        IcoTime
    },
    directives: {
        ClickOutside
    },
    props: {
        item: {
            type: Object,
            require: true,
        }
    },
    filters: {
        time: function(time) {
            return moment(time).format('H:mm');
        }
    },
    computed: {
        role() {
            return this.$store.getters.getMeCompanyRole;
        },
        selectedCompany() {
            return this.$store.getters.getSelectedCompany;
        },
        computedChannelName() {
            if(this.item.channel && this.item.channel.name) {
                return this.item.channel.name
            }

            return "";
        },
        todayTimeStamp() {
            const date = DateTime.now().setZone( this.selectedCompany.zoneId );
            return date.toMillis();
        },
        timeToShow() {
            return this.item.luxonDate.toFormat("H:mm")
        }
    },
    data() {
        return {
            today: new Date(),
            showControll: false,
            autoHide: true,
        }
    },
    methods: {
        async shareContent() {
            this.showControll = false;
            const link = "" + window.location.protocol + '//' + window.location.host + "/calendar/" + this.selectedCompany.id + "/" + this.item.id;
            
            await navigator.clipboard.writeText(link);
          
            this.$notify({
                title: this.$t('share_content_title_copied_to_clipboard'), 
                text: this.$t('share_content_description_copied_to_clipboard'), 
                type: 'info',
                duration: 5000
            });
        }, 
        duplicateContent() {
            this.showControll = false;
            this.$store.commit('setDuplicateContentModalId', this.item.id);
        },
        deleteContent() {
            this.showControll = false;
            this.$store.commit("setContentToDelete", this.item);
        },
        editContent() {
            this.$store.commit("setEditContentModalStatus", this.item);
        },
        getColor(name) {
            return ColorService.computedColor(name);
        },
        dragStart(e) {
            this.$store.commit("setGlobalDrag", true);
            const target = e.target;
            e.dataTransfer.setData('idea_id', this.item.id);
        },
        dragEnd(e) {
            this.$store.commit("setGlobalDrag", false);
            this.$store.commit("setActiveDropZone", "");
        },
        toggleControll() {
            this.showControll = !this.showControll;
            this.autoHide = false; setTimeout(() => this.autoHide = true, 300);
        },
        hideControll() {
            this.showControll = false;
        },
        closeData() {
            this.showControl = false;
        }
    }
})
</script>
