<template>
    <div class="search" :class="color">
        <input v-model="data" type="text" :placeholder="$t('6_3_placeholder_search')">
        <IcoSearch />
    </div>
</template>

<script>
import IcoSearch from '@/components/svg/IcoSearch.vue';

export default {
    components: {
        IcoSearch
    },
    props: {
        value: {
            type: String,
            default: ""
        },
        color: {
            type: String,
            default: "dark"
        }
    },
    mounted() {
        this.data = this.value;
    },
    data() {
        return {
            data: ''
        }
    }, methods: {

    },
    watch: {
        data() {
            this.$emit('input', this.data);
        }
    }
}
</script>

<style scoped>
.search{        
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;
    background: #EAEAEA;
    mix-blend-mode: normal;
    border-radius: 8px;
    padding: 8px 12px;
}

.search.light {
    background: #F6F6F7;
}

.search input{
    width: 100%;
    background: transparent;
    border: none;

    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1;
    color: #17252A;
}

.search input::placeholder{
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 1;
    color: #AEB3B5;
}

.search input:focus{
    outline: none;
}
</style>