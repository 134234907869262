<template>
  <div class="small-date-picker">
    <div class="small-date-picker__calendar">
      <VDropdown
        popperClass="popper-modal"
        :triggers="[]"
        :shown="show"
        :eagerMount="autoHide"
        :auto-hide="autoHide"
        @hide="show = false"
      >
        <div class="small-date-picker--selected" @click="toggle">
          <slot :show="show"></slot>
        </div>

        <template #popper>
          <div class="date-picker__wrapper">
            <v-date-picker
              ref="small_date_picker"
              mode="date"
              v-model="date"
              :attributes="attrs"
              :locale="$i18n.locale"
              :first-day-of-week="2"
            />
          </div>
        </template>
      </VDropdown>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
  directives: {
    ClickOutside,
  },
  props: ['value', 'publishDate'],
  data() {
    return {
      date: '',
      show: false,
      canClose: true,
      canBeClosed: false,
      attrs: [],
      autoHide: true,
    }
  },
  methods: {
    toggle() {
      this.canBeClosed = false
      this.show = !this.show

      if (this.show) {
        this.autoHide = false
        setTimeout(() => (this.autoHide = true), 300)
      }
    },
    hide() {
      this.canClose = false
      this.show = false

      setTimeout(() => {
        this.canClose = true
      }, 300)
    },
  },
  created() {
    if (this.value) {
      this.date = new Date(this.value)
    } else {
      this.date = new Date()
    }
  },
  watch: {
    date() {
      if (this.show) {
        this.$emit('input', this.date)
        this.$emit('changeTime')
        this.show = false
      }
    },
    show() {
      if (this.show && this.date !== '') {
        this.attrs = [
          {
            highlight: {
              class: 'current-date',
              contentClass: 'current-date-text',
            },
            dates: new Date(this.date),
          },
          {
            highlight: {
              class: 'publish-date',
              contentClass: 'publish-date-text',
            },
            dates: new Date(this.publishDate),
          },
        ]

        setTimeout(() => {
          const calendar = this.$refs.small_date_picker

          if (!this.value) {
            calendar.move(new Date())
          } else {
            calendar.move(new Date(this.date))
          }
        }, 100)
      }
    },
  },
}
</script>

<style scoped>
.small-date-picker {
  position: relative;
}
</style>
