var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Menu'),(!_vm.billingOverdue)?_c('div',{staticClass:"wrapper wrapper--calendar wrapper--full"},[_c('CalendarHeader',{attrs:{"selectedDate":_vm.formattedCurrentDate,"type":'calendarWeekView',"nameList":_vm.nameList},on:{"selectToday":_vm.selectToday,"datePrev":_vm.datePrev,"dateNext":_vm.dateNext,"dropToContainer":_vm.dropToContainer}}),_c('GlobalNotification'),_c('div',{staticClass:"calendar-week"},[_vm._l((_vm.days),function(day,index){return _c('div',{key:'day-name-' + index,staticClass:"calendar-week__weekday",class:{
          'current-day': day.date.toMillis() === _vm.currentDate.toMillis(),
          'old-day': day.date.toMillis() < _vm.currentDate.toMillis(),
          'future-day': day.date.toMillis() > _vm.currentDate.toMillis(),
        }},[_c('div',{staticClass:"calendar-week__weekday__name"},[_vm._v(" "+_vm._s(_vm.getDayNameFromDate(day.date))+" ")])])}),(!_vm.loaded)?_c('div',{staticClass:"calendar-week__day",style:({
          height: (_vm.availableSpace + "px"),
        })},[_c('div',{staticClass:"calendar-week__day__wrapper"},[_vm._m(0),_c('div',{staticClass:"calendar-week__day__body"},[_c('div',_vm._l((_vm.randomNumber),function(item){return _c('IdeaWeekPlaceholder',{key:item})}),1)])])]):_vm._e(),(!_vm.loaded)?_c('div',{staticClass:"calendar-week__day",style:({
          height: (_vm.availableSpace + "px"),
        })},[_c('div',{staticClass:"calendar-week__day__wrapper"},[_vm._m(1),_c('div',{staticClass:"calendar-week__day__body"},[_c('div',[_c('IdeaWeekPlaceholder'),_c('IdeaWeekPlaceholder'),_c('IdeaWeekPlaceholder'),_c('IdeaWeekPlaceholder')],1)])])]):_vm._e(),(!_vm.loaded)?_c('div',{staticClass:"calendar-week__day",style:({
          height: (_vm.availableSpace + "px"),
        })},[_c('div',{staticClass:"calendar-week__day__wrapper"},[_vm._m(2),_c('div',{staticClass:"calendar-week__day__body"},[_c('div',[_c('IdeaWeekPlaceholder'),_c('IdeaWeekPlaceholder')],1)])])]):_vm._e(),(!_vm.loaded)?_c('div',{staticClass:"calendar-week__day",style:({
          height: (_vm.availableSpace + "px"),
        })},[_c('div',{staticClass:"calendar-week__day__wrapper"},[_vm._m(3),_c('div',{staticClass:"calendar-week__day__body"},[_c('div',[_c('IdeaWeekPlaceholder'),_c('IdeaWeekPlaceholder'),_c('IdeaWeekPlaceholder'),_c('IdeaWeekPlaceholder'),_c('IdeaWeekPlaceholder')],1)])])]):_vm._e(),(!_vm.loaded)?_c('div',{staticClass:"calendar-week__day",style:({
          height: (_vm.availableSpace + "px"),
        })},[_c('div',{staticClass:"calendar-week__day__wrapper"},[_vm._m(4),_c('div',{staticClass:"calendar-week__day__body"},[_c('div',[_c('IdeaWeekPlaceholder'),_c('IdeaWeekPlaceholder'),_c('IdeaWeekPlaceholder')],1)])])]):_vm._e(),(!_vm.loaded)?_c('div',{staticClass:"calendar-week__day",style:({
          height: (_vm.availableSpace + "px"),
        })},[_c('div',{staticClass:"calendar-week__day__wrapper"},[_vm._m(5),_c('div',{staticClass:"calendar-week__day__body"},[_c('div',[_c('IdeaWeekPlaceholder'),_c('IdeaWeekPlaceholder')],1)])])]):_vm._e(),(!_vm.loaded)?_c('div',{staticClass:"calendar-week__day",style:({
          height: (_vm.availableSpace + "px"),
        })},[_c('div',{staticClass:"calendar-week__day__wrapper"},[_vm._m(6),_c('div',{staticClass:"calendar-week__day__body"},[_c('div',[_c('IdeaWeekPlaceholder'),_c('IdeaWeekPlaceholder'),_c('IdeaWeekPlaceholder'),_c('IdeaWeekPlaceholder'),_c('IdeaWeekPlaceholder'),_c('IdeaWeekPlaceholder')],1)])])]):_vm._e(),(_vm.loaded)?_vm._l((_vm.days),function(day,index){return _c('div',{key:'day-data-' + index,staticClass:"calendar-week__day",class:{
            'current-day': day.date.toMillis() === _vm.currentDate.toMillis(),
            'old-day': day.date.toMillis() < _vm.currentDate.toMillis(),
            'future-day': day.date.toMillis() > _vm.currentDate.toMillis(),
          },style:({
            height: (_vm.availableSpace + "px"),
          }),attrs:{"data-date":day.date}},[(_vm.globalDragStatus)?_c('div',{staticClass:"calendar-week__drop-area",class:{
              'is-dropped': _vm.activeDropzone === 'day-' + day.date.toMillis(),
            }},[_c('span',[_vm._v(_vm._s(_vm.$t('8_2_drop_area')))])]):_vm._e(),_c('div',{staticClass:"calendar-week__day__wrapper",on:{"drop":function($event){$event.preventDefault();return _vm.drop($event, day.date)},"mouseleave":_vm.hideExtra,"mouseover":function($event){return _vm.showExtra(day.date)},"dragover":function($event){$event.preventDefault();_vm.showActiveDrop('day-' + day.date.toMillis())}}},[_c('vuescroll',{attrs:{"ops":_vm.scrollPanelIdea}},[_c('div',{staticClass:"calendar-week__day__header"},[_c('span',{class:{
                    'current-day':
                      day.date.toMillis() === _vm.currentDate.toMillis(),
                    'old-day': day.date.toMillis() < _vm.currentDate.toMillis(),
                    'future-day':
                      day.date.toMillis() > _vm.currentDate.toMillis(),
                  }},[_c('div',{staticClass:"calendar-week__day__no"},[_vm._v(" "+_vm._s(_vm.getDayFromDate(day.date))+" ")]),_c('span',{staticClass:"calendar-wweek__day-labels"},[(day.suggestions.length > 0)?_c('span',{staticClass:"content-suggestion"},[_c('IcoSuggestion')],1):_vm._e(),(day.ideas.length > 0)?_c('span',{staticClass:"content-counter"},[_c('IcoContent'),_vm._v(" "+_vm._s(day.ideas.length)+" ")],1):_vm._e()])])]),_c('div',{staticClass:"calendar-week__day__body"},[_vm._l((day.suggestions),function(item){return (_vm.ideasContainerStatus)?_c('Suggestion',{key:item.id,attrs:{"size":"large","content":item}}):_vm._e()}),_vm._l((day.ideas),function(item){return _c('IdeaWeek',{key:item.id,attrs:{"id":item.key,"item":item},on:{"deleteContent":_vm.deleteContent}})}),(_vm.dayShowExtra === day.date && _vm.role !== 'READ_ONLY')?_c('div',{staticClass:"calendar-week__add-content"},[_c('button',{staticClass:"calendar-week__add-content__button",on:{"click":function($event){return _vm.addContent(day.date)}}},[_c('IcoPlus'),_vm._v(" "+_vm._s(_vm.$t('8_2_add_content'))+" ")],1)]):_vm._e()],2)])],1)])}):_vm._e()],2)],1):_c('ExpiredCompany',{attrs:{"company":_vm.selectedCompany}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar-week__day__header"},[_c('span')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar-week__day__header"},[_c('span')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar-week__day__header"},[_c('span')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar-week__day__header"},[_c('span')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar-week__day__header"},[_c('span')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar-week__day__header"},[_c('span')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar-week__day__header"},[_c('span')])}]

export { render, staticRenderFns }