<template>
  <div class="container container--top">
    <div class="inactive-company">
      <InactiveCompany />

      <div class="inactive-company__data">
        <div class="inactive-company__name">
          <CompanyAvatar :company="company" />
          {{ company.name }}
        </div>
        <h1 class="main-title">{{ $t('6_5_title') }}</h1>

        <p class="inactive-company__description">{{ $t('6_5_description') }}</p>

        <div class="inactive-company__buttons">
          <Button
            @click="sendEmail()"
            class="inactive-company__button"
            type="primary"
          >
            {{ $t('6_5_btn_open_billing') }}
          </Button>
          <p
            class="inactive-company__information"
            v-html="$t('6_5_information')"
          ></p>
        </div>

        <a style="display: none" target="_blank" ref="mailto" :href="mailToLink">
          {{ $t('6_5_btn_open_billing') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/button/Button.vue'
import CompanyAvatar from '@/components/CompanyAvatar.vue'
import InactiveCompany from '@/components/svg/InactiveCompany.vue'

export default {
  components: {
    CompanyAvatar,
    InactiveCompany,
    Button,
  },
  props: ['company'],
  computed: {
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
    mailToBody() {
      let _content = ''
      const content_cs =
        'Společnost: {companyName}, {companyId} \nZašlete mi fakturu na předplatné: Sólo Dino / Triasový Tým / Jurská Smečka / Obsahový T-Rex \nMám zájem o období: ROČNÍ / MĚSÍČNÍ \n\nSlevový kód:\nFakturační údaje: \n- Název firmy \n- Sídlo (ulice, město, psč, země) \n- IČO \n- DIČ'
      const content_en = `Company: {companyName}, {companyId} \nSend me an invoice for subscription: Solo Dino / Triassic Team / Jurassic Crew / Content T-Rex \nI am interested in this period: YEARLY / MONTHLY \n\nPromo code: \nBilling info: \n- Company name \n- Address (street, city, postal code, country) \n- ID \n- TID (VAT no.)`

      _content = content_cs

      if (this.$i18n.locale === 'en') {
        _content = content_en
      }

      _content = _content
        .replace('{companyName}', this.company.name)
        .replace('{companyId}', this.company.id)

      return encodeURIComponent(_content)
    },
    mailToLink() {
      const subject = this.$t('6_5_email_subject_request_subscription')

      return (
        'mailto:support@contesaur.com?subject=' +
        subject +
        '&body=' +
        this.mailToBody
      )
    },
  },
  data() {
    return {}
  },
  methods: {
    sendEmail() {
      this.$refs.mailto.click()
    },
  },
}
</script>

<style scoped>
.inactive-company {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  justify-content: center;
  gap: 60px;
  height: calc(100vh - 180px);
}

.inactive-company__data {
  flex-shrink: 0;
  flex-grow: 0;
  width: 50%;
}

.inactive-company__description {
  margin-top: 15px;
  max-width: 380px;
  color: #888f91;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  font-family: 'Nunito';
  text-align: left;
}

.inactive-company__button {
  flex-shrink: 0;
  flex-grow: 0;
}

.inactive-company__information {
  margin-top: 15px;
  width: 100%;
}

.inactive-company__name {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #17252a;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  line-height: 1;
  font-family: 'Nunito';
}

.inactive-company .main-title {
  text-align: left;
  margin-top: 25px;
}

.inactive-company__buttons {
  margin-top: 40px;
}
</style>
