<template>
    <div class="sp" :class="{
            'is-open': showData
            }">
        <div
            v-if="slim"
            class="select-person"
            :class="{
                'slim': slim,
                'is-open': showData
                }"
            v-click-outside="hide"
            >
            <div @click="toggle" class="select-person__selected" :class="{'is-disabled': disabled}">

                <div v-if="selected.id" class="select-person-selected">
                    <div class="select-person-selected__avatar">
                        <Avatar :name="selected.name" :user="selected" />
                    </div>
                    <div class="select-person-selected__data">
                        <div class="select-person-selected__name">{{ selected.name }}</div>
                        <div class="select-person-selected__role">{{ selected.position }}</div>
                    </div>
                </div>

                <div v-else class="select-person__placeholder">{{ $t('9_2_select_user') }}</div>

                <span :class="{'rotate': showData}">
                    <IcoDown />
                </span>
            </div>
            <div v-if="showData" class="select-person__data">
                <div
                    class="select-person__item"
                    v-for="(item, index) in usersList"
                    :key="index"
                    >

                    <SelectPersonItem
                        :user="item"
                        :index="index"
                        @selectPerson="selectPerson(index)"
                        />
                </div>
            </div>
        </div>


        <div v-else class="select-person" v-click-outside="hide">
            <div @click="toggle" class="select-person__selected" :class="{'is-disabled': disabled}">
                <SelectPersonItem v-if="selected.id" :user="selected" />
                <div v-else class="select-person__placeholder">{{ $t('9_2_select_user') }}</div>

                <span :class="{'rotate': showData}">
                    <IcoDown />
                </span>
            </div>
            <div v-if="showData && usersList.length > 0" class="select-person__data">
                <div
                    class="select-person__item"
                    v-for="(item, index) in usersList"
                    :key="index"
                    >

                    <SelectPersonItem
                        :user="item"
                        :index="index"
                        @selectPerson="selectPerson(index)"
                        />
                </div>
            </div>

            <div v-if="showData && usersList.length == 0" class="select-person__data">
                <p style="padding: 15px;">{{ $t("9_1_no_persons") }}</p>
            </div>
        </div>

    </div>

</template>

<script>
import SelectPersonItem from '@/components/SelectPersonItem.vue';
import Avatar from '@/components/Avatar.vue';
import FormGroup from '@/components/FormGroup.vue';

import ClickOutside from 'vue-click-outside';
import IcoDown from '@/components/svg/IcoDown.vue';

export default {
    components: {
        SelectPersonItem,
        Avatar,
        FormGroup,
        IcoDown
    },
    directives: {
        ClickOutside
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object,
            default() {
                return {}
            }
        },
        slim: {
            type: Boolean,
            default: false,
        },
        users: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    computed: {
        usersList() {
            let list = [];
            this.users.forEach(item => {
                list.push({
                    id: item.id,
                    name: item.label,
                    position: item.position,
                });
            });

            list.sort(function(a, b) {
              return a.name.localeCompare(b.name);
            });

            return list;
        }
    },
    created() {
        this.selected = this.value;
    },
    data() {
        return {
            showData: false,
            selected: {},
        }
    },
    methods: {
        selectPerson(index) {
            this.selected = this.usersList[index];
            this.showData = false;

            this.$emit('updateUser', this.selected);
        },
        hide() {
            this.showData = false;
            this.$emit('blur');
        },
        toggle() {
            if( this.disabled ) {
                this.showData = false
                return
            }
            this.showData = !this.showData;

            if(this.showData) {
                this.$emit('focus');
            } else {
                this.$emit('blur');
            }
        },
        unsetUser() {
            this.selected = {};
            this.showData = false;
            this.$emit('updateUser', this.selected);
        }
    },
    watch: {
        value() {
            this.selected = this.value;
        }
    }
}
</script>


<style>
    .sp.is-open .select-person__selected span svg path{
        stroke: #2CE0C1 !important;
    }

    .select-person.slim .select-person__selected span svg{
        display: none;
    }

    .select-person.slim span.rotate {
        height: 5px;
    }

    .select-person.slim.is-open .select-person__selected span svg{
        display: block;
    }

    .select-person.slim .select-person-selected__data{
        width: 270px;
    }
</style>


<style scoped>

    .select-person-selected{
        display: flex;
        padding-top: 4px;
        gap: 8px;
    }

    .select-person-selected__data{
        width: 154px;
        overflow: hidden;
    }

    .select-person-selected__avatar{
        width: 36px;
    }

    .select-person-selected__name{
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        /* identical to box height */

        /* 100 Black */
        color: #17252A;

        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        height: 18px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .select-person-selected__role{
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;

        color: #AEB3B5;

        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        height: 16px;
        text-overflow: ellipsis;
    }

    .select-person{
        position: relative;
        padding: 10px 15px;
    }

    .select-person.slim{
        border: 1px solid transparent;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 2px 4px;
    }

    .select-person.slim.is-open{
        border: 1px solid #2CE0C1;
    }

    .select-person__selected{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 34px;
        line-height: 34px;
        cursor: pointer;
        overflow: hidden;
    }

    .select-person__selected.is-disabled{
        cursor: auto !important;
    }

    .select-person__selected span{
        transform: rotate(0deg);
        transition-duration: 0.2s;
        transition-property: transform;
        display: inline-block;
        height: 8px;
        line-height: 8px;

        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 10px;
    }

    .select-person__selected span svg{
        height: 8px;
        line-height: 8px;
    }

    .select-person__selected span.rotate{
        transform: rotate(180deg);
        transition-duration: 0.2s;
        transition-property: transform;
    }

    .select-person__placeholder{
        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: #C5C5C5;

        height: 30px;
        line-height: 30px;
    }

    .select-person__data{
        position: absolute;
        z-index: 992;
        padding: 0;
        top: 100%;
        left: 10px;
        right: 10px;
        border-radius: 8px;
        background: #FFFFFF;
        box-shadow: 15px 15px 60px rgba(0, 0, 0, 0.05);
    }

    .select-person__item{
        /* margin-top: 14px; */
        cursor: pointer;
        padding: 7px 15px;
    }

    .select-person__item:hover{
        background-color: #F6F6F7;
    }

    .select-person__item:first-child{
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .select-person__item:last-child{
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
</style>
