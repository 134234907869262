<template>
  <Sidebar>
    <h2>{{ $t("3_3_title") }}</h2>
    <p>{{ $t("3_3_description") }}</p>

    <div class="line">
      <FormGroup
        :focused="focusNewEmail"
        :label="$t('3_3_label_new_password')"
        :required="true"
        :validationError="validationError.new_email"
      >
        <input
          class="form-group__input"
          :type="inputType"
          placeholder="••••••••"
          v-model="new_email"
          @focus="focusNewEmail = true"
          @blur="focusNewEmail = false"
          :maxlength="length.short"
        />

        <button @click="togglePassword" class="toggle-password">
          <span v-if="!showPassword"><IcoShowPassword /></span>
          <span v-if="showPassword"><IcoHiddePassword /></span>
        </button>
      </FormGroup>
    </div>

    <div class="line">
      <FormGroup
        :focused="focusConfirmEmail"
        :label="$t('3_3_label_confirm_password')"
        :required="true"
        :validationError="validationError.confirm_email"
      >
        <input
          class="form-group__input"
          :type="inputTypeConfirm"
          :maxlength="length.short"
          placeholder="••••••••"
          v-model="confirm_email"
          @focus="focusConfirmEmail = true"
          @blur="focusConfirmEmail = false"          
        />

        <button @click="togglePasswordConfirm" class="toggle-password">
          <span v-if="!showPasswordConfirm"><IcoShowPassword /></span>
          <span v-if="showPasswordConfirm"><IcoHiddePassword /></span>
        </button>
      </FormGroup>
    </div>

    <div class="line">
      <Button
        style="width: 100%"
        @click="recoverPassword()"
        :size="'max'"
        :type="'primary'"
        >{{ $t("3_3_btn_reset_password") }}</Button
      >
    </div>

    <div class="line">
      <p>
        {{ $t("3_3_trouble_recover") }}
        <MailToSupport :subject="$t('3_3_support_mail_subject')" />
      </p>
    </div>
  </Sidebar>
</template>

<script>
import textLength from "@/constants/TextLengthConstant.js";
import { URL } from "@/constants/URLConstant.js";
import { SignService } from "@/services/SignService.js";

import Sidebar from "@/components/layout/Sidebar.vue";
import FormGroup from "@/components/FormGroup.vue";
import Button from "@/components/button/Button.vue";
import Link from "@/components/design/Link.vue";
import MailToSupport from '@/components/MailToSupport.vue';

import IcoLogoGoogle from "@/components/svg/IcoLogoGoogle.vue";
import IcoArrowBack from "@/components/svg/IcoArrowBack.vue";
import IcoShowPassword from "@/components/svg/IcoShowPassword.vue";
import IcoHiddePassword from "@/components/svg/IcoHiddePassword.vue";

export default {
  components: {
    Sidebar,
    FormGroup,
    Button,
    Link,
    IcoLogoGoogle,
    IcoArrowBack,
    IcoShowPassword,
    IcoHiddePassword,
    MailToSupport,
  },
  created() {
    const locale = navigator.language.split("-")[0];
    this.$i18n.locale = locale;
    console.log("locale", locale);

    if (locale == "en") {
      this.$moment.locale("en-gb");
    } else {
      this.$moment.locale(locale);
    }
  },
  computed: {
    length() {
      return textLength;
    },
  },
  data() {
    return {
      focusNewEmail: false,
      focusConfirmEmail: false,

      showPassword: false,
      showPasswordConfirm: false,
      inputType: "password",
      inputTypeConfirm: "password",
      new_email: "",
      confirm_email: "",
      validationError: {
        new_email: false,
        confirm_email: false,
      },
      invalidForm: false,
    };
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;

      if (this.showPassword) {
        this.inputType = "text";
      } else {
        this.inputType = "password";
      }
    },
    togglePasswordConfirm() {
      this.showPasswordConfirm = !this.showPasswordConfirm;

      if (this.showPasswordConfirm) {
        this.inputTypeConfirm = "text";
      } else {
        this.inputTypeConfirm = "password";
      }
    },

    recoverPassword() {
      
      if( this.new_email !== this.confirm_email ) {
        this.$notify({
            title: this.$t("3_3_toaster_error_title_password_mismatch"),
            text: this.$t("3_3_toaster_error_description_password_mismatch"),
            type: "error",
            duration: 5000,
        });

        return
      }

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: this.$route.params.token,
          password: this.new_email,
        }),
      };

      fetch(URL + "/api/login/reset", requestOptions)
        .then((data) => {

          console.log("data", data)

          if(data.status === 409) {
            this.$notify({
                title: this.$t("3_3_toaster_error_title_change_password"),
                text: this.$t("3_3_toaster_error_description_change_password"),
                type: "error",
                duration: 5000,
            });

            return
          }

          if(data.status === 200) {
            this.$notify({
                title: this.$t("3_3_toaster_success_title_change_password"),
                text: this.$t("3_3_toaster_success_description_change_password"),
                type: "success",
                duration: 5000,
            });

            this.new_email = '';
            this.confirm_email = '';

            this.$router.push({name: 'Login'});

          } else {

            this.$notify({
                title: this.$t("3_3_toaster_error_title_change_password"),
                text: this.$t("3_3_toaster_error_description_change_password"),
                type: "error",
                duration: 5000,
            });

            return
          }
          

        }).catch((error) => {

            this.$notify({
                title: this.$t("3_3_toaster_error_title_change_password"),
                text: this.$t("global_toaster_error_description"),
                type: "error",
                duration: 5000,
            });
        })
    },
    toSignIn() {
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style scoped></style>
