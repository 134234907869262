<template>
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L11 11M1 11L11 1" :stroke="color" stroke-width="1.5" stroke-linecap="round"/>
    </svg>
</template>

<script>

export default {
    props: { 
        color: {
            type: String,
            default: '#979797'
        }
    }
}
</script>