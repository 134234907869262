<template>
  <div class="modal" v-prevent-parent-scroll>
    <div @click="closeModal" class="modal__bg"></div>

    <div class="modal__body" :class="type">
      <button v-if="showClose" @click="closeModal" class="modal__close">
        <IcoClose />
      </button>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import vuescroll from 'vuescroll'
import IcoClose from '@/components/svg/IcoClose.vue'

export default {
  components: {
    vuescroll,
    IcoClose,
  },
  props: {
    type: {
      type: String,
      default: 'normal',
    },
    showClose: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scrollPanel: {
        initialScrollY: false,
        initialScrollX: false,
        scrollingX: true,
        scrollingY: true,
        speed: 300,
        easing: undefined,
        verticalNativeBarPos: 'right',
        topPosition: 0,
      },
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
    },
  },
  mounted() {
    this.topPosition = window.pageYOffset | document.body.scrollTop
    document.body.classList.add('fixed')
  },
  destroyed() {
    document.body.classList.remove('fixed')
    console.log('this.topPosition', this.topPosition)
    window.scrollTo(0, this.topPosition)
  },
}
</script>
