<template>
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L9 9M1 9L9 1" :stroke="color" stroke-width="1.5" stroke-linecap="round"/>
    </svg>
</template>

<script>
    export default {
        props: {
            color: {
                type: String,
                default: '#979797'
            }
        }
    }    
</script>