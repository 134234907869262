<template>
    <Sidebar>
        <Link @click="toSignIn()"><IcoArrowBack /> {{ $t('3_1_go_back') }}</Link>
        <div class="line">
            <h2>{{ $t('3_1_title') }}</h2>
        </div>
        <div class="line line--half">
            <p>{{ $t("3_1_description") }}</p>
        </div>

        <div class="line">
            <FormGroup :focused="focusEmail" :label="$t('3_1_label_email')" :required="true" :validationError="validationError.email">
                <input                   
                    class="form-group__input"                     
                    type="email"
                    :placeholder="$t('3_1_input_email_placeholder')"
                    v-model="email"
                    @focus="focusEmail = true"
                    @blur="focusEmail = false"
                    >
            </FormGroup>    
        </div>

        <div class="line">
            <Button style="width:100%" @click="recoverPassword()" :size="'max'" :type="'primary'">{{ $t('3_1_btn_recover') }}</Button>
        </div>

        <div class="line">
            <p>{{ $t("3_1_trouble_recover") }} <MailToSupport :subject="$t('3_1_support_mail_subject')" /></p>
        </div>
    </Sidebar>
</template>

<script>
import { SignService } from "@/services/SignService.js";
import { URL } from "@/constants/URLConstant.js";

import Sidebar from '@/components/layout/Sidebar.vue';
import FormGroup from '@/components/FormGroup.vue';
import Button from '@/components/button/Button.vue';
import Link from '@/components/design/Link.vue';
import MailToSupport from '@/components/MailToSupport.vue';

import IcoLogoGoogle from '@/components/svg/IcoLogoGoogle.vue';
import IcoArrowBack from '@/components/svg/IcoArrowBack.vue';

export default {
  components: { 
    Sidebar,
    FormGroup,
    Button,
    Link,
    IcoLogoGoogle,
    IcoArrowBack,
    MailToSupport,
  },
  data() {
      return { 
          focusEmail: false, 
          email: '',
          validationError: {
              email: false,
          },
          invalidForm: false,
      }
  },
  methods: {
      recoverPassword() {

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: this.email,
          }),
        };

        fetch(URL + "/api/login/stuck", requestOptions)
          .then((data) => {

            console.log("data", data)

            if(data.status === 409) {
              this.$notify({
                  title: this.$t("3_1_toaster_error_title_invalid_reset_password"),
                  text: this.$t("3_1_toaster_error_description_invalid_reset_password"),
                  type: "error",
                  duration: 5000,
              });

              return
            }

            this.email = '';
            this.$router.push({name: 'CheckEmail'});

          }).catch(error => {

              this.$notify({
                  title: this.$t("3_1_toaster_error_title_invalid_reset_password"),
                  text: this.$t("global_toaster_error_description"),
                  type: "error",
                  duration: 5000,
              });
        })
      },
      toSignIn() {
          this.$router.push({name: 'Login'});
      },
      validateFields() {
          this.invalidForm = false; 

          this.validationError.email = false;
          if(!SignService.validateEmail( this.email )) {
              this.validationError.email = true;
              this.invalidForm = true;
          }
            
          if(this.invalidForm) {
              // this.$snotify.html('<h2>Test</h2><p>some desc</p>');
          }

          return this.invalidForm;
      }
  }
}
</script>