<template>
  <div class="select-company" v-click-outside="hide">
    <div @click="toggle" class="select-company__selected">
      <SelectCompanyItem v-if="selected.id" :company="selected" />
      <div v-else class="select-company__placeholder">
        {{ $t('9_2_select_company') }}
      </div>

      <IcoDown :class="{ 'is-opened': showData }" :textColor="dropDownColor" />
    </div>
    <div v-if="showData" class="select-company__data">
      <vuescroll :ops="ops">
        <div
          class="select-company__item"
          v-for="(item, index) in companies"
          :key="index"
        >
          <SelectCompanyItem
            :company="item"
            @click.native="selectCompany(index)"
          />
        </div>
      </vuescroll>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import ClickOutside from 'vue-click-outside'

import vuescroll from 'vuescroll'
import SelectCompanyItem from '@/components/SelectCompanyItem.vue'
import IcoDown from '@/components/svg/IcoDown.vue'

export default {
  components: {
    SelectCompanyItem,
    IcoDown,
    vuescroll,
  },
  directives: {
    ClickOutside,
  },
  mounted() {
    this.selected = this.selectedCompany
    this.$emit('input', this.selected)
  },
  computed: {
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
  },
  apollo: {
    companies: {
      query: gql`
        query getCompanies {
          companies {
            name
            id
          }
        }
      `,
    },
  },
  data() {
    return {
      dropDownColor: '#17252A',
      companies: [],
      showData: false,
      selected: {},
      ops: {
        vuescroll: {},
        scrollPanel: {
          scrollingX: false,
          scrollingY: true,
          initialScrollY: false,
          initialScrollX: false,
        },
        rail: {},
        bar: {
          onlyShowBarOnScroll: true,
          keepShow: false,
          minSize: 0,
        },
      },
    }
  },
  methods: {
    selectCompany(index) {
      this.selected = this.companies[index]
      this.$emit('input', this.selected)

      this.showData = false
    },
    hide() {
      this.showData = false
    },
    toggle() {
      this.showData = !this.showData
    },
  },
}
</script>

<style scoped>
.select-company {
  position: relative;
  width: 144px;
}

.select-company__selected {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  background: #f6f6f7;
  height: 30px;
  line-height: 30px;
}

.select-company__selected svg {
  position: absolute;
  top: 11px;
  right: 7px;
  transform: rotate(0deg);
  transition-duration: 0.2s;
  transition-property: transform;
}

.select-company__selected svg.is-opened {
  transform: rotate(180deg);
}

.select-company__placeholder {
  height: 30px;
  color: #c5c5c5;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 30px;
  font-family: Nunito;
}

.select-company__data {
  position: absolute;
  top: 100%;
  right: -10px;
  z-index: 992;
  margin-top: -4px;
  box-shadow: 15px 15px 60px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background: #ffffff;
  padding: 0;
  width: 185px;
  height: 240px;
}

.select-company__item {
  cursor: pointer;
  padding: 7px 15px;
}

.select-company__item:hover {
  background-color: #f6f6f7;
}

.select-company__item:first-child {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.select-company__item:last-child {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
</style>

<style>
.select-company__selected svg.is-opened path {
  stroke: #2ce0c1 !important;
}
</style>
