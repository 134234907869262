<template>
    <div 
        class="status"
        :style="{backgroundColor: state.color, 'color': state.textColor}"
        >
        {{ $t(state.id) }}
    </div>
</template>

<script>
import { StateService } from "@/services/StateService.js";

export default {
    computed: {
        state() {
            return StateService.getState(this.id);
        }
    },
    props: {
        id: {
            type: String,
            require: true,
        }
    }
}
</script>
