<template>
  <div>
    <div class="sticky-header">
      <Menu />
    </div>

    <div
      class="container container--top"
      :class="{
        'days-to-expiration': this.selectedCompany && this.selectedCompany.showExpirationWarning,
      }"
    >
      <h1 class="main-title">{{ $t('6_1_title') }}</h1>

      <div class="cols">
        <div class="col">
          <div class="col__inner">
            <h2 class="sub-title">{{ $t('6_1_create_subtitle') }}</h2>
            <p class="mt12">{{ $t('6_1_create_description') }}</p>

            <form action="" @submit.prevent="savePersonalInformation()">
              <div class="line">
                <FormGroup
                  :focused="focusName"
                  :label="$t('6_1_label_name')"
                  :required="true"
                  :validationError="invalidName"
                >
                  <input
                    class="form-group__input"
                    type="text"
                    :placeholder="$t('6_1_placeholder_name')"
                    v-model="name"
                    @focus="focusName = true"
                    @blur="focusName = false"
                    @input="invalidName = false"
                    :maxlength="length.companyName"
                  />
                </FormGroup>
              </div>

              <div class="line">
                <FormGroup
                  :label="$t('6_1_label_description')"
                  :required="false"
                  :focused="focusDescription"
                >
                  <textarea-autosize
                    class="form-group__input"
                    type="text"
                    :placeholder="$t('6_1_placeholder_description')"
                    v-model="description"
                    @focus.native="focusDescription = true"
                    @blur.native="focusDescription = false"
                    :maxlength="length.companyDescription"
                  ></textarea-autosize>
                </FormGroup>
              </div>

              <div class="line">
                <div
                  class="timezone"
                  :class="{
                    'is-invalid': invalidTimezone,
                    'has-focus': focusTimezone,
                  }"
                >
                  <div class="timezone__label">
                    {{ $t('6_3_label_timezone') }}
                    <span class="form-group__required">*</span>
                  </div>

                  <v-select
                    :options="timezones"
                    v-model="timezone"
                    :clearable="false"
                    :searchable="true"
                    :reduce="(timezone) => timezone.value"
                    @input="timezoneUpdated()"
                    @open="focusTimezone = true"
                    @close="focusTimezone = false"
                  >
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><IcoCarretDownBlack /></span>
                    </template>
                    <span slot="no-options" @click="$refs.select.open = false">
                      {{ $t('8_5_select_no_result') }}
                    </span>
                  </v-select>
                </div>
              </div>

              <div class="line">
                <CompanyLogoNewCompany
                  @setAvatar="setAvatar"
                  :companyIdToUpdate="companyIdToUpdate"
                  :title="$t('6_3_image_default_name')"
                />
              </div>

              <div class="line">
                <Button
                  style="width: 100%"
                  :disabled="disabledCreateCompany"
                  :type="'primary'"
                  >{{ $t('6_1_btn_create_company') }}</Button
                >
              </div>
            </form>
          </div>
        </div>

        <div class="col">
          <div class="col__inner">
            <JoinCompany :showDescription="true" class="line" />
          </div>
        </div>
      </div>
    </div>

    <ModalHowJoinCompany
      v-if="showModalHowJoinCompany"
      @closeModal="showModalHowJoinCompany = false"
    />
  </div>
</template>

<script>
import { URL } from '@/constants/URLConstant.js'

import textLength from '@/constants/TextLengthConstant.js'
import { DateService } from '@/services/DateService.js'
import ME_PROFILE from '@/graphql/MeProfile.gql'
import COMPANY_CREATE from '@/graphql/CompanyCreate.gql'
import COMPANY_UPDATE from '@/graphql/CompanyUpdate.gql'
import COMPANY_JOIN from '@/graphql/CompanyJoin.gql'
import Link from '@/components/design/Link.vue'

import Menu from '@/components/Menu.vue'
import FormGroup from '@/components/FormGroup.vue'
import Button from '@/components/button/Button.vue'
import LinkedAccounts from '@/components/LinkedAccounts.vue'
import CompanyLogoNewCompany from '@/components/CompanyLogoNewCompany.vue'
import ShowError from '@/components/ShowError.vue'
import IcoCarretDownBlack from '@/components/svg/IcoCarretDownBlack.vue'

import IcoBin from '@/components/svg/IcoBin.vue'
import ModalHowJoinCompany from '@/components/ModalHowJoinCompany.vue'
import JoinCompany from '@/components/JoinCompany.vue'

export default {
  apollo: {
    me: {
      query: ME_PROFILE,
      error(error) {
        console.log('error', error)
      },
    },
  },
  components: {
    IcoCarretDownBlack,
    Menu,
    FormGroup,
    LinkedAccounts,
    CompanyLogoNewCompany,
    ShowError,
    Link,
    Button,
    IcoBin,
    ModalHowJoinCompany,
    JoinCompany,
  },
  computed: {
    mainUrl() {
      return URL
    },
    timezones() {
      return DateService.getListOfAvailableTimezones()
    },
    length() {
      return textLength
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
  },
  data() {
    return {
      companyIdToUpdate: null,
      createCompany: null,
      companyAvatar: null,
      focusName: false,
      focusDescription: false,
      focusTimezone: false,
      focusCompanyID: false,
      showModalHowJoinCompany: false,
      me: '',
      showModalDeleteAccount: false,
      showModalForgotPassword: false,
      disabledCreateCompany: true,
      disabledJoinCompany: true,
      errorJoinCompany: [],
      errorCreateCompany: [],
      name: '',
      description: '',
      companyID: '',
      timezone: 'Europe/Prague',
      openLightBox: false,
      invalidName: false,
      invalidDescription: false,
      invalidTimezone: false,
      media: [
        {
          thumb: require('@/assets/images/screenshot.png'),
          src: require('@/assets/images/screenshot.png'),
          caption: '',
        },
      ],
    }
  },
  methods: {
    setAvatar(image) {
      this.companyAvatar = image
    },
    timezoneUpdated() {
      this.invalidTimezone = false
      this.disabledEditCompany = false
    },
    actionOpenLightBox() {
      this.$refs.lightbox.showImage(0)
      this.openLightBox = true
    },
    savePersonalInformation() {
      this.invalidName = false
      this.invalidDescription = false
      this.invalidTimezone = false

      if (this.name === '' || this.name.length < 3 || this.name.length > 50) {
        this.$notify({
          title: this.$t('6_3_toaster_error_title_invalid_name'),
          text: this.$t('6_3_toaster_error_description_invalid_name'),
          type: 'error',
          duration: 5000,
        })

        this.invalidName = true
      }

      if (this.description.length > 100) {
        this.$notify({
          title: this.$t('6_3_toaster_error_title_invalid_description'),
          text: this.$t('6_3_toaster_error_description_invalid_description'),
          type: 'error',
          duration: 5000,
        })

        this.invalidDescription = true
      }

      if (this.timezone === '') {
        this.$notify({
          title: this.$t('6_3_toaster_error_title_invalid_timezone'),
          text: this.$t('6_3_toaster_error_description_invalid_timezone'),
          type: 'error',
          duration: 5000,
        })

        this.invalidTimezone = true
      }

      if (this.invalidName || this.invalidTimezone || this.invalidDescription) {
        return
      }

      try {
        this.$apollo
          .mutate({
            mutation: COMPANY_CREATE,
            variables: {
              name: this.name,
              description: this.description,
            },
            update: (store, { data: { createCompany } }) => {
              this.createCompany = createCompany

              this.$apollo.mutate({
                mutation: COMPANY_UPDATE,
                variables: {
                  id: createCompany.id,
                  name: this.name,
                  description: this.description,
                  zoneId: this.timezone,
                },
                update: (store, { data: { updateCompany } }) => {
                  console.log('updateCompany', updateCompany)

                  this.$notify({
                    title: this.$t(
                      '6_3_2_toaster_success_title_company_create'
                    ),
                    text: this.$t(
                      '6_3_2_toaster_success_description_company_create'
                    ),
                    type: 'success',
                    duration: 5000,
                  })

                  if (this.companyAvatar !== null) {
                    this.companyIdToUpdate = createCompany
                  } else {
                    this.$store.commit('setCompany', {
                      id: createCompany.id,
                      name: createCompany.name,
                      zoneId: createCompany.zoneId,
                    })

                    setTimeout(() => {
                      this.$router.push({
                        name: 'Company',
                      })
                    }, 300)
                  }
                },
              })
            },
          })
          .then((data) => {
            // console.log("data", data);
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('6_3_2_toaster_error_title_company_create'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('6_3_2_toaster_error_title_company_create'),
          text: this.$t('6_3_2_toaster_error_description_company_create'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    joinCompany() {
      try {
        this.$apollo
          .mutate({
            mutation: COMPANY_JOIN,
            variables: {
              id: this.companyID,
            },
            update: (store, { data: { createCompany } }) => {
              this.$notify({
                title: this.$t('6_3_2_toaster_success_title_company_join'),
                text: this.$t('6_3_2_toaster_success_description_company_join'),
                type: 'success',
                duration: 5000,
              })

              this.$store.commit('clearCompanyLogos')
              this.$store.commit('clearUserAvatars')

              this.$router.push({ name: 'Companies' })
            },
          })
          .then((data) => {
            // console.log("data", data);
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('6_3_2_toaster_error_title_company_join'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('6_3_2_toaster_error_title_company_join'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    saveSecurity() {
      this.error_security = []

      if (this.current_password === '') {
        this.error_security.push($('6_1_error_current_password'))
      }

      if (this.new_password === '') {
        this.error_security.push($('6_1_error_new_password'))
      }

      if (this.confirm_new_password === '') {
        this.error_security.push('6_1_error_confirm_password')
      }

      if (this.new_password !== this.current_password) {
        this.error_security.push('6_1_error_password_missmatch')
      }
    },
  },
  watch: {
    name() {
      this.disabledCreateCompany = this.name === ''
    },
    companyID() {
      this.disabledJoinCompany = this.companyID === ''
    },
  },
}
</script>
