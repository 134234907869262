<template>
  <Sidebar>
    <div class="profile">
      <MenuUser />
    </div>

    <h2>{{ $t('4_1_title') }}</h2>
    <p>{{ $t('4_1_description') }}</p>

    <div class="line">
      <Button
        style="width: 100%"
        @click="createCompany()"
        :size="'max'"
        :type="'primary'"
        >{{ $t('4_1_btn_create') }}</Button
      >
    </div>

    <JoinCompany :show-description="true" class="line" />
  </Sidebar>
</template>

<script>
import MenuUser from '@/components/MenuUser.vue'
import Button from '@/components/button/Button.vue'
import JoinCompany from '@/components/JoinCompany.vue'
import Sidebar from '@/components/layout/Sidebar.vue'

export default {
  components: {
    Button,
    JoinCompany,
    Sidebar,
    MenuUser,
  },
  methods: {
    createCompany() {
      this.$router.push({ name: 'CreateCompany' })
    },
    joinCompany() {
      this.$router.push({ name: 'JoinCompany' })
    },
  },
}
</script>

<style scoped>
.profile {
  position: absolute;
  top: 43px;
  right: 43px;
}
</style>
