<template>
    <div 
        class="form-group"
        :class="{
            'is-disabled': disabled,
            'is-invalid': validationError,
            'has-focus': focused,
            'is-rounded': rounded,
            'has-border': border,
            'is-inline': !border,
            }"
        >
        <slot></slot>
        <span class="form-group__label">
            {{ label }} <span v-if="required" class="form-group__required">*</span>
        </span>
        <div class="form-group__validation-error"><IcoValidationError /></div>
    </div>
</template>

<script>
import IcoValidationError from '@/components/svg/IcoValidationError.vue';

export default {
    components: {
        IcoValidationError
    },
    props: {
        label: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        validationError: {
            type: Boolean,
            default: false
        },
        focused: {
            type: Boolean,
            default: false,
        },
        rounded: {
            type: Boolean,
            default: false
        },
        border: {
            type: Boolean,
            default: true,
        }
    }
}
</script>
