<template>
  <Modal @closeModal="closeModal">
    <div class="logout" style="width: 390px">
      <h2 class="logout__title">{{ $t("4_1_2_title") }}</h2>

      <p>{{ $t("4_1_2_examples") }}</p>
      <p><strong>{{ $t("4_1_2_marketing_agency_title") }}</strong> - {{ $t("4_1_2_marketing_agency_description") }}</p>
      <p><strong>{{ $t("4_1_2_international_company_title") }}</strong> - {{ $t("4_1_2_international_company_description") }}</p>
      <p><strong>{{ $t("4_1_2_enterpreneur_title") }}</strong> - {{ $t("4_1_2_enterpreneur_description") }}</p>
      <p><strong>{{ $t("4_1_2_influencer_title") }}</strong> - {{ $t("4_1_2_influencer_description") }}</p>

      <Button
        class="confirm"
        style="width: 100%"
        @click="closeModal()"
        type="secondary"
        >{{ $t("4_1_2_btn_got_it") }}</Button
      >
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import Button from "@/components/button/Button.vue";

export default {
  components: {
    Modal,
    Button,
  },
  methods: {
    closeModal() {
      this.$store.commit("setModalUserRolesInfo", false)
    },
  },
};
</script>

<style scoped>
  p strong{
    font-size: 16px;
    color: #171717;
  }

  p{
    font-size: 16px;
    margin-top: 25px;
  }

  .confirm{
    margin-top: 25px;
  }
</style>