<template>
    <div class="accordion">
        <slot></slot>
    </div>
</template>

<script>

export default {
    
}
</script>

<style scoped>
.accordion{
    margin-top: 31px;
}
</style>