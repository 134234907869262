<template>
  <Modal @closeModal="closeModal">
    <div class="low-resolution">
      <p class="important center">
        {{ $t('resolution_warning_description') }}
      </p>

      <div class="low-resolution__buttons">
        <Button :type="'delete'" @click="closeModal">
          {{ $t('resolution_warning_continue') }}
        </Button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Button from '@/components/button/Button.vue'

export default {
  components: {
    Modal,
    Button,
  },
  methods: {
    closeModal() {
      localStorage.setItem('small_resolution_warning', '1')
      this.$emit('closeModal')
      window.location.reload()
    },
  },
}
</script>

<style>
.low-resolution {
  max-width: 460px;
}

.low-resolution__buttons {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  width: 100%;
}
</style>
