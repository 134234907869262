<template>
    <div class="password-strength">
        <div 
            class="password-strength__indicator"
            v-bind:class="[className]"
            >
            <div 
                class="password-strength__indicator__line"
                v-for="(item, index) in indicator"
                :key="index"
                :class="{'active': item}"
                ></div>
        </div>
        <div 
            class="password-strength__text"
            v-bind:class="[className]"
            >
            {{ type }}
        </div>
    </div>
</template>

<script>
export default {
    components: {

    },
    props: {
        value: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            indicator: [true, false, false],
            type: '',
            className: ''
        }
    },
    created() {
        this.getPasswordStrength();
    },
    methods: {
        getPasswordStrength() {
            if(this.value.length == 0) {

                this.type = this.$t('7_1_password_strength_weak');
                this.className = 'weak';
                this.indicator = [true, false, false];

            } else if(this.value.length > 0 && this.value.length <= 4) {

                this.type = this.$t('7_1_password_strength_weak');
                this.className = 'weak';
                this.indicator = [true, false, false];

            } else if(this.value.length > 4 && this.value.length < 8) {

                this.type = this.$t('7_1_password_strength_average');
                this.className = 'average';
                this.indicator = [true, true, false];

            } else {

                this.type = this.$t('7_1_password_strength_strong');
                this.className = 'strong';
                this.indicator = [true, true, true];

            }
        }
    },
    watch: {
        value() {
            this.getPasswordStrength();
        }
    }
}
</script>

<style scoped>
    .password-strength{
        padding-top: 4px;
    }

    .password-strength__indicator{
        display: inline-block;
    }

    .password-strength__indicator__line{
        display: inline-block;
        width: 40px;
        height: 4px;
        background: #C4C4C4;
        mix-blend-mode: normal;
        border-radius: 2px;
        margin-right: 5px;
    }

    .password-strength__text{
        display: inline-block;
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 14px;
        letter-spacing: -0.2px;
        text-transform: uppercase;
        color: #2CE0C1;
        position: relative;
        top: 1px;
        padding-left: 4px;
    }

    .password-strength__indicator.weak .password-strength__indicator__line.active{
        background: #F45050;
    }

    .password-strength__indicator.average .password-strength__indicator__line.active{
        background: #F18F48;
    }

    .password-strength__indicator.strong .password-strength__indicator__line.active{
        background: #2CE0C1;
    }

    .password-strength__text.weak{
        color: #F45050;
    }

    .password-strength__text.average{
        color: #F18F48;
    }

    .password-strength__text.strong{
        color: #2CE0C1;
    }
</style>