var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.title.length > 0)?_c('h2',{staticClass:"content-edit__title"},[_c('IcoDescription'),_vm._v(" "+_vm._s(_vm.title)+" "),(!_vm.isEditing && !_vm.content && !_vm.disabled)?_c('Button',{staticStyle:{"margin-left":"15px"},attrs:{"size":'small'},on:{"click":function($event){return _vm.startEdit()}}},[_vm._v(_vm._s(_vm.buttonTitle))]):_vm._e()],1):_vm._e(),(!_vm.isEditing)?_c('div',{class:{
      'content-edit__name': _vm.format === 'title',
      'content-edit__name--clipped': _vm.format === 'title',
      'content-edit__description': _vm.format === 'description',
    },on:{"click":function($event){return _vm.startEdit()}}},[_vm._v(" "+_vm._s(_vm.content.trim())+" ")]):_c('div',{staticClass:"content-edit__name-edit",class:{
      'content-edit__name-edit': _vm.format === 'title',
      'content-edit__description-edit': _vm.format === 'description',
    }},[_c('div',{class:{
        'content-edit__name-edit__data': _vm.format === 'title',
        'content-edit__description-edit__data': _vm.format === 'description',
        'show-error': _vm.showError,
      }},[_c('textarea-autosize',{ref:"textarea",class:{ 'is-invalid': !_vm.isValid },attrs:{"min-height":100,"max-height":300,"maxlength":_vm.maxLength},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1),_c('div',{staticClass:"content-edit__name-edit__controll"},[_c('Button',{attrs:{"size":'small',"type":'primary'},on:{"click":function($event){return _vm.saveEditName()}}},[_vm._v(_vm._s(_vm.$t('10_1_btn_name_save')))]),_c('Button',{attrs:{"size":'small',"type":'secondary'},on:{"click":function($event){return _vm.cancelEditName()}}},[_vm._v(_vm._s(_vm.$t('10_1_btn_name_cancel')))])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }