<template>
  <Modal @closeModal="closeModal">
    <div class="logout" style="width: 320px">
      <h2 class="logout__title">{{ $t('9_1_modal_close_content_title') }}</h2>

      <div class="modal-description">
        <p>{{ $t('9_1_modal_close_content_description') }}</p>
        <ul>
          <li v-if="modalConfirmCloseEditContentData.titleIsOpen">
            {{ $t('9_1_modal_close_content_is_open_title') }}
          </li>
          <li v-if="modalConfirmCloseEditContentData.descriptionIsOpen">
            {{ $t('9_1_modal_close_content_is_open_description') }}
          </li>
          <li v-if="modalConfirmCloseEditContentData.finalTextIsOpen">
            {{ $t('9_1_modal_close_content_is_open_final_text') }}
          </li>
          <li v-if="modalConfirmCloseEditContentData.contentReviewTextIsOpen">
            {{ $t('9_1_modal_close_content_is_open_content_review') }}
          </li>
          <li v-if="modalConfirmCloseEditContentData.resourcesIsOpen">
            {{ $t('9_1_modal_close_content_is_open_resources') }}
          </li>
          <li v-if="modalConfirmCloseEditContentData.contentLinksIsOpen">
            {{ $t('9_1_modal_close_content_is_open_content_link') }}
          </li>
          <li v-if="modalConfirmCloseEditContentData.checklistIsOpen">
            {{ $t('9_1_modal_close_content_is_open_checklist') }}
          </li>
          <li v-if="modalConfirmCloseEditContentData.feedbacksIsOpen">
            {{ $t('9_1_modal_close_content_is_open_feedback') }}
          </li>
          <li v-if="modalConfirmCloseEditContentData.commentsIsOpen">
            {{ $t('9_1_modal_close_content_is_open_comments') }}
          </li>
        </ul>
      </div>

      <div class="modal-options">
        <ul>
          <li>
            <Button
              style="width: 100%"
              @click="closeModal()"
              :type="'secondary'"
              >{{ $t('9_1_modal_close_content_btn_cancel') }}</Button
            >
          </li>
          <li>
            <Button
              style="width: 100%"
              @click="deleteContent()"
              :type="'delete'"
              >{{ $t('9_1_modal_close_content_btn_close') }}</Button
            >
          </li>
        </ul>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Button from '@/components/button/Button.vue'

export default {
  components: {
    Modal,
    Button,
  },
  computed: {
    modalConfirmCloseEditContentData() {
      return this.$store.getters.getModalConfirmCloseEditContentData
    },
  },
  methods: {
    closeModal() {
      this.$store.commit('setModalConfirmCloseEditContent', false)
    },
    deleteContent() {
      this.$store.commit('setEditContentModalStatus', {})
      this.$store.commit('setModalConfirmCloseEditContent', false)
    },
  },
}
</script>
