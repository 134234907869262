<template>
    <div class="tag">
        <div :style="{'background-color': getColor(item.name)}" class="tag__bg"></div>
        <div :style="{'color': getColor(item.name)}" class="tag__name">{{ item.name }}</div>
    </div>
</template>

<script>
import { ColorService } from "@/services/ColorService.js";

export default ({
    props: {
        item: {
            type: Object,
            require: true
        }
    },
    methods: {
        getColor(string) {
            return ColorService.computedColor(string);
        },
    }
})
</script>
