<template>
    <a @click.prevent="doAction()">
        <slot></slot>
    </a>
</template>

<script>
export default {
    components: {

    },
    methods: {
        doAction(){
            this.$emit('click');
        }
    }
}
</script>

<style scoped>
a{
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 138%;
    letter-spacing: -0.4px;
    color: #2CE0C1;

    text-decoration: none;
    cursor: pointer;

    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

a:hover{    
    color: #2CE0C1;
}

a:active{    
    color: #23C2A7;
}
</style>