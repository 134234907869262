<template>
    <div class="search-input">
        <div class="search-input__input" :class="{'isOpen': showSearch}">
            <input
                type="text"
                v-bind:value="value"
                v-on:input="$emit('input', $event.target.value)"
                @focus="showSearch = true"
                @blur="hide"
              >
        </div>
        <div
            v-if="showSearch"
            class="search-input__data"
            id="search-input-data"
            >
            <div
                class="search-input__item"
                :class="{
                    'focused': currentItem === index
                }"
                style="display: block"
                v-for="(item, index) in getSearchValues(value)"
                :key="index"
                :tabindex="index"
                @click.stop="setItemNameValue(item.value)"
                >
                    {{ item.label }}
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['value', 'nameList'],
    data(){
        return {
            showSearch: false,
            selectedIndex: 0,
            currentItem: -1,
            topScroll: 0,
        }
    },
    mounted() {
        document.addEventListener("keydown", this.focusNextItem);
    },
    destroyed() {
        document.removeEventListener("keydown", this.focusNextItem)
    },
    methods: {
        focusNextItem(event) {
            const itemsLength = this.getSearchValues(this.value).length -1;
            const maxTopScroll = itemsLength * 33;

            if (event.keyCode === 38) {

      	        this.currentItem--

                this.topScroll -= 33
                if(this.topScroll < 0) {
                    this.topScroll = 0
                }
                document.getElementById("search-input-data").scroll({top: this.topScroll})

                if(this.currentItem < 0) {
                    this.currentItem = 0
                }

            } else if (event.keyCode === 40) {

                if(this.currentItem < itemsLength) {
                    this.currentItem++

                    if(this.currentItem > 9) {
                        this.topScroll += 33

                        if(maxTopScroll > maxTopScroll) {
                            this.topScroll = maxTopScroll
                        }

                        document.getElementById("search-input-data").scroll({top: this.topScroll})
                    }

                }

            } else if(event.keyCode === 13) {

                const values = this.getSearchValues(this.value);
                this.$emit('input', values[this.currentItem].value);
                this.showSearch = false;

            } else {
                this.currentItem = -1;
                this.showSearch = true;
            }
        },
        hide(){
            // setTimeout(() => this.showSearch = false, 1000);
        },
        setItemNameValue(value) {
            this.$emit('input', value);
            this.showSearch = false;
        },
        getSearchValues(search) {
            return this.nameList.filter(item => {
                return item.value.toUpperCase().includes( search.toUpperCase() );
            });
        },
    }
}
</script>
