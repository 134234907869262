var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"idea",class:{
    'is-invisible': _vm.item.id === 0,
    'is-completed':
      _vm.item.state === 'PUBLISHED' &&
      _vm.item.luxonDate.toMillis() < _vm.todayTimeStamp,
    'is-overdue':
      _vm.item.state != 'PUBLISHED' && _vm.item.luxonDate.toMillis() < _vm.todayTimeStamp,
    'read-only': _vm.role === 'READ_ONLY',
  },attrs:{"draggable":_vm.draggable,"data-state":_vm.item.state,"data-date":_vm.item.dates},on:{"click":function($event){return _vm.editContent()},"dragstart":_vm.dragStart,"dragend":_vm.dragEnd,"dragover":function($event){$event.preventDefault();}}},[(_vm.item.id !== 0)?_c('div',{staticClass:"idea__inner"},[_c('div',{staticClass:"idea__label",style:({ color: _vm.getColor(_vm.computedChannelName) })},[_vm._v(" "+_vm._s(_vm.computedChannelName)+" ")]),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideControll),expression:"hideControll"}],staticClass:"idea__controll"},[_c('VDropdown',{attrs:{"triggers":[],"eagerMount":_vm.autoHide,"auto-hide":_vm.autoHide,"shown":_vm.showControll},on:{"hide":_vm.closeData},scopedSlots:_vm._u([{key:"popper",fn:function(){return [_c('div',{staticClass:"idea__controll__items"},[_c('ul',[_c('li',[_c('button',{on:{"click":function($event){$event.stopPropagation();return _vm.shareContent()}}},[_c('IcoShare'),_vm._v(" "+_vm._s(_vm.$t('8_1_idea_btn_share'))+" ")],1)]),(_vm.role !== 'READ_ONLY')?_c('li',[_c('button',{on:{"click":function($event){$event.stopPropagation();return _vm.duplicateContent()}}},[_c('IcoDuplicate'),_vm._v(" "+_vm._s(_vm.$t('8_1_idea_btn_duplicate'))+" ")],1)]):_vm._e(),(_vm.role !== 'READ_ONLY')?_c('li',[_c('button',{on:{"click":function($event){$event.stopPropagation();return _vm.deleteContent()}}},[_c('IcoBin'),_vm._v(" "+_vm._s(_vm.$t('8_1_idea_btn_delete'))+" ")],1)]):_vm._e()])])]},proxy:true}],null,false,4037018458)},[_c('button',{staticClass:"idea__controll__button",on:{"click":function($event){$event.stopPropagation();return _vm.toggleControll()}}},[_c('IcoKebab')],1)])],1),_c('div',{staticClass:"idea__title"},[_vm._v(" "+_vm._s(_vm.item.name)+" ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }