<template>
    <div class="layout">
        <div class="layout__data"> 
                       
            <div class="layout__wrapper">
                <div class="layout__header">
                    <div @click="toHome()" class="layout__logo">
                        <Logo />
                    </div>
                </div>

                <div class="layout__slot">
                    <slot></slot>
                </div>                
            </div>
        </div>

        <div class="layout__bg">
            <TopRight style="position: absolute; top: 0; right: 0;" />
            <Person class="person" />
            <BottomLeft style="position: absolute; bottom: 0; left: 0;" />
        </div>        
    </div>
</template>

<script>
import Logo from '@/components/svg/Logo.vue';
import Person from '@/components/svg/Person.vue';
import TopRight from '@/components/svg/TopRight.vue';
import BottomLeft from '@/components/svg/BottomLeft.vue';

export default {
  components: {
      Logo,
      Person,
      TopRight,
      BottomLeft
  },
  methods: {
      toHome() {
          this.$router.push({path: '/'});
      }
  }
}
</script>

<style scoped>
    .layout{
        display: flex;
        width: 100%;
        height: 100vh;
        min-width: 960px;
        background-color: #fff;
        min-height: 100vh;
    }

    @media screen and (max-width: 998px) {
        .layout{
            height: auto;
            min-width: 320px;
        }
    }

    .layout__data{
        position: relative;
        width: 58.4%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        background-color: #fff;
        min-height: 660px;
    }

    @media screen and (max-width: 998px) {
        .layout__data{
            width: 100%;
        }        
    }
    
    .layout__logo{
        position: absolute;
        top: 30px;
        left: 40px;
        right: 0;
    }

    @media screen and (max-width: 400px) {
        .layout__logo{
            left: 8%;
        }
    }

    .layout__wrapper{
        display: flex;
        align-items: center;
        justify-content: center;        
    }

    @media screen and (max-width: 998px) {
        .layout__wrapper{
            padding-top: 90px;
        }
    }

    @media screen and (max-height: 840px) {

        .layout{
            height: auto;
        }
        .layout__wrapper{
            align-items: top;
            display: block;
            padding-top: 90px;
            padding-bottom: 45px;
        }
    }

    @media screen and (max-width: 400px) {
        .layout__wrapper{
            width: 84%;
        }
    }

    .layout__slot{
        width: 360px;
    }

    @media screen and (max-width: 400px) {
        .layout__slot{
            width: 100%;
        }
    }

    .layout__bg{
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
        width: 41.6%;
        background-color: #2CE0C1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media screen and (max-width: 1240px) {
        .layout__bg .person svg{
            width: 100% !important;
            height: auto !important;
        }
    }

    @media screen and (max-width: 998px) {
        .layout__bg{
            display: none;
        }
    }
</style>