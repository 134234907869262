<template>
    <Modal :showClose="true" @closeModal="closeModal">
      <div style="max-width: 360px">
        <h2>{{ $t("3_3_1_modal_title") }}</h2>
        <p>{{ $t('3_3_1_modal_description') }}</p>

        <div class="line">
          <FormGroup :label="$t('3_3_1_label_name')" :required="true" :validationError="validationError.name">
            <input                   
              class="form-group__input"                     
              type="text"
              :placeholder="$t('3_3_1_placeholder_name')"
              v-model="name"
              >
          </FormGroup>
        </div> 

        <div class="line">
          <FormGroup :label="$t('3_3_1_label_email')" :required="true" :validationError="validationError.email">
            <input                   
              class="form-group__input"                     
              type="text"
              :placeholder="$t('3_3_1_placeholder_email')"
              v-model="email"
              >
          </FormGroup>
        </div>

        <div class="line">
          <FormGroup :label="$t('3_3_1_label_message')" :required="false" :validationError="validationError.message">
            <textarea-autosize
              class="form-group__textarea" 
              :placeholder="$t('3_3_1_placeholder_message')"
              v-model="message"
              :min-height="100"
              :max-height="100"
              ></textarea-autosize>
          </FormGroup>
        </div>

        <div class="line">
          <p>{{ $t('3_3_1_agree_submit_form') }} <Link @click="goToTermsAndConditions()">{{ $t('3_3_1_link_terms_condition') }}</Link> {{ $t('3_3_1_and') }} <Link @click="goToPrivacyPolicy()">{{ $t('3_3_1_link_privacy_policy') }}</Link></p>
        </div>

        <div class="line">
          <Button @click="send()" style="width: 100%" :type="'primary'">{{ $t('3_3_1_button_send') }}</Button>
        </div>
      </div>
        
    </Modal>
</template>

<script>
import { SignService } from "@/services/SignService.js";

import Modal from '@/components/Modal.vue';
import Link from '@/components/design/Link.vue';
import FormGroup from '@/components/FormGroup.vue';
import Button from '@/components/button/Button.vue';
import Checkbox from '@/components/Checkbox.vue';

export default {
  components: {
    Modal,
    FormGroup,
    Button,
    Checkbox,
    Link
  },
  data() {
    return {
      agree: false,
      name: '',
      email: '',
      message: '',
      validationError: {
        name: false,
        email: false
      }
    }
  },
  methods: {
    goToTermsAndConditions() {
      switch(this.$i18n.locale) {
        case 'cs':
          window.open('https://www.contesaur.cz/vseobecne-obchodni-podminky/', '_blank')
          break

        case 'en':
          window.open('https://www.contesaur.com/general-terms-and-conditions/', '_blank')
          break
      }
    },
    goToPrivacyPolicy() {
      switch(this.$i18n.locale) {
        case 'cs':
          window.open('https://www.contesaur.cz/zpracovani-osobnich-udaju/', '_blank')
          break

        case 'en':
          window.open('https://www.contesaur.com/privacy-policy/', '_blank')
          break
      }
    },
    send() {
      this.validateFields();
    },

    closeModal() {
      this.$store.commit("setContactModal", false);
    },

    validateFields() {
      this.invalidForm = false; 

      this.validationError.name = false;
      if(this.name == '') {
          this.validationError.name = true;
          this.invalidForm = true;
      }

      this.validationError.email = false;
      if(!SignService.validateEmail( this.email )) {
          this.validationError.email = true;
          this.invalidForm = true;
      }
        
      if(this.invalidForm) {
          // this.$snotify.html('<h2>Test</h2><p>some desc</p>');
      }

      return this.invalidForm;
    }
  }
}
</script>
