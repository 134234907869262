<template>
    <div class="search-input">
        <div class="search-input__input" :class="{'isOpen': showSearch}">

            <div class="review-filter__wrapper">
                <div class="review-filter__items">
                    <div class="review-filter__item" v-for="(item, index) in activeFilters" :key="index">

                        <span v-if="item.value === null" style="padding: 5px 0">{{ $t('8_5_not_rated') }}</span>

                        <star-rating
                            v-else
                            :star-size="20"
                            :increment="0.5"
                            :read-only="true"
                            v-model="item.value"
                        ></star-rating>

                        <button @click.stop="removeValue(index)" type="button" class="vs__deselect"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"></path></svg></button>
                    </div>
                </div>
            </div>
            
        </div>
        <div
            class="review-filter__data"
            >
                <star-rating
                    :star-size="30"
                    :increment="0.5"
                    v-model="currentRating"
                    @rating-selected="setRating()"
                  ></star-rating>

                <button class="review-filter__data__button" @click="setRatingUndefined()">{{ $t('8_5_not_rated') }}</button>
        </div>
    </div>
</template>

<script>
import StarRating from "vue-star-rating";

export default {
    components: {
        StarRating
    },
    props: {
        values: {
            type: Array | String,
            default: () => []
        },
        index: {
            type: Number,
            default: 0
        }
    },
    data(){
        return {
            currentRating: 0,
            newRating: 0,
            activeFilters: [],

            showSearch: false,
            selectedIndex: 0,
            currentItem: -1,
            topScroll: 0,
        }
    },
    created() {
        this.activeFilters = this.values;
    },
    methods: {
        removeValue(index) {
            this.activeFilters.splice(index, 1);
            this.updateData();
        },
        setRatingUndefined() {
            if (!Array.isArray(this.activeFilters)) {
                this.activeFilters = []
            }
            
            const hasValue = this.activeFilters.find(item => item.id === null)
            if(!hasValue) {
                this.activeFilters.push( { label: 'Rating', value: null, id: null } )                    
            }
            
            this.updateData()
            return
        },
        setRating() {
            if (!Array.isArray(this.activeFilters)) {
                this.activeFilters = [];
            }
           
           const hasValue = this.activeFilters.find(item => item.id === this.currentRating)
            if(!hasValue) {
                this.activeFilters.push( { label: 'Rating', value: this.currentRating, id: this.currentRating } )
            }

            this.updateData();

            setTimeout(() => {
                this.currentRating = 0
            }, 100)
        },    
        updateData() {
            this.$emit('updated', this.index, this.activeFilters)
        }
    }
}
</script>

<style scoped>
    .review-filter__wrapper{        
        margin-bottom: 6px;
        width: 100%;
        background: none;
        border: 1px solid rgba(60,60,60,.26);
        border-radius: 4px;
        white-space: normal;
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        color: #888F91;
        padding: 6px;
    }

    .review-filter__items{
        display: inline-flex;
        gap: 4px;
        flex-direction: column;
    }
    .review-filter__item{
        display: inline-flex;
        gap: 4px;
        background: #E7E9E9;
        border: none;
        border-radius: 3px;
        padding-left: 7px;
        padding-right: 7px;
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        color: #888F91;
        align-items: center;
    }

    .review-filter__data{

    }

    .review-filter__data__button {
        border: none;
        background: transparent;
        cursor: pointer;
    }
</style>