<template>
  <Sidebar>
    <div class="profile">
      <MenuUser />
    </div>

    <h2>{{ $t('4_2_title') }}</h2>
    <p>{{ $t('4_2_description') }}</p>

    <div class="line">
      <FormGroup
        :label="$t('4_2_label_name')"
        :required="true"
        :validationError="invalidName"
        :focused="focusName"
      >
        <input
          class="form-group__input"
          type="text"
          :placeholder="$t('4_2_placeholder_name')"
          v-model="name"
          @focus="focusName = true"
          @blur="focusName = false"
          @input="invalidName = false"
          :maxlength="length.companyName"
        />
      </FormGroup>
    </div>

    <div class="line">
      <FormGroup
        :focused="focusDescription"
        :label="$t('4_2_label_description')"
        :required="false"
      >
        <textarea-autosize
          class="form-group__textarea"
          :placeholder="$t('4_2_placeholder_description')"
          v-model="description"
          :min-height="100"
          :max-height="100"
          @focus.native="focusDescription = true"
          @blur.native="focusDescription = false"
          :maxlength="length.companyDescription"
        ></textarea-autosize>
      </FormGroup>
    </div>

    <div class="line">
      <div
        class="timezone"
        :class="{ 'is-invalid': invalidTimezone, 'has-focus': focusTimezone }"
      >
        <div class="timezone__label">
          {{ $t('6_3_label_timezone') }}
          <span class="form-group__required">*</span>
        </div>
        <div class="timezone__placeholder" v-if="timezone === ''">
          {{ $t('6_3_placeholder_timezone') }}
        </div>
        <v-select
          :options="timezones"
          v-model="timezone"
          :clearable="false"
          :reduce="(timezone) => timezone.value"
          @input="timezoneUpdated()"
          @open="focusTimezone = true"
          @close="focusTimezone = false"
        >
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes"><IcoCarretDownBlack /></span>
          </template>
          <span slot="no-options" @click="$refs.select.open = false">
            {{ $t('8_5_select_no_result') }}
          </span>
        </v-select>
      </div>
    </div>

    <div class="line">
      <CompanyLogoNewCompany
        @setAvatar="setAvatar"
        :companyIdToUpdate="companyIdToUpdate"
        :title="$t('6_3_image_default_name')"
      />
    </div>

    <div class="line">
      <Button
        style="width: 100%"
        @click="createCompany()"
        :size="'max'"
        :type="'primary'"
        :disabled="disabledCreateCompany"
        >{{ $t('4_2_btn_create') }}</Button
      >
    </div>

    <div class="line">
      <p>
        {{ $t('4_2_already_have_company') }}
        <Link @click="joinCompany()">{{ $t('4_2_link_join_company') }}</Link>
      </p>
    </div>
  </Sidebar>
</template>

<script>
import textLength from '@/constants/TextLengthConstant.js'
import { DateService } from '@/services/DateService.js'
import COMPANY_CREATE from '@/graphql/CompanyCreate.gql'
import COMPANY_UPDATE from '@/graphql/CompanyUpdate.gql'
import router from '../router'

import Sidebar from '@/components/layout/Sidebar.vue'
import Button from '@/components/button/Button.vue'
import Link from '@/components/design/Link.vue'
import FormGroup from '@/components/FormGroup.vue'
import ProfilePicture from '@/components/ProfilePicture.vue'
import IcoCarretDownBlack from '@/components/svg/IcoCarretDownBlack.vue'
import MenuUser from '@/components/MenuUser.vue'
import CompanyLogoNewCompany from '@/components/CompanyLogoNewCompany.vue'

export default {
  components: {
    CompanyLogoNewCompany,
    Sidebar,
    Button,
    Link,
    FormGroup,
    ProfilePicture,
    IcoCarretDownBlack,
    MenuUser,
  },
  computed: {
    timezones() {
      return DateService.getListOfAvailableTimezones()
    },
    length() {
      return textLength
    },
  },
  data() {
    return {
      companyIdToUpdate: null,
      disabledCreateCompany: true,
      focusName: false,
      focusDescription: false,
      focusTimezone: false,

      name: '',
      description: '',
      timezone: 'Europe/Prague',
      validationError: {
        name: false,
      },
      invalidName: false,
      invalidForm: false,
      invalidTimezone: false,
    }
  },
  methods: {
    setAvatar(image) {
      this.companyAvatar = image
    },
    timezoneUpdated() {
      this.invalidTimezone = false
      this.disabledEditCompany = false
    },
    createCompany() {
      this.validateFields()
      this.invalidTimezone = false

      if (this.invalidForm) {
        return
      }

      if (this.name == '' || this.name.length < 3 || this.name.length > 50) {
        this.$notify({
          title: this.$t('6_3_toaster_error_title_invalid_name'),
          text: this.$t('6_3_toaster_error_description_invalid_name'),
          type: 'error',
          duration: 5000,
        })

        this.invalidName = true
      }

      if (this.timezone === '') {
        this.$notify({
          title: this.$t('6_3_toaster_error_title_invalid_timezone'),
          text: this.$t('6_3_toaster_error_description_invalid_timezone'),
          type: 'error',
          duration: 5000,
        })

        this.invalidTimezone = true
      }

      if (this.invalidName || this.invalidTimezone) {
        return
      }

      try {
        this.$apollo.mutate({
          mutation: COMPANY_CREATE,
          variables: {
            name: this.name,
          },
          update: (store, { data: { createCompany } }) => {
            this.$apollo.mutate({
              mutation: COMPANY_UPDATE,
              variables: {
                id: createCompany.id,
                name: this.name,
                description: this.description,
                zoneId: this.timezone,
              },
              update: (store, { data: { updateCompany } }) => {
                this.$notify({
                  title: this.$t('4_2_toaster_success_title_company_create'),
                  text: this.$t(
                    '4_2_toaster_success_description_company_create'
                  ),
                  type: 'success',
                  duration: 5000,
                })

                if (this.companyAvatar !== null) {
                  this.companyIdToUpdate = createCompany
                } else {
                  this.$store.commit('setCompany', {
                    id: createCompany.id,
                    name: createCompany.name,
                    zoneId: createCompany.zoneId,
                  })

                  setTimeout(() => {
                    this.$router.push({
                      name: 'Company',
                      params: { id: updateCompany.id },
                    })
                  }, 300)
                }

                // this.$router.push({ name: "Companies" });
                /*
                this.$store.commit('setCompany', {
                  id: updateCompany.id,
                  name: updateCompany.name,
                  zoneId: updateCompany.zoneId,
                })

                this.$router.push({
                  name: 'Company',
                  params: { id: updateCompany.id },
                })
                */
              },
            })
          },
        })
      } catch (e) {
        this.$notify({
          title: this.$t('4_2_toaster_error_title_company_create'),
          text: this.$t('4_2_toaster_error_description_company_create'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    joinCompany() {
      this.$router.push({ name: 'JoinCompany' })
    },
    validateFields() {
      this.invalidForm = false

      this.validationError.name = false
      if (this.name == '') {
        this.validationError.name = true
        this.invalidForm = true
      }

      if (this.invalidForm) {
        // this.$snotify.html('<h2>Test</h2><p>some desc</p>');
      }

      return this.invalidForm
    },
  },
  watch: {
    name() {
      if (this.name != '') {
        this.disabledCreateCompany = false
      } else {
        this.disabledCreateCompany = true
      }
    },
  },
}
</script>

<style scoped>
.profile {
  position: absolute;
  top: 43px;
  right: 43px;
}
</style>
