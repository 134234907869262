<template>
    <div 
        class="status-card"        
        >

        <div class="status-card__header">
            <div class="status-card__title">
                <div class="status-card__title__ico"></div>
                <div class="status-card__title__name"></div>
            </div>            
            <div class="status-card__controll">
                <div class="status-card__counter"></div>
                <button
                    class="status-card__toggle"
                >
                    
                </button>
            </div>
        </div>
        <div class="status-card__body">
            <IdeaStatusCardPreloader v-for="(item) in randomNumber" :key="item" />
        </div>
    </div>
</template>

<script>
import IdeaStatusCardPreloader from '@/components/IdeaStatusCardPreloader.vue';


export default {
    components: {        
        IdeaStatusCardPreloader,        
    },
    computed: {
        randomNumber() {
            return Math.floor(Math.random() * 8) + 1
        },
    }
}

</script>