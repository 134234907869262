<template>
    <div class="button-calendar">
        <button class="button-calendar__button">
            <IcoCalendar />
        </button>

        <div v-if="showCalendar" class="button-calendar__data" :class="{'show': showCalendar}">
            <button class="button-nav" @click="datePrev"><IcoPrev /></button>
            <!--
            <v-select :options="months" v-model="selectedMonth" @change="updateCalendar" />
            <v-select :options="years" v-model="selectedYear" @change="updateCalendar" />            
            -->
            <button class="button-nav" @click="dateNext"><IcoNext /></button>
            <v-date-picker 
                :locale="$i18n.locale"
                :first-day-of-week="2"
                v-model="date"
                ref="buttonCalendar" />
        </div>
    </div>
</template>

<script>
import IcoCalendar from '@/components/svg/IcoCalendar.vue';
import IcoPrev from '@/components/svg/IcoPrev.vue';
import IcoNext from '@/components/svg/IcoNext.vue';

export default ({
    components: {
        IcoCalendar,
        IcoPrev,
        IcoNext
    },
    data() {
        return {
            showCalendar: false,
            date: new Date(),
            months: [
            {
                label: "January",
                code: 1
            },
            {
                label: "February",
                code: 1
            },
            {
                label: "March",
                code: 1
            },
            {
                label: "April",
                code: 1
            },
            {
                label: "May",
                code: 1
            },
            {
                label: "June",
                code: 1
            },
            {
                label: "July",
                code: 1
            },
            {
                label: "August",
                code: 1
            },
            {
                label: "September",
                code: 1
            },
            {
                label: "October",
                code: 1
            },
            {
                label: "November",
                code: 1
            },
            {
                label: "December",
                code: 1
            }
            ],
            year: new Date().getFullYear(),
            selectedMonth: new Date().getMonth()+1,
            selectedYear: new Date().getFullYear(),
        }
    },
    mounted() {
        console.log("is mounted");
        this.selectedMonth = 5;
    },
    computed: {
        years () {
            const year = new Date().getFullYear()
            return Array.from({length: year - 1900}, (value, index) => 1901 + index).reverse();
        }
    },
    methods: {
        toggleCalendar() {
            this.showCalendar = !this.showCalendar;
        },
        async updateCalendar() {
            console.log("updateCalendar");
            const calendar = this.$refs.buttonCalendar;
            await calendar.move({ month: this.selectedMonth, year: this.selectedYear });
        },
        async datePrev() {

            if( this.selectedMonth - 1 <= 0 ) {
                this.selectedYear--;
                this.selectedMonth = 12;
            } else {
                this.selectedMonth--;
            }

/*
            const calendar = this.$refs.buttonCalendar;
            await calendar.move({ month: this.selectedMonth, year: this.selectedYear });
            */

            console.log("this.selectedMonth", this.selectedMonth);
            console.log("this.selectedYear", this.selectedYear);
        },
        async dateNext() {
            // const calendar = this.$refs.buttonCalendar;
            // await calendar.move(1);
            // console.log("calendar.month", await calendar.month);
            
            if( this.selectedMonth + 1 > 12 ) {
                this.selectedYear++;
                this.selectedMonth = 1;
            } else {
                this.selectedMonth++;
            }

/*
            const calendar = this.$refs.buttonCalendar;
            await calendar.move({ month: this.selectedMonth, year: this.selectedYear });
            */

            console.log("this.selectedMonth", this.selectedMonth);
            console.log("this.selectedYear", this.selectedYear);
        }
    },
    watch: {
        date(oldValue, newValue) {
            console.log("data has changes", newValue);
        }
    }
})
</script>
