var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"sticky-header"},[_c('Menu')],1),_c('div',{staticClass:"container container--top",class:{
      'days-to-expiration': this.selectedCompany && this.selectedCompany.showExpirationWarning,
    }},[_c('h1',{staticClass:"main-title"},[_vm._v(_vm._s(_vm.$t('6_1_title')))]),_c('div',{staticClass:"cols"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"col__inner"},[_c('h2',{staticClass:"sub-title"},[_vm._v(_vm._s(_vm.$t('6_1_create_subtitle')))]),_c('p',{staticClass:"mt12"},[_vm._v(_vm._s(_vm.$t('6_1_create_description')))]),_c('form',{attrs:{"action":""},on:{"submit":function($event){$event.preventDefault();return _vm.savePersonalInformation()}}},[_c('div',{staticClass:"line"},[_c('FormGroup',{attrs:{"focused":_vm.focusName,"label":_vm.$t('6_1_label_name'),"required":true,"validationError":_vm.invalidName}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"form-group__input",attrs:{"type":"text","placeholder":_vm.$t('6_1_placeholder_name'),"maxlength":_vm.length.companyName},domProps:{"value":(_vm.name)},on:{"focus":function($event){_vm.focusName = true},"blur":function($event){_vm.focusName = false},"input":[function($event){if($event.target.composing){ return; }_vm.name=$event.target.value},function($event){_vm.invalidName = false}]}})])],1),_c('div',{staticClass:"line"},[_c('FormGroup',{attrs:{"label":_vm.$t('6_1_label_description'),"required":false,"focused":_vm.focusDescription}},[_c('textarea-autosize',{staticClass:"form-group__input",attrs:{"type":"text","placeholder":_vm.$t('6_1_placeholder_description'),"maxlength":_vm.length.companyDescription},nativeOn:{"focus":function($event){_vm.focusDescription = true},"blur":function($event){_vm.focusDescription = false}},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c('div',{staticClass:"line"},[_c('div',{staticClass:"timezone",class:{
                  'is-invalid': _vm.invalidTimezone,
                  'has-focus': _vm.focusTimezone,
                }},[_c('div',{staticClass:"timezone__label"},[_vm._v(" "+_vm._s(_vm.$t('6_3_label_timezone'))+" "),_c('span',{staticClass:"form-group__required"},[_vm._v("*")])]),_c('v-select',{attrs:{"options":_vm.timezones,"clearable":false,"searchable":true,"reduce":function (timezone) { return timezone.value; }},on:{"input":function($event){return _vm.timezoneUpdated()},"open":function($event){_vm.focusTimezone = true},"close":function($event){_vm.focusTimezone = false}},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
                var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('IcoCarretDownBlack')],1)]}}]),model:{value:(_vm.timezone),callback:function ($$v) {_vm.timezone=$$v},expression:"timezone"}},[_c('span',{attrs:{"slot":"no-options"},on:{"click":function($event){_vm.$refs.select.open = false}},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('8_5_select_no_result'))+" ")])])],1)]),_c('div',{staticClass:"line"},[_c('CompanyLogoNewCompany',{attrs:{"companyIdToUpdate":_vm.companyIdToUpdate,"title":_vm.$t('6_3_image_default_name')},on:{"setAvatar":_vm.setAvatar}})],1),_c('div',{staticClass:"line"},[_c('Button',{staticStyle:{"width":"100%"},attrs:{"disabled":_vm.disabledCreateCompany,"type":'primary'}},[_vm._v(_vm._s(_vm.$t('6_1_btn_create_company')))])],1)])])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"col__inner"},[_c('JoinCompany',{staticClass:"line",attrs:{"showDescription":true}})],1)])])]),(_vm.showModalHowJoinCompany)?_c('ModalHowJoinCompany',{on:{"closeModal":function($event){_vm.showModalHowJoinCompany = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }