<template>
  <div class="feedbacks" :class="{ 'show-error': showError }">
    <div class="feedbacks-create" :class="{ 'has-error': invalidFeedback }">
      <div style="flex: 0 0 25px">
        <Avatar :name="loggedUser.name" :user="loggedUser" :size="'small'" />
      </div>

      <div class="position-relative">
        <div
          ref="textareaCover"
          class="textarea-cover"
          style="z-index: 10"
          v-html="feedbackMention"
        />

        <button
          v-if="!addFeedback"
          class="feedbacks__create"
          @click="startFeedback()"
        >
          {{ $t('10_1_btn_create_feedback') }}
        </button>

        <textarea-autosize
          v-if="addFeedback"
          :min-height="30"
          v-model="newFeedback"
          :placeholder="$t('10_1_btn_create_feedback')"
          ref="feedback"
          @keyup="feedbackScroll"
          @scroll="feedbackScroll"
          @input="invalidFeedback = false"
          :maxlength="length.short"
        ></textarea-autosize>
      </div>
    </div>

    <div v-if="addFeedback" class="feedbacks-create__controll">
      <Button @click="createFeedback()" :type="'primary'" :size="'small'">{{
        $t('10_1_btn_feedback_save')
      }}</Button>
      <Button @click="clearFeedback()" :type="'secondary'" :size="'small'">{{
        $t('10_1_btn_feedback_cancel')
      }}</Button>
    </div>

    <div class="feedbacks__list" :class="{ 'show-more': !showMore }">
      <Feedback
        v-for="(feedback, index) in feedbacks_data.slice().reverse()"
        :key="feedback.id"
        :index="index"
        :feedback="feedback"
        @deleteFeedback="deleteFeedback"
        @updateFeedback="updateFeedback"
        @editFeedback="editFeedback"
        @closeFeedback="closeFeedback"
      />
    </div>

    <div class="feedbacks__footer">
      <div v-if="showMore" class="feedbacks__show-all">
        <Button
          @click="showMoreFeedbacks"
          style="width: 100%"
          :size="'small'"
          >{{ $t('10_1_view_more_feedbacks') }}</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import FEEDBACK_CREATE from '@/graphql/FeedbackCreate.gql'
import FEEDBACK_UPDATE from '@/graphql/FeedbackUpdate.gql'
import { CommentService } from '@/services/CommentService.js'

import textLength from '@/constants/TextLengthConstant.js'
import Avatar from '@/components/Avatar.vue'
import Button from '@/components/button/Button.vue'
import Feedback from '@/components/feedbacks/Feedback.vue'
import { Mentionable } from 'vue-mention'

Mentionable.methods.onKeyDown = function (e) {
  if (this.key) {
    if (e.key === 'ArrowDown' || e.keyCode === 40) {
      this.selectedIndex++
      if (this.selectedIndex >= this.displayedItems.length) {
        this.selectedIndex = 0
      }
      this.cancelEvent(e)
    }
    if (e.key === 'ArrowUp' || e.keyCode === 38) {
      this.selectedIndex--
      if (this.selectedIndex < 0) {
        this.selectedIndex = this.displayedItems.length - 1
      }
      this.cancelEvent(e)
    }
    if (
      (e.keyCode === 13 || e.keyCode === 9) &&
      this.displayedItems.length > 0
    ) {
      this.applyMention(this.selectedIndex)
      this.cancelEvent(e)
    }
    if (e.key === 'Escape' || e.keyCode === 27) {
      this.closeMenu()
      this.cancelEvent(e)
    }
  }
}

export default {
  components: {
    Avatar,
    Button,
    Feedback,
    Mentionable,
  },
  props: {
    feedbacks: {
      type: Array,
      default() {
        return []
      },
    },
    contentId: {
      type: String,
      required: true,
    },
    showError: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    length() {
      return textLength
    },
    codelist() {
      return this.$store.getters.getCodelist
    },
    loggedUser() {
      return this.$store.getters.getMe
    },
    feedbackMention() {
      function escapeRegExp(text) {
        return text.replaceAll(/[-[\]{}()*+?.,\\^$#\s]/g, '\\$&')
      }

      if (typeof this.newFeedback?.replaceAll !== 'function') {
        return this.newFeedback
      }

      return CommentService.highlightUsers(this.newFeedback, this.users)
    },
  },
  mounted() {
    this.initFeedbacks()
  },
  data() {
    return {
      invalidFeedback: false,
      addFeedback: false,
      newFeedback: '',
      feedback: '',
      feedbacks_data: [],
      feedbacks_data_init: [],
      users: [],
      showMore: true,
      editingItems: 0,
    }
  },
  methods: {
    editFeedback() {
      this.editingItems += 1
      this.$emit('opened')
    },
    closeFeedback() {
      if (this.editingItems > 0) {
        this.editingItems -= 1
      }

      if (this.editingItems < 1) {
        this.$emit('closed')
      }
    },
    initFeedbacks() {
      this.generateUserList()
      this.feedbacks_data = this.feedbacks

      if (this.feedbacks_data.length < 4) {
        this.showMore = false
      }
    },
    startFeedback() {
      this.addFeedback = true

      setTimeout(() => {
        this.$refs.feedback.$el.focus()
      }, 100)

      this.editFeedback()
    },
    selectUser() {
      setTimeout(() => {
        this.$refs.feedback.$el.focus()
      }, 100)
    },
    showMoreFeedbacks() {
      this.showMore = false
      this.feedbacks_data_init = this.feedbacks_data
    },
    generateUserList() {
      let users = []
      if (this.codelist.users) {
        this.codelist.users.forEach((user) => {
          users.push({
            value: '@' + user.value,
          })
        })
      }

      this.users = users
    },
    mounted() {
      setTimeout(() => {
        this.resize()
        window.addEventListener('resize', this.resize)
        this.$once('hook:beforeDestroy', () => {
          window.removeEventListener('resize', this.resize)
        })
      })
    },
    resize() {
      const barWidth =
        this.$refs.feedback.getBoundingClientRect().width -
        this.$refs.feedback.clientWidth -
        2 // border
      this.$refs.textareaCover.style.paddingRight = `calc(12px + ${barWidth}px)`
    },
    feedbackScroll() {
      this.$refs.textareaCover.scrollTop = this.$refs.feedback.scrollTop
      this.resize()
    },
    createFeedback() {
      this.invalidFeedback = false

      if (this.newFeedback === '') {
        this.invalidFeedback = true

        this.$notify({
          title: this.$t('10_1_toaster_error_title_feedback_name_empty'),
          text: this.$t('10_1_toaster_error_description_feedback_name_empty'),
          type: 'error',
          duration: 5000,
        })

        return
      }

      try {
        this.$apollo
          .mutate({
            mutation: FEEDBACK_CREATE,
            variables: {
              contentId: this.contentId,
              text: this.newFeedback,
            },
            update: (store, { data: { createFeedback } }) => {
              this.$notify({
                title: this.$t('10_1_toaster_success_title_feedback_create'),
                text: this.$t(
                  '10_1_toaster_success_description_feedback_create'
                ),
                type: 'success',
                duration: 5000,
              })

              this.newFeedback = ''
              this.addFeedback = false
            },
          })
          .then((data) => {
            this.$emit('feedbacksUpdated')
            this.closeFeedback()
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_title_feedback_create'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_title_feedback_create'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    updateFeedback(feedback, text) {
      try {
        this.$apollo
          .mutate({
            mutation: FEEDBACK_UPDATE,
            variables: {
              id: feedback.id,
              text: text,
            },
            update: (store, { data: { updateFeedback } }) => {
              /* this.$notify({
                    title: this.$t('10_1_toaster_success_title_feedback_updated'),
                    text: this.$t('10_1_toaster_success_description_feedback_updated'),
                    type: 'success',
                    duration: 5000
                }); */

              let editedFeedback = this.feedbacks_data.find(
                (item) => item.id == feedback.id
              )
              // console.log("editedFeedback", editedFeedback);
              editedFeedback.text = text
            },
          })
          .then((data) => {
            // console.log("data", data);
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_title_feedback_update'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_title_feedback_update'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    deleteFeedback(feedback) {
      try {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!) {
                deleteFeedback(id: $id)
              }
            `,
            variables: {
              id: feedback.id,
            },
            update: (store, { data: { deleteFeedback } }) => {
              if (deleteFeedback) {
                this.$notify({
                  title: this.$t('10_1_toaster_success_title_feedback_deleted'),
                  text: this.$t(
                    '10_1_toaster_success_description_feedback_deleted'
                  ),
                  type: 'success',
                  duration: 5000,
                })

                this.feedbacks_data = this.feedbacks_data.filter(
                  (item) => item.id !== feedback.id
                )
                this.clearFeedback()
              } else {
                this.$notify({
                  title: this.$t('10_1_toaster_error_title_feedback_delete'),
                  text: this.$t('global_toaster_error_description'),
                  type: 'error',
                  duration: 5000,
                })
              }
            },
          })
          .then((data) => {
            // console.log("data", data);
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_title_feedback_delete'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_title_feedback_delete'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    clearFeedback() {
      this.newFeedback = ''
      this.addFeedback = false
      this.closeFeedback()
    },
  },
  watch: {
    codelist() {
      this.generateUserList()
    },
    feedbacks() {
      this.initFeedbacks()
    },
  },
}
</script>

<style scoped>
.feedbacks {
  margin-top: 30px;
}
.feedbacks-create {
  display: flex;
  gap: 8px;
}

.feedbacks-create__controll {
  display: flex;
  gap: 5px;
  padding-top: 10px;
  padding-left: 30px;
}

/*
.feedbacks-create textarea{
    width: 100%;
    border: 1px solid #C5C5C5;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 7px 10px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 129%;
    letter-spacing: -0.2px;
    color: #17252A;
}
*/

.feedbacks__create {
  cursor: text;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #9795ff;
  border-radius: 4px;
  background: transparent;
  padding: 0 10px;
  width: 100%;
  height: 33px;
  color: #9795ff;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  line-height: 33px;
  font-family: Nunito;
  letter-spacing: -0.2px;
  text-align: left;
}

.feedbacks__footer {
  margin-top: 25px;
}
</style>

<style>
[id^='popover_'].tooltip {
  display: block !important;
  z-index: 10000;
}
[id^='popover_'].tooltip .tooltip-inner {
  border-radius: 16px;
  background: transparent;
  padding: 5px 10px 4px;
  color: white;
}
[id^='popover_'].tooltip .tooltip-arrow {
  position: absolute;
  z-index: 1;
  margin: 5px;
  border-style: solid;
  width: 0;
  height: 0;
}
[id^='popover_'].tooltip[x-placement^='top'] {
  margin-bottom: 5px;
}
[id^='popover_'].tooltip[x-placement^='top'] .tooltip-arrow {
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
  border-width: 5px 5px 0 5px;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  border-left-color: transparent !important;
}
[id^='popover_'].tooltip[x-placement^='bottom'] {
  margin-top: 5px;
}
[id^='popover_'].tooltip[x-placement^='bottom'] .tooltip-arrow {
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
  border-width: 0 5px 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-left-color: transparent !important;
}
[id^='popover_'].tooltip[x-placement^='right'] {
  margin-left: 5px;
}
[id^='popover_'].tooltip[x-placement^='right'] .tooltip-arrow {
  top: calc(50% - 5px);
  left: -5px;
  margin-right: 0;
  margin-left: 0;
  border-width: 5px 5px 5px 0;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  border-left-color: transparent !important;
}
[id^='popover_'].tooltip[x-placement^='left'] {
  margin-right: 5px;
}
[id^='popover_'].tooltip[x-placement^='left'] .tooltip-arrow {
  top: calc(50% - 5px);
  right: -5px;
  margin-right: 0;
  margin-left: 0;
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
}
[id^='popover_'].tooltip.popover .popover-inner {
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background: #f9f9f9;
  padding: 10px;
  color: black;
}
[id^='popover_'].tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}
[id^='popover_'].tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}
[id^='popover_'].tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
.mention-item {
  border-radius: 4px;
  padding: 8px 10px;
  color: #23c2a7;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  font-family: Nunito;
  text-align: left;
}
.mention-selected {
  background: rgba(44, 224, 193, 0.2);
  color: #23c2a7;
}
.textarea-cover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  border: 1px solid transparent;
  padding: 6px 12px;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  pointer-events: none;
  color: #17252a;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 129%;
  font-family: Nunito;
  letter-spacing: -0.2px;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
}

.feedbacks-create textarea,
.feedback__edit textarea {
  -webkit-text-fill-color: transparent;
  box-sizing: border-box;
  border: 1px solid #17252a;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  padding: 6px 12px;

  width: 100%;
  color: #17252a;
  color: #000;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 129%;
  font-family: Nunito;
  letter-spacing: -0.2px;
}

.feedbacks-create.has-error textarea,
.feedback__edit.has-error textarea {
  border: 1px solid #f45050 !important;
}

.feedbacks-create textarea,
.feedback__edit textarea {
  border: 1px solid #c5c5c5;
  color: #9795ff;
}

.feedbacks-create textarea:focus,
.feedback__edit textarea:focus {
  border: 1px solid #9795ff;
}

.feedbacks-create textarea:focus,
.feedback__edit textarea:focus {
  outline: none;
}

.feedbacks-create textarea::placeholder,
.feedback__edit textarea::placeholder {
  color: #bfc5c5;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 129%;
  font-family: Nunito;
  letter-spacing: -0.2px;
}

.textarea-under {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  border: 1px solid transparent;
  padding: 6px 12px;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  pointer-events: none;
  color: transparent;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
}
.feedback {
  width: 100%;
}
.mention-str {
  position: relative;
  border-radius: 3px;
  background: rgba(44, 224, 193, 0.2);
  color: #23c2a7;
}

.position-relative {
  position: relative;
  width: 100%;
}

.tooltip.popover {
  background: transparent !important;
}

.feedbacks__list {
  margin-top: 30px;
  margin-left: -15px;
  border-radius: 4px;
  background: rgba(151, 149, 255, 0.1);
  padding-top: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
}

.feedbacks__list .feedback:nth-child(n + 4) {
  display: none !important;
}

.feedbacks__list.show-more .feedback:nth-child(n + 4) {
  display: block !important;
}
</style>

<style>
.feedbacks.show-error .feedbacks-create textarea,
.feedbacks.show-error .feedback__edit textarea {
  border: 1px solid #f45050;
}
</style>
