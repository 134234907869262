<template>
  <div>
    <div class="sticky-header">
      <Menu />

      <div v-if="!billingOverdue" class="wrapper wrapper--calendar-fixed">
        <IdeasHeader
          :selectedDate="formattedRange"
          :showGroupBy="true"
          :type="'listView'"
          :nameList="nameList"
          @dropToContainer="dropToContainer"
        />
      </div>
    </div>

    <div
      v-if="!billingOverdue"
      class="wrapper wrapper--calendar wrapper--top wrapper--list"
      :class="{
        'days-to-expiration':
          this.selectedCompany && this.selectedCompany.showExpirationWarning,
      }"
    >
      <GlobalNotification />

      <IdeasNoContent v-if="loaded && contents.length === 0" />

      <div v-else class="list">
        <div class="list__controll">
          <button
            v-if="collapseAll"
            class="list__controll__item list__controll__item--active"
            @click="actionExpandAll"
          >
            {{ $t('8_4_control_expand') }}
          </button>
          <button
            v-if="!collapseAll"
            class="list__controll__item"
            @click="actionCollapseAll"
          >
            {{ $t('8_4_control_collapse') }}
          </button>
        </div>

        <div v-if="loaded && contents.length > 0" class="list__items">
          <StatusCardIdea
            v-for="(group, index) in data"
            :key="index"
            :item="group.name"
            :ids="group.ids"
            :type="groupBy"
            :contents="group.items"
            :collapseAll="collapseAll"
            :collapse="cardsStatus[group.name]"
            :showIdeaDate="false"
            @update_content="updateContent"
            @deleteContent="deleteContent"
          />
        </div>

        <div v-if="!loaded" class="list__items">
          <StatusCardPreloader />
        </div>
      </div>
    </div>

    <ExpiredCompany v-else :company="selectedCompany" />

    <small style="display: none">{{ contents }}</small>
  </div>
</template>

<script>
import CONTENT_UPDATE from '@/graphql/ContentUpdate.gql'
import CONTENT_STATE_UPDATE from '@/graphql/ContentStateUpdate.gql'
import CONTENT_DELETE from '@/graphql/ContentDelete.gql'
import CONTENTS_IDEA from '@/graphql/ContentsIdea.gql'

import { obj_clone } from '@/services/helpers/clone.js'
import { DateService } from '@/services/DateService.js'
import { FilterService } from '@/services/FilterService.js'
import { ContentService } from '@/services/ContentService.js'
import moment from 'moment'

import Menu from '@/components/Menu.vue'
import IdeasHeader from '@/components/IdeasHeader.vue'
import StatusCardIdea from '@/components/StatusCardIdea.vue'
import StatusCardPreloader from '@/components/StatusCardPreloader.vue'
import GlobalNotification from '@/components/GlobalNotification.vue'
import IdeasNoContent from '@/components/IdeasNoContent.vue'
import ExpiredCompany from '@/components/ExpiredCompany.vue'

export default {
  components: {
    Menu,
    IdeasHeader,
    StatusCardIdea,
    StatusCardPreloader,
    GlobalNotification,
    IdeasNoContent,
    ExpiredCompany,
  },
  apollo: {
    companies: {
      query: CONTENTS_IDEA,
      variables() {
        return {
          from: new Date(-8640000000000000),
          to: new Date(8640000000000000),
          id: this.selectedCompany.id,
        }
      },
      skip() {
        if (!this.selectedCompany) {
          return true
        }

        return false
      },
      error(error) {
        console.log('error', error)
        this.$notify({
          title: this.$t('8_4_error_title_load_contents'),
          text: this.$t('8_4_error_description_load_contents'),
          type: 'error',
          duration: 5000,
        })
      },
    },
  },
  computed: {
    collapseAll() {
      return this.$store.getters.getListCollapsedAll
    },
    isEmpty() {
      var name
      for (name in this.data) {
        if (this.data.hasOwnProperty(name)) {
          return false
        }
      }
      return true
    },
    refreshContent() {
      return this.$store.getters.getRefreshContent
    },
    groupBy() {
      return this.$store.getters.getSelectedIdeaGroupBy
      // return 'state';
    },
    from() {
      const _dateFrom = DateService.deltaDate(new Date(), 0, -6, 0)
      _dateFrom.setHours(0, 0, 0, 0)
      return _dateFrom
    },
    to() {
      const _dateTo = new Date()
      _dateTo.setHours(23, 59, 59, 0)
      return _dateTo
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
    formattedRange() {
      return this.$store.getters.getFormattedRange
    },
    contentOwner() {
      return this.$store.getters.getContentOwner
    },
    codelist() {
      return this.$store.getters.getCodelist
      // return { "state": [ { "label": "Idea", "value": "IDEA", "id": "IDEA" } ] };
    },
    contentFilter() {
      let result = []
      const _contentFilter = this.$store.getters.getContentFilter
      _contentFilter.forEach((item) => {
        let values = []

        if (item.name.id === 'name') {
          if (item.value.length > 0) {
            values.push(item.value)
          }
        } else {
          if (item.value !== '') {
            item.value.forEach((value) => {
              values.push(value.value)
            })
          }
        }

        result.push({
          type: item.name.id,
          values: values,
        })
      })

      return result
    },
  },
  data() {
    return {
      billingOverdue: false,
      loaded: false,
      refresh: 0,
      companies: [],
      formattedCurrentDate: '',
      statusList: [],
      groupByList: [],
      nameList: [],
      contentGroup: [],
      data: {},
      contentReady: false,
      contents: [],
      cardsStatus: {},
    }
  },
  methods: {
    checkCollapseAll() {
      if (this.collapseAll) {
        this.actionCollapseAll()
      } else {
        this.actionExpandAll()
      }
    },
    deleteContent(content_id) {
      try {
        this.$apollo
          .mutate({
            mutation: CONTENT_DELETE,
            variables: {
              id: content_id,
            },
            update: (store, { data: { deleteContent } }) => {
              if (!deleteContent) {
                return
              }

              const cached = store.readQuery({
                query: CONTENTS_IDEA,
                variables: {
                  from: DateService.dateToUTC(this.from),
                  to: DateService.dateToUTC(this.to),
                  id: this.selectedCompany.id,
                },
              })

              cached.companies[0].contents =
                cached.companies[0].contents.filter(
                  (item) => item.id !== content_id
                )

              store.writeQuery({
                query: CONTENTS_IDEA,
                data: cached,
                variables: {
                  from: DateService.dateToUTC(this.from),
                  to: DateService.dateToUTC(this.to),
                  id: this.selectedCompany.id,
                },
              })

              this.$notify({
                title: this.$t('8_4_success_deleted_content'),
                type: 'success',
                duration: 5000,
              })

              this.generateContents()
              this.prepareContentGroup()
            },
          })
          .then((data) => {
            // console.log("data", data);
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('8_4_toaster_error_content_delete'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('8_4_toaster_error_content_delete'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    generateContents() {
      this.contents = []
      this.groupByList = []
      let contents = []

      if (this.companies.length == 0) {
        return contents
      }

      const _contents = this.companies[0].contents

      for (var i = 0; i < _contents.length; i++) {
        let content = _contents[i]

        content.key = 'key-' + content.id + '-' + new Date().getTime()

        const date = new Date(
          DateService.getDateToTimezone(
            content.date,
            this.selectedCompany.zoneId
          )
        )
        content.date_local = date

        if (content.channel === null) {
          content.channel = { id: 0, name: '' }
        }

        if (content.personas === null || content.personas.length === 0) {
          content.personas = [{ id: 0, name: '' }]
        }

        if (content.products === null || content.products.length === 0) {
          content.products = [{ id: 0, name: '' }]
        }

        if (content.tags === null || content.tags.length === 0) {
          content.tags = [{ id: 0, name: '' }]
        }

        if (content.stdcs === null || content.stdcs.length === 0) {
          content.stdcs = ['']
        }

        contents.push(content)

        this.nameList = FilterService.addNameList(content.name, this.nameList)
      }

      const filteredService = FilterService.filterContent(
        contents,
        this.contentFilter,
        this.contentOwner
      )

      /*
            function compare(a, b) {
                if ( new Date(a.date).getTime() > new Date(b.date).getTime() ){
                    return -1;
                }
                if ( new Date(a.date).getTime() < new Date().getTime() ){
                    return 1;
                }
                return 0;
            };
            filteredService.sort(compare);
            */

      this.contentReady = true
      this.contents = filteredService
    },
    updateContentState(content, state) {
      if (this.role === 'READ_ONLY') {
        return
      }

      try {
        this.$apollo
          .mutate({
            mutation: CONTENT_STATE_UPDATE,
            variables: {
              id: content.id,
              state: state,
            },
            update: (store, { data: { updateContent } }) => {},
          })
          .then((data) => {
            this.$store.commit('setRefreshToken')
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('8_4_toaster_error_content_state_update'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('8_4_toaster_error_content_state_update'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    updateContent(data) {
      if (this.role === 'READ_ONLY') {
        return
      }

      const tasks = this.contents.filter((item) => item.id === data.id)
      const content = obj_clone(tasks[0])
      content.date_original = content.date

      const updated_content = ContentService.prepareContentForUpdate(
        content,
        data,
        this.selectedCompany.zoneId
      )

      try {
        this.$apollo
          .mutate({
            mutation: CONTENT_UPDATE,
            variables: {
              ...updated_content,
            },
          })
          .then(() => {
            this.$store.commit('setRefreshToken')
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('8_4_toaster_error_content_update'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('8_4_toaster_error_content_update'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    addGroupByList(value) {
      const result = this.groupByList.filter((item) => item === value)
      if (result.length < 1) {
        this.groupByList.push(value)
      }
    },
    prepareContentGroup() {
      let data = {}
      this.data = {}

      this.contents.forEach((item) => {
        if (item.state == 'IDEA') {
          switch (this.groupBy) {
            case 'state':
              const state_key = ContentService.getStateOrder(item['state'])

              if (!data.hasOwnProperty(state_key)) {
                data[state_key] = {
                  items: [],
                  ids: [item['state']],
                  name: item['state'],
                }
              }

              data[state_key].items.push(item)
              break

            case 'owner':
              const owner_key = item['owner']

              if (!data.hasOwnProperty(owner_key.id)) {
                data[owner_key.id] = {
                  items: [],
                  ids: [owner_key.id],
                  name: owner_key.name,
                }
              }

              data[owner_key.id].items.push(item)
              break

            case 'date':
              const date_display = moment(new Date(item['date'])).format(
                'D.M.YYYY'
              )
              const date_key = new Date(item['date']).setHours(0, 0, 0, 0)

              if (!data.hasOwnProperty(date_key)) {
                data[date_key] = {
                  items: [],
                  ids: [item['date']],
                  name: date_display,
                }
              }

              data[date_key].items.push(item)
              break

            case 'channel':
              const channel_key = item[this.groupBy]
              // console.log("channel_key", channel_key)
              // console.log("data", data)

              if (channel_key) {
                if (!data.hasOwnProperty(channel_key.id)) {
                  data[channel_key.id] = {
                    items: [],
                    ids: [channel_key.id],
                    name: channel_key.name,
                  }
                }

                data[channel_key.id].items.push(item)
              }
              break

            case 'products':
            case 'personas':
            case 'tags':
              //if( !item.hasOwnProperty(this.groupBy) ) {
              // const key = item[this.groupBy].map(e => e.name).join(", ");
              // const ids = item[this.groupBy].map(e => e.id);

              // console.log("item")

              const items_data = item[this.groupBy].sort((a, b) => {
                const fa = a.name.toLowerCase(),
                  fb = b.name.toLowerCase()

                if (fa < fb) {
                  return -1
                }
                if (fa > fb) {
                  return 1
                }
                return 0
              })

              const key = items_data.map((e) => e.name).join(', ')

              const ids = items_data.map((e) => e.id)

              if (!data.hasOwnProperty(key)) {
                data[key] = { items: [], ids: [], name: key }
              }

              data[key].items.push(item)
              data[key].ids = ids
              //}
              break

            case 'stdcs':
              const stdc_key =
                item['stdcs'].length > 1
                  ? item['stdcs'].join(', ')
                  : ContentService.getSTDCOrder(item['stdcs'][0])

              if (!data.hasOwnProperty(stdc_key)) {
                data[stdc_key] = {
                  items: [],
                  ids: item['stdcs'],
                  name: item['stdcs'].join(', '),
                }
              }

              data[stdc_key].items.push(item)
              break
          }
        }
      })

      if (this.codelist.hasOwnProperty(this.groupBy)) {
        this.codelist[this.groupBy].forEach((item) => {
          if (this.groupBy === 'state') {
            if (item.id !== 'IDEA') {
              const key = ContentService.getStateOrder(item.id)
              if (!data.hasOwnProperty(key)) {
                data[key] = { items: [], ids: [item.id], name: item.value }
              }
            }
          }
        })
      }

      if (this.groupBy == 'channel') {
        this.codelist.channels.forEach((channel) => {
          if (!data.hasOwnProperty(channel.id)) {
            data[channel.id] = {
              items: [],
              ids: [channel.id],
              name: channel.label,
            }
          }
        })
      }

      // Add options from codelist
      if (this.groupBy == 'tags') {
        this.codelist.tags.forEach((tag) => {
          if (!data.hasOwnProperty(tag.value)) {
            data[tag.value] = { items: [], ids: [tag.id], name: tag.label }
          }
        })
      }

      if (this.groupBy == 'products') {
        this.codelist.products.forEach((product) => {
          if (!data.hasOwnProperty(product.value)) {
            data[product.value] = {
              items: [],
              ids: [product.id],
              name: product.label,
            }
          }
        })
      }

      if (this.groupBy == 'personas') {
        this.codelist.personas.forEach((persona) => {
          if (!data.hasOwnProperty(persona.value)) {
            data[persona.value] = {
              items: [],
              ids: [persona.id],
              name: persona.label,
            }
          }
        })
      }

      if (this.groupBy == 'owner') {
        this.codelist.users.forEach((user) => {
          if (!data.hasOwnProperty(user.id)) {
            data[user.id] = { items: [], ids: [user.id], name: user.label }
          }
        })
      }

      if (
        this.groupBy === 'products' ||
        this.groupBy === 'personas' ||
        this.groupBy == 'tags' ||
        this.groupBy == 'date' ||
        this.groupBy == 'channel' ||
        this.groupBy == 'owner' ||
        this.groupBy == 'stdcs'
      ) {
        let index = 0
        let orderedData = {}

        for (const key in data) {
          index++
          const item = data[key]
          const order_key = ContentService.getItemsOrder(
            item.ids.length,
            item.items.length,
            index,
            item.name
          )
          orderedData[order_key] = item
        }

        this.data = orderedData
      } else {
        this.data = data
      }

      function compare(a, b) {
        if (new Date(a.date).getTime() < new Date(b.date).getTime()) {
          return -1
        }
        if (new Date(a.date).getTime() > new Date().getTime()) {
          return 1
        }
        return 0
      }

      for (const key in this.data) {
        this.data[key].items.sort(compare)
      }
    },
    filterContents(value) {
      switch (this.groupBy) {
        case 'state':
        case 'channel':
          return this.contents.filter((item) => item[this.groupBy] === value)

        case 'date':
          return this.contents.filter(
            (item) => moment(item[this.groupBy]).format('D.M.YYYY') === value
          )

        case 'owner':
          return this.contents.filter((item) => item.owner.name === value)

        case 'stdcs':
        case 'tags':
          return this.contents.filter((x) =>
            x[this.groupBy].some((g) => g.includes(value))
          )

        case 'personas':
        case 'products':
          return this.contents.filter((content) => {
            return content[this.groupBy].filter((obj) => {
              return obj.name === value
            })
          })
      }
    },
    actionCollapseAll() {
      this.$store.commit('setListCollapsedAll', true)
      this.cardsStatus = {}

      for (let key in this.data) {
        let item = this.data[key]

        this.cardsStatus[item.name] = false
      }

      this.prepareContentGroup()
    },
    actionExpandAll() {
      this.$store.commit('setListCollapsedAll', false)
      this.cardsStatus = {}

      for (let key in this.data) {
        let item = this.data[key]

        this.cardsStatus[item.name] = true
      }

      this.prepareContentGroup()
    },
    dropToContainer(idea_id) {
      this.clearTaskDate(idea_id)
    },
    clearTaskDate(idea_id) {
      const tasks = this.ideas.filter((item) => item.key === idea_id)
      tasks[0].dates = null
    },
  },
  watch: {
    selectedCompany() {
      this.billingOverdue = this.selectedCompany.billingOverdue
    },
    refreshContent() {
      this.$apollo.queries.companies.refetch()
    },
    companies() {
      if (this.companies.length > 0) {
        this.loaded = true
      }

      this.generateContents()
      this.prepareContentGroup()
    },
    groupBy() {
      this.prepareContentGroup()

      setTimeout(() => {
        this.checkCollapseAll()
      }, 100)
    },
    contentReady() {
      // this.prepareContentGroup();
    },
    codelist() {
      this.prepareContentGroup()
    },
    contentFilter() {
      // console.log("content filter is changed");
      this.generateContents()
      this.prepareContentGroup()
    },
    contentOwner() {
      this.generateContents()
      this.prepareContentGroup()
    },
  },
}
</script>
