<template>
  <div>
    <Modal
      v-if="
        wasLoaded ||
        (content_static &&
          content_static.id &&
          !$apollo.queries.companies.loading)
      "
      @closeModal="close"
      :type="'slim'"
    >
      <div style="height: 100%" class="modal-content__body--large">
        <div class="content-edit content-edit--header">
          <div
            style="padding-bottom: 10px"
            class="content-edit__main content-edit__main--title"
          >
            <div v-if="currentCompanyName !== ''" class="content-edit__company">
              <CompanyAvatar size="small" :company="selectedCompany" />
              {{ currentCompanyName }}
            </div>

            <TextAreaEditor
              ref="content_name"
              :content="content.name"
              :isValid="formValidation.name"
              :maxLength="length.short"
              :disabled="role === 'READ_ONLY'"
              :showError="titleIsOpenShowError"
              format="title"
              @saveEditName="saveEditName"
              @cancelEditName="cancelEditName"
              @opened="titleIsOpen = true"
              @closed=";(titleIsOpen = false), (titleIsOpenShowError = false)"
              @input.native="formValidation.name = true"
            />
          </div>
          <div class="content-edit__sidebar">
            <div class="content-edit__sidebar__header">
              <SelectColor
                :disabled="role === 'READ_ONLY'"
                v-model="content.state"
                @save_changed_value="stateSaveChangedValue"
              />

              <div class="content-edit__controll">
                <button
                  @click="handleOpenModalFeedback"
                  class="modal-open-feedback"
                >
                  <IcoFeedback />
                </button>

                <ContentEditSubmenu :content="content" />

                <button @click="close" class="modal-content__close">
                  <IcoClose />
                </button>
              </div>
            </div>
          </div>
        </div>

        <vuescroll ref="vs" :ops="ops">
          <div
            id="test"
            class="content-edit content-edit--body"
            :class="{ 'content-edit--top': titleIsOpen }"
          >
            <div class="content-edit__main">
              <WYSIWYGEditor
                id="content_description"
                ref="content_description"
                tinymceRef="update_content_description"
                :content="content.description"
                :isValid="true"
                :title="$t('10_1_title_description')"
                :buttonTitle="$t('10_1_title_add_description')"
                :maxLength="length.long"
                :disabled="role === 'READ_ONLY'"
                :showError="descriptionIsOpenShowError"
                format="description"
                @saveEditName="saveEditDescription"
                @cancelEditName="cancelEditDescription"
                @opened="descriptionIsOpen = true"
                @closed="
                  ;(descriptionIsOpen = false),
                    (descriptionIsOpenShowError = false)
                "
              />

              <h2 class="content-edit__title">
                <IcoResources /> {{ $t('10_1_title_resources') }}
              </h2>
              <div>
                <Resources
                  :items="content.resources"
                  :disabled="role === 'READ_ONLY'"
                  :showError="resourcesIsOpenShowError"
                  transCreateNew="10_1_resource_add"
                  @updateResources="updateResources"
                  @opened="resourcesIsOpen = true"
                  @closed="
                    ;(resourcesIsOpen = false),
                      (resourcesIsOpenShowError = false)
                  "
                />
              </div>

              <h2 class="divider mt-30">
                <span>{{ $t('10_1_title_content') }}</span>
              </h2>

              <WYSIWYGEditor
                tinymceRef="update_content_final_text"
                format="description"
                :content="content.finalText"
                :isValid="true"
                :title="$t('10_1_title_final_text')"
                :buttonTitle="$t('10_1_btn_add_text')"
                :maxLength="length.long"
                :disabled="role === 'READ_ONLY'"
                :externalEditState="editFinal"
                :showError="finalTextIsOpenShowError"
                @saveEditName="saveEditFinal"
                @cancelEditName="cancelEditFinal"
                @opened="finalTextIsOpen = true"
                @closed="
                  ;(finalTextIsOpen = false), (finalTextIsOpenShowError = false)
                "
              >
                <template v-slot:title>
                  <IcoFinalText /> {{ $t('10_1_title_final_text') }}
                  <Button
                    style="margin-left: 15px"
                    v-if="
                      !editFinal && !content.finalText && role !== 'READ_ONLY'
                    "
                    @click="addFinalText()"
                    :size="'small'"
                  >
                    {{ $t('10_1_btn_add_text') }}
                  </Button>
                  <Button
                    class="btn-generate-ai"
                    :class="{ 'btn-generate-ai--loading': generateAIFinalText }"
                    :disabled="generateAIFinalText"
                    v-tooltip="$t('10_1_tooltip_generate_ai_text')"
                    style="margin-left: 15px"
                    v-if="
                      !editFinal &&
                      !content.finalText &&
                      role !== 'READ_ONLY &&' &&
                      selectedCompany.aiTokenAvailable
                    "
                    @click="generateFinalTextAI()"
                    :size="'small'"
                  >
                    {{ $t('10_1_btn_generate_ai_text') }}
                    <span class="loading-icon"><IcoLoading /></span>
                  </Button>
                  <Button
                    v-if="
                      content.finalText !== null &&
                      !editFinal &&
                      content.finalText.length > 0
                    "
                    style="margin-left: 15px; position: relative; top: 2px"
                    :size="'slim-duplicate'"
                    @click="copyFinalText()"
                  >
                    <IcoSmallDuplicate />
                  </Button>
                </template>
              </WYSIWYGEditor>

              <h2 class="content-edit__title">
                <IcoContentLink /> {{ $t('10_1_title_content_links') }}
              </h2>
              <div>
                <Resources
                  :disabled="role === 'READ_ONLY'"
                  :items="contentDetail.contentLinks"
                  :showError="contentLinksIsOpenShowError"
                  transCreateNew="10_1_link_add"
                  @updateResources="updateContentLinks"
                  @opened="contentLinksIsOpen = true"
                  @closed="
                    ;(contentLinksIsOpen = false),
                      (contentLinksIsOpenShowError = false)
                  "
                />
              </div>

              <h2
                v-if="role !== 'READ_ONLY'"
                class="divider divider--wide mt-30"
              >
                <span>{{ $t('10_1_title_checklist') }}</span>
              </h2>

              <h2 v-if="role !== 'READ_ONLY'" class="content-edit__title">
                <IcoChecklist /> {{ $t('10_1_subtitle_checklist') }}
                <Button
                  style="margin-left: 15px"
                  v-if="
                    content &&
                    content.checklist &&
                    content.checklist.length === 0
                  "
                  @click="addChecklist()"
                  :size="'small'"
                >
                  {{ $t('10_1_btn_checklist_add') }}
                </Button>
              </h2>

              <IdeaTask
                v-if="role !== 'READ_ONLY'"
                style="margin-top: 20px"
                :items="content.checklist"
              />

              <Checklist
                v-if="role !== 'READ_ONLY'"
                :contentId="content_static.id"
                :publishDate="content.date"
                :items="content_static.checklist"
                :eod="content.eod"
                :addChecklistItem="addChecklistItem"
                :showError="checklistIsOpenShowError"
                @silentDeletedCheckbox="addChecklistItem = false"
                @tasksUpdated="tasksUpdated"
                @opened="checklistIsOpen = true"
                @closed="
                  ;(checklistIsOpen = false),
                    (addChecklistItem = false),
                    (checklistIsOpenShowError = false)
                "
              />

              <div
                v-if="content.state === 'PUBLISHED'"
                class="part-content-review"
              >
                <h2 class="divider divider--wide mt-30">
                  <span>{{ $t('10_1_title_content_review') }}</span>
                </h2>

                <div class="part-content-review__title">
                  <h2 class="content-edit__title">
                    <IcoContentReview />
                    {{ $t('10_1_subtitle_content_review') }}
                    <Button
                      style="margin-left: 15px"
                      v-if="
                        !externalEditState &&
                        !contentReviewText &&
                        role !== 'READ_ONLY'
                      "
                      @click="addContentReview()"
                      :size="'small'"
                      >{{ $t('10_1_btn_add_text') }}</Button
                    >
                  </h2>
                  <div class="rating-box">
                    <div class="rating-box__value">
                      <span v-if="contentReviewValue === 0">
                        {{ $t('10_1_review_value_none') }}
                      </span>
                      <span v-else>{{ contentReviewValue }}</span>
                      <IcoInfo v-tooltip="$t('10_1_tooltip_review_remove')" />
                    </div>

                    <star-rating
                      v-model="contentReviewValue"
                      :show-rating="false"
                      :read-only="role === 'READ_ONLY'"
                      :clearable="true"
                      :star-size="30"
                      :increment="0.5"
                      :max-rating="5"
                      @rating-selected="saveEditContentReview"
                    ></star-rating>
                  </div>
                </div>

                <WYSIWYGEditor
                  format="description"
                  title=""
                  tinymceRef="update_content_review_text"
                  :content="contentReviewText"
                  :isValid="true"
                  :buttonTitle="$t('10_1_title_add_description')"
                  :maxLength="length.long"
                  :disabled="role === 'READ_ONLY'"
                  :externalEditState="externalEditState"
                  :showError="contentReviewTextIsOpenShowError"
                  @saveEditName="saveEditContentReviewText"
                  @cancelEditName="cancelEditContentReview"
                  @opened="contentReviewTextIsOpen = true"
                  @closed="
                    ;(contentReviewTextIsOpen = false),
                      (contentReviewTextIsOpenShowError = false)
                  "
                />
              </div>

              <h2 class="divider mt-30">
                <span>{{ $t('10_1_title_communication') }}</span>
              </h2>

              <ul v-if="role !== 'READ_ONLY'" class="communication-tab">
                <li
                  :class="{
                    'is-active': currentCommunicationTab === 'comments',
                  }"
                >
                  <button @click="handleShowComments()">
                    {{ $t('10_1_btn_name_comments') }}
                  </button>
                </li>
                <li
                  :class="{
                    'is-active': currentCommunicationTab === 'feedbacks',
                  }"
                >
                  <button @click="handleShowFeedbacks()">
                    {{ $t('10_1_btn_name_feedback') }}
                  </button>
                </li>
              </ul>

              <Feedbacks
                v-show="content.id && currentCommunicationTab === 'feedbacks'"
                :contentId="editContent.id"
                :eod="content.eod"
                :feedbacks="content.feedbacks"
                :showError="feedbacksIsOpenShowError"
                @feedbacksUpdated="handleUpdateContent"
                @opened="feedbacksIsOpen = true"
                @closed="
                  ;(feedbacksIsOpen = false), (feedbacksIsOpenShowError = false)
                "
              />

              <Comments
                v-show="
                  content.id &&
                  role !== 'READ_ONLY' &&
                  currentCommunicationTab === 'comments'
                "
                :contentId="editContent.id"
                :eod="content.eod"
                :comments="content.comments"
                :showError="commentsIsOpenShowError"
                @commentsUpdated="handleUpdateContent"
                @opened="commentsIsOpen = true"
                @closed="
                  ;(commentsIsOpen = false), (commentsIsOpenShowError = false)
                "
              />
            </div>

            <div class="content-edit__sidebar">
              <div class="content-edit__channel">
                <FormGroup
                  :focused="focusContentChannel"
                  :label="$t('10_1_name_channel')"
                  :required="false"
                  :rounded="true"
                >
                  <ChannelInput
                    :disabled="role === 'READ_ONLY'"
                    :multiple="true"
                    :name="$t('10_1_name_channel')"
                    :items="codelist.channels"
                    v-model="channel"
                    :placeholder="$t('10_1_placeholder_channel')"
                    boxSize="popper-big-edit-codelist"
                    @save_changed_value="channelSaveChangedValue"
                    @focus="focusContentChannel = true"
                    @blur="focusContentChannel = false"
                  />
                </FormGroup>
              </div>

              <div class="modal-content__date-time">
                <DatePickerEditContent
                  v-model="content.date"
                  :luxonDate="content.luxonDate"
                  :disabled="role === 'READ_ONLY'"
                  :state="content.state"
                  :eod="content.eod"
                  @updatedDate="updatedDate"
                  @updatedDateClear="updatedDateClear"
                />

                <TimePickerEditContent
                  :content="content"
                  :readonly="role === 'READ_ONLY'"
                  :slim="true"
                  @updatedTime="updatedTime"
                  @updatedTimeClear="updatedTimeClear"
                />
              </div>

              <div v-if="role !== 'READ_ONLY'" class="modal-content__owner">
                <SelectPerson
                  v-model="content.owner"
                  :disabled="role === 'READ_ONLY'"
                  :users="
                    codelist.users.filter((user) => user.role !== 'APPROVER')
                  "
                  :slim="true"
                  @updateUser="updateUser"
                />
              </div>

              <div class="content-edit-details">
                <div
                  class="content-edit-details__header"
                  :class="{ 'show-content-details': showContentDetails }"
                  @click="toggleContentDetails()"
                >
                  <span>{{ $t('10_1_title_details') }}</span>
                  <IcoToggle />
                </div>
                <div
                  v-if="showContentDetails"
                  class="content-edit-details__body"
                >
                  <div class="content-edit-details__items">
                    <div class="content-edit-details__item">
                      <div class="content-edit-details__item__label">
                        {{ $t('10_1_label_stdc') }}
                      </div>
                      <div class="content-edit-details__item__data">
                        <STDCInput
                          :disabled="role === 'READ_ONLY'"
                          :multiple="true"
                          :name="$t('10_1_name_stdc')"
                          :items="codelist.stdcs"
                          :type="'stdc'"
                          :slim="true"
                          v-model="stdcs"
                          :placeholder="$t('10_1_placeholder_stdc')"
                          @save_changed_value="stdcSaveChangedValue"
                        />
                      </div>
                    </div>

                    <div class="content-edit-details__item">
                      <div class="content-edit-details__item__label">
                        {{ $t('10_1_label_persona') }}
                      </div>
                      <div class="content-edit-details__item__data">
                        <PersonaInput
                          :disabled="role === 'READ_ONLY'"
                          :multiple="true"
                          :name="$t('10_1_name_persona')"
                          :max="5"
                          :items="codelist.personas"
                          :slim="true"
                          v-model="content.personas"
                          :placeholder="$t('10_1_placeholder_product')"
                          @save_changed_value="personasSaveChangedValue"
                        />
                      </div>
                    </div>

                    <div class="content-edit-details__item">
                      <div class="content-edit-details__item__label">
                        {{ $t('10_1_label_product') }}
                      </div>
                      <div class="content-edit-details__item__data">
                        <ProductInput
                          :disabled="role === 'READ_ONLY'"
                          :multiple="true"
                          :name="$t('10_1_name_product')"
                          :max="5"
                          :items="codelist.products"
                          :slim="true"
                          v-model="content.products"
                          :placeholder="$t('10_1_placeholder_product')"
                          @save_changed_value="productsSaveChangedValue"
                        />
                      </div>
                    </div>

                    <div class="content-edit-details__item">
                      <div class="content-edit-details__item__label">
                        {{ $t('10_1_label_tag') }}
                      </div>
                      <div class="content-edit-details__item__data">
                        <TagInput
                          :disabled="role === 'READ_ONLY'"
                          :multiple="true"
                          :name="$t('10_1_name_tag')"
                          :max="5"
                          :items="codelist.tags"
                          :slim="true"
                          v-model="tags"
                          :placeholder="$t('10_1_placeholder_tag')"
                          @save_changed_value="tagsSaveChangedValue"
                        />
                      </div>
                    </div>

                    <div class="content-edit-details__item">
                      <div class="content-edit-details__item__label">
                        {{ $t('10_1_label_keywords') }}
                      </div>
                      <div class="content-edit-details__item__data">
                        <Keywords
                          v-model="keywords"
                          :disabled="role === 'READ_ONLY'"
                          :placeholder="$t('10_1_placeholder_keyword')"
                          :editMode="true"
                          :slim="true"
                          @save_changed_value="keywordsSaveChangedValue"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vuescroll>
      </div>
    </Modal>

    <Modal
      v-if="!content_static && $apollo.queries.companies.loading && !wasLoaded"
      @closeModal="close"
    ></Modal>

    <Modal
      v-if="!content_static && !$apollo.queries.companies.loading"
      @closeModal="close"
    >
      <div class="logout">
        <h2
          class="logout__title"
          v-html="$t('10_1_title_content_not_found')"
        ></h2>

        <div class="modal-description">
          <p>{{ $t('10_1_description_content_not_found') }}</p>
        </div>

        <div class="logout__options">
          <Button style="width: 100%" type="delete" @click="close">
            {{ $t('10_1_btn_close') }}
          </Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import vuescroll from 'vuescroll'
import SINGLE_CONTENT from '@/graphql/SingleContent.gql'
import CONTENT_GENERATE_AI_FINAL_TEXT from '@/graphql/ContentGenerateAIFinalText.gql'
import { obj_clone } from '@/services/helpers/clone.js'
import { DateService } from '@/services/DateService.js'
import { DateTime } from 'luxon'
import textLength from '@/constants/TextLengthConstant.js'
import gql from 'graphql-tag'

import Modal from '@/components/Modal.vue'
import FormGroup from '@/components/FormGroup.vue'
import TagInput from '@/components/TagInput.vue'
import ProductInput from '@/components/ProductInput.vue'
import PersonaInput from '@/components/PersonaInput.vue'
import STDCInput from '@/components/STDCInput.vue'
import ChannelInput from '@/components/ChannelInput.vue'

import Keywords from '@/components/Keywords.vue'
import SelectPerson from '@/components/SelectPerson.vue'
import SelectColor from '@/components/SelectColor.vue'
import SelectCompany from '@/components/SelectCompany.vue'
import Button from '@/components/button/Button.vue'

import TimePicker from '@/components/TimePicker.vue'
import DatePickerEditContent from '@/components/DatePickerEditContent.vue'

import IdeaTask from '@/components/IdeaTask.vue'
import Checklist from '@/components/Checklist.vue'
import Accordion from '@/components/design/Accordion.vue'
import AccordionItem from '@/components/design/AccordionItem.vue'
import Resources from '@/components/design/Resources.vue'
import ResourceItem from '@/components/design/ResourceItem.vue'
import ContentEditSubmenu from '@/components/ContentEditSubmenu.vue'
import Comments from '@/components/comments/Comments.vue'
import Feedbacks from '@/components/feedbacks/Feedbacks.vue'
import CompanyAvatar from '@/components/CompanyAvatar.vue'
import StarRating from 'vue-star-rating'

import IcoClose from '@/components/svg/IcoClose.vue'
import IcoDescription from '@/components/svg/small/IcoDescription.vue'
import IcoResources from '@/components/svg/small/IcoResources.vue'
import IcoFinalText from '@/components/svg/small/IcoFinalText.vue'
import IcoContentLink from '@/components/svg/small/IcoContentLink.vue'
import IcoChecklist from '@/components/svg/small/IcoChecklist.vue'
import IcoToggle from '@/components/svg/IcoToggle.vue'
import IcoSubmenu from '@/components/svg/IcoSubmenu.vue'
import IcoContentReview from '@/components/svg/IcoContentReview.vue'
import IcoDuplicate from '@/components/svg/IcoDuplicate.vue'
import IcoSmallDuplicate from '@/components/svg/small/IcoDuplicate.vue'
import IcoInfo from '@/components/svg/small/IcoInfo.vue'
import IcoFeedback from '@/components/svg/IcoFeedback.vue'
import IcoLoading from '@/components/svg/IcoLoading.vue'

import TextAreaEditor from '@/components/TextAreaEditor'
import WYSIWYGEditor from '@/components/WYSIWYGEditor'
import TimePickerEditContent from '@/components/TimePickerEditContent.vue'
import COMPANY_UPDATE from '@/graphql/CompanyUpdate.gql'

export default {
  components: {
    IcoLoading,
    vuescroll,
    IdeaTask,
    Modal,
    FormGroup,
    TagInput,
    ProductInput,
    PersonaInput,
    STDCInput,
    ChannelInput,
    Keywords,
    SelectPerson,
    SelectColor,
    StarRating,
    TextAreaEditor,
    WYSIWYGEditor,
    IcoClose,
    IcoDescription,
    IcoResources,
    IcoFinalText,
    IcoContentLink,
    IcoChecklist,
    IcoToggle,
    IcoSubmenu,
    IcoContentReview,
    IcoDuplicate,
    IcoSmallDuplicate,
    IcoFeedback,
    SelectCompany,
    Button,
    DatePickerEditContent,
    Checklist,
    Accordion,
    AccordionItem,
    Resources,
    ResourceItem,
    ContentEditSubmenu,
    Comments,
    Feedbacks,
    TimePickerEditContent,
    IcoInfo,
    CompanyAvatar,
  },
  apollo: {
    companies: {
      query: SINGLE_CONTENT,
      variables() {
        return {
          from: new Date(-8640000000000000),
          to: new Date(8640000000000000),
          id: this.editContent.id,
          companyId: this.editContent.hasOwnProperty('companyId')
            ? this.editContent.companyId
            : this.selectedCompany.id,
        }
      },
      skip() {
        return (
          !this.editContent.hasOwnProperty('id') || this.editContent.id === ''
        )
      },
      error(error) {
        this.$notify({
          title: this.$t('trs_cannot_load_data'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
        this.$store.commit('setEditContentModalStatus', {})
      },
    },
  },
  computed: {
    refreshContent() {
      return this.$store.getters.getRefreshContent
    },
    me() {
      return this.$store.getters.getMe
    },
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    length() {
      return textLength
    },
    editContent() {
      return this.$store.getters.getEditContentModalStatus
    },
    dateFrom() {
      let ideaDate = new Date(this.editContent.date)
      return new Date(ideaDate.setMonth(ideaDate.getMonth() - 1))
    },
    dateTo() {
      let ideaDate = new Date(this.editContent.date)
      return new Date(ideaDate.setMonth(ideaDate.getMonth() + 1))
    },
    content_static() {
      if (this.companies.length === 0) {
        return {
          id: 0,
          checklist: [],
        }
      }
      return this.companies[0]['contents'][0]
    },
    codelist() {
      return this.$store.getters.getCodelist
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
  },
  created() {
    this.content = obj_clone(this.editContent)

    if (this.role === 'READ_ONLY') {
      this.currentCommunicationTab = 'feedbacks'
    }
  },
  mounted() {
    document.addEventListener('keydown', this.escapeEvent)
  },
  destroyed() {
    document.removeEventListener('keydown', this.escapeEvent)
  },
  data() {
    const timeNow = new Date().getTime()

    return {
      generateAIFinalText: false,
      currentCommunicationTab: 'comments',
      copyFinalTextToClipboard: timeNow,
      wasLoaded: false,
      externalEditState: false,
      currentCompanyName: '',
      focusContentChannel: false,

      titleIsOpen: false,
      titleIsOpenShowError: false,

      descriptionIsOpen: false,
      descriptionIsOpenShowError: false,

      finalTextIsOpen: false,
      finalTextIsOpenShowError: false,

      resourcesIsOpen: false,
      resourcesIsOpenShowError: false,

      checklistIsOpen: false,
      checklistIsOpenShowError: false,

      contentReviewTextIsOpen: false,
      contentReviewTextIsOpenShowError: false,

      feedbacksIsOpen: false,
      feedbacksIsOpenShowError: false,

      contentLinksIsOpen: false,
      contentLinksIsOpenShowError: false,

      commentsIsOpen: false,
      commentsIsOpenShowError: false,

      formValidation: {
        name: true,
      },
      addChecklistItem: false,
      contentReviewValue: 0,
      contentReviewText: '',
      contentId: '',
      loaded: false,
      editName: false,
      editDescription: false,
      editFinal: false,
      editContentReview: false,
      finalText: '',
      showContentDetails: true,
      openSubmenu: false,
      keywords: [],
      state: {},
      channel: [],
      tags: [],
      stdcs: [],
      companies: [],
      companies_to_share: [],
      company: [],
      content: {
        id: '',
        tags: [],
        finalText: '',
      },
      contentDetail: {},
      scrollPanel: {
        initialScrollY: false,
        initialScrollX: false,
        scrollingX: true,
        scrollingY: true,
        speed: 300,
        easing: undefined,
        verticalNativeBarPos: 'right',
      },
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {},
      },
    }
  },
  methods: {
    handleOpenModalFeedback() {
      this.$store.commit('setFeedbackModal', {
        status: true,
        contentId: this.content.id,
      })
    },
    handleShowFeedbacks() {
      this.currentCommunicationTab = 'feedbacks'
    },
    handleShowComments() {
      this.currentCommunicationTab = 'comments'
    },
    handleUpdateContent() {
      this.$apollo.queries.companies.refetch()
    },
    tasksUpdated() {
      this.$apollo.queries.companies.refetch()
    },
    copyFinalText() {
      window
        .getSelection()
        .selectAllChildren(
          document.getElementById('wysiwyg_update_content_final_text')
        )

      document.execCommand('copy')

      this.$notify({
        title: this.$t('10_1_toaster_success_title_final_text_copied'),
        text: this.$t('10_1_toaster_success_description_final_text_copied'),
        type: 'info',
        duration: 5000,
      })

      if (window.getSelection) {
        if (window.getSelection().empty) {
          // Chrome
          window.getSelection().empty()
        } else if (window.getSelection().removeAllRanges) {
          // Firefox
          window.getSelection().removeAllRanges()
        }
      } else if (document.selection) {
        // IE?
        document.selection.empty()
      }
    },
    updatedDate(date) {
      const _date = new Date(date)

      if (this.content.eod) {
        this.content.date = DateTime.fromISO(this.content.date)
          .setZone(this.selectedCompany.zoneId)
          .set({
            year: _date.getFullYear(),
            month: _date.getMonth() + 1,
            day: _date.getDate(),
            hour: 11,
            minute: 59,
            second: 59,
          })
          .toUTC()
          .set({
            hour: 11,
            minute: 59,
            second: 59,
          })
          .toISO()
      } else {
        this.content.date = DateTime.fromISO(this.content.date)
          .setZone(this.selectedCompany.zoneId)
          .set({
            year: _date.getFullYear(),
            month: _date.getMonth() + 1,
            day: _date.getDate(),
          })
          .toUTC()
          .toISO()
      }

      if (this.content.state === 'IDEA') {
        this.content.state = 'TODO'
        this.state = 'TODO'
      }

      try {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!, $date: Date, $eod: Boolean!, $state: State!) {
                updateContent(id: $id, date: $date, eod: $eod, state: $state) {
                  id
                  date
                  eod
                  state
                }
              }
            `,
            variables: {
              id: this.content.id,
              date: this.content.date,
              eod: this.content.eod,
              state: this.content.state,
            },
            update: (store, { data: { updateContent } }) => {
              this.$apollo.queries.companies.refetch()
            },
          })
          .then((data) => {
            // this.content.date = xdate
            // this.editContent.date = xdate
            this.$store.commit('setRefreshToken')
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_title_content_date_updated'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_title_content_date_updated'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    updatedDateClear() {
      try {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!, $state: State!, $eod: Boolean!, $date: Date) {
                updateContent(id: $id, state: $state, eod: $eod, date: $date) {
                  id
                  date
                  state
                  eod
                }
              }
            `,
            variables: {
              id: this.content.id,
              state: 'IDEA',
              date: null,
              eod: true,
            },
            update: (store, { data: { updateContent } }) => {
              /*  this.$notify({
                  title: this.$t('10_1_toaster_success_title_content_date_updated'),
                  text: this.$t('10_1_toaster_success_description_content_date_updated'),
                  type: 'success',
                  duration: 5000
                }); */

              this.state = 'IDEA'
              this.content.state = 'IDEA'
              this.content.eod = true
              this.stateSaveChangedValue('IDEA')
            },
          })
          .then((data) => {
            this.$store.commit('setRefreshToken')
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_title_content_date_updated'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_title_content_date_updated'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    updatedTime(hours, minutes) {
      this.content.date = DateTime.fromISO(this.content.date)
        .setZone(this.selectedCompany.zoneId)
        .set({
          hour: hours,
          minutes: minutes,
          seconds: 0,
        })
        .toUTC()
        .toISO()

      this.content.eod = false

      try {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!, $date: Date, $eod: Boolean!) {
                updateContent(id: $id, date: $date, eod: $eod) {
                  id
                  date
                  eod
                }
              }
            `,
            variables: {
              id: this.content.id,
              date: this.content.date,
              eod: this.content.eod,
            },
            update: (store, { data: { updateContent } }) => {
              this.content.eod = false
              this.$apollo.queries.companies.refetch()
            },
          })
          .then((data) => {
            this.$store.commit('setRefreshToken')
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_title_content_time_updated'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_title_content_time_updated'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    updatedTimeClear(time) {
      const date = DateTime.fromISO(this.content.date)
        .setZone(this.selectedCompany.zoneId)
        .set({
          hour: 11,
          minutes: 59,
          seconds: 59,
        })
        .toUTC()
        .set({
          hour: 11,
          minutes: 59,
          seconds: 59,
        })
        .toISO()

      try {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!, $date: Date, $eod: Boolean!) {
                updateContent(id: $id, date: $date, eod: $eod) {
                  id
                  date
                  eod
                }
              }
            `,
            variables: {
              id: this.content.id,
              date: date,
              eod: true,
            },
            update: (store, { data: { updateContent } }) => {
              /* this.$notify({
                  title: this.$t('10_1_toaster_success_title_content_time_updated'),
                  text: this.$t('10_1_toaster_success_description_content_time_updated'),
                  type: 'success',
                  duration: 5000
                }); */

              this.$apollo.queries.companies.refetch()
            },
          })
          .then((data) => {
            this.$store.commit('setRefreshToken')
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_title_content_time_updated'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_title_content_time_updated'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    addChecklist() {
      this.addChecklistItem = true
      this.checklistIsOpen = true
    },
    prepareChecklistItemsForUpdate(items) {
      let updated_items = []
      items.forEach((item) => {
        let updated_item = {
          assigneeId: item.assigneeId,
          date: item.date,
          done: item.done,
          name: item.name,
        }

        updated_items.push(updated_item)
      })

      return updated_items
    },
    prepareLinksForUpdate(items) {
      let updated_items = []
      items.forEach((item) => {
        let updated_item = {
          link: item.link,
          name: item.name,
        }

        updated_items.push(updated_item)
      })

      return updated_items
    },
    updateContentLinks(list) {
      let linksToUpdate = this.prepareLinksForUpdate(list)

      try {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!, $contentLinks: [LinkInput!]) {
                updateContent(id: $id, contentLinks: $contentLinks) {
                  id
                  contentLinks {
                    name
                    link
                  }
                }
              }
            `,
            variables: {
              id: this.content.id,
              contentLinks: linksToUpdate,
            },
            update: (store, { data: { updateContent } }) => {
              /* this.$notify({
                  title: this.$t('10_1_toaster_success_title_content_links_updated'),
                  text: this.$t('10_1_toaster_success_description_content_links_updated'),
                  type: 'success',
                  duration: 5000
                }); */

              this.$apollo.queries.companies.refetch()
            },
          })
          .then((data) => {})
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_title_content_links_updated'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_title_content_links_updated'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    updateResources(list) {
      let linksToUpdate = this.prepareLinksForUpdate(list)

      try {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!, $resources: [LinkInput!]) {
                updateContent(id: $id, resources: $resources) {
                  id
                  resources {
                    name
                    link
                  }
                }
              }
            `,
            variables: {
              id: this.content.id,
              resources: linksToUpdate,
            },
            update: (store, { data: { updateContent } }) => {
              /* this.$notify({
                title: this.$t('10_1_toaster_success_title_resources_updated'),
                text: this.$t('10_1_toaster_success_description_resources_updated'),
                type: 'success',
                duration: 5000
              }); */

              this.$apollo.queries.companies.refetch()
            },
          })
          .then((data) => {})
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_title_resources_updated'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_title_resources_updated'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    deleteChecklistItem(index) {
      let itemsToUpdate = this.prepareChecklistItemsForUpdate(
        this.content_static.checklist
      )
      itemsToUpdate.splice(index, 1)

      // console.log("itemsToUpdate", itemsToUpdate);

      try {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!, $checklist: [ToDoInput!]) {
                updateContent(id: $id, checklist: $checklist) {
                  id
                  checklist {
                    done
                    name
                    assignee {
                      id
                      name
                    }
                    date
                  }
                }
              }
            `,
            variables: {
              id: this.content.id,
              checklist: itemsToUpdate,
            },
            update: (store, { data: { updateContent } }) => {
              // console.log("products is updated");
              // this.$store.commit("setRefreshToken");

              this.$notify({
                title: this.$t('10_1_toaster_success_title_checklist_deleted'),
                text: this.$t(
                  '10_1_toaster_success_description_checklist_deleted'
                ),
                type: 'success',
                duration: 5000,
              })

              this.$apollo.queries.companies.refetch()
            },

            /*
            optimisticResponse: {
                __typename: 'Mutation',
                updateContent: {
                    __typename: 'Content',
                }
            },
            */
          })
          .then((data) => {
            // console.log('data', data)
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_title_checklist_deleted'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })

        this.editName = false
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_title_checklist_deleted'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    getContentDetail() {
      if (this.companies.length <= 0) {
        return {}
      }

      this.contentDetail = obj_clone(this.companies[0]['contents'][0])
      this.content = obj_clone(this.companies[0]['contents'][0])

      this.content.luxonDate = DateTime.fromISO(this.content.date).setZone(
        this.selectedCompany.zoneId
      )

      if (this.content) {
        this.wasLoaded = true
        this.loaded = true

        if (this.content.hasOwnProperty('review')) {
          if (this.content.review && this.content.review.value) {
            this.contentReviewValue = this.content.review.value / 2
          }

          if (this.content.review && this.content.review.text) {
            this.contentReviewText = this.content.review.text
          }
        }

        this.keywords = this.content.keywords

        this.stdcs = []
        if (this.content.stdcs) {
          if (this.content.stdcs.length > 0) {
            if (this.content.stdcs[0] !== '') {
              this.content.stdcs.forEach((item) => {
                this.stdcs.push({
                  name: item,
                  id: item,
                })
              })
            }
          }
        }

        this.channel = []
        if (this.content.channel) {
          if (this.content.channel.id !== 0) {
            this.channel.push(this.content.channel)
          }
        }

        this.state = this.content.state

        if (this.content.tags) {
          if (this.content.tags.length > 0) {
            if (this.content.tags[0].id !== 0) {
              this.tags = this.content.tags
            }
          }
        }

        this.contentId = this.editContent.id
      }
    },
    escapeEvent(event) {
      if (event.key === 'Escape') {
        this.close()
      }
    },
    addFinalText() {
      this.editFinal = true
      this.finalTextIsOpen = true
    },
    generateFinalTextAI() {
      this.generateAIFinalText = true

      this.$notify({
        title: this.$t('10_1_toaster_title_start_generating_final_text_ai'),
        text: this.$t(
          '10_1_toaster_description_start_generating_final_text_ai'
        ),
        type: 'success',
        duration: 5000,
      })

      try {
        this.$apollo.mutate({
          mutation: CONTENT_GENERATE_AI_FINAL_TEXT,
          variables: {
            id: this.content.id,
          },
          update: (store, { data: { updatedContent } }) => {
            this.$apollo.queries.companies.refetch()

            this.$notify({
              title: this.$t(
                '10_1_toaster_title_finish_generating_final_text_ai'
              ),
              text: this.$t(
                '10_1_toaster_description_finish_generating_final_text_ai'
              ),
              type: 'success',
              duration: 5000,
            })

            this.generateAIFinalText = false
          },
        })
      } catch (e) {
        this.generateAIFinalText = false
        this.$notify({
          title: this.$t('10_1_toaster_title_error_generating_final_text_ai'),
          text: this.$t(
            '10_1_toaster_description_error_generating_final_text_ai'
          ),
          type: 'error',
          duration: 5000,
        })
      }
    },
    toggleSubmenu() {
      this.openSubmenu = !this.openSubmenu
    },
    toggleContentDetails() {
      this.showContentDetails = !this.showContentDetails
    },
    channelSaveChangedValue(items) {
      let channelId = null
      if (items.length > 0) {
        channelId = items[0].id
      }

      try {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!, $channelId: ID) {
                updateContent(id: $id, channelId: $channelId) {
                  id
                }
              }
            `,
            variables: {
              id: this.content.id,
              channelId: channelId,
            },
            update: (store, { data: { updateContent } }) => {
              this.$store.commit('setRefreshToken')

              /* this.$notify({
                  title: this.$t('10_1_toaster_success_title_channel_updated'),
                  text: this.$t('10_1_toaster_success_description_channel_updated'),
                  type: 'success',
                  duration: 5000
                }); */

              this.$apollo.queries.companies.refetch()
            },
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_title_channel_updated'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_title_channel_updated'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    keywordsSaveChangedValue(items) {
      try {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!, $keywords: [String!]) {
                updateContent(id: $id, keywords: $keywords) {
                  id
                  keywords
                }
              }
            `,
            variables: {
              id: this.content.id,
              keywords: items,
            },
            update: (store, { data: { updateContent } }) => {
              this.keywords = items

              /* this.$notify({
                  title: this.$t('10_1_toaster_success_title_keywords_updated'),
                  text: this.$t('10_1_toaster_success_description_keywords_updated'),
                  type: 'success',
                  duration: 5000
                }); */

              this.$apollo.queries.companies.refetch()
            },
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_title_keywords_updated'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_title_keywords_updated'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    productsSaveChangedValue(items) {
      const toUpdate = items.map((e) => e.id)
      try {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!, $productIds: [ID!]) {
                updateContent(id: $id, productIds: $productIds) {
                  id
                  products {
                    name
                    id
                  }
                }
              }
            `,
            variables: {
              id: this.content.id,
              productIds: toUpdate,
            },
            update: (store, { data: { updateContent } }) => {
              this.$store.commit('setRefreshToken')

              /* this.$notify({
                  title: this.$t('10_1_toaster_success_title_products_updated'),
                  text: this.$t('10_1_toaster_success_description_products_updated'),
                  type: 'success',
                  duration: 5000
                }); */

              this.$apollo.queries.companies.refetch()
            },
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_title_products_updated'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_title_products_updated'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    updateUser(user) {
      // console.log('user', user)

      try {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!, $ownerId: ID) {
                updateContent(id: $id, ownerId: $ownerId) {
                  id
                  owner {
                    name
                    id
                  }
                }
              }
            `,
            variables: {
              id: this.content.id,
              ownerId: user.id,
            },
            update: (store, { data: { updateContent } }) => {
              this.$store.commit('setRefreshToken')

              /* this.$notify({
                  title: this.$t('10_1_toaster_success_title_user_updated'),
                  text: this.$t('10_1_toaster_success_description_user_updated'),
                  type: 'success',
                  duration: 5000
                }); */

              this.$apollo.queries.companies.refetch()
            },
            error: (e) => {
              // console.log('error - e:', e)
            },
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_title_user_updated'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_title_user_updated'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    personasSaveChangedValue(items) {
      const toUpdate = items.map((e) => e.id)
      try {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!, $personaIds: [ID!]) {
                updateContent(id: $id, personaIds: $personaIds) {
                  id
                  personas {
                    name
                    id
                  }
                }
              }
            `,
            variables: {
              id: this.content.id,
              personaIds: toUpdate,
            },
            update: (store, { data: { updateContent } }) => {
              this.$store.commit('setRefreshToken')

              /* this.$notify({
                  title: this.$t('10_1_toaster_success_title_personas_updated'),
                  text: this.$t('10_1_toaster_success_description_personas_updated'),
                  type: 'success',
                  duration: 5000
                }); */

              this.$apollo.queries.companies.refetch()
            },
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_title_personas_updated'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_title_personas_updated'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    stateSaveChangedValue(state) {
      if (this.content_static.state === 'IDEA') {
        this.content.date = new Date()
        this.content.eod = true
      }

      const xdate = this.content.eod
        ? DateService.formatDate(this.content.date)
        : DateService.timeZoneToUTC(
            this.content.date,
            this.selectedCompany.zoneId
          )

      try {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!, $state: State!, $date: Date) {
                updateContent(id: $id, state: $state, date: $date) {
                  id
                  state
                  date
                  eod
                }
              }
            `,
            variables: {
              id: this.content.id,
              state: state,
              date: this.content.date,
              eod: this.content.eod,
            },
            update: (store, { data: { updateContent } }) => {
              this.$store.commit('setRefreshToken')

              /* this.$notify({
                  title: this.$t('10_1_toaster_success_title_state_updated'),
                  text: this.$t('10_1_toaster_success_description_state_updated'),
                  type: 'success',
                  duration: 5000
                }); */

              this.$apollo.queries.companies.refetch()
            },
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_title_state_updated'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_title_state_updated'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    tagsSaveChangedValue(items) {
      const toUpdate = items.map((e) => e.id)

      try {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!, $tagIds: [ID!]) {
                updateContent(id: $id, tagIds: $tagIds) {
                  id
                  tags {
                    id
                    name
                  }
                }
              }
            `,
            variables: {
              id: this.content.id,
              tagIds: toUpdate,
            },
            update: (store, { data: { updateContent } }) => {
              this.$store.commit('setRefreshToken')

              /* this.$notify({
                  title: this.$t('10_1_toaster_success_title_tags_updated'),
                  text: this.$t('10_1_toaster_success_description_tags_updated'),
                  type: 'success',
                  duration: 5000
                }); */

              this.$apollo.queries.companies.refetch()
            },
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_title_tags_updated'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_title_tags_updated'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    stdcSaveChangedValue(items) {
      const toUpdate = items.map((e) => e.id)
      try {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!, $stdcs: [STDC!]) {
                updateContent(id: $id, stdcs: $stdcs) {
                  id
                  stdcs
                }
              }
            `,
            variables: {
              id: this.content.id,
              stdcs: toUpdate,
            },
            update: (store, { data: { updateContent } }) => {
              this.$store.commit('setRefreshToken')

              /* this.$notify({
                  title: this.$t('10_1_toaster_success_title_stdc_updated'),
                  text: this.$t('10_1_toaster_success_description_stdc_updated'),
                  type: 'success',
                  duration: 5000
                }); */

              this.$apollo.queries.companies.refetch()
            },
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_title_stdc_updated'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_title_stdc_updated'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    close() {
      if (
        this.titleIsOpen ||
        this.descriptionIsOpen ||
        this.finalTextIsOpen ||
        this.contentReviewTextIsOpen ||
        this.resourcesIsOpen ||
        this.contentLinksIsOpen ||
        this.checklistIsOpen ||
        this.feedbacksIsOpen ||
        this.commentsIsOpen
      ) {
        this.$store.commit('setModalConfirmCloseEditContent', {
          status: true,
          data: {
            titleIsOpen: this.titleIsOpen,
            descriptionIsOpen: this.descriptionIsOpen,
            finalTextIsOpen: this.finalTextIsOpen,
            contentReviewTextIsOpen: this.contentReviewTextIsOpen,
            resourcesIsOpen: this.resourcesIsOpen,
            contentLinksIsOpen: this.contentLinksIsOpen,
            checklistIsOpen: this.checklistIsOpen,
            feedbacksIsOpen: this.feedbacksIsOpen,
            commentsIsOpen: this.commentsIsOpen,
          },
        })

        if (this.titleIsOpen) {
          this.titleIsOpenShowError = true
        }

        if (this.descriptionIsOpen) {
          this.descriptionIsOpenShowError = true
        }

        if (this.finalTextIsOpen) {
          this.finalTextIsOpenShowError = true
        }

        if (this.resourcesIsOpen) {
          this.resourcesIsOpenShowError = true
        }

        if (this.contentReviewTextIsOpen) {
          this.contentReviewTextIsOpenShowError = true
        }

        if (this.contentLinksIsOpen) {
          this.contentLinksIsOpenShowError = true
        }

        if (this.checklistIsOpen) {
          this.checklistIsOpenShowError = true
        }

        if (this.feedbacksIsOpen) {
          this.feedbacksIsOpenShowError = true
        }

        if (this.commentsIsOpen) {
          this.commentsIsOpenShowError = true
        }

        return
      }

      if (this.editContent.companyId) {
        this.$router.push({ name: 'Calendar' })
      }

      this.$store.commit('setEditContentModalStatus', {})
    },
    toggleAccordion(item) {
      item = !item
    },
    addContentReview() {
      if (this.role === 'READ_ONLY') {
        return
      }
      this.externalEditState = true
      this.contentReviewTextIsOpen = true
    },
    startEditReviewText() {
      if (this.role === 'READ_ONLY') {
        return
      }

      this.editContentReview = true

      this.$nextTick(() => {
        this.$refs.content_review_text.$el.focus()
      })
    },
    cancelEditContentReview() {
      if (this.content_static.review != null) {
        if (this.content_static.review.hasOwnProperty('text')) {
          this.contentReviewText = this.content_static.review.text
          this.content.review.text = this.content_static.review.text
        } else {
          this.contentReviewText = ''
          this.content.review.text = ''
        }
      } else {
        this.contentReviewText = ''
        this.content.review = null
      }

      this.$nextTick(() => {
        this.editContentReview = false
        this.contentReviewTextIsOpen = false
        this.externalEditState = false
      })
    },
    saveEditContentReviewText(value) {
      this.$nextTick(() => {
        this.editContentReview = false
      })

      this.contentReviewText = value

      try {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!, $review: ReviewInput) {
                updateContent(id: $id, review: $review) {
                  id
                  review {
                    text
                    value
                  }
                }
              }
            `,
            variables: {
              id: this.content.id,
              review: {
                text: this.contentReviewText,
                value:
                  this.contentReviewValue === 0 ||
                  this.contentReviewValue === null
                    ? null
                    : this.contentReviewValue * 2,
              },
            },
            update: (store, { data: { updateContent } }) => {
              /* this.$notify({
                  title: this.$t('10_1_toaster_success_title_content_review_updated'),
                  text: this.$t('10_1_toaster_success_description_content_review_updated'),
                  type: 'success',
                  duration: 5000
                }); */

              this.$apollo.queries.companies.refetch()
            },
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_title_content_review_updated'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_title_content_review_updated'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    saveEditContentReview() {
      this.$nextTick(() => {
        this.editContentReview = false
      })

      try {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!, $review: ReviewInput) {
                updateContent(id: $id, review: $review) {
                  id
                  review {
                    text
                    value
                  }
                }
              }
            `,
            variables: {
              id: this.content.id,
              review: {
                text: this.contentReviewText,
                value:
                  this.contentReviewValue === 0 ||
                  this.contentReviewValue === null
                    ? null
                    : this.contentReviewValue * 2,
              },
            },
            update: (store, { data: { updateContent } }) => {
              /* this.$notify({
                  title: this.$t('10_1_toaster_success_title_content_review_updated'),
                  text: this.$t('10_1_toaster_success_description_content_review_updated'),
                  type: 'success',
                  duration: 5000
                }); */

              this.$apollo.queries.companies.refetch()
            },
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_title_content_review_updated'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_title_content_review_updated'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    cancelEditName() {
      this.content.name = this.content_static.name
    },
    saveEditName(value) {
      this.content.name = value
      this.formValidation.name = true

      if (this.content.name === '') {
        this.formValidation.name = false

        this.$notify({
          title: this.$t('10_1_toaster_error_title_title_is_empty'),
          text: this.$t('10_1_toaster_error_description_title_is_empty'),
          type: 'error',
          duration: 5000,
        })

        this.content.name = this.content_static.name

        return
      }

      try {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!, $name: String!) {
                updateContent(id: $id, name: $name) {
                  id
                  name
                }
              }
            `,
            variables: {
              id: this.content.id,
              name: this.content.name,
            },
            update: (store, { data: { updateContent } }) => {
              this.$store.commit('setRefreshToken')

              /* this.$notify({
                  title: this.$t('10_1_toaster_success_title_title_updated'),
                  text: this.$t('10_1_toaster_success_description_title_updated'),
                  type: 'success',
                  duration: 5000
                }); */

              this.editName = false
              this.$apollo.queries.companies.refetch()
            },
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_title_title_updated'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_title_title_updated'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    cancelEditDescription() {
      this.editDescription = false
      this.content.description = this.content_static.description
    },
    saveEditDescription(value) {
      this.content.description = value
      this.editDescription = false

      try {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!, $description: String) {
                updateContent(id: $id, description: $description) {
                  id
                  description
                }
              }
            `,
            variables: {
              id: this.content.id,
              description: this.content.description,
            },
            update: (store, { data: { updateContent } }) => {
              this.$store.commit('setRefreshToken')

              /* this.$notify({
                  title: this.$t('10_1_toaster_success_title_description_updated'),
                  text: this.$t('10_1_toaster_success_description_description_updated'),
                  type: 'success',
                  duration: 5000
                }); */

              this.$apollo.queries.companies.refetch()
            },
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_title_description_updated'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })

        this.editDescription = false
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_title_description_updated'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    cancelEditFinal() {
      this.editFinal = false
      this.content.finalText = this.content_static.finalText
    },
    saveEditFinal(value) {
      // TODO is loading
      this.editFinal = false
      this.content.finalText = value

      try {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!, $finalText: String) {
                updateContent(id: $id, finalText: $finalText) {
                  id
                  finalText
                }
              }
            `,
            variables: {
              id: this.content.id,
              finalText: this.content.finalText,
            },
            update: (store, { data: { updateContent } }) => {
              this.finalText = updateContent.finalText

              /* this.$notify({
                  title: this.$t('10_1_toaster_success_title_final_updated'),
                  text: this.$t('10_1_toaster_success_description_final_updated'),
                  type: 'success',
                  duration: 5000
                }); */

              this.$apollo.queries.companies.refetch()
            },
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_title_final_updated'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_title_final_updated'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
  },
  watch: {
    refreshContent() {
      this.$apollo.queries.companies.refetch()
    },
    companies() {
      this.currentCompanyName = this.companies[0].name

      this.getContentDetail()

      if (this.company.length === 0) {
        return
      }

      let tags = []
      this.company[0].tags.forEach((element) => {
        tags.push({
          label: element.name,
          value: element.name,
          id: element.id,
        })
      })

      let products = []
      this.company[0].products.forEach((element) => {
        products.push({
          label: element.name,
          value: element.name,
          id: element.id,
        })
      })

      let personas = []
      this.company[0].personas.forEach((element) => {
        personas.push({
          label: element.name,
          value: element.name,
          id: element.id,
        })
      })

      let users = []
      this.company[0].users.forEach((element) => {
        users.push({
          label: element.name,
          value: element.name,
          id: element.id,
          role: element.role,
          position: element.position,
        })
      })

      let channels = []
      this.company[0].channels.forEach((element) => {
        channels.push({
          label: element.name,
          value: element.name,
          id: element.id,
        })
      })

      this.codelist['products'] = products
      this.codelist['personas'] = personas
      this.codelist['users'] = users
      this.codelist['channels'] = channels
      this.codelist['tags'] = tags

      this.$store.commit('setCodelist', this.codelist)
    },
    content() {
      if (this.content.state !== 'IDEA' && this.content.date === null) {
        let date = new Date()
        date.setHours(23, 59, 59, 0)
        this.content.date = new Date(date)
        this.updatedDate(date)
      }
    },
    role() {
      if (this.role === 'READ_ONLY') {
        this.currentCommunicationTab = 'feedbacks'
      }
    },
  },
}
</script>

<style scoped>
.btn-generate-ai {
}

.btn-generate-ai--loading {
  position: relative;
  padding-right: 20px !important;
}
.loading-icon {
  position: absolute;
  top: 2px;
  right: 4px;
}
.communication-tab {
  display: inline-block;
  border: 2px solid #e7e9e9;
  border-radius: 4px;
  background: #e7e9e9;
  padding: 0;
  list-style-type: none;
}
.communication-tab li {
  display: inline-block;
  border-radius: 4px;
}
.communication-tab li.is-active {
  background: #ffffff;
}
.communication-tab button {
  cursor: pointer;
  border: none;
  background: none;
  color: #888f91;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 10px;
  font-family: 'Nunito';
  letter-spacing: -0.2px;
}
.communication-tab li:hover button {
  color: #17252a;
}
.communication-tab li.is-active button {
  color: #17252a;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 10px;
  font-family: 'Nunito';
  letter-spacing: -0.2px;
}

.rating-box {
  display: flex;
  gap: 10px;
}
.rating-box__value {
  display: flex;
  gap: 5px;
  color: #17252a;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 14px;
  font-family: 'Nunito';
  letter-spacing: -0.2px;
}

.part-content-review__title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 30px;
}

.part-content-review__title .content-edit__title {
  margin-top: 0;
}

.part-content-review__title .vue-star-rating {
  position: relative;
  top: -10px;
}

.vue-star-rating span:nth-child(n + 1) {
  order: 2;
}
.content-edit {
  display: flex;
  padding: 30px;
}

.content-edit--body {
  padding-top: 80px;
}

.content-edit--top {
  padding-top: 155px;
}
.content-edit--header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9991;
  background: #fff;
  padding-bottom: 0;
}

.content-edit__main {
  border-right: 1.5px solid #f5f5f5;
  padding-right: 25px;
  width: 60%;
  max-width: 552px;
}

.content-edit__main--title {
  position: relative;
}
.content-edit__sidebar {
  top: 30px;
  right: 17px;
  padding-left: 25px;
  width: 40%;
}

.content-edit__title {
  margin-top: 32px;
  color: #17252a;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  font-family: Nunito;
  letter-spacing: -0.2px;
}

.content-edit__title svg {
  position: relative;
  top: 2px;
}

.content-edit__name {
  color: #17252a;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  font-family: Nunito;
}

.content-edit__name--clipped {
  height: 30px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content-edit__name-edit {
  width: 100%;
}

.content-edit__main--title .content-edit__name-edit {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  padding-right: 21px;
  padding-bottom: 10px;
}

.content-edit__name-edit__data {
  width: 100%;
}

.content-edit__name-edit__data textarea {
  border: 1px solid #2ce0c1;
  border-radius: 4px;
  padding: 5px;
  width: 100%;
  color: #17252a;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  font-family: Nunito;
}

.content-edit__name-edit__data textarea:focus {
  outline: none;
}

.content-edit__description {
  margin-top: 15px;
  max-width: 530px;
  color: #17252a;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-family: Nunito;
  overflow-wrap: break-word;
}

.content-edit__description--placeholder {
  color: #c5c5c5;
}

.content-edit__description-edit {
  margin-top: 10px;
  width: 100%;
}

.content-edit__description-edit__data {
  width: 100%;
}

.content-edit__description-edit__data textarea {
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  padding: 5px;
  width: 100%;
  color: #17252a;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-family: Nunito;
}

.content-edit__description-edit__data textarea:focus {
  border: 1px solid #2ce0c1;
}

.content-edit__name-edit__data textarea.is-invalid {
  border: 1px solid #f45050;
}

.content-edit__description-edit__data textarea:focus {
  outline: none;
}

.content-edit__name-edit__controll {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
}

.divider {
  position: relative;
  width: 100%;
}

.divider:after {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 1;
  margin-top: 2px;
  background-color: #17252a;
  height: 2px;
  content: '';
}

.divider span {
  display: inline-block;
  position: relative;
  z-index: 2;
  background-color: #fff;
  padding-right: 15px;
  color: #17252a;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 14px;
  font-family: Nunito;
  letter-spacing: -0.2px;
  text-transform: uppercase;
}

.content-edit-details {
  margin-top: 30px;
  border: 1.5px solid #f5f5f5;
  border-radius: 10px;
  max-width: 540px;
}

.content-edit-details__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  padding: 15px 20px;
  color: #17252a;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 14px;
  font-family: Nunito;
  letter-spacing: -0.2px;
  text-transform: uppercase;
}

.content-edit-details__header svg {
  transform: rotate(180deg);
  transition-duration: 0.2s;
  transition-property: transform;
}

.content-edit-details__header.show-content-details svg {
  transform: rotate(0deg);
  transition-duration: 0.2s;
  transition-property: transform;
}

.content-edit-details__body {
  padding: 0 20px;
  padding-bottom: 20px;
}

.content-edit-details__item {
  display: flex;
  padding-top: 14px;
}

.content-edit-details__item__label {
  flex-basis: 100px;
  padding-top: 5px;
  width: 120px;
  color: #17252a;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  font-family: Nunito;
  letter-spacing: -0.2px;
}

.content-edit-details__item__data {
  width: 100%;
  max-width: 220px;
}

.modal-content__owner {
  display: block;
  margin-top: 26px;
  width: 100%;
}

.modal-content__date-time {
  display: flex;
  gap: 7px;
  margin-top: 30px;
}

.content-edit__sidebar__header {
  display: flex;
  justify-content: space-between;
}

.content-edit__channel {
  margin-top: 52px;
}

.content-edit__controll {
  display: flex;
  align-items: center;
  gap: 25px;
}
</style>

<style>
.rating-box__value svg {
  width: 14px;
  height: 14px;
}

.content-edit__channel .tag-input__selected span {
  font-size: 18px !important;
  line-height: 18px !important;
}
</style>

<style>
.part-content-review__title .vue-star-rating span:nth-child(1) {
  order: 2 !important;
}

.part-content-review__title .vue-star-rating span:nth-child(2) {
  order: 3;
}

.part-content-review__title .vue-star-rating span:nth-child(3) {
  order: 4;
}

.part-content-review__title .vue-star-rating span:nth-child(4) {
  order: 5;
}

.part-content-review__title .vue-star-rating span:nth-child(5) {
  order: 6;
}

.part-content-review__title .vue-star-rating span:nth-child(6) {
  order: 1;
  padding-right: 10px;
  color: #17252a;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 14px;
  font-family: Nunito;
  letter-spacing: -0.2px;
}

.v-popper__popper {
  z-index: 99999 !important;
}
</style>
