<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.2857 1.71428H12.5714V0.571439C12.5714 0.255837 12.3156 0 12 0C11.6844 0 11.4286 0.255837 11.4286 0.571439V1.71428H4.57141V0.571439C4.57141 0.255837 4.31558 0 4.00001 0C3.68444 0 3.42857 0.255837 3.42857 0.571439V1.71428H1.71428C0.767511 1.71428 0 2.4818 0 3.42857V14.2857C0 15.2325 0.767511 16 1.71428 16H14.2857C15.2325 16 16 15.2325 16 14.2857V3.42857C16 2.4818 15.2325 1.71428 14.2857 1.71428ZM14.8571 14.2857C14.8571 14.6013 14.6013 14.8572 14.2857 14.8572H1.71428C1.39868 14.8572 1.14285 14.6013 1.14285 14.2857V6.85714H14.8571V14.2857ZM14.8571 5.71429H1.14285V3.42857C1.14285 3.11297 1.39868 2.85713 1.71428 2.85713H3.42857V3.99997C3.42857 4.31558 3.68441 4.57141 4.00001 4.57141C4.31561 4.57141 4.57145 4.31558 4.57145 3.99997V2.85713H11.4286V3.99997C11.4286 4.31558 11.6844 4.57141 12 4.57141C12.3156 4.57141 12.5715 4.31558 12.5715 3.99997V2.85713H14.2857C14.6014 2.85713 14.8572 3.11297 14.8572 3.42857V5.71429H14.8571Z" fill="#17252A"/>
        <path d="M4.5717 7.99988H3.42886C3.11326 7.99988 2.85742 8.25571 2.85742 8.57131C2.85742 8.88691 3.11326 9.14275 3.42886 9.14275H4.5717C4.88731 9.14275 5.14314 8.88691 5.14314 8.57131C5.14314 8.25571 4.88731 7.99988 4.5717 7.99988Z" fill="#17252A"/>
        <path d="M8.57171 7.99988H7.42886C7.11326 7.99988 6.85742 8.25571 6.85742 8.57131C6.85742 8.88691 7.11326 9.14275 7.42886 9.14275H8.57171C8.88732 9.14275 9.14316 8.88691 9.14316 8.57131C9.14316 8.25571 8.88732 7.99988 8.57171 7.99988Z" fill="#17252A"/>
        <path d="M12.5717 7.99988H11.4289C11.1133 7.99988 10.8574 8.25571 10.8574 8.57131C10.8574 8.88691 11.1133 9.14275 11.4289 9.14275H12.5717C12.8873 9.14275 13.1431 8.88691 13.1431 8.57131C13.1431 8.25571 12.8873 7.99988 12.5717 7.99988Z" fill="#17252A"/>
        <path d="M4.5717 10.2858H3.42886C3.11326 10.2858 2.85742 10.5416 2.85742 10.8572C2.85742 11.1728 3.11326 11.4286 3.42886 11.4286H4.5717C4.88731 11.4286 5.14314 11.1728 5.14314 10.8572C5.14314 10.5416 4.88731 10.2858 4.5717 10.2858Z" fill="#17252A"/>
        <path d="M8.57171 10.2858H7.42886C7.11326 10.2858 6.85742 10.5416 6.85742 10.8572C6.85742 11.1728 7.11326 11.4286 7.42886 11.4286H8.57171C8.88732 11.4286 9.14316 11.1728 9.14316 10.8572C9.14316 10.5416 8.88732 10.2858 8.57171 10.2858Z" fill="#17252A"/>
        <path d="M12.5717 10.2858H11.4289C11.1133 10.2858 10.8574 10.5416 10.8574 10.8572C10.8574 11.1728 11.1133 11.4286 11.4289 11.4286H12.5717C12.8873 11.4286 13.1431 11.1728 13.1431 10.8572C13.1431 10.5416 12.8873 10.2858 12.5717 10.2858Z" fill="#17252A"/>
        <path d="M4.5717 12.5715H3.42886C3.11326 12.5715 2.85742 12.8274 2.85742 13.143C2.85742 13.4586 3.11326 13.7144 3.42886 13.7144H4.5717C4.88731 13.7144 5.14314 13.4585 5.14314 13.1429C5.14314 12.8273 4.88731 12.5715 4.5717 12.5715Z" fill="#17252A"/>
        <path d="M8.57171 12.5715H7.42886C7.11326 12.5715 6.85742 12.8274 6.85742 13.143C6.85742 13.4586 7.11326 13.7144 7.42886 13.7144H8.57171C8.88732 13.7144 9.14316 13.4586 9.14316 13.143C9.14316 12.8274 8.88732 12.5715 8.57171 12.5715Z" fill="#17252A"/>
        <path d="M12.5717 12.5715H11.4289C11.1133 12.5715 10.8574 12.8274 10.8574 13.143C10.8574 13.4586 11.1133 13.7144 11.4289 13.7144H12.5717C12.8873 13.7144 13.1431 13.4586 13.1431 13.143C13.1431 12.8274 12.8873 12.5715 12.5717 12.5715Z" fill="#17252A"/>
    </svg>
</template>

<script>

export default {
    props: {
        size: Array,
        default: ['auto', 'auto']
    }
}
</script>
