<template>
  <div class="ideas-no-content">
    <img
      v-if="$i18n.locale === 'cs'"
      src="../assets/images/empty-ideas-cs.png"
      alt=""
    />
    <img v-else src="../assets/images/empty-ideas-en.png" alt="" />

    <div class="ideas-no-content__data">
      <h2>{{ $t('8_4_1_1_title') }}</h2>
      <p>{{ $t('8_4_1_1_description') }}</p>
      <div class="ideas-no-content__buttons">
        <Button
          v-if="role !== 'READ_ONLY'"
          type="primary"
          style="width: 100%"
          @click="addContent()"
        >
          <IcoPlus />
          {{ $t('8_4_1_1_btn_add_content') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/button/Button.vue'
import IcoPlus from '@/components/svg/IcoPlus.vue'

export default {
  components: {
    Button,
    IcoPlus,
  },
  computed: {
    role() {
      return this.$store.getters.getMeCompanyRole
    },
  },
  methods: {
    addContent() {
      this.$store.commit('setCreateContentDefaultValue', {})
      this.$store.commit('setCreateContentModalStatus', true)
    },
  },
}
</script>

<style scoped>
h2 {
  color: #17252a;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 49px;
  font-family: 'Nunito';
  text-align: left;
}

p {
  color: #888f91;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  font-family: 'Nunito';
  text-align: left;
}

.ideas-no-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 408px);
}

.ideas-no-content img {
  max-width: 250px;
}

.ideas-no-content__data {
  width: 100%;
  max-width: 425px;
  text-align: left;
  align-items: start;
}

.ideas-no-content__buttons {
  display: inline-block;
  margin-top: 40px;
}
</style>
