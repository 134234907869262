<template>
  <div>
    <div class="sticky-header">
      <Menu />
    </div>

    <div
      class="container container--top"
      :class="{
        'days-to-expiration': this.selectedCompany && this.selectedCompany.showExpirationWarning,
      }"
    >
      <GlobalNotification />

      <h1 class="main-title">{{ $t('7_1_title') }}</h1>

      <div class="cols">
        <div class="col">
          <div class="col__inner">
            <h2 class="sub-title">{{ $t('7_1_subtitle_personal') }}</h2>

            <div class="line">
              <FormGroup
                :label="$t('7_1_label_first_name')"
                :required="true"
                :validationError="invalidFirstName"
                :focused="focusMeFirstName"
              >
                <input
                  class="form-group__input"
                  type="text"
                  :placeholder="$t('7_1_placeholder_first_name')"
                  :maxlength="length.userName"
                  v-model="me.firstName"
                  @input="invalidFirstName = false"
                  @focus="focusMeFirstName = true"
                  @blur="focusMeFirstName = false"
                />
              </FormGroup>
            </div>

            <div class="line">
              <FormGroup
                :label="$t('7_1_label_last_name')"
                :required="true"
                :validationError="invalidLastName"
                :focused="focusMeLastName"
              >
                <input
                  class="form-group__input"
                  type="text"
                  :placeholder="$t('7_1_placeholder_last_name')"
                  :maxlength="length.userName"
                  v-model="me.lastName"
                  @input="invalidLastName = false"
                  @focus="focusMeLastName = true"
                  @blur="focusMeLastName = false"
                />
              </FormGroup>
            </div>

            <div class="line">
              <FormGroup
                :focused="focusMePosition"
                :label="$t('7_1_label_job')"
                :required="false"
              >
                <input
                  class="form-group__input"
                  type="text"
                  :placeholder="$t('7_1_description_job')"
                  :maxlength="length.userJob"
                  v-model="me.position"
                  @focus="focusMePosition = true"
                  @blur="focusMePosition = false"
                />
              </FormGroup>
            </div>

            <div class="line">
              <h3>{{ $t('7_1_current_email_title') }}</h3>
              <p class="black">
                {{ $t('7_1_current_email_info') }}
                <strong>{{ user.email }}</strong>
              </p>
            </div>

            <!--
            <div class="line">
              <FormGroup
                :label="$t('7_1_label_new_email')"
                :required="true"
                :disabled="true"
              >
                <input
                  class="form-group__input"
                  type="text"
                  :placeholder="$t('7_1_placeholder_new_email')"
                  v-model="new_email_address"
                  disabled
                />
              </FormGroup>
            </div>
            -->

            <!--
            <div class="line">
              <h3>{{ $t("7_1_linked_accounts") }}</h3>
              <LinkedAccounts />
            </div>
            -->

            <div class="line">
              <ProfilePicture :title="$t('7_1_image_default_name')" />
            </div>

            <div class="line">
              <Button
                @click="updateProfile()"
                :type="'primary'"
                style="width: 100%"
                :disabled="disabledEditPersonalInformation"
                >{{ $t('7_1_btn_save_personal') }}</Button
              >
            </div>
          </div>
        </div>

        <div class="col">
          <div class="col__inner">
            <h2>{{ $t('7_1_subtitle_security') }}</h2>

            <form @submit.prevent="updatePassword" autocomplete="off">
              <div class="line">
                <FormGroup
                  :label="$t('7_1_label_current_password')"
                  :required="true"
                  :validationError="invalidCurrentPassword"
                  :focused="focusCurrentPassword"
                >
                  <input
                    class="form-group__input"
                    :type="inputTypeCurrent"
                    :maxlength="length.short"
                    placeholder="••••••••"
                    v-model="current_password"
                    @keydown="invalidCurrentPassword = false"
                    @focus="focusCurrentPassword = true"
                    @blur="focusCurrentPassword = false"
                  />
                  <button
                    @click.prevent="toggleCurrentPassword"
                    class="toggle-password"
                  >
                    <span v-if="!showCurrentPassword"><IcoShowPassword /></span>
                    <span v-if="showCurrentPassword"><IcoHiddePassword /></span>
                  </button>
                </FormGroup>
              </div>

              <div class="line">
                <Link @click="openModalForgotPassword()">{{
                  $t('7_1_link_forgot_password')
                }}</Link>
              </div>

              <div class="line">
                <FormGroup
                  :label="$t('7_1_label_new_password')"
                  :required="true"
                  :validationError="invalidNewPassword"
                  :focused="focusNewPassword"
                >
                  <input
                    class="form-group__input"
                    :type="inputType"
                    :maxlength="length.short"
                    placeholder="••••••••"
                    v-model="new_password"
                    @keydown="invalidNewPassword = false"
                    @focus="focusNewPassword = true"
                    @blur="focusNewPassword = false"
                  />
                  <button
                    @click.prevent="togglePassword"
                    class="toggle-password"
                  >
                    <span v-if="!showPassword"><IcoShowPassword /></span>
                    <span v-if="showPassword"><IcoHiddePassword /></span>
                  </button>
                </FormGroup>
              </div>

              <div class="line">
                <FormGroup
                  :label="$t('7_1_label_confirm_password')"
                  :required="true"
                  :validationError="invalidNewPassword"
                  :focused="focusRepeatPassword"
                >
                  <input
                    class="form-group__input"
                    :type="inputTypeConfirm"
                    :maxlength="length.short"
                    placeholder="••••••••"
                    v-model="confirm_new_password"
                    @keydown="invalidNewPassword = false"
                    @focus="focusRepeatPassword = true"
                    @blur="focusRepeatPassword = false"
                  />
                  <button
                    @click.prevent="togglePasswordConfirm"
                    class="toggle-password"
                  >
                    <span v-if="!showPasswordConfirm"><IcoShowPassword /></span>
                    <span v-if="showPasswordConfirm"><IcoHiddePassword /></span>
                  </button>
                </FormGroup>
              </div>

              <div v-if="new_password.length > 0">
                <PasswordStrength v-model="new_password" />
              </div>

              <div class="line">
                <Button
                  :type="'primary'"
                  style="width: 100%"
                  :disabled="disabledEditSecurity"
                  >{{ $t('7_1_btn_save_security') }}</Button
                >
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="cols cols--nostrech">
        <div class="col col--full col--center">
          <Button
            @click="deleteAccount()"
            :type="'secondary'"
            style="max-width: 360px; width: 100%"
          >
            <IcoBin /> {{ $t('7_1_btn_delete_account') }}
          </Button>
        </div>
      </div>
    </div>

    <ModalDeleteAccount
      :companiesJoinedCount="me.companiesJoinedCount"
      :contentsPlannedCount="me.contentsPlannedCount"
      v-if="showModalDeleteAccount"
      @closeModal="closeModalDeleteAccount"
      @openForgotPasswordAndCloseModal="openForgotPasswordAndCloseModal"
    />
    <ModalForgotPassword
      v-if="showModalForgotPassword"
      @closeModal="closeModalForgotPassword"
    />
  </div>
</template>

<script>
import textLength from '@/constants/TextLengthConstant.js'
import ME_PROFILE from '@/graphql/MeProfile.gql'
import ME_UPDATE from '@/graphql/MeUpdate.gql'
import PASSWORD_UPDATE from '@/graphql/PasswordUpdate.gql'

import Link from '@/components/design/Link.vue'

import Menu from '@/components/Menu.vue'
import FormGroup from '@/components/FormGroup.vue'
import Button from '@/components/button/Button.vue'
import LinkedAccounts from '@/components/LinkedAccounts.vue'
import ProfilePicture from '@/components/ProfilePicture.vue'
import ShowError from '@/components/ShowError.vue'
import ModalDeleteAccount from '@/components/ModalDeleteAccount.vue'
import ModalForgotPassword from '@/components/ModalForgotPassword.vue'
import PasswordStrength from '@/components/PasswordStrength.vue'

import IcoBin from '@/components/svg/IcoBin.vue'
import IcoShowPassword from '@/components/svg/IcoShowPassword.vue'
import IcoHiddePassword from '@/components/svg/IcoHiddePassword.vue'
import GlobalNotification from '@/components/GlobalNotification.vue'

export default {
  apollo: {
    me: {
      query: ME_PROFILE,
      error(error) {
        console.log('error', error)
      },
    },
  },
  components: {
    Menu,
    FormGroup,
    LinkedAccounts,
    ProfilePicture,
    ShowError,
    ModalDeleteAccount,
    ModalForgotPassword,
    Link,
    PasswordStrength,

    Button,
    IcoBin,
    IcoShowPassword,
    IcoHiddePassword,
    GlobalNotification,
  },
  computed: {
    user() {
      return this.me
    },
    length() {
      return textLength
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
  },
  data() {
    return {
      focusMeFirstName: false,
      focusMeLastName: false,
      focusMePosition: false,
      focusCurrentPassword: false,
      focusNewPassword: false,
      focusRepeatPassword: false,

      invalidFirstName: false,
      invalidLastName: false,
      me: '',
      showPassword: false,
      showCurrentPassword: false,
      showPasswordConfirm: false,
      inputType: 'password',
      inputTypeConfirm: 'password',
      inputTypeCurrent: 'password',

      currentPasswordHasError: false,
      passwordHasError: false,
      confirmPasswordHasError: false,

      showModalDeleteAccount: false,
      showModalForgotPassword: false,

      disabledEditPersonalInformation: true,
      disabledEditSecurity: true,
      error_security: [],
      error_personalInformation: [],
      firstName: '',
      lastName: '',
      job: '',
      current_password: '',
      new_password: '',
      new_email_address: '',
      confirm_new_password: '',

      invalidNewPassword: false,
      invalidCurrentPassword: false,
    }
  },
  methods: {
    updatePassword() {
      this.invalidCurrentPassword = false
      this.invalidNewPassword = false

      if (this.current_password === '') {
        this.invalidCurrentPassword = true

        this.$notify({
          title: this.$t('7_1_toaster_error_title_current_password_required'),
          text: this.$t(
            '7_1_toaster_error_description_current_password_required'
          ),
          type: 'error',
          duration: 5000,
        })

        return
      }

      if (this.new_password === '') {
        this.invalidNewPassword = true

        this.$notify({
          title: this.$t('7_1_toaster_error_title_new_password_required'),
          text: this.$t('7_1_toaster_error_description_new_password_required'),
          type: 'error',
          duration: 5000,
        })

        return
      }

      if (this.new_password !== this.confirm_new_password) {
        this.invalidNewPassword = true

        this.$notify({
          title: this.$t('7_1_toaster_error_title_password_mismatch'),
          text: this.$t('7_1_toaster_error_description_password_mismatch'),
          type: 'error',
          duration: 5000,
        })

        return
      }

      try {
        this.$apollo
          .mutate({
            mutation: PASSWORD_UPDATE,
            variables: {
              oldPassword: this.current_password,
              newPassword: this.new_password,
            },
            update: (store, { data: { updateContent } }) => {},
          })
          .then((data) => {
            this.$notify({
              title: this.$t('7_1_toaster_success_title_password_updated'),
              text: this.$t('7_1_toaster_success_description_password_updated'),
              type: 'success',
              duration: 5000,
            })

            this.current_password = ''
            this.new_password = ''
            this.confirm_new_password = ''
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('7_1_toaster_error_title_password_updated'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('7_1_toaster_error_title_password_updated'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    updateProfile() {
      this.invalidName = false

      if (this.me.firstName.length < 3) {
        this.$notify({
          title: this.$t('7_1_toaster_error_title_first_name_is_short'),
          text: this.$t('7_1_toaster_error_description_first_name_is_short'),
          type: 'error',
          duration: 5000,
        })

        this.invalidFirstName = true

        return
      }

      if (this.me.firstName.length > 50) {
        this.$notify({
          title: this.$t('7_1_toaster_error_title_first_name_is_long'),
          text: this.$t('7_1_toaster_error_description_first_name_is_long'),
          type: 'error',
          duration: 5000,
        })

        this.invalidFirstName = true

        return
      }

      if (this.me.lastName.length < 3) {
        this.$notify({
          title: this.$t('7_1_toaster_error_title_last_name_is_short'),
          text: this.$t('7_1_toaster_error_description_last_name_is_short'),
          type: 'error',
          duration: 5000,
        })

        this.invalidLastName = true

        return
      }

      if (this.me.lastName.length > 50) {
        this.$notify({
          title: this.$t('7_1_toaster_error_title_last_name_is_long'),
          text: this.$t('7_1_toaster_error_description_last_name_is_long'),
          type: 'error',
          duration: 5000,
        })

        this.invalidLastName = true

        return
      }

      try {
        this.$apollo
          .mutate({
            mutation: ME_UPDATE,
            variables: {
              firstName: this.me.firstName,
              lastName: this.me.lastName,
              position: this.me.position,
            },
            update: (store, { data: { updateMe } }) => {
              this.$store.commit('setMe', updateMe)
            },
          })
          .then((data) => {
            this.$notify({
              title: this.$t('7_1_toaster_success_title_profile_updated'),
              text: this.$t('7_1_toaster_success_description_profile_updated'),
              type: 'success',
              duration: 5000,
            })

            this.$store.commit('setUserProfile')
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('7_1_toaster_error_title_profile_updated'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('7_1_toaster_error_title_profile_updated'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    togglePassword() {
      this.showPassword = !this.showPassword

      if (this.showPassword) {
        this.inputType = 'text'
      } else {
        this.inputType = 'password'
      }
    },
    toggleCurrentPassword() {
      this.showCurrentPassword = !this.showCurrentPassword

      if (this.showCurrentPassword) {
        this.inputTypeCurrent = 'text'
      } else {
        this.inputTypeCurrent = 'password'
      }
    },
    togglePasswordConfirm() {
      this.showPasswordConfirm = !this.showPasswordConfirm

      if (this.showPasswordConfirm) {
        this.inputTypeConfirm = 'text'
      } else {
        this.inputTypeConfirm = 'password'
      }
    },
    deleteAccount() {
      this.showModalDeleteAccount = true
    },
    closeModalDeleteAccount() {
      this.showModalDeleteAccount = false
    },
    openModalForgotPassword() {
      this.showModalForgotPassword = true
    },
    closeModalForgotPassword() {
      this.showModalForgotPassword = false
    },
    openForgotPasswordAndCloseModal() {
      this.showModalDeleteAccount = false
      this.showModalForgotPassword = true
    },
  },
  watch: {
    me() {
      this.disabledEditPersonalInformation = false
    },
    new_email_address() {
      this.disabledEditPersonalInformation = false
    },
    current_password() {
      this.disabledEditSecurity = false
    },
    new_password() {
      this.disabledEditSecurity = false
    },
    confirm_new_password() {
      this.disabledEditSecurity = false
    },
  },
}
</script>

<style scoped>
p.black {
  color: #000000;
}
</style>
