import axiosInstance from "@/services/rest-api/restapi";
// import TokenService from "./token.service";

const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("access_token")
      if (token) {
        config.headers["Authorization"] = 'Bearer ' + token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
        const originalConfig = err.config;

        // Access Token was expired
        if (err.response.status === 401) {
            originalConfig._retry = true;

            try {
                const refresh_token = localStorage.getItem("refresh_token");

                const rs = await axiosInstance.post("/api/login/refresh", {
                    token: refresh_token,
                });

                localStorage.setItem("access_token", rs.data.access_token);
                localStorage.setItem("refresh_token", rs.data.refresh_token);

                return axiosInstance(originalConfig);
            } catch (_error) {
                return Promise.reject(_error);
            }
        }

      return Promise.reject(err);
    }
  );
};

export default setup;