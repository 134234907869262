<template>
  <Sidebar>
    <h2>{{ $t('1_1_title') }}</h2>
    <p>{{ $t('1_1_description') }}</p>

    <form action="" @submit.prevent="login()">
      <div class="line">
        <FormGroup
          :label="$t('1_1_label_email')"
          :required="true"
          :validationError="validationError.email"
          :focused="focusEmail"
        >
          <input
            class="form-group__input"
            type="text"
            :placeholder="$t('1_1_placeholder_email')"
            v-model="email"
            autocomplete="off"
            @focus="focusEmail = true"
            @blur="focusEmail = false"
            @input="validationError.email = false"
          />
        </FormGroup>
      </div>

      <div class="line">
        <FormGroup
          :label="$t('1_1_label_password')"
          :required="true"
          :validationError="validationError.password"
          :focused="focusPassword"
        >
          <input
            class="form-group__input"
            :type="inputType"
            :placeholder="'••••••••'"
            v-model="password"
            autocomplete="off"
            @focus="focusPassword = true"
            @blur="focusPassword = false"
            @input="validationError.password = false"
          />

          <button
            @click.stop="togglePassword"
            class="toggle-password"
            type="button"
          >
            <span v-if="!showPassword"><IcoShowPassword /></span>
            <span v-if="showPassword"><IcoHiddePassword /></span>
          </button>
        </FormGroup>
      </div>

      <div class="line line--column line--last">
        <Link @click="toForgotPassword()">{{
          $t('1_1_link_forgot_password')
        }}</Link>
      </div>

      <div class="line">
        <Button style="width: 100%" :size="'max'" :type="'primary'">{{
          $t('1_1_btn_login')
        }}</Button>
      </div>
    </form>

    <!--
    <div class="line line--half">
      <p class="center">
        <strong>{{ $t("1_1_or") }}</strong>
      </p>
    </div>


    <div id="gSignInWrapper">
      <span class="label">Sign in with:</span>
      <div id="customBtn" class="customGPlusSignIn">
        <span class="icon"></span>
        <span class="buttonText">Google</span>
      </div>
    </div>
    <div id="name"></div>
    -->

    <!--
    <div class="line line--half">
      <GoogleLogin class="google-login" :params="params" :onSuccess="onSuccess" :onFailure="onFailure"><IcoLogoGoogle /> {{ $t("1_1_btn_google_login") }}</GoogleLogin>
    </div>
    -->

    <div class="line">
      <p>
        {{ $t('1_1_no_account') }}
        <Link @click="toSignUp()">{{ $t('1_1_sign_up') }}</Link>
      </p>
    </div>
  </Sidebar>
</template>

<script>
import GoogleLogin from 'vue-google-login'
import gql from 'graphql-tag'
import { URL } from '@/constants/URLConstant.js'
import { SignService } from '@/services/SignService.js'

import Sidebar from '@/components/layout/Sidebar.vue'
import FormGroup from '@/components/FormGroup.vue'
import Button from '@/components/button/Button.vue'
import Link from '@/components/design/Link.vue'
import Checkbox from '@/components/Checkbox.vue'

import IcoLogoGoogle from '@/components/svg/IcoLogoGoogle.vue'
import IcoShowPassword from '@/components/svg/IcoShowPassword.vue'
import IcoHiddePassword from '@/components/svg/IcoHiddePassword.vue'

export default {
  components: {
    GoogleLogin,
    Sidebar,
    FormGroup,
    Button,
    Link,
    Checkbox,
    IcoLogoGoogle,
    IcoShowPassword,
    IcoHiddePassword,
  },
  created() {
    /*
    gapi.load('auth2', function(){
      // Retrieve the singleton for the GoogleAuth library and set up the client.
      auth2 = gapi.auth2.init({
        client_id: '514048619966-cqa96ol8qrci85cmb16mpu6rcqjaobuo.apps.googleusercontent.com',
        cookiepolicy: 'single_host_origin',
        // Request scopes in addition to 'profile' and 'email'
        //scope: 'additional_scope'
      });
      attachSignin(document.getElementById('customBtn'));

    });


    function attachSignin(element) {
      console.log(element.id);
      auth2.attachClickHandler(element, {},
          function(googleUser) {
            document.getElementById('name').innerText = "Signed in: " +
                googleUser.getBasicProfile().getName();
          }, function(error) {
            alert(JSON.stringify(error, undefined, 2));
          });
    }
    */
  },
  data() {
    return {
      params: {
        client_id:
          '514048619966-cqa96ol8qrci85cmb16mpu6rcqjaobuo.apps.googleusercontent.com',
      },
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true,
      },
      focusEmail: false,
      focusPassword: false,

      email: '',
      password: '',
      showPassword: false,
      inputType: 'password',
      validationError: {
        email: false,
        password: false,
      },
      rememberMe: false,
    }
  },
  methods: {
    onSuccess(googleUser) {
      console.log(googleUser)
      console.log(googleUser.getBasicProfile())
    },
    onFailure() {
      this.$notify({
        title: this.$t('1_2_invalid_google_login_title'),
        text: this.$t('1_2_invalid_google_login_text'),
        type: 'error',
        duration: 5000,
      })
    },
    togglePassword() {
      this.showPassword = !this.showPassword

      if (this.showPassword) {
        this.inputType = 'text'
      } else {
        this.inputType = 'password'
      }
    },
    login() {
      if (this.validateFields()) {
        this.$notify({
          title: this.$t('1_2_invalid_login_title'),
          text: this.$t('1_2_invalid_login_text'),
          type: 'error',
          duration: 5000,
        })

        return
      }

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: this.email,
          password: this.password,
        }),
      }

      fetch(URL + '/api/login', requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 412) {
            this.$notify({
              title: this.$t('1_2_toaster_error_title_not_confirmed'),
              text: this.$t('1_2_toaster_error_description_not_confirmed'),
              type: 'error',
              duration: 5000,
            })

            const encryptedEmail = this.$CryptoJS.AES.encrypt(
              this.email,
              'ContPass'
            ).toString()
            this.$router.push({
              name: 'VerifyEmail',
              params: { email: encryptedEmail },
            })

            return
          }

          if (data.status === 423) {
            this.$notify({
              title: this.$t('1_2_toaster_error_title_account_locked'),
              text: this.$t('1_2_toaster_error_description_account_locked'),
              type: 'error',
              duration: 5000,
            })

            return
          }

          if (data.status === 401) {
            this.$notify({
              title: this.$t('1_2_toaster_error_title_unauthorized'),
              text: this.$t('1_2_toaster_error_description_unauthorized'),
              type: 'error',
              duration: 5000,
            })

            return
          }

          localStorage.setItem('access_token', data.access_token)
          localStorage.setItem('refresh_token', data.refresh_token)

          this.email = ''
          this.password = ''

          if (this.$route.params.redirect_url) {
            let url = this.$route.params.redirect_url
            if (url[0] === '/') {
              this.$router.push({ path: url })
            } else {
              this.$router.push({ path: '/' + url })
            }

            return
          }

          this.$router.push({ name: 'Calendar' })
        })
        .catch((error) => {
          this.$notify({
            title: this.$t('1_2_invalid_login_title'),
            text: this.$t('1_2_invalid_login_text'),
            type: 'error',
            duration: 5000,
          })
        })
    },
    loginGoogle() {
      this.$notify({
        title: this.$t('1_2_ok_google_complete_title'),
        text: this.$t('1_2_ok_google_complete_text'),
        type: 'warning',
        duration: 5000,
      })
    },
    toSignUp() {
      this.$router.push({ name: 'SignUp' })
    },
    toForgotPassword() {
      this.$router.push({ name: 'ResetPassword' })
    },
    validateFields() {
      this.invalidForm = false

      this.validationError.email = false
      if (!SignService.validateEmail(this.email)) {
        this.validationError.email = true
        this.invalidForm = true
      }

      this.validationError.password = false
      if (this.password === '') {
        this.validationError.password = true
        this.invalidForm = true
      }

      return this.invalidForm
    },
    validateEmail() {
      if (this.validationError.email) {
        this.validationError.email = !SignService.validateEmail(this.email)
      }
    },
  },
}
</script>

<style scoped>
.google-login {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  cursor: pointer;
  border: none;
  border-radius: 9px;
  background: #e7e9e9;
  padding: 12px 25px;
  width: 100%;
  height: 46px;
  color: #17252a;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 1;
  font-family: Nunito;
}

.google-login:hover {
  background: #d1d4d4;
  color: #17252a;
}

.google-login:active {
  background: #c2c2c2;
}
</style>
