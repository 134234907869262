import Vue from 'vue'
import VueRouter from 'vue-router'

import Calendar from '../views/Calendar.vue'
import CalendarWeek from '../views/CalendarWeek.vue'
import CalendarDay from '../views/CalendarDay.vue'
import List from '../views/List.vue'
import Ideas from '../views/Ideas.vue'
import Profile from '../views/Profile.vue'
import Login from '../views/Login.vue'
import SignUp from '../views/SignUp.vue'
import ResetPassword from '../views/ResetPassword.vue'
import CheckEmail from '../views/CheckEmail.vue'
import CreateNewPassword from '../views/CreateNewPassword.vue'
import PasswordSuccessfullyChanged from '../views/PasswordSuccessfullyChanged.vue'
import CreateOrJoin from '../views/CreateOrJoin.vue'
import CreateCompany from '../views/CreateCompany.vue'
import JoinCompany from '../views/JoinCompany.vue'
import Dashboard from '../views/Dashboard.vue'
import Invite from '../views/Invite.vue'
import Verify from '../views/Verify.vue'
import VerifyEmail from '../views/VerifyEmail.vue'

import Companies from '../views/Companies.vue'
import Company from '../views/Company.vue'
import AddCompany from '../views/AddCompany.vue'

import NotFound from '../views/NotFound.vue'
import NetworkIssue from '../views/NetworkIssue.vue'

import VueJwtDecode from 'vue-jwt-decode'
import { URL } from '@/constants/URLConstant.js'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/calendar',
  },
  {
    path: '/login/:redirect_url?',
    name: 'Login',
    component: Login,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/check-email',
    name: 'CheckEmail',
    component: CheckEmail,
  },
  {
    path: '/create-new-password/:token',
    name: 'CreateNewPassword',
    component: CreateNewPassword,
  },
  {
    path: '/password-successfully-changed',
    name: 'PasswordSuccessfullyChanged',
    component: PasswordSuccessfullyChanged,
  },
  {
    path: '/create-or-join',
    name: 'CreateOrJoin',
    component: CreateOrJoin,
  },
  {
    path: '/create-company',
    name: 'CreateCompany',
    component: CreateCompany,
  },
  {
    path: '/join-company',
    name: 'JoinCompany',
    component: JoinCompany,
  },
  {
    path: '/calendar/:company_id?/:content_id?',
    name: 'Calendar',
    component: Calendar,
  },
  {
    path: '/calendar-week',
    name: 'CalendarWeek',
    component: CalendarWeek,
  },
  {
    path: '/calendar-day',
    name: 'CalendarDay',
    component: CalendarDay,
  },
  {
    path: '/list',
    name: 'List',
    component: List,
  },
  {
    path: '/ideas',
    name: 'Ideas',
    component: Ideas,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/add-company',
    name: 'AddCompany',
    component: AddCompany,
  },
  {
    path: '/companies',
    name: 'Companies',
    component: Companies,
  },
  {
    path: '/company/:id?',
    name: 'Company',
    component: Company,
  },
  {
    path: '/invite/:token',
    name: 'Invite',
    component: Invite,
  },
  {
    path: '/verify/:token',
    name: 'Verify',
    component: Verify,
  },
  {
    path: '/verify-email/:email',
    name: 'VerifyEmail',
    component: VerifyEmail,
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
    props: true,
  },
  {
    path: '/network-issue',
    name: 'network-issue',
    component: NetworkIssue,
  },
  {
    path: '*',
    redirect: { name: '404', params: { resource: 'page' } },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  store.commit('setPrevRouteName', from.name)

  if (
    to.name === 'Login' ||
    to.name === 'SignUp' ||
    to.name === 'VerifyEmail' ||
    to.name === 'Verify' ||
    to.name === 'CheckEmail' ||
    to.name === 'ResetPassword' ||
    to.name === 'CreateNewPassword'
  ) {
    return next()
  }

  const token = localStorage.getItem('access_token')

  if (token === 'undefined' || !token) {
    if (to.name === 'Invite') {
      return next({
        name: 'Login',
        params: {
          redirect_url: 'invite/' + to.params.token,
        },
      })
    } else {
      return next({
        name: 'Login',
        params: { redirect_url: to.fullPath },
      })
    }
  }

  if (
    to.name === 'CreateOrJoin' ||
    to.name === 'CreateCompany' ||
    to.name === 'JoinCompany'
  ) {
    return next()
  }

  // Fetch ME and refresh token if invalid
  const requestOptionsMe = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify({
      query: 'query {me {id name email anyCompanyJoined invitesCount}}',
    }),
  }

  const responseMe = await fetch(URL + '/graphql', requestOptionsMe)
  if (responseMe.status === 401) {
    const refresh_token = localStorage.getItem('refresh_token')
    const requestOptionsToRefresh = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        token: refresh_token,
      }),
    }

    const responseRefreshToken = await fetch(
      URL + '/api/login/refresh',
      requestOptionsToRefresh
    )
    if (!responseRefreshToken.ok) {
      localStorage.removeItem('access_token')
      localStorage.removeItem('refresh_token')

      if (to.name === 'Invite') {
        return next({
          name: 'Login',
          params: {
            redirect_url: 'invite/' + to.params.token,
          },
        })
      } else {
        return next({
          name: 'Login',
          params: { redirect_url: to.fullPath },
        })
      }
    }

    let refreshTokenJson = await responseRefreshToken.json()
    localStorage.setItem('access_token', refreshTokenJson.access_token)
    localStorage.setItem('refresh_token', refreshTokenJson.refresh_token)

    const requestOptionsMeAfterRefresh = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + refreshTokenJson.access_token,
      },
      body: JSON.stringify({
        query: 'query {me {id name email anyCompanyJoined invitesCount}}',
      }),
    }

    const responseMeAfterRefresh = await fetch(
      URL + '/graphql',
      requestOptionsMeAfterRefresh
    )
    if (!responseMeAfterRefresh.ok) {
      return next({ name: 'Login' })
    }

    let jsonMe = await responseMeAfterRefresh.json()
    store.commit('setMe', jsonMe.data.me)

    if (!jsonMe.data.me.anyCompanyJoined) {
      if (to.name !== 'Profile') {
        return next({
          name: 'CreateOrJoin',
          params: { redirect_url: to.fullPath },
        })
      }
    }

    return next()
  }

  try {
    let jsonMe = await responseMe.json()
    store.commit('setMe', jsonMe.data.me)

    if (!jsonMe.data.me.anyCompanyJoined) {
      if (to.name !== 'Profile') {
        return next({ name: 'CreateOrJoin' })
      }
    }
  } catch {
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')

    return next({ name: 'Login', params: { redirect_url: to.fullPath } })
  }

  return next()
})

export default router
