<template>
  <div class="tinymce-editor">
    <editor
      :id="tinymceRef"
      :ref="tinymceRef"
      v-model="myValue"
      :init="init"
      :disabled="disabled"
      :placeholder="placeholder"
      @onClick="onClick">
    </editor>
  </div>
</template>
<script> 
import tinymce from 'tinymce/tinymce'
import Editor from '@tinymce/tinymce-vue'

import 'tinymce/themes/silver'
import 'tinymce/icons/default'
import 'tinymce/models/dom'

import 'tinymce/plugins/emoticons'
import 'tinymce/plugins/emoticons/js/emojis'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/link'
import 'tinymce/plugins/visualblocks'

// https://www.tiny.cloud/docs/tinymce/6/migration-from-5x/#things-we-renamed

import '@/i18n/tinymce/cs.js';

export default {
  components: {
    Editor
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    plugins: {
      type: [String, Array],
      default: 'visualblocks lists wordcount emoticons autoresize fullscreen link'
    },
    toolbar: {
      type: [String, Array],
      default: 'undo redo | copy blocks | bold italic forecolor fullscreen | link emoticons | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists | removeformat'
    },
    placeholder: {
      type: String,
      default: "",
    },
    tinymceRef: {
      type: String,
      required: true,
    }
  },
  data () {
    return {
      init: {
        language: this.$i18n.locale,
        skin_url: '/tinymce/skins/ui/oxide',
        height: 300,
        plugins: this.plugins,
        toolbar: this.toolbar,
        autoresize_bottom_margin: 0,
        branding: false,
        elementpath: false,
        menubar: false,
        // auto_focus: this.tinymceRef,
        content_style: "@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800&display=swap'); body { font-family: Nunito !important; font-size: 14px; }",
        setup : function(ed)
            {
                ed.on('init', function() 
                {
                    // this.getDoc().body.style.fontSize = '12';
                    // this.getDoc().body.style.fontFamily = 'Arial';
                });
            },
      },
      myValue: this.value
    }
  },
  mounted () {
    tinymce.init({})
  },
  created() {
    if(this.tinymceRef) {
      this.init.auto_focus = this.tinymceRef
    }
  },
  methods: {
    onClick (e) {
      this.$emit('onClick', e, tinymce)
    },
    clear () {
      this.myValue = ''
    }
  },
  watch: {
    value (newValue) {
      this.myValue = newValue
    },
    myValue (newValue) {
      this.$emit('input', newValue)
    }
  }
}

</script>