<template>
    <button @click.stop="addContent" class="button-add-content">
        <span>
            <IcoPlus /> {{ $t('8_4_add_content') }}
        </span>
    </button>
</template>

<script>
import IcoPlus from '@/components/svg/IcoPlus.vue';

export default ({
    components: {
        IcoPlus
    },
    props: {
        item: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        },
        ids: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    methods: {
        addContent() {
            this.$store.commit("setCreateContentDefaultValue", {                
                [this.type]: this.item,
                ids: this.ids
            });

            this.$store.commit("setCreateContentModalStatus", true);
        }
    }
})
</script>
