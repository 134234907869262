<template>
    <div
        class="select-language"
        :class="{'is-open': showData}"
        v-click-outside="hide"
    >
        <div @click="toggle()" class="select-language__selected">
            <FormGroup
                :label="$t('2_1_language')"
            >
                <div v-if="selected" class="select-language__item">
                    <div class="select-language__ico" :class="[selected.ico]"></div>
                    <div class="select-language__name">{{ selected.name }}</div>
                </div>
            </FormGroup>            
        </div>
        <div v-show="showData" class="select-language__items">
            <div v-for="(item, index) in languages" :key="index" @click="selectItem(item)" class="select-language__item">
                <div class="select-language__ico" :class="[item.ico]"></div>
                <div class="select-language__name">{{ item.name }}</div>
            </div>    
        </div>
    </div>
</template>


<script>
import ClickOutside from 'vue-click-outside';
import IcoDown from '@/components/svg/IcoDown.vue';
import FormGroup from '@/components/FormGroup.vue';

export default {
    components: {
        IcoDown,
        FormGroup
    },
    directives: {
        ClickOutside
    },
    props: {
        value: {
            type: Object,
            default: () => {}
        },
        languages: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            showData: false,
            selected: undefined
        }
    },
    created() {
        this.selected = this.value
    },
    methods: {
        toggle() {
            this.showData = !this.showData

            if(this.showData) {
                this.$emit('focus')
            } else {
                this.$emit('blur')
            }
        },
        hide() {
            this.showData = false
            this.$emit('blur')
        },
        selectItem(item) {
            console.log("item", item)
            this.selected = item
            this.hide()
            this.$emit('updateLanguage', this.selected)            
        }
    },
    watch: {
        value() {
            this.selected = this.value
        }
    }
}

</script>


<style scoped>
.select-language{
    position: relative;
}

.select-language__item{
    display: flex;
    align-content: center;
    gap: 8px;
    padding: 15px;
    cursor: pointer;
}

.select-language__ico{
    display: inline-block;
    min-width: 20px;
    max-width: 20px;
    width: 20px;
    height: 20px;
    max-height: 20px;
    min-height: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

.select-language__ico.cs{
    background-image: url('../assets/images/cs.png');
}

.select-language__ico.en{
    background-image: url('../assets/images/en.png');
}

.select-language__items{
    position: absolute;
    z-index: 2;
    margin-top: -1px;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    box-shadow: 15px 15px 60px rgb(0 0 0 / 5%);
    border-radius: 8px;
}

.select-language__items .select-language__item:hover{
    background-color: #F6F6F7;
}

.select-language__items .select-language__item:first-child{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.select-language__items .select-language__item:last-child{
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
</style>