<template>
    <div 
        class="content-edit-submenu"
        :class="{'show': show}"
        v-click-outside="hide"
        >
        <button @click="toggleSubmenu" class="content-edit-submenu__ico">
            <IcoSubmenu />
        </button>

        <div v-if="show" class="content-edit-submenu__data">
            <ul>
                <li>
                    <button @click="shareContent()">
                        <span><IcoShare /></span>
                        {{ $t("10_3_btn_share") }}
                    </button>                    
                </li>
                <li v-if="role !== 'READ_ONLY'">
                    <button @click="duplicateContent()">
                        <span><IcoDuplicate /></span>
                        {{ $t("10_3_btn_duplicate") }}
                    </button>                    
                </li>
                <li v-if="role !== 'READ_ONLY'">
                    <button @click="deleteContent()">
                        <span><IcoBin /></span>
                        {{ $t("10_3_btn_delete") }}
                    </button>                    
                </li>
            </ul>
        </div>                        
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

import IcoSubmenu from '@/components/svg/IcoSubmenu.vue';
import IcoShare from '@/components/svg/IcoShare.vue';
import IcoDuplicate from '@/components/svg/IcoDuplicate.vue';
import IcoBin from '@/components/svg/IcoBin.vue';

export default {
    components: {
        IcoSubmenu,
        IcoShare,
        IcoDuplicate,
        IcoBin
    },
    directives: {
        ClickOutside
    },
    props: {
        content: {
            type: Object,
            required: true
        }
    },
    computed: {
        me() {
            return this.$store.getters.getMe
        },
        role() {
            return this.$store.getters.getMeCompanyRole;
        },
        selectedCompany() {
            return this.$store.getters.getSelectedCompany;
        },
    },
    data() {
        return {
            show: false
        }
    },
    methods: {        
        toggleSubmenu() {
            this.show = !this.show;
        },
        async shareContent() {
            this.showControll = false;
            const link = "" + window.location.protocol + '//' + window.location.host + "/calendar/" + this.selectedCompany.id + "/" + this.content.id;

            await navigator.clipboard.writeText(link);
          
            this.$notify({
                title: this.$t('share_content_title_copied_to_clipboard'), 
                text: this.$t('share_content_description_copied_to_clipboard'), 
                type: 'info',
                duration: 5000
            });
        },
        duplicateContent() {
            if(this.role === 'READ_ONLY') {
                return
            }

            this.hide();
            
            this.$store.commit("setEditContentModalStatus", {});
            this.$store.commit('setDuplicateContentModalId', this.content.id);            
        },
        deleteContent() {
            if(this.role === 'READ_ONLY') {
                return
            }

            this.$store.commit('setContentToDelete', this.content);
            this.hide();
            return;
        },
        hide() {
            this.show = false;
        }
    }
}
</script>

<style scoped>
.content-edit-submenu{
    position: relative;
    top: -2px;
}

.content-edit-submenu__ico{
    background: transparent;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

.content-edit-submenu__data{
    position: absolute;
    z-index: 992;
    top: 100%;
    right: -55px;
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 15px 15px 60px rgb(0 0 0 / 5%);
}

ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
}

ul li{
    width: 100%;
}

ul li button{
    width: 100%;
    border: none;
    background: transparent;
    padding: 13px 25px;
    margin: 0;
    display: flex;
    gap: 11px;
    align-items: center;

    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: #17252A;

    cursor: pointer;
}

ul li button:hover{
    background-color: #F6F6F7;
}

ul li:first-child button{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

ul li:last-child button{
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

ul li button span{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: #E7E9E9;
    border-radius: 4px;
}
</style>

<style>
.content-edit-submenu.show .content-edit-submenu__ico svg circle{
    fill: #2CE0C1;
}
</style>