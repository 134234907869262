<template>
  <Modal :showClose="false" @closeModal="closeModal">
    <div class="feedback">
      <div v-if="!submitted" class="feedback-title">
        <h3>{{ $t('5_3_3_title') }}</h3>
      </div>

      <div v-if="submitted" class="feedback-animation">
        <LottieSuccessAnimation
          :options="defaultOptions"
          :height="300"
          :width="300"
          @animCreated="handleAnimation"
        />
      </div>

      <div v-if="!submitted" class="feedback-data">
        <ModalFeedbackSelection
          @click.native="selectItem('BUG')"
          :selected="selectedItem === 'BUG'"
        >
          <template #ico><IcoBug /></template>
          <template #title>{{ $t('5_3_3_report_bug') }}</template>
        </ModalFeedbackSelection>

        <ModalFeedbackSelection
          @click.native="selectItem('IMPROVEMENT')"
          :selected="selectedItem === 'IMPROVEMENT'"
        >
          <template #ico><IcoBrowserIdea /></template>
          <template #title>{{ $t('5_3_3_report_suggestion') }}</template>
        </ModalFeedbackSelection>

        <ModalFeedbackSelection
          @click.native="selectItem('LIKE')"
          :selected="selectedItem === 'LIKE'"
        >
          <template #ico><IcoCircleLike /></template>
          <template #title>{{ $t('5_3_3_report_like') }}</template>
        </ModalFeedbackSelection>

        <ModalFeedbackSelection
          @click.native="selectItem('DISLIKE')"
          :selected="selectedItem === 'DISLIKE'"
        >
          <template #ico><IcoCircleDislike /></template>
          <template #title>{{ $t('5_3_3_report_dislike') }}</template>
        </ModalFeedbackSelection>
      </div>

      <div v-if="selectedItem && !submitted" class="feedback-message">
        <FormGroup
          :label="$t('5_3_3_label_message')"
          :focused="focusedMessage"
          :required="true"
          :validationError="validationError.message"
        >
          <textarea
            :placeholder="$t('5_3_3_textarea_placeholder')"
            v-model="message"
            rows="6"
          ></textarea>
        </FormGroup>
      </div>

      <div v-if="!submitted" class="feedback-navigation">
        <Button @click.native="closeModal">{{ $t('5_3_3_btn_back') }}</Button>
        <Button
          @click.native="submit"
          type="primary"
          :disabled="selectedItem && message.length < 3"
          >{{ $t('5_3_3_btn_submit') }}</Button
        >
      </div>
    </div>
  </Modal>
</template>

<script>
import SEND_USER_FEEDBACK from '@/graphql/SendUserFeedback.gql'

import LottieSuccessAnimation from '@/components/LottieSuccessAnimation.vue'
import animationData from '@/assets/animations/animation-success.json'

import Modal from '@/components/Modal.vue'
import Button from '@/components/button/Button.vue'
import ModalFeedbackSelection from '@/components/ModalFeedbackSelection.vue'
import FormGroup from '@/components/FormGroup.vue'

import IcoBrowserIdea from '@/components/svg/IcoBrowserIdea.vue'
import IcoCircleLike from '@/components/svg/IcoCircleLike.vue'
import IcoCircleDislike from '@/components/svg/IcoCircleDislike.vue'
import IcoBug from '@/components/svg/IcoBug.vue'

export default {
  components: {
    Modal,
    Button,
    ModalFeedbackSelection,
    IcoBrowserIdea,
    IcoCircleLike,
    IcoCircleDislike,
    IcoBug,
    FormGroup,
    LottieSuccessAnimation,
  },
  computed: {
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
    usedCompanyId() {
      return this.$store.getters.getModalFeedbackContentId
    },
  },
  created() {
    this.submitted = false
  },
  data() {
    return {
      selectedItem: '',
      focusedMessage: false,
      validationError: {
        message: false,
      },
      message: '',
      defaultOptions: {
        animationData: animationData,
        loop: false,
      },
      animationSpeed: 1,
      submitted: false,
    }
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim
    },
    closeModal() {
      this.$store.commit('setFeedbackModal', false)
    },
    selectItem(item) {
      this.selectedItem = item
    },
    submit() {
      // TODO Validation

      try {
        this.$apollo
          .mutate({
            mutation: SEND_USER_FEEDBACK,
            variables: {
              category: this.selectedItem,
              viewName: this.$route.name,
              companyId: this.selectedCompany.id,
              contentId: this.usedCompanyId,
              text: this.message,
            },
            update: (store, { data: { createComment } }) => {
              this.message = ''
              this.selectedItem = ''
            },
          })
          .then((data) => {
            this.submitted = true

            setTimeout(() => {
              this.closeModal()
              this.submitted = false
            }, 3000)
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('5_3_3_toaster_error_title_feedback_send'),
              text: this.$t('5_3_3_toaster_error_description_feedback_send'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('5_3_3_toaster_error_title_feedback_send'),
          text: this.$t('5_3_3_toaster_error_description_feedback_send'),
          type: 'error',
          duration: 5000,
        })
      }
    },
  },
}
</script>

<style scoped>
.feedback {
  width: 310px;
}

.feedback-animation {
  display: flex;
  text-align: center;
}

.feedback-animation > div {
  display: inline-block;
}

.feedback-title h3 {
  margin: 0;
  padding: 0;
  color: #171717;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 28px;
  font-family: 'Nunito';
  letter-spacing: -0.4px;
}

.feedback-data {
  margin-top: 19px;
}

.feedback-message {
  padding: 34px 0 0 0;
}

.feedback-navigation {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 30px;
}

.feedback-navigation button {
  width: 100%;
}
</style>
