<template>
  <div class="comment">
    <div class="comment__header">
      <Avatar
        :name="comment.owner.name"
        :user="comment.owner"
        :size="'small'"
      />
      <div class="comment__header__meta">
        <div class="comment__header__title">{{ comment.owner.name }}</div>
        <div class="comment__header__date">
          {{ commentDate }}
        </div>
      </div>
    </div>
    <div
      v-if="!isEditingComment"
      class="comment__body"
      v-html="commentMention"
    ></div>

    <div
      v-if="isEditingComment"
      class="comment__edit"
      :class="{ 'has-error': invalidComment }"
    >
      <div class="position-relative">
        <div
          ref="textareaCover"
          class="textarea-cover"
          style="z-index: 10"
          v-html="commentEditMention"
        />

        <mentionable
          :keys="['@', '＠']"
          :items="users"
          :insertSpace="true"
          omit-key
        >
          <textarea-autosize
            :min-height="30"
            v-model="editedComment"
            :placeholder="$t('10_1_btn_create_comment')"
            ref="comment_item"
            @keyup="commentScroll"
            @scroll="commentScroll"
            @input="invalidComment = false"
            :maxlength="length.comment"
          ></textarea-autosize>

          <template #item="{ item }">
            <div @click="selectUser()" class="user">
              <span class="font-weight-bold">
                {{ item.value }}
              </span>
            </div>
          </template>
        </mentionable>
      </div>
    </div>
    <div class="comment__footer">
      <ul v-if="!isEditingComment" class="comment__footer__links">
        <li v-if="comment.owner.id === me.id">
          <button @click="editComment()">{{ $t('10_1_btn_edit') }}</button>
        </li>
        <li v-if="!toDelete && comment.owner.id === me.id">
          <button @click="clickToDeleteItem()">
            {{ $t('10_1_btn_delete') }}
          </button>
        </li>
        <li v-if="toDelete">
          <button v-click-outside="cancelDeleteItem" @click="deleteComment()">
            <span style="color: #f45050">{{ $t('10_1_btn_delete') }}</span>
          </button>
        </li>
      </ul>

      <div v-if="isEditingComment" class="comment__footer__controll">
        <div class="comment__footer__controll__left">
          <Button @click="saveComment()" :size="'small'" :type="'primary'">{{
            $t('10_1_btn_save')
          }}</Button>
          <Button @click="cancelEdit()" :size="'small'" :type="'secondary'">{{
            $t('10_1_btn_cancel')
          }}</Button>
        </div>

        <div class="comment__footer__controll__right">
          <Button
            v-if="!toDelete"
            @click="clickToDeleteItem()"
            :size="'slim'"
            :type="'secondary'"
            ><IcoBin
          /></Button>
          <Button
            v-if="toDelete"
            v-click-outside="cancelDeleteItem"
            :size="'slim'"
            @click="deleteComment()"
            :type="'delete'"
            ><IcoBin
          /></Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CommentService } from '@/services/CommentService.js'

import textLength from '@/constants/TextLengthConstant.js'
import ClickOutside from 'vue-click-outside'
import moment from 'moment'
import Avatar from '@/components/Avatar.vue'
import Button from '@/components/button/Button.vue'
import IcoBin from '@/components/svg/small/IcoBin.vue'
import { Mentionable } from 'vue-mention'

Mentionable.methods.onKeyDown = function (e) {
  if (this.key) {
    if (e.key === 'ArrowDown' || e.keyCode === 40) {
      this.selectedIndex++
      if (this.selectedIndex >= this.displayedItems.length) {
        this.selectedIndex = 0
      }
      this.cancelEvent(e)
    }
    if (e.key === 'ArrowUp' || e.keyCode === 38) {
      this.selectedIndex--
      if (this.selectedIndex < 0) {
        this.selectedIndex = this.displayedItems.length - 1
      }
      this.cancelEvent(e)
    }
    if (
      (e.keyCode === 13 || e.keyCode === 9) &&
      this.displayedItems.length > 0
    ) {
      this.applyMention(this.selectedIndex)
      this.cancelEvent(e)
    }
    if (e.key === 'Escape' || e.keyCode === 27) {
      this.closeMenu()
      this.cancelEvent(e)
    }
  }
}

export default {
  components: {
    Avatar,
    Button,
    IcoBin,
    Mentionable,
  },
  directives: {
    ClickOutside,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    me() {
      return this.$store.getters.getMe
    },
    length() {
      return textLength
    },
    codelist() {
      return this.$store.getters.getCodelist
    },
    commentDate() {
      const date1 = new Date()
      const date2 = new Date(this.comment.date)
      const diffTime = Math.abs(date2 - date1)
      const diffDays = diffTime / (1000 * 60 * 60 * 24)

      if (diffDays <= 1) {
        return this.msToTime(diffTime)
      }

      if (Math.ceil(diffDays) == 1) {
        return Math.ceil(diffDays) + ' ' + this.$t('10_1_day')
      } else if (Math.ceil(diffDays) > 1 && Math.ceil(diffDays) < 4) {
        return Math.ceil(diffDays) + ' ' + this.$t('10_1_days')
      } else {
        return moment(this.comment.date).format('dddd, Do MMM YYYY')
        // return this.comment.date;
      }
    },
    commentMention() {
      if (typeof this.commentText?.replaceAll !== 'function') {
        return this.commentText
      }

      return CommentService.highlightUsers(this.commentText, this.users)
    },
    commentEditMention() {
      if (typeof this.editedComment?.replaceAll !== 'function') {
        return this.editedComment
      }

      return CommentService.highlightUsers(this.editedComment, this.users)
    },
  },
  created() {
    this.generateUserList()
    this.commentText = this.comment.text
  },
  data() {
    return {
      invalidComment: false,
      isEditingComment: false,
      editedComment: '',
      toDelete: false,
      users: [],
      commentText: '',
    }
  },
  methods: {
    clickToDeleteItem() {
      this.toDelete = true

      this.$notify({
        title: this.$t('toaster_info_title_confirm_delete'),
        text: this.$t('toaster_info_description_confirm_delete'),
        type: 'info',
        duration: 5000,
      })

      setTimeout(() => {
        this.toDelete = false
      }, 2000)
    },
    selectUser() {
      setTimeout(() => {
        this.$refs.comment_item.$el.focus()
      }, 100)
    },
    generateUserList() {
      let users = []
      this.codelist.users
        .filter((user) => user.role !== 'APPROVER')
        .forEach((user) => {
          users.push({
            value: '@' + user.value,
          })
        })
      this.users = users
    },
    cancelDeleteItem() {
      this.toDelete = false
    },
    editComment() {
      this.editedComment = this.comment.text
      this.isEditingComment = true

      setTimeout(() => {
        this.$refs.comment_item.$el.focus()
      }, 100)

      this.$emit('editComment')
    },
    deleteComment() {
      this.$emit('deleteComment', this.comment)
    },
    saveComment() {
      this.invalidComment = false

      if (this.editedComment === '') {
        this.invalidComment = true

        this.$notify({
          title: this.$t('10_1_toaster_error_title_comment_name_empty'),
          text: this.$t('10_1_toaster_error_description_comment_name_empty'),
          type: 'error',
          duration: 5000,
        })

        return
      }

      this.isEditingComment = false
      this.commentText = this.editedComment
      this.$emit('updateComment', this.comment, this.editedComment)
      this.$emit('closeComment')
    },
    cancelEdit() {
      this.isEditingComment = false
      this.$emit('closeComment')
    },
    msToTime(s) {
      const ms = s % 1000
      s = (s - ms) / 1000
      const secs = s % 60
      s = (s - secs) / 60
      const mins = s % 60
      const hrs = (s - mins) / 60

      let stringHours = ''
      let stringMinutes = ''

      if (hrs === 0) {
        stringHours = ''
      } else {
        stringHours = hrs + 'h '
      }

      if (mins === 0) {
        stringMinutes = ''
      } else {
        stringMinutes = mins + 'm '
      }

      if (hrs === 0 && mins === 0) {
        return this.$t('10_1_now')
      }

      return (
        this.$t('time_before') +
        stringHours +
        stringMinutes +
        this.$t('time_after')
      )
    },

    mounted() {
      setTimeout(() => {
        this.resize()
        window.addEventListener('resize', this.resize)
        this.$once('hook:beforeDestroy', () => {
          window.removeEventListener('resize', this.resize)
        })
      })
    },
    resize() {
      const barWidth =
        this.$refs.comment_item.getBoundingClientRect().width -
        this.$refs.comment_item.clientWidth -
        2 // border
      this.$refs.textareaCover.style.paddingRight = `calc(12px + ${barWidth}px)`
    },
    commentScroll() {
      this.$refs.textareaCover.scrollTop = this.$refs.comment_item.scrollTop
      this.resize()
    },
  },
}
</script>

<style scoped>
.comment {
  margin-top: 25px;
}

.comment__header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.comment__header__meta {
  display: flex;
  gap: 10px;
}

.comment__header__title {
  color: #17252a;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  font-family: Nunito;
}

.comment__header__date {
  color: #aeb3b5;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  font-family: Nunito;
}

.comment__body {
  margin-top: 6px;

  max-width: 530px;
  color: #17252a;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 171%;
  font-family: Nunito;
  overflow-wrap: break-word;
}

.comment__edit {
  margin-top: 6px;
}

/*
.comment__edit textarea{
    width: 100%;
    border: 1px solid #2CE0C1;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 7px 10px;

    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: #17252A;
}
*/

.comment__footer {
}

.comment__footer__links {
  margin: 0;
  margin-top: 5px;
  padding: 0;
  list-style-type: none;
}

.comment__footer__links li {
  display: inline-block;
}

.comment__footer__links li:after {
  display: inline-block;
  padding-right: 4px;
  padding-left: 4px;
  content: '-';

  color: #aeb3b5;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
  font-family: Nunito;
}

.comment__footer__links li:last-child:after {
  display: none;
}

.comment__footer__links button {
  cursor: pointer;
  margin: 0;
  border: none;
  background: transparent;
  padding: 0;
  color: #aeb3b5;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
  font-family: Nunito;
}

.comment__footer__links button:active {
  color: #17252a;
}

.comment__footer__links button:focus {
  outline: none;
}

.comment__footer__links button:hover {
  color: #17252a;
}

.comment__footer__controll {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.comment__footer__controll__left {
  display: flex;
  gap: 8px;
}
</style>
