<template>
  <Sidebar>
    <Link @click="toSignIn()"><IcoArrowBack /> {{ $t('1_1_4_back_to_sign_in') }}</Link>
    <div class="line">
        <h2>{{ $t('1_1_4_title') }}</h2>
    </div>
    <div class="line line--half">
        <p>{{ $t("1_1_4_description").replace('%email%', email) }}</p>
    </div>

    <div class="line">
        <Button style="width:100%" @click="resendVerificationEmail()" :size="'max'" :type="'primary'">{{ $t('1_1_4_btn_resend') }}</Button>
    </div>

    <div class="line">
        <p>{{ $t("1_1_4_trouble_verify") }} <MailToSupport :subject="$t('1_1_4_support_mail_subject')" /></p>
    </div>
  </Sidebar>
</template>

<script>
import { URL } from "@/constants/URLConstant.js";
import Sidebar from "@/components/layout/Sidebar.vue";
import MailToSupport from "@/components/MailToSupport.vue";
import Button from "@/components/button/Button.vue";
import Link from "@/components/design/Link.vue";

import IcoArrowBack from '@/components/svg/IcoArrowBack.vue';

export default {
  components: {
    Sidebar,
    Button,
    Link,
    IcoArrowBack,
    MailToSupport
  },
  computed: {
    email() {
      return this.$CryptoJS.AES.decrypt(this.$route.params.email, "ContPass").toString(this.CryptoJS.enc.Utf8)
    }
  },
  methods: {
    resendVerificationEmail() {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            email: this.email,
        }),
      };

      fetch(URL + "/api/register/verify/resend", requestOptions)
        .then(() => {

          this.$notify({
            title: this.$t("1_1_4_toaster_success_title_resend_verification_email"),
            text: this.$t("1_1_4_toaster_success_description_resend_verification_email"),
            type: "success",
            duration: 5000,
          });

      }).catch(() => {

        this.$notify({
          title: this.$t("1_1_4_toaster_error_title_resend_verification_email"),
          text: this.$t("global_toaster_error_description"),
          type: "error",
          duration: 5000,
        });

      })
    },
    toSignIn() {
      this.$router.push({name: 'Login'});
    }
  },
};
</script>
