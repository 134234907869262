<template>
  <div>
    <div class="controll" style="position: relative; z-index: 10">
      <div class="controll__item controll__item--left">
        <div class="button-calendar" v-click-outside="hideCalendarData">
          <button
            @click="toggleCalendarData"
            class="button-calendar__button"
            :class="{ 'is-open': showCalendarData }"
          >
            <IcoCalendar />
          </button>
          <div v-if="showCalendarData" class="button-calendar__data">
            <div class="button-calendar__data__content is-week">
              <v-date-picker
                ref="date_picker"
                v-model="range"
                :value="range"
                :columns="2"
                :is-range="isRange"
                :attributes="attrs"
                :first-day-of-week="2"
                :locale="$i18n.locale"
                @dayclick="changeDate"
              >
              </v-date-picker>
            </div>

            <div class="button-calendar__data__controll">
              <ul class="button-calendar__data__controll__list">
                <li class="button-calendar__data__controll__item">
                  <button
                    class="button-calendar__data__controll__button"
                    :class="{
                      enabled:
                        this.type === 'listView' ||
                        this.type === 'calendarDayView',
                    }"
                    @click="setDateToday()"
                  >
                    {{ $t('8_5_btn_today') }}
                  </button>
                </li>
                <li class="button-calendar__data__controll__item">
                  <button
                    class="button-calendar__data__controll__button"
                    :class="{
                      enabled:
                        this.type === 'calendarWeekView' ||
                        this.type === 'listView',
                    }"
                    @click="setDateThisWeek()"
                  >
                    {{ $t('8_5_btn_this_week') }}
                  </button>
                </li>
                <li class="button-calendar__data__controll__item">
                  <button
                    class="button-calendar__data__controll__button"
                    :class="{
                      enabled:
                        this.type === 'calendarWeekView' ||
                        this.type === 'listView',
                    }"
                    @click="setDateLastWeek()"
                  >
                    {{ $t('8_5_btn_last_week') }}
                  </button>
                </li>
                <li class="button-calendar__data__controll__item">
                  <button
                    class="button-calendar__data__controll__button"
                    :class="{
                      enabled:
                        this.type === 'calendarMonthView' ||
                        this.type === 'listView',
                    }"
                    @click="setDateThisMonth()"
                  >
                    {{ $t('8_5_btn_this_month') }}
                  </button>
                </li>
                <li class="button-calendar__data__controll__item">
                  <button
                    class="button-calendar__data__controll__button"
                    :class="{
                      enabled:
                        this.type === 'calendarMonthView' ||
                        this.type === 'listView',
                    }"
                    @click="setDateLastMonth()"
                  >
                    {{ $t('8_5_btn_last_month') }}
                  </button>
                </li>
                <li class="button-calendar__data__controll__item">
                  <button
                    class="button-calendar__data__controll__button"
                    :class="{ enabled: this.type === 'listView' }"
                    @click="setDateThisYear()"
                  >
                    {{ $t('8_5_btn_this_year') }}
                  </button>
                </li>
                <li class="button-calendar__data__controll__item">
                  <button
                    class="button-calendar__data__controll__button"
                    :class="{ enabled: this.type === 'listView' }"
                    @click="setDateLastYear()"
                  >
                    {{ $t('8_5_btn_last_year') }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <button class="button-nav button-nav--large" @click="selectToady">
          {{ $t('8_5_btn_today') }}
        </button>
        <button class="button-nav" @click="datePrev"><IcoPrev /></button>
        <button class="button-nav" @click="dateNext"><IcoNext /></button>

        <div class="selected-date selected-date--left">
          {{ selectedDate }}
        </div>
      </div>
      <div class="controll__item controll__item--right">
        <button @click="goToListView" class="button-nav button-nav--large">
          {{ $t('8_5_btn_open_in_list_view') }}
        </button>
        <button @click="handlePrintPage" class="button-nav button-nav--large">
          {{ $t('8_5_btn_print_page') }}
        </button>
      </div>
    </div>

    <div
      v-if="showIdeas"
      class="calendar-ideas"
      @dragover.prevent="showActiveDrop('container')"
      @drop.prevent="drop"
    >
      <div
        v-if="globalDragStatus"
        class="calendar-ideas__drop-zone"
        :class="{
          'is-dropped': activeDropzone === 'container',
        }"
      >
        <span>{{ $t('8_1_1_drop_here') }}</span>
      </div>

      <div class="calendar-ideas__container">
        <VueSlickCarousel
          v-bind="carouselSettigns"
          ref="slickone"
          :class="{
            slickUpdate: slickUpdate !== '',
          }"
        >
          <Idea
            v-for="item in ideas"
            :key="item.id"
            :item="item"
            :draggable="true"
            :id="item.key"
            :type="'idea'"
          />

          <template #prevArrow="">
            <div class="custom-arrow">
              <IcoPrev />
            </div>
          </template>
          <template #nextArrow="">
            <div class="custom-arrow">
              <IcoNext />
            </div>
          </template>
        </VueSlickCarousel>

        <button @click="redirectToIdeas()" class="calendar-ideas__all">
          {{ $t('8_1_1_all_ideas') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CONTENTS_IDEA from '@/graphql/ContentsIdea.gql'

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'

import { DateService } from '@/services/DateService.js'
import moment from 'moment'
import ClickOutside from 'vue-click-outside'
import IcoCalendar from '@/components/svg/IcoCalendar.vue'
import IcoIdea from '@/components/svg/IcoIdea.vue'
import IcoUser from '@/components/svg/IcoUser.vue'
import IcoUsers from '@/components/svg/IcoUsers.vue'
import IcoPrev from '@/components/svg/IcoPrev.vue'
import IcoNext from '@/components/svg/IcoNext.vue'
import IcoGroupBy from '@/components/svg/IcoGroupBy.vue'
import IcoInfo from '@/components/svg/small/IcoInfo.vue'

import Idea from '@/components/Idea.vue'
import ButtonCalendar from '@/components/ButtonCalendar.vue'
import CalendarFilter from '@/components/CalendarFilter.vue'
import GroupByFilter from '@/components/GroupByFilter.vue'
import Button from './button/Button.vue'

export default {
  components: {
    VueSlickCarousel,
    IcoCalendar,
    IcoIdea,
    IcoUser,
    IcoUsers,
    IcoPrev,
    IcoNext,
    IcoGroupBy,
    IcoInfo,
    ButtonCalendar,
    CalendarFilter,
    GroupByFilter,
    Idea,
    Button,
  },
  directives: {
    ClickOutside,
  },
  mounted() {
    this.initSelectedDate()
  },
  props: {
    selectedDate: {
      type: String,
    },
    showGroupBy: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'calendarMonthView',
    },
    nameList: {
      type: Array,
      default: [],
    },
    disableIdeas: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    refreshContent() {
      return this.$store.getters.getRefreshContent
    },
    globalSelectedDate() {
      return this.$store.getters.getSelectedDate
    },
    dateRangeFrom() {
      return this.$store.getters.getDateRangeFrom
    },
    dateRangeTo() {
      return this.$store.getters.getDateRangeTo
    },
    fullDateRange() {
      return `${this.dateRangeFrom}|${this.dateRangeTo}`
    },
    ideas() {
      let ideas = [
        {
          channel: '',
          checklist: [],
          date: new Date(),
          dates: new Date(),
          id: 0,
          name: '',
          owner: '',
          personas: [],
          products: [],
          state: 'IDEA',
          stdcs: [],
          tags: [],
        },
      ]
      if (this.companies.length == 0) {
        return ideas
      }

      ideas = []
      const _ideas = this.companies[0].contents
      for (var i = 0; i < _ideas.length; i++) {
        let idea = _ideas[i]

        idea['key'] = 'key-' + idea.id
        let dateObject = new Date(idea.date)
        idea['date_local'] = dateObject
        idea['dates'] = dateObject

        ideas.push(idea)
      }

      return ideas
    },
    slickUpdate() {
      return this.$store.getters.getSlickUpdate
    },
    globalDragStatus() {
      return this.$store.getters.getGlobalDragStatus
    },
    activeDropzone() {
      return this.$store.getters.getActiveDropZone
    },
    selectedCompany() {
      this.showIdeas = false
      this.$store.commit('setIdeasContainerStatus', false)
      return this.$store.getters.getSelectedCompany
    },
    contentOwner() {
      return this.$store.getters.getContentOwner
    },
    from() {
      const _dateFrom = DateService.deltaDate(new Date(), 0, -6, 0)
      _dateFrom.setHours(0, 0, 0, 0)
      return _dateFrom
    },
    to() {
      const _dateTo = new Date()
      _dateTo.setHours(23, 59, 59, 0)
      return _dateTo
    },
    range: {
      set(val) {
        this.testFrom = val.start
        this.testTo = val.end
      },
      get() {
        let range = {
          start: this.testFrom,
          end: this.testTo,
        }
        return range
      },
    },
  },
  apollo: {
    companies: {
      query: CONTENTS_IDEA,
      variables() {
        return {
          from: DateService.dateToUTC(this.from),
          to: DateService.dateToUTC(this.to),
          id: this.selectedCompany.id,
        }
      },
      skip() {
        return !this.selectedCompany
      },
    },
  },
  data() {
    return {
      testFrom: new Date(2021, 11, 1),
      testTo: new Date(2021, 11, 23),
      x: 0,
      ops: {
        vuescroll: {
          mode: 'slide',
        },
        scrollPanel: {},
        rail: {},
        bar: {},
        scrollButton: {
          enable: true,
          background: 'rgb(3, 185, 118)',
          opacity: 1,
          step: 300,
          mousedownStep: 30,
        },
      },

      cards: [],
      innerStyles: {},
      step: '',
      transitioning: false,
      isRange: false,
      masks: {
        input: 'YYYY-MM-DD h:mm A',
      },
      companies: [],
      _selectedDate: '',
      showCalendarData: false,
      currentDate: new Date(),
      scrollPanel: {
        initialScrollY: false,
        initialScrollX: false,
        scrollingX: true,
        scrollingY: true,
        speed: 300,
        easing: undefined,
        verticalNativeBarPos: 'right',
      },
      carouselSettigns: {
        dots: true,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 6,
        autoplay: false,
        autoplaySpeed: 2000,
        pauseOnDotsHover: false,
        pauseOnFocus: false,
        pauseOnHover: false,
        swipe: false,
        // "draggable": false
      },
      showIdeas: false,
      attrs: [],
    }
  },
  methods: {
    handlePrintPage() {
      window.print()
    },
    goToListView() {
      this.$router.push({ name: 'List' })
    },
    changeDate(day) {
      const date = day.date

      switch (this.type) {
        case 'calendarMonthView':
          this.testFrom = new Date(
            new Date(date.getFullYear(), date.getMonth(), 1).setHours(
              0,
              0,
              0,
              0
            )
          )
          this.testTo = new Date(
            new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(
              23,
              59,
              59,
              0
            )
          )

          this.$store.commit('setDate', date)

          this.attrs = [
            {
              highlight: {
                start: { fillMode: 'outline' },
                base: { fillMode: 'light' },
                end: { fillMode: 'outline' },
              },
              dates: {
                start: new Date(date.getFullYear(), date.getMonth(), 1),
                end: new Date(date.getFullYear(), date.getMonth() + 1, 0),
              },
            },
          ]

          break

        case 'calendarWeekView':
          const weekDate = new Date(date)
          const dateFrom = this.firstDayOfWeek(weekDate, 1)
          const dateTo = new Date(dateFrom)
          dateTo.setDate(dateTo.getDate() + 6)

          this.$store.commit('setDate', date)

          this.testFrom = new Date(dateFrom.setHours(0, 0, 0, 0))
          this.testTo = new Date(dateTo.setHours(23, 59, 59, 0))

          this.attrs = [
            {
              highlight: {
                start: { fillMode: 'outline' },
                base: { fillMode: 'light' },
                end: { fillMode: 'outline' },
              },
              dates: {
                start: new Date(dateFrom.setHours(0, 0, 0, 0)),
                end: new Date(dateTo.setHours(23, 59, 59, 0)),
              },
            },
          ]

          break

        case 'calendarDayView':
          this.testFrom = new Date(date.setHours(0, 0, 0, 0))
          this.testTo = new Date(date.setHours(23, 59, 59, 0))

          this.$store.commit('setDate', date)

          this.attrs = []
          break

        case 'listView':
          break
      }
    },
    async setDateToday() {
      if (this.type == 'listView' || this.type == 'calendarDayView') {
        const today = new Date()
        this.testFrom = new Date(today.setHours(0, 0, 0, 0))
        this.testTo = new Date(today.setHours(23, 59, 59, 0))

        this.$store.commit('setDate', this.testFrom)
        this.$store.commit('setDateRangeFrom', this.testFrom)
        this.$store.commit('setDateRangeTo', this.testTo)

        setTimeout(() => {
          const calendar = this.$refs.date_picker.$refs.calendar
          calendar.move(today)
        }, 100)
      }
    },
    async setDateThisWeek() {
      if (this.type == 'calendarWeekView' || this.type == 'listView') {
        const dateFrom = this.firstDayOfWeek(new Date(), 1)
        let dateTo = new Date(dateFrom)
        dateTo.setDate(dateFrom.getDate() + 6)
        this.testFrom = new Date(new Date(dateFrom).setHours(0, 0, 0, 0))
        this.testTo = new Date(new Date(dateTo).setHours(23, 59, 59, 0))

        const calendar = this.$refs.date_picker.$refs.calendar
        await calendar.move(dateFrom)

        this.$store.commit('setDate', dateFrom)

        if (this.type == 'calendarWeekView') {
          this.attrs = [
            {
              highlight: {
                start: { fillMode: 'outline' },
                base: { fillMode: 'light' },
                end: { fillMode: 'outline' },
              },
              dates: {
                start: new Date(new Date(dateFrom).setHours(0, 0, 0, 0)),
                end: new Date(new Date(dateTo).setHours(23, 59, 59, 0)),
              },
            },
          ]
        }
      }
    },
    async setDateLastWeek() {
      if (this.type == 'calendarWeekView' || this.type == 'listView') {
        let dateFrom = this.firstDayOfWeek(new Date(), 1)
        let dateTo = new Date(dateFrom)
        dateTo.setDate(dateTo.getDate() + 6)

        dateFrom.setDate(dateFrom.getDate() - 7)
        dateTo.setDate(dateTo.getDate() - 7)

        this.testFrom = dateFrom
        this.testTo = dateTo

        setTimeout(() => {
          const calendar = this.$refs.date_picker
          calendar.move(dateFrom)
        }, 100)

        this.$store.commit('setDate', dateFrom)
        this.$store.commit('setDateRangeFrom', this.testFrom)
        this.$store.commit('setDateRangeTo', this.testTo)

        if (this.type == 'calendarWeekView') {
          this.attrs = [
            {
              highlight: {
                start: { fillMode: 'outline' },
                base: { fillMode: 'light' },
                end: { fillMode: 'outline' },
              },
              dates: {
                start: new Date(
                  new Date(dateFrom.setDate(dateFrom.getDate() - 7)).setHours(
                    0,
                    0,
                    0,
                    0
                  )
                ),
                end: new Date(
                  new Date(dateFrom.setDate(dateFrom.getDate() + 6)).setHours(
                    23,
                    59,
                    59,
                    0
                  )
                ),
              },
            },
          ]
        }
      }
    },
    async setDateThisMonth() {
      if (this.type == 'calendarMonthView' || this.type == 'listView') {
        var date = new Date()
        this.testFrom = new Date(
          new Date(date.getFullYear(), date.getMonth(), 1).setHours(0, 0, 0, 0)
        )
        this.testTo = new Date(
          new Date(date.getFullYear(), date.getMonth() + 1, 0).setHours(
            23,
            59,
            59,
            0
          )
        )

        setTimeout(() => {
          const calendar = this.$refs.date_picker
          calendar.move(new Date(date.getFullYear(), date.getMonth(), 1))
        }, 100)

        this.$store.commit(
          'setDate',
          new Date(date.getFullYear(), date.getMonth(), 1)
        )
        this.$store.commit(
          'setDateRangeFrom',
          new Date(date.getFullYear(), date.getMonth(), 1)
        )
        this.$store.commit('setDateRangeTo', this.testTo)

        /*
                if(this.type == 'calendarMonthView') {
                    this.attrs = [
                        {
                            highlight: {
                                start: { fillMode: 'outline' },
                                base: { fillMode: 'light' },
                                end: { fillMode: 'outline' },
                            },
                            dates: {
                                start: new Date(date.getFullYear(), date.getMonth(), 1),
                                end: new Date(date.getFullYear(), date.getMonth() + 1, 0)
                            },
                        }
                    ];
                }
                */

        // this.initSelectedDate()
      }
    },
    async setDateLastMonth() {
      if (this.type == 'calendarMonthView' || this.type == 'listView') {
        var date = new Date()
        this.testFrom = new Date(
          new Date(date.getFullYear(), date.getMonth() - 1, 1).setHours(
            0,
            0,
            0,
            0
          )
        )
        this.testTo = new Date(
          new Date(date.getFullYear(), date.getMonth(), 0).setHours(
            23,
            59,
            59,
            0
          )
        )

        setTimeout(() => {
          const calendar = this.$refs.date_picker.$refs.calendar
          calendar.move(new Date(date.getFullYear(), date.getMonth() - 1, 1))
        }, 100)

        this.$store.commit(
          'setDate',
          new Date(date.getFullYear(), date.getMonth() - 1, 1)
        )

        if (this.type == 'calendarMonthView') {
          this.attrs = [
            {
              highlight: {
                start: { fillMode: 'outline' },
                base: { fillMode: 'light' },
                end: { fillMode: 'outline' },
              },
              dates: {
                start: new Date(date.getFullYear(), date.getMonth() - 1, 1),
                end: new Date(date.getFullYear(), date.getMonth(), 0),
              },
            },
          ]
        }
      }
    },
    async setDateThisYear() {
      if (this.type == 'listView') {
        var date = new Date()
        this.testFrom = new Date(
          new Date(date.getFullYear(), 0, 1).setHours(0, 0, 0, 0)
        )
        this.testTo = new Date(
          new Date(date.getFullYear(), 11, 31).setHours(23, 59, 59, 0)
        )

        this.$store.commit('setDate', this.testFrom)
        this.$store.commit('setDateRangeFrom', this.testFrom)
        this.$store.commit('setDateRangeTo', this.testTo)

        setTimeout(() => {
          const calendar = this.$refs.date_picker.$refs.calendar
          calendar.move(new Date(date.getFullYear(), 0, 1))
        }, 100)
      }
    },
    async setDateLastYear() {
      if (this.type == 'listView') {
        var date = new Date()
        this.testFrom = new Date(
          new Date(date.getFullYear() - 1, 0, 1).setHours(0, 0, 0, 0)
        )
        this.testTo = new Date(
          new Date(date.getFullYear() - 1, 11, 31).setHours(23, 59, 59, 0)
        )

        this.$store.commit('setDate', this.testFrom)
        this.$store.commit('setDateRangeFrom', this.testFrom)
        this.$store.commit('setDateRangeTo', this.testTo)

        setTimeout(() => {
          const calendar = this.$refs.date_picker.$refs.calendar
          calendar.move(new Date(date.getFullYear() - 1, 0, 1))
        }, 100)
      }
    },
    firstDayOfWeek(dateObject, firstDayOfWeekIndex) {
      const dayOfWeek = dateObject.getDay(),
        firstDayOfWeek = new Date(dateObject),
        diff =
          dayOfWeek >= firstDayOfWeekIndex
            ? dayOfWeek - firstDayOfWeekIndex
            : 6 - dayOfWeek

      firstDayOfWeek.setDate(dateObject.getDate() - diff)
      firstDayOfWeek.setHours(0, 0, 0, 0)

      return firstDayOfWeek
    },
    containerNext() {
      this.x += 300

      this.$refs.idea_container.scrollTo(
        {
          x: this.x,
        },
        500
      )
    },
    containerPrev() {
      this.x -= 300

      if (this.x < 0) {
        this.x = 0
      }

      this.$refs.idea_container.scrollTo(
        {
          x: this.x,
        },
        500
      )
    },
    test() {
      this.setStep()
      this.resetTranslate()
    },
    setStep() {
      const innerWidth = this.$refs.inner.scrollWidth
      const totalCards = this.cards.length
      this.step = `${innerWidth / totalCards}px`
    },
    next() {
      if (this.transitioning) return

      this.transitioning = true
      this.moveLeft()

      this.afterTransition(() => {
        const card = this.cards.shift()
        this.cards.push(card)
        this.resetTranslate()
        this.transitioning = false
      })
    },
    prev() {
      if (this.transitioning) return

      this.transitioning = true

      this.moveRight()

      this.afterTransition(() => {
        const card = this.cards.pop()
        this.cards.unshift(card)
        this.resetTranslate()
        this.transitioning = false
      })
    },
    moveLeft() {
      this.innerStyles = {
        transform: `translateX(-${this.step})
                            translateX(-${this.step})`,
      }
    },
    moveRight() {
      this.innerStyles = {
        transform: `translateX(${this.step})
                            translateX(-${this.step})`,
      }
    },
    afterTransition(callback) {
      const listener = () => {
        callback()
        this.$refs.inner.removeEventListener('transitionend', listener)
      }
      this.$refs.inner.addEventListener('transitionend', listener)
    },
    resetTranslate() {
      this.innerStyles = {
        transition: 'none',
        transform: `translateX(-${this.step})`,
      }
    },
    setContentOwner(type) {
      this.$store.commit('setContentOwner', type)
    },
    showActiveDrop(key) {
      this.$store.commit('setActiveDropZone', key)
    },
    toggleCalendarData() {
      this.initSelectedDate()
      this.showCalendarData = !this.showCalendarData
    },
    async initSelectedDate() {
      this._selectedDate = this.selectedDate

      let date = new Date()
      if (this.globalSelectedDate !== undefined) {
        date = new Date(this.globalSelectedDate)
      }

      if (!this.isRange) {
        this.range = this.globalSelectedDate
      }

      switch (this.type) {
        case 'calendarMonthView':
          const dateMonth = new Date(this.globalSelectedDate)
          this.testFrom = new Date(
            new Date(dateMonth.getFullYear(), dateMonth.getMonth(), 1).setHours(
              0,
              0,
              0,
              0
            )
          )
          this.testTo = new Date(
            new Date(
              dateMonth.getFullYear(),
              dateMonth.getMonth() + 1,
              0
            ).setHours(23, 59, 59, 0)
          )

          this.attrs = [
            {
              highlight: {
                start: { fillMode: 'light' },
                base: { fillMode: 'light' },
                end: { fillMode: 'light' },
              },
              dates: {
                start: new Date(
                  dateMonth.getFullYear(),
                  dateMonth.getMonth(),
                  1
                ),
                end: new Date(
                  dateMonth.getFullYear(),
                  dateMonth.getMonth() + 1,
                  0
                ),
              },
            },
          ]

          setTimeout(() => {
            if (this.showCalendarData) {
              const calendar = this.$refs.date_picker
              calendar.move(this.globalSelectedDate)
            }
          }, 100)

          break

        case 'calendarWeekView':
          const date = new Date(this.globalSelectedDate)
          const dateFrom = this.firstDayOfWeek(date, 1)
          const dateTo = new Date(dateFrom)
          dateTo.setDate(dateTo.getDate() + 6)

          this.testFrom = new Date(dateFrom.setHours(0, 0, 0, 0))
          this.testTo = new Date(dateTo.setHours(23, 59, 59, 0))

          this.attrs = [
            {
              highlight: {
                start: { fillMode: 'light' },
                base: { fillMode: 'light' },
                end: { fillMode: 'light' },
              },
              dates: {
                start: new Date(dateFrom.setHours(0, 0, 0, 0)),
                end: new Date(dateTo.setHours(23, 59, 59, 0)),
              },
            },
          ]

          setTimeout(() => {
            if (this.showCalendarData) {
              const calendar = this.$refs.date_picker
              calendar.move(this.globalSelectedDate)
            }
          }, 100)

          break

        case 'calendarDayView':
          const dayDate = new Date(this.globalSelectedDate)
          this.testFrom = new Date(dayDate.setHours(0, 0, 0, 0))
          this.testTo = new Date(dayDate.setHours(23, 59, 59, 0))

          this.attrs = [
            {
              highlight: {
                start: { fillMode: 'light' },
                base: { fillMode: 'light' },
                end: { fillMode: 'light' },
              },
              dates: this.testFrom,
            },
          ]

          setTimeout(() => {
            if (this.showCalendarData) {
              const calendar = this.$refs.date_picker
              calendar.move(this.globalSelectedDate)
              // calendar.focusDate(this.globalSelectedDate);
            }
          }, 100)

          break

        case 'listView':
          this.isRange = true

          const listViewDateFrom = new Date(this.globalSelectedDate)
          this.testFrom = new Date(this.dateRangeFrom.setHours(0, 0, 0, 0))
          this.testTo = new Date(this.dateRangeTo.setHours(23, 59, 59, 0))

          this.attrs = [
            {
              highlight: {
                start: { fillMode: 'light' },
                base: { fillMode: 'light' },
                end: { fillMode: 'light' },
              },
              dates: {
                start: this.testFrom,
                end: this.testTo,
              },
            },
          ]

          setTimeout(() => {
            if (this.showCalendarData) {
              const calendar = this.$refs.date_picker
              calendar.move(this.dateRangeFrom)
            }
          }, 100)

          break
      }
    },
    hideCalendarData() {
      if (this.showCalendarData == true) {
        this.showCalendarData = false
      }
    },
    drop(e) {
      const idea_id = e.dataTransfer.getData('idea_id')
      this.$emit('dropToContainer', idea_id)
    },
    selectToady() {
      this.$emit('selectToday')
    },
    datePrev() {
      this.$emit('datePrev')
    },
    dateNext() {
      this.$emit('dateNext')
    },
    toggleIdea() {
      this.showIdeas = !this.showIdeas
      this.$store.commit('setIdeasContainerStatus', this.showIdeas)
    },
    firstDayOfWeek(dateObject, firstDayOfWeekIndex) {
      const dayOfWeek = dateObject.getDay(),
        firstDayOfWeek = new Date(dateObject),
        diff =
          dayOfWeek >= firstDayOfWeekIndex
            ? dayOfWeek - firstDayOfWeekIndex
            : 6 - dayOfWeek

      firstDayOfWeek.setDate(dateObject.getDate() - diff)
      firstDayOfWeek.setHours(0, 0, 0, 0)

      return firstDayOfWeek
    },
    redirectToIdeas() {
      this.$router.push({ name: 'Ideas' })
    },
  },
  watch: {
    fullDateRange() {
      this.initSelectedDate()
    },
    refreshContent() {
      this.$apollo.queries.companies.refetch()
    },
    range() {
      if (this.range.start !== undefined) {
        this.$store.commit('setDateRangeFrom', this.range.start)
      }

      if (this.range.end !== undefined) {
        this.$store.commit('setDateRangeTo', this.range.end)
      }

      if (this.range.start !== undefined && this.range.end !== undefined) {
        // this.initSelectedDate();
      }
    },
    currentDate(oldValue, newValue) {
      this.showCalendarData = false

      switch (this.type) {
        case 'calendarDayView':
        case 'listView':
          this.selectedDate = moment(this.currentDate).format(
            'dddd, Do MMM YYYY'
          )
          break

        case 'calendarWeekView':
          const dateFrom = this.firstDayOfWeek(this.currentDate, 1)
          const dateTo = new Date()
          dateTo.setDate(dateFrom.getDate() + 6)

          this.selectedDate =
            moment(dateFrom).format('D MMM') +
            ' - ' +
            moment(dateTo).format('D MMM, YYYY')
          break

        case 'calendarMonthView':
          this.selectedDate = moment(this.currentDate).format('MMMM YYYY')
          break
      }
    },
  },
}
</script>

<style>
.calendar-ideas .calendar-ideas__drop-zone {
  z-index: 2;
}

.calendar-ideas .calendar-ideas__container {
  position: relative;
  z-index: 3;
}

.calendar-ideas__container {
  position: relative;
  padding-right: 90px;
}

.calendar-ideas__all {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  border: none;
  border-radius: 6px;
  background: #d1d4d4;
  width: 82px;
  height: 40px;
  color: #17252a;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  font-family: Nunito;
}

.slick-slider.slickUpdate .slick-list {
  margin-bottom: -127px;
  padding-bottom: 127px;
}

.slick-slider.slickUpdate .slick-dots {
  bottom: 115px;
  z-index: -1;
}
</style>
