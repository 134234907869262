<template>
    <Sidebar>
        <h2>{{ $t('3_4_title') }}</h2>
        <p>{{ $t('3_4_description') }}</p>

        <div class="line">
            <Button style="width:100%" @click="toSignIn()" :size="'max'" :type="'primary'">{{ $t('3_4_btn_sign_in') }}</Button>
        </div>        
    </Sidebar>
</template>

<script>
import Sidebar from '@/components/layout/Sidebar.vue';
import Button from '@/components/button/Button.vue';

export default {
  components: { 
    Sidebar,
    Button,    
  },
  methods: {
      toSignIn() {
          this.$router.push({name: 'Login'})
      },
  }
}
</script>

<style scoped>

</style>


