<template>    
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.625 1.875H9.375" stroke="#17252A" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.9375 0.625H4.0625C3.71732 0.625 3.4375 0.904822 3.4375 1.25V1.875H6.5625V1.25C6.5625 0.904822 6.28268 0.625 5.9375 0.625Z" stroke="#17252A" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.0625 7.1875V4.0625" stroke="#17252A" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5.9375 7.1875V4.0625" stroke="#17252A" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.86042 8.80167C7.83354 9.12572 7.56267 9.375 7.2375 9.375H2.76292C2.43775 9.375 2.16688 9.12572 2.14 8.80167L1.5625 1.875H8.4375L7.86042 8.80167Z" stroke="#17252A" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>

<script>

export default {
    
}
</script>
