<template>
  <Sidebar>
    <h2>{{ $t("2_1_title") }}</h2>
    <p>{{ $t("2_1_description") }}</p>

    <div class="line">
      <FormGroup
        :focused="focusFirstName"
        :label="$t('2_1_label_first_name')"
        :required="true"
        :validationError="validationError.firstName"
      >
        <input
          class="form-group__input"
          type="text"
          :placeholder="$t('2_1_placeholder_first_name')"
          :maxlength="length.short"
          v-model="firstName"
          @focus="focusFirstName = true"
          @blur="focusFirstName = false"
          @input="validationError.firstName = false"
        />
      </FormGroup>
    </div>

    <div class="line">
      <FormGroup
        :focused="focusLastName"
        :label="$t('2_1_label_last_name')"
        :required="true"
        :validationError="validationError.lastName"
      >
        <input
          class="form-group__input"
          type="text"
          :placeholder="$t('2_1_placeholder_last_name')"
          :maxlength="length.short"
          v-model="lastName"
          @focus="focusLastName = true"
          @blur="focusLastName = false"
          @input="validationError.lastName = false"
        />
      </FormGroup>
    </div>

    <div class="line">
      <FormGroup
        :focused="focusEmail"
        :label="$t('2_1_label_email')"
        :required="true"
        :validationError="validationError.email"
      >
        <input
          class="form-group__input"
          type="email"
          :placeholder="$t('2_1_placeholder_email')"
          :maxlength="length.short"
          v-model="email"
          @focus="focusEmail = true"
          @blur="focusEmail = false"
          @input="validationError.email = false"
        />
      </FormGroup>
    </div>

    <div class="line">
      <FormGroup
        :focused="focusPassword"
        :label="$t('2_1_label_password')"
        :required="true"
        :validationError="validationError.password"
      >
        <input
          class="form-group__input"
          :type="inputType"
          :placeholder="'••••••••'"
          :maxlength="length.short"
          v-model="password"
          @focus="focusPassword = true"
          @blur="focusPassword = false"
          @input="validationError.password = false"
        />

        <button @click="togglePassword" class="toggle-password">
          <span v-if="!showPassword"><IcoShowPassword /></span>
          <span v-if="showPassword"><IcoHiddePassword /></span>
        </button>
      </FormGroup>
    </div>

    <div class="line">
      <FormGroup
        :focused="focusPasswordConfirm"
        :label="$t('2_1_label_confirm_password')"
        :required="true"
        :validationError="validationError.password_confirm"
      >
        <input
          class="form-group__input"
          :type="inputTypeConfirm"
          :maxlength="length.short"
          placeholder="••••••••"
          v-model="password_confirm"
          @focus="focusPasswordConfirm = true"
          @blur="focusPasswordConfirm = false"
          @input="validationError.password_confirm = false"
        />

        <button @click="togglePasswordConfirm" class="toggle-password">
          <span v-if="!showPasswordConfirm"><IcoShowPassword /></span>
          <span v-if="showPasswordConfirm"><IcoHiddePassword /></span>
        </button>
      </FormGroup>
    </div>

    <div>
      <PasswordStrength v-if="password.length > 0" v-model="password" />
    </div>

    <div class="line">
      <SelectLanguage 
        v-model="language" 
        :languages="languages"
        @updateLanguage="updateLanguage"
      />
    </div>

    <div class="line">
      <span
        >{{ $t("2_1_checkbox_terms_line_1") }} <br /><Link
          @click="goToTermsAndConditions()"
          >{{ $t("2_1_link_terms_condition") }}</Link
        >
        {{ $t("2_1_and") }}
        <Link @click="goToPrivacyPolicy()">{{
          $t("2_1_link_privacy_policy")
        }}</Link></span
      >
    </div>

    <div class="line line--half">
      <Button
        style="width: 100%"
        @click="signUp()"
        :size="'max'"
        :type="'primary'"
        >{{ $t("2_1_btn_register") }}</Button
      >
    </div>

    <!--
    <div class="line line--half">
      <p class="center">
        <strong>{{ $t("2_1_or") }}</strong>
      </p>
    </div>

    <div class="line line--half">
      <Button
        style="width: 100%"
        @click="signUp()"
        :size="'max'"
        :type="'secondary'"
        ><IcoLogoGoogle /> {{ $t("2_1_btn_google_login") }}</Button
      >
    </div>
    -->

    <div class="line">
      <p>
        {{ $t("2_1_have_account") }}
        <Link @click="toLogin()">{{ $t("2_1_btn_sign_in") }}</Link>
      </p>
    </div>
  </Sidebar>
</template>

<script>
import textLength from "@/constants/TextLengthConstant.js";
import { URL } from "@/constants/URLConstant.js";
import { SignService } from "@/services/SignService.js";

import PasswordStrength from "@/components/PasswordStrength.vue";

import Sidebar from "@/components/layout/Sidebar.vue";
import FormGroup from "@/components/FormGroup.vue";
import Button from "@/components/button/Button.vue";
import Link from "@/components/design/Link.vue";
import SelectLanguage from "@/components/SelectLanguage.vue";

import IcoLogoGoogle from "@/components/svg/IcoLogoGoogle.vue";
import IcoShowPassword from "@/components/svg/IcoShowPassword.vue";
import IcoHiddePassword from "@/components/svg/IcoHiddePassword.vue";

export default {
  components: {
    Sidebar,
    FormGroup,
    Button,
    Link,
    IcoLogoGoogle,
    PasswordStrength,
    IcoShowPassword,
    IcoHiddePassword,
    SelectLanguage,
  },
  computed: {
    length() {
      return textLength;
    },
  },
  data() {
    return {
      language: '',
      focusFirstName: false,
      focusLastName: false,
      focusEmail: false,
      focusPassword: false,
      focusPasswordConfirm: false,
      showPassword: false,
      showPasswordConfirm: false,
      inputType: "password",
      inputTypeConfirm: "password",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      password_confirm: "",
      validationError: {
        firstName: false,
        lastName: false,
        email: false,
        password: false,
        password_confirm: false,
      },
      invalidForm: false,
      language: {
          name: this.$t('2_1_lang_czech'),
          ico: 'cs',
          id: 'CZ',
        },
      languages: [
        {
          name: this.$t('2_1_lang_czech'),
          ico: 'cs',
          id: 'CZ',
        },
        {
          name: this.$t('2_1_lang_english'),
          ico: 'en',
          id: 'ENG',
        }
      ]
    };
  },
  created() {
    this.firstName = ""
    this.lastName = ""
    this.email = ""
    this.password = ""
    this.password_confirm = ""

    let storageSelectedLanguage = localStorage.getItem('selectedLanguage')
    if (storageSelectedLanguage) {

      this.$i18n.locale = storageSelectedLanguage;

      if(storageSelectedLanguage == "en") {
        this.$moment.locale("en-gb")
      } else {
        this.$moment.locale("cs")
      }

      if (storageSelectedLanguage === 'en') {
        localStorage.setItem('selectedLanguage', 'en')
        this.language = {name: this.$t('2_1_lang_english'), ico: 'en', id: 'ENG'}
      }

      if (storageSelectedLanguage === 'cs') {
        localStorage.setItem('selectedLanguage', 'cs')
        this.language = {name: this.$t('2_1_lang_czech'), ico: 'cs', id: 'CZ'}
      }

    } else {

      this.$moment.locale(navigator.language)

      if (navigator.language == "cs-CZ") {
        localStorage.setItem('selectedLanguage', 'cs')
        this.language = {name: this.$t('2_1_lang_czech'), ico: 'cs', id: 'CZ'}
      } else {
        localStorage.setItem('selectedLanguage', 'en')
        this.language = {name: this.$t('2_1_lang_english'), ico: 'en', id: 'ENG'}
      }

    }
  },
  methods: {
    updateLanguage(item) {
      this.language = item
    },
    goToTermsAndConditions() {
      switch(this.$i18n.locale) {
        case 'cs':
          window.open('https://www.contesaur.cz/vseobecne-obchodni-podminky/', '_blank')
          break

        case 'en':
          window.open('https://www.contesaur.com/general-terms-and-conditions/', '_blank')
          break
      }
    },
    goToPrivacyPolicy() {
      switch(this.$i18n.locale) {
        case 'cs':
          window.open('https://www.contesaur.cz/zpracovani-osobnich-udaju/', '_blank')
          break

        case 'en':
          window.open('https://www.contesaur.com/privacy-policy/', '_blank')
          break
      }
    },
    togglePassword() {
      this.showPassword = !this.showPassword;

      if (this.showPassword) {
        this.inputType = "text";
      } else {
        this.inputType = "password";
      }
    },
    togglePasswordConfirm() {
      this.showPasswordConfirm = !this.showPasswordConfirm;

      if (this.showPasswordConfirm) {
        this.inputTypeConfirm = "text";
      } else {
        this.inputTypeConfirm = "password";
      }
    },
    signUp() {
      if ( this.validateFields() ) {
        this.$notify({
          title: this.$t("2_2_invalid_fields_title"),
          text: this.$t("2_2_invalid_fields_description"),
          type: "error",
          duration: 5000,
        });

        return;
      }

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          firstName: this.firstName,
          lastName: this.lastName,
          language: this.language.id,
          email: this.email,
          password: this.password,
        }),
      };

      fetch(URL + "/api/register", requestOptions)
        .then((data) => {

          if(data.status === 409) {
            this.$notify({
                title: this.$t("2_2_toaster_error_title_register_conflict"),
                text: this.$t("2_2_toaster_error_description_register_conflict"),
                type: "error",
                duration: 5000,
            });

            return
          }

          const encryptedEmail = this.$CryptoJS.AES.encrypt(this.email, "ContPass").toString()
          this.$router.push({ name: "VerifyEmail", params: { email: encryptedEmail } });

          this.firstName = ''
          this.lastName = ''
          this.email = ''
          this.password = ''
          this.password_confirm = ''

        }).catch(error => {

          this.$notify({
              title: this.$t("2_1_toaster_error_title_registration"),
              text: this.$t("global_toaster_error_description"),
              type: "error",
              duration: 5000,
          });
        })
    },

    toLogin() {
      this.$router.push({ name: "Login" });
    },

    validateName() {
      this.validationError.name = false;
      if (this.name == "") {
        this.validationError.name = true;
        this.invalidForm = true;
      }
    },

    validateFields() {
      this.invalidForm = false;

      this.validationError.firstName = false;
      if (this.firstName == "") {
        this.validationError.firstName = true;
        this.invalidForm = true;
      }

      if (this.firstName.length < 3) {
        this.$notify({
          title: this.$t("2_1_toaster_error_title_first_name_is_short"),
          text: this.$t("2_1_toaster_error_description_first_name_is_short"),
          type: "error",
          duration: 5000,
        });

        this.validationError.firstName = true;
        this.invalidForm = true;
      }

      if (this.firstName.length > 50) {
        this.$notify({
          title: this.$t("2_1_toaster_error_title_first_name_is_long"),
          text: this.$t("2_1_toaster_error_description_first_name_is_long"),
          type: "error",
          duration: 5000,
        });

        this.validationError.firstName = true;
        this.invalidForm = true;
      }

      

      this.validationError.lastName = false;
      if (this.lastName == "") {
        this.validationError.lastName = true;
        this.invalidForm = true;
      }

      if (this.lastName.length < 3) {
        this.$notify({
          title: this.$t("2_1_toaster_error_title_last_name_is_short"),
          text: this.$t("2_1_toaster_error_description_last_name_is_short"),
          type: "error",
          duration: 5000,
        });

        this.validationError.lastName = true;
        this.invalidForm = true;
      }

      if (this.lastName.length > 50) {
        this.$notify({
          title: this.$t("2_1_toaster_error_title_last_name_is_long"),
          text: this.$t("2_1_toaster_error_description_last_name_is_long"),
          type: "error",
          duration: 5000,
        });

        this.validationError.lastName = true;
        this.invalidForm = true;
      }




      this.validationError.email = false;
      if (!SignService.validateEmail(this.email)) {
        this.validationError.email = true;
        this.invalidForm = true;
      }

      this.validationError.password = false;
      if (this.password == "") {
        this.validationError.password = true;
        this.invalidForm = true;
      }

      this.validationError.password_confirm = false;
      if (this.password_confirm == "") {
        this.validationError.password_confirm = true;
        this.invalidForm = true;
      }

      if( this.password !== this.password_confirm ) {
        this.validationError.password = true;
        this.validationError.password_confirm = true;
        this.invalidForm = true;
      }

      return this.invalidForm;
    },
  },
};
</script>