<template>
  <div>
    <h2 v-if="title.length > 0" class="content-edit__title">
      <IcoDescription /> {{ title }}
      <Button
        style="margin-left: 15px"
        v-if="!isEditing && !content && !disabled"
        @click="startEdit()"
        :size="'small'"
        >{{ buttonTitle }}</Button
      >
    </h2>

    <div
      v-if="!isEditing"
      @click="startEdit()"
      :class="{
        'content-edit__name': format === 'title',
        'content-edit__name--clipped': format === 'title',
        'content-edit__description': format === 'description',
      }"
    >
      {{ content.trim() }}
    </div>
    <div
      v-else
      class="content-edit__name-edit"
      :class="{
        'content-edit__name-edit': format === 'title',
        'content-edit__description-edit': format === 'description',
      }"
    >
      <div
        :class="{
          'content-edit__name-edit__data': format === 'title',
          'content-edit__description-edit__data': format === 'description',
          'show-error': showError,
        }"
      >
        <textarea-autosize
          :class="{ 'is-invalid': !isValid }"
          ref="textarea"
          :min-height="100"
          :max-height="300"
          :maxlength="maxLength"
          v-model="text"
        ></textarea-autosize>
      </div>
      <div class="content-edit__name-edit__controll">
        <Button @click="saveEditName()" :size="'small'" :type="'primary'">{{
          $t('10_1_btn_name_save')
        }}</Button>
        <Button @click="cancelEditName()" :size="'small'" :type="'secondary'">{{
          $t('10_1_btn_name_cancel')
        }}</Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/button/Button.vue'
import IcoDescription from '@/components/svg/small/IcoDescription.vue'

export default {
  components: {
    Button,
    IcoDescription,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: 500,
    },
    content: {
      type: String,
      default: '',
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    buttonTitle: {
      type: String,
      default: '',
    },
    format: {
      type: String,
      default: 'description',
    },
    showError: {
      type: Boolean,
      required: true,
    },
  },
  created() {
    this.text = this.content
  },
  data() {
    return {
      msg: 'Welcome to Use Tinymce Editor',
      text: '',
      isEditing: false,
    }
  },
  methods: {
    onClick() {
      alert('clicked')
    },
    startEdit() {
      if (this.disabled) {
        return
      }

      this.isEditing = true
      this.text = this.content
      this.$emit('opened')

      this.$nextTick(() => {
        this.$refs.textarea.$el.focus()
      })
    },
    saveEditName() {
      this.$emit('saveEditName', this.text)
      this.$emit('closed')

      setTimeout(() => {
        if (this.isValid) {
          this.isEditing = false
        }
      }, 200)
    },
    cancelEditName() {
      this.isEditing = false
      this.$emit('closed')
      this.$emit('cancelEditName')
    },
  },
  watch: {
    content() {
      this.text = this.content
    },
  },
}
</script>

<style scoped>
.content-edit__main--title {
  position: relative;
}
.content-edit__sidebar {
  top: 30px;
  right: 17px;
  padding-left: 25px;
  width: 40%;
}

.content-edit__title {
  margin-top: 32px;
  color: #17252a;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  font-family: Nunito;
  letter-spacing: -0.2px;
}

.content-edit__title svg {
  position: relative;
  top: 2px;
}

.content-edit__name {
  color: #17252a;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  font-family: Nunito;
}

.content-edit__name--clipped {
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content-edit__name-edit {
  width: 100%;
}

.content-edit__main--title .content-edit__name-edit {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  padding-right: 21px;
  padding-bottom: 10px;
}

.content-edit__name-edit__data {
  width: 100%;
}

.content-edit__name-edit__data textarea {
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  padding: 5px;
  width: 100%;
  color: #17252a;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  font-family: Nunito;
}

.content-edit__name-edit__data textarea:focus {
  border: 1px solid #2ce0c1;
}

.content-edit__name-edit__data.show-error textarea {
  border: 1px solid red;
}

.content-edit__name-edit__data textarea:focus {
  outline: none;
}

.content-edit__description {
  margin-top: 15px;
  max-width: 530px;
  color: #17252a;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-family: Nunito;
  overflow-wrap: break-word;
}

.content-edit__description--placeholder {
  color: #c5c5c5;
}

.content-edit__description-edit {
  margin-top: 10px;
  width: 100%;
}

.content-edit__description-edit__data {
  width: 100%;
}

.content-edit__description-edit__data textarea {
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  padding: 5px;
  width: 100%;
  color: #17252a;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-family: Nunito;
}

.content-edit__description-edit__data textarea:focus {
  border: 1px solid #2ce0c1;
}

.content-edit__name-edit__data textarea.is-invalid {
  border: 1px solid #f45050;
}

.content-edit__description-edit__data textarea:focus {
  outline: none;
}

.content-edit__name-edit__controll {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}
</style>
