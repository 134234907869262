<template>
    <Sidebar>
        <Link @click="toSignIn()"><IcoArrowBack /> {{ $t('3_2_go_back') }}</Link>
        <div class="line">
            <h2>{{ $t('3_2_title') }}</h2>
        </div>
        <div class="line line--half">
            <p>{{ $t('3_2_description') }}</p>
        </div>

        <Info />

        <div class="line">
            <p>{{ $t('3_2_trouble_recover') }} <MailToSupport :subject="$t('3_2_support_mail_subject')" /></p>
        </div>
    </Sidebar>
</template>

<script>
import { SignService } from "@/services/SignService.js";

import Sidebar from '@/components/layout/Sidebar.vue';
import FormGroup from '@/components/FormGroup.vue';
import Button from '@/components/button/Button.vue';
import Link from '@/components/design/Link.vue';
import Info from '@/components/Info.vue';
import MailToSupport from '@/components/MailToSupport.vue';

import IcoLogoGoogle from '@/components/svg/IcoLogoGoogle.vue';
import IcoArrowBack from '@/components/svg/IcoArrowBack.vue';

export default {
  components: { 
    Sidebar,
    FormGroup,
    Button,
    Link,
    IcoLogoGoogle,
    IcoArrowBack,
    Info,
    MailToSupport
  },
  data() {
      return {  
          email: '',
          validationError: {
              email: false,
          },
          invalidForm: false,
      }
  },
  methods: {
      signUp() {
          // this.$snotify.html('<h2>Test</h2><p>some desc</p>');
          // console.log("signUp");
          if( this.validateFields() ) {
              return;
          }
      },
      toSignIn() {
          this.$router.push({name: 'Login'});
      },
      validateFields() {
          this.invalidForm = false; 

          this.validationError.email = false;
          if(!SignService.validateEmail( this.email )) {
              this.validationError.email = true;
              this.invalidForm = true;
          }
            
          if(this.invalidForm) {
              // this.$snotify.html('<h2>Test</h2><p>some desc</p>');
          }

          return this.invalidForm;
      }
  }
}
</script>

<style scoped>

</style>


