<template>
    <div>
        <div 
            class="date-picker" 
            :class="{
                'slim': true,
                'is-open': show
            }"
            v-click-outside="hide"
            >
            <div v-if="edited" class="date-picker__selected" @click="toggle">
                <IcoCalendar />
                {{ time }}
            </div>
            <div v-if="!edited" class="date-picker__default" @click="toggle">
                <IcoCalendar />
                {{ time }}
            </div>
            <div v-if="show" class="date-picker__time">
                <div class="date-picker__wrapper">
                    <v-date-picker
                        mode="date" 
                        v-model="date"
                        :attributes="attrs"
                        :locale="$i18n.locale"
                        :first-day-of-week="2"
                    />
                </div>

                <div class="clear-timer">
                    <button @click.stop="clearDate()"><IcoBin /> <span>{{ $t('9_2_clear') }}</span></button>
                </div>             
            </div>    
        </div>
    </div>
</template>

<script>
import { DateService } from "@/services/DateService";
import { DateTime } from 'luxon'
import ClickOutside from 'vue-click-outside';
import moment from 'moment';
import FormGroup from '@/components/FormGroup.vue';
import IcoCalendar from '@/components/svg/IcoCalendar.vue';
import IcoBin from '@/components/svg/IcoBin.vue';

export default {
    components: {
        FormGroup,
        IcoCalendar,
        IcoBin,
    },
    directives: {
        ClickOutside
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String|Date,
            default() {
                return new Date();
            }
        },        
        state: {
            type: String,
            default: 'IDEA'
        },
        luxonDate: {
            type: Object,
            required: true
        }
    },
    created() {
        if(this.state === 'IDEA') {
            this.edited = false;
            this.date = undefined;
        } else {
            this.edited = true;
            this.date = new Date(DateService.getDateToTimezone(this.value, this.selectedCompany.zoneId))
        }
    },
    computed: {
        selectedCompany() {
            return this.$store.getters.getSelectedCompany;
        },
        time() {
            if(this.state === 'IDEA') {
                return this.$t('10_1_date_picker_not_set');
            }

            if(this.date) {                
                if(this.value !== null) {
                    return this.luxonDate.setLocale(this.$i18n.locale).toFormat('dd. LLL yyyy')
                } else {
                    this.edited = false;
                    return this.$t('10_1_date_picker_not_set');
                }                
            } else {
                return this.$t('10_1_date_picker_not_set');
            }            
        }
    },
    data() {
        return {            
            date: null,
            show: false,
            timezone: '',
            now: Date(),
            edited: false,
            attrs: [],
        }
    },
    methods: {
        clearDate() {
            this.show = false;
            this.$emit("updatedDateClear", null);
        },
        hide() {
            this.show = false;
        },
        toggle() {
            if(this.disabled) {
                this.show = false
                return
            }

            if(this.date) {
                this.attrs = [
                    {
                        highlight: {
                            class: 'publish-date',
                            contentClass: 'publish-date-text',
                        },
                        dates: new Date(this.date),
                    },
                ];
            }
            
            
            this.show = !this.show;
        }
    },
    watch: {
        date() {     
            if(this.show) {
                this.show = false;
                this.edited = true;
                
                if(this.date === null) {
                    this.date = this.value;
                }

                this.$emit("updatedDate", this.date);
            }
        },
        allowEmpty() {
            if(this.allowEmpty) {
                this.date = null;
            } else {
                this.edited = true;
                this.date = new Date(this.value);
            }
        },
        state(newIdea, oldIdea) {

            if(this.state === 'IDEA') {
                this.edited = false;                        
            } else {
                this.edited = true;
                this.date = new Date();
            }
        },
        value() {
            if(this.value === null) {
                this.date = null;
                this.edited = false;
                return;
            }

            if(this.state === 'IDEA') {
                this.edited = false;
            } else {
                this.edited = true;
                this.date = new Date(this.value);
            }            
        }
    }
}
</script>

<style scoped>
    .date-picker{
        position: relative;
    }

    .date-picker.slim{
        border: 1px solid transparent;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 4px;
    }

    .date-picker.slim.is-open{
        border: 1px solid #2CE0C1;
    }

    .date-picker__selected{
        display: flex;
        align-items: center;
        gap: 10px;
        height: 45px;
        line-height: 45px;
        padding: 0 15px;
        cursor: text;

        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #17252A;
    }

    .date-picker.slim .date-picker__selected{
        padding: 0;
        height: auto;
        line-height: 1;
    }

    .date-picker__selected svg{
        position: relative;
        top: -1px;
        width: 16px;
    }

    .date-picker__default{
        display: flex;
        align-items: center;
        gap: 10px;
        height: 45px;
        line-height: 45px;
        padding: 0 15px;
        cursor: text;

        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #17252A;
    }

    .date-picker.slim .date-picker__default{
        padding: 0;
        height: auto;
        line-height: 1;
    }

    .date-picker__default svg{
        position: relative;
        top: -1px;
        width: 16px;
    }

    .date-picker__time{
        position: absolute;
        z-index: 992;
        background-color: #fff;
        top: 100%;
        
        background: #FFFFFF;
        box-shadow: 15px 15px 60px rgba(0, 0, 0, 0.05);
        border-radius: 8px;        
    }
</style>

<style>
    html body .content-edit__sidebar .date-picker.slim .date-picker__default svg path{
        fill: #17252A !important;
    }

    .date-picker .vc-time-picker .vc-date{
        display: none !important;
    }

    .date-picker .vc-highlight{
        background-color: #2CE0C1 !important;
        border-radius: 5px !important;
    }

    .date-picker .vc-day-content {
        border-radius: 5px !important;
    }

    .date-picker .vc-day-content:hover {
        background-color: #E7E9E9 !important;
    }

    .date-picker .vc-day-content:focus {
        background-color: #2CE0C1 !important;
        color: #fff;
    }

    .date-picker .vc-day-content{
        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 12px !important;
        line-height: 12px;
        text-align: center;
        color: #17252A;
    }

    .date-picker .vc-arrow{
        background: #E7E9E9;
        border-radius: 4px;
        display: flex;
        align-items: center;

    }

    .date-picker .vc-arrow{
        width: 24px;
        height: 24px;
        margin-top: -2px;
    }

    .date-picker .vc-arrow svg{
        position: relative;
        top: -1px;
        width: 18px;
        height: 18px;
    }

    .date-picker .vc-arrow.is-left svg{
        position: relative;
        left: 1px;
    }

    .date-picker .vc-arrow.is-right svg{
        position: relative;
        right: 1px;
    }

    .date-picker .vc-title{
        font-family: Nunito;
        font-style: normal;
        font-weight: 600 !important;
        font-size: 16px !important;
        line-height: 16px !important;
        text-align: center;
        color: #17252A;
    }

    .date-picker .vc-weekday{
        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 12px !important;
        line-height: 12px;
        text-align: center;
        color: #BFC5C5;
    }

    .date-picker .vc-day{
        border-top: 1px solid #E7E9E9;
    }

    .date-picker .vc-container.vc-blue{
        border: none;
        padding: 10px;
    }

    .clear-timer svg path{
        stroke: #AEB3B5;
    }

    .clear-timer{
        text-align: center;
        padding-bottom: 10px;
    }

    .clear-timer button{
        
        width: 100%;
        border: none;
        background: none;
        padding: 0;
        margin: 0;
        display: inline-flex;
        gap: 5px;
        cursor: pointer;
        text-align: center;
        align-items: center;
        justify-content: center;
    }

    .clear-timer span{
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.2px;
        color: #AEB3B5;
        text-align: center;
    }

</style>