<template>
  <div>
    <div class="sticky-header" :class="{ expanded: ideasContainerStatus }">
      <Menu />
      <div v-if="!billingOverdue" class="wrapper wrapper--calendar-fixed">
        <CalendarHeader
          :selectedDate="formattedCurrentDate"
          :type="'calendarMonthView'"
          :nameList="nameList"
          @selectToday="selectToady"
          @datePrev="datePrev"
          @dateNext="dateNext"
          @dropToContainer="dropToContainer"
        />
      </div>
    </div>

    <div
      v-if="!billingOverdue"
      class="wrapper wrapper--calendar wrapper--top page"
      :class="{
        expanded: ideasContainerStatus,
        'days-to-expiration': selectedCompany.showExpirationWarning,
      }"
    >
      <GlobalNotification />

      <v-calendar
        ref="calendar"
        class="custom-calendar max-w-full"
        :first-day-of-week="2"
        :masks="masks"
        :locale="$i18n.locale"
        :attributes="calendarData"
        disable-page-swipe
        is-expanded
        :model-config="modelConfig"
      >
        <template v-slot:day-content="{ day, attributes }">
          <div
            class="flex flex-col h-full z-10 overflow-hidden day-data"
            :class="{
              'is-selected': day === currentDate,
              'is-active': globalDragStatus,
              'is-dropped': activeDropzone === 'day-' + day.id,
            }"
            @click="selectDay(day)"
            @dragover.prevent="showActiveDrop('day-' + day.id)"
            @drop.prevent="drop($event, day)"
            @mouseleave="hideExtra"
            @mouseover="showExtra(day.id)"
            :id="'day-' + day.id"
          >
            <div class="day-data__drop-area" @dragover.prevent>
              <span>{{ $t('8_1_drop_zone') }}</span>
            </div>

            <div class="day-data__labels" @dragover.prevent>
              <span class="day-label day-label--day text-sm text-gray-900">
                {{ day.day }}.
              </span>
              <span class="day-data__labels__data">
                <span
                  v-if="attributes && getDaySuggestionCount(attributes)"
                  class="day-data__label-suggestion"
                >
                  <IcoSuggestion />
                </span>
                <span
                  v-if="
                    attributes && getDayContentCount(attributes) > 0 && loaded
                  "
                  class="
                    content-counter content-counter--small
                    day-label day-label--content
                    text-sm text-gray-900
                  "
                >
                  <IcoContent /> {{ getDayContentCount(attributes) }}
                </span>
              </span>
              <span v-if="!loaded" class="counter-loader"></span>
            </div>

            <div
              class="day-data__ideas"
              @dragover.prevent="showActiveDrop('day-' + day.id)"
              @mouseleave="hideExtra"
              @mouseover="showExtra(day.id)"
            >
              <vuescroll :ops="ops">
                <div v-if="!loaded">
                  <IdeaPreloader
                    v-for="(item, index) in generateRandomNumber(day.day, 3)"
                    :key="index"
                  />
                </div>

                <template v-if="loaded">
                  <template v-for="item in attributes">
                    <Idea
                      :key="item.key"
                      v-if="item.customData.type === 'content'"
                      :item="item.customData"
                      :draggable="role !== 'READ_ONLY'"
                      :id="item.key"
                      @deleteContent="deleteContent"
                    />
                    <Suggestion
                      v-if="
                        item.customData.type === 'suggestion' &&
                        ideasContainerStatus
                      "
                      :key="item.key"
                      :content="item.customData"
                    />
                  </template>
                </template>

                <div v-if="role !== 'READ_ONLY'" class="day-data__add-content">
                  <button
                    v-if="dayShowExtra === day.id"
                    class="day-data__add-content__button"
                    @click="addContent(day.date)"
                  >
                    <IcoPlus /> {{ $t('8_1_add_content') }}
                  </button>
                </div>
              </vuescroll>
            </div>
          </div>
        </template>
      </v-calendar>
    </div>

    <ExpiredCompany v-else :company="selectedCompany" />

    <Modal v-if="cannotOpenContent" @closeModal="cannotOpenContent = false">
      <div class="logout">
        <h2
          class="logout__title"
          v-html="$t('10_1_title_content_not_found')"
        ></h2>

        <div class="modal-description">
          <p>{{ $t('10_1_description_content_not_found') }}</p>
        </div>

        <div class="logout__options">
          <Button
            style="width: 100%"
            type="delete"
            @click="cannotOpenContent = false"
          >
            {{ $t('10_1_btn_close') }}
          </Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import CONTENTS from '@/graphql/Contents.gql'
import CONTENT_DATE_UPDATE from '@/graphql/ContentDateUpdate.gql'
import CONTENT_IDEA_DATE_UPDATE from '@/graphql/ContentIdeaDateUpdate.gql'
import CONTENT_STATE_UPDATE from '@/graphql/ContentStateUpdate.gql'
import CONTENT_DELETE from '@/graphql/ContentDelete.gql'

import vuescroll from 'vuescroll'
import { DateTime } from 'luxon'
import { obj_clone } from '@/services/helpers/clone.js'
import { firstDayOfWeek } from '@/constants/CalendarConstant.js'
import { DateService } from '@/services/DateService.js'
import { FilterService } from '@/services/FilterService.js'
import moment from 'moment'
import IcoPlus from '@/components/svg/IcoPlus.vue'
import IcoContent from '@/components/svg/IcoContent.vue'
import IcoSuggestion from '@/components/svg/IcoSuggestion.vue'

import Menu from '@/components/Menu.vue'
import CalendarHeader from '@/components/CalendarHeader.vue'
import Idea from '@/components/Idea.vue'
import IdeaPreloader from '@/components/IdeaPreloader.vue'
import GlobalNotification from '@/components/GlobalNotification.vue'
import ExpiredCompany from '@/components/ExpiredCompany.vue'
import Suggestion from '@/components/Suggestion.vue'
import Modal from '@/components/Modal.vue'
import Button from '@/components/button/Button.vue'

export default {
  components: {
    Suggestion,
    vuescroll,
    Menu,
    CalendarHeader,
    Idea,
    IcoPlus,
    IcoContent,
    IcoSuggestion,
    IdeaPreloader,
    GlobalNotification,
    ExpiredCompany,
    Modal,
    Button,
  },
  created() {
    this.initPage()
  },
  apollo: {
    companies: {
      query: CONTENTS,
      variables() {
        return {
          from: DateService.dateToUTC(this.from),
          to: DateService.dateToUTC(this.to),
          id: this.selectedCompany.id,
        }
      },
      skip() {
        if (!this.selectedCompany) {
          return true
        }

        return !(this.from !== '' && this.to !== '')
      },
      error(error) {},
    },
  },
  computed: {
    calendarData() {
      return [...this.contents, ...this.suggestions]
    },
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    profile() {
      return this.$store.getters.getMe
    },
    dateRangeFrom() {
      return this.$store.getters.getDateRangeFrom
    },
    ideasContainerStatus() {
      return this.$store.getters.getIdeasContainerStatus
    },
    randomNumber() {
      return Math.floor(Math.random() * 4) + 1
    },
    refreshContent() {
      return this.$store.getters.getRefreshContent
    },
    firstDayOfWeek() {
      return firstDayOfWeek
    },
    globalDragStatus() {
      return this.$store.getters.getGlobalDragStatus
    },
    activeDropzone() {
      return this.$store.getters.getActiveDropZone
    },
    selectedCompany() {
      if (this.$store.getters.getSelectedCompany) {
        return this.$store.getters.getSelectedCompany
      }

      return {
        zoneId: 'Europe/Prague',
      }
    },
    selectedDate() {
      return this.$store.getters.getSelectedDate
    },
    contentOwner() {
      return this.$store.getters.getContentOwner
    },
    contentFilter() {
      let result = []
      const _contentFilter = this.$store.getters.getContentFilter
      _contentFilter.forEach((item) => {
        let values = []

        if (item.name.id === 'name') {
          if (item.value.length > 0) {
            values.push(item.value)
          }
        } else {
          if (item.value !== '') {
            item.value.forEach((value) => {
              values.push(value.value)
            })
          }
        }

        result.push({
          type: item.name.id,
          values: values,
        })
      })

      return result
    },
    companyList() {
      return this.$store.getters.getCompanies
    },
  },
  data() {
    const d = this.$store.getters.getSelectedDate
    d.setDate(1)
    d.setHours(0, 0, 0, 0)

    return {
      cannotOpenContent: false,
      billingOverdue: false,
      loaded: false,
      test: '',
      data: [],
      refresh: 0,
      from: '',
      to: '',
      companies: [],
      formattedCurrentDate: '',
      currentDate: d,
      date: '',
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      showIdeas: false,
      selectedDay: '',
      masks: {
        weekdays: 'WWW',
      },
      locale: {
        en: {
          firstDayOfWeek: 2,
          masks: {
            weekdays: 'WWW',
          },
        },
      },
      dayShowExtra: '',
      nameList: [],
      contents: [],
      suggestions: [],
      ops: {
        vuescroll: {},
        scrollPanel: {
          scrollingX: false,
          scrollingY: true,
          initialScrollY: false,
          initialScrollX: false,
        },
        rail: {},
        bar: {
          onlyShowBarOnScroll: true,
          keepShow: false,
          minSize: 0,
        },
      },
    }
  },
  methods: {
    generateRandomNumber(day, limit) {
      if (day > 30) return

      if (day === 1) {
        return Math.floor(Math.random() * limit) + 1
      }

      if (day === 3) {
        return Math.floor(Math.random() * limit) + 1
      }

      if (day === 7) {
        return Math.floor(Math.random() * limit) + 1
      }

      if (day === 12) {
        return Math.floor(Math.random() * limit) + 1
      }

      if (day === 17) {
        return Math.floor(Math.random() * limit) + 1
      }

      if (day === 20) {
        return Math.floor(Math.random() * limit) + 1
      }

      if (day === 24) {
        return Math.floor(Math.random() * limit) + 1
      }
      if (day === 25) {
        return Math.floor(Math.random() * limit) + 1
      }

      if (day === 28) {
        return Math.floor(Math.random() * limit) + 1
      }

      if (day === 30) {
        return Math.floor(Math.random() * limit) + 1
      }
    },
    getDayContentCount(attributes) {
      const items = attributes.filter(
        (item) => item.customData.type === 'content'
      )
      return items.length
    },
    getDaySuggestionCount(attributes) {
      const items = attributes.filter(
        (item) => item.customData.type === 'suggestion'
      )
      return items.length
    },
    initPage() {
      if (this.$route.name === 'Calendar') {
        this.$store.commit('setDate', new Date(this.dateRangeFrom))

        this.getFormattedCurrentDay(this.dateRangeFrom)
        this.processDate(this.dateRangeFrom)

        if (this.$route.params.company_id && this.$route.params.content_id) {
          if (this.$route.params.company_id !== this.selectedCompany.id) {
            if (this.companyList.length === 0) return

            const redirectToCompany = this.companyList.filter(
              (item) => item.id === this.$route.params.company_id
            )

            if (redirectToCompany.length > 0) {
              this.$store.commit('setCompany', redirectToCompany[0])
              this.$store.commit('setRefreshToken')
            } else {
              this.cannotOpenContent = true
            }
          }

          const item = {
            companyId: this.$route.params.company_id,
            id: this.$route.params.content_id,
          }
          this.$store.commit('setEditContentModalStatus', item)
        }
      }
    },
    deleteContent(content_id) {
      try {
        this.$apollo
          .mutate({
            mutation: CONTENT_DELETE,
            variables: {
              id: content_id,
            },
            update: (store, { data: { deleteContent } }) => {
              if (!deleteContent) {
                this.$notify({
                  title: '8_1_toaster_error_title_delete_content',
                  text: '8_1_toaster_error_description_delete_content',
                  type: 'error',
                  duration: 5000,
                })
                return
              }

              const cached = store.readQuery({
                query: CONTENTS,
                variables: {
                  from: DateService.dateToUTC(this.from),
                  to: DateService.dateToUTC(this.to),
                  id: this.selectedCompany.id,
                },
              })

              cached.companies[0].contents =
                cached.companies[0].contents.filter(
                  (item) => item.id !== content_id
                )

              store.writeQuery({
                query: CONTENTS,
                data: cached,
                variables: {
                  from: DateService.dateToUTC(this.from),
                  to: DateService.dateToUTC(this.to),
                  id: this.selectedCompany.id,
                },
              })

              this.getContents()
              // this.getSuggestions()

              this.$notify({
                title: $t('8_1_toaster_success_title_deleted_content'),
                text: $t('8_1_toaster_success_description_deleted_content'),
                type: 'success',
                duration: 5000,
              })
            },
            onQueryUpdated(observableQuery) {
              return observableQuery.refetch()
            },
          })
          .then((data) => {
            // console.log("data", data);
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('8_1_error_delete_content'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: $('8_1_error_delete_content'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    getContents() {
      let contents = []

      if (this.companies.length === 0) {
        return contents
      }

      const _contents = this.companies[0].contents
      const from = new Date(this.selectedDate).getTime()
      const to = new Date(
        new Date(
          this.selectedDate.getFullYear(),
          this.selectedDate.getMonth() + 1,
          0
        ).setHours(23, 59, 59, 0)
      ).getTime()

      for (let i = 0; i < _contents.length; i++) {
        let content = obj_clone(_contents[i])

        if (content.state === 'IDEA') {
          continue
        }

        content.key = 'key-' + content.id
        content.type = 'content'

        if (content.eod === true) {
          const dateObject = DateService.dateFromString(content.date)
          dateObject.setHours(23, 59, 59, 0)

          content.luxonDate = DateTime.fromISO(dateObject.toISOString())
          content.luxonDate.set({ hour: 23, minutes: 59, seconds: 59 })

          content.date = DateTime.fromISO(content.date)
            .setZone(this.selectedCompany.zoneId)
            .set({
              hour: 23,
              minutes: 59,
              second: 50,
            })
            .toUTC()
            .toISO()
        } else {
          content.luxonDate = DateTime.fromISO(content.date).setZone(
            this.selectedCompany.zoneId
          )
        }

        contents.push(content)

        const content_date = new Date(content.date).getTime()
        if (from <= content_date && to >= content_date) {
          this.nameList = FilterService.addNameList(content.name, this.nameList)
        }
      }

      let data = []
      const filteredContent = FilterService.filterContent(
        contents,
        this.contentFilter,
        this.contentOwner
      )

      function compare(a, b) {
        if (new Date(a.date).getTime() < new Date(b.date).getTime()) {
          return -1
        }
        if (new Date(a.date).getTime() > new Date().getTime()) {
          return 1
        }
        return 0
      }
      filteredContent.sort(compare)

      let order = 1
      filteredContent.forEach((content) => {
        order += 1

        let dateObject = DateService.getDateToTimezone(
          content.date,
          this.selectedCompany.zoneId
        )
        // let dateObject = content.date

        let item = {
          dates: dateObject,
          id: content.id,
          key: 'key-' + content.id,
          customData: content,
          order: order,
        }

        data.push(item)
      })

      this.contents = data
    },
    getSuggestions() {
      let suggestions = []

      if (this.companies.length === 0) {
        return suggestions
      }

      const _suggestions = this.companies[0].suggestedContents

      for (let i = 0; i < _suggestions.length; i++) {
        let content = obj_clone(_suggestions[i])

        content.key = 'key-' + content.id
        content.type = 'suggestion'

        const dateObject = DateService.dateFromString(content.date)
        dateObject.setHours(23, 59, 59, 0)

        content.luxonDate = DateTime.fromISO(dateObject.toISOString())
        content.luxonDate.set({ hour: 23, minutes: 59, seconds: 59 })

        const day = DateService.getDateFromString(content.date)
        content.date = DateTime.fromISO(content.date)
          .setZone(this.selectedCompany.zoneId)
          .set({
            year: day[0],
            month: day[1],
            day: day[2],
            hour: 11,
            minute: 59,
            second: 59,
          })
          .toUTC()
          .set({
            hour: 11,
            minute: 59,
            second: 59,
          })
          .toISO()

        suggestions.push(content)
      }

      let data = []
      suggestions.forEach((content) => {
        let item = {
          dates: content.date,
          id: content.id,
          key: 'key-' + content.id,
          customData: content,
          order: 1,
        }

        data.push(item)
      })

      this.suggestions = data
    },
    updateContentState(content, state) {
      if (this.role === 'READ_ONLY') {
        return
      }

      try {
        this.$apollo
          .mutate({
            mutation: CONTENT_STATE_UPDATE,
            variables: {
              id: content.id,
              state: state,
              eod: content.customData.eod,
            },
            update: (store, { data: { updateContent } }) => {
              this.$nextTick(() => {
                this.getContents()
                // this.getSuggestions()
              })

              this.$store.commit('setRefreshToken')
            },
          })
          .then((data) => {
            // console.log("data", data);
          })
          .catch((error) => {
            /*
                    this.$notify({
                        title: this.$t('8_1_toaster_error_content_state_update'),
                        text: error,
                        type: 'error',
                        duration: 5000
                    });
                    */
          })
      } catch (e) {
        this.$notify({
          title: this.$t('8_1_toaster_error_content_state_update'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    updateContent(content, date) {
      // console.log('to update content', content)
      // console.log('to update date', date)

      try {
        this.$apollo
          .mutate({
            mutation: CONTENT_DATE_UPDATE,
            variables: {
              id: content.id,
              date: date,
              eod: content.eod,
            },
            update: (store, { data: { updateContent } }) => {},
          })
          .then((data) => {
            this.$store.commit('setRefreshToken')
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('8_1_toaster_error_content_update'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('8_1_toaster_error_content_update'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    updateIdeaContent(idea_id, date, eod) {
      if (this.role === 'READ_ONLY') {
        return
      }

      try {
        this.$apollo
          .mutate({
            mutation: CONTENT_IDEA_DATE_UPDATE,
            variables: {
              id: idea_id,
              date: date,
              state: 'TODO',
            },
            update: (store, { data: { updateContent } }) => {},
          })
          .then((data) => {
            this.$store.commit('setRefreshToken')
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('8_1_toaster_error_content_state_update'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('8_1_toaster_error_content_state_update'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    showExtra(day) {
      this.dayShowExtra = day
    },
    hideExtra() {
      this.dayShowExtra = ''
    },
    getFormattedCurrentDay(selectedDate) {
      this.formattedCurrentDate = ''

      setTimeout(() => {
        const td = new Date(selectedDate)
        const month = DateService.getMonthName(td.getMonth())
        this.formattedCurrentDate =
          this.$t(month) + ' ' + moment(selectedDate).format('YYYY')
      }, 100)
    },
    addContent(day) {
      this.$store.commit('setCreateContentDefaultValue', {
        date: day,
      })
      this.$store.commit('setCreateContentModalStatus', true)
    },
    dropToContainer(idea_id) {
      const contents = this.contents.filter((item) => item.id === idea_id)
      this.updateContentState(contents[0], 'IDEA')
    },
    drop(e, day) {
      const idea_id = e.dataTransfer.getData('idea_id')
      const idea_date = e.dataTransfer.getData('idea_date')
      const idea_eod = e.dataTransfer.getData('idea_eod')

      let lxnDate = undefined

      if (idea_eod === 'yes') {
        lxnDate = DateTime.fromISO(idea_date)
          .setZone(this.selectedCompany.zoneId)
          .set({
            year: day.date.getFullYear(),
            month: day.date.getMonth() + 1,
            day: day.date.getDate(),
            hour: 11,
            minute: 59,
            second: 59,
          })
          .toUTC()
          .set({
            hour: 11,
            minute: 59,
            second: 59,
          })
          .toISO()
      } else {
        lxnDate = DateTime.fromISO(idea_date)
          .setZone(this.selectedCompany.zoneId)
          .set({
            year: day.date.getFullYear(),
            month: day.date.getMonth() + 1,
            day: day.date.getDate(),
          })
          .toUTC()
          .toISO()
      }

      const tasks = this.contents.filter((item) => item.id === idea_id)
      if (tasks.length > 0) {
        const task = tasks[0].customData
        this.updateContent(task, lxnDate)
      } else {
        lxnDate = DateTime.fromISO(new Date(idea_date).toISOString())
          .setZone(this.selectedCompany.zoneId)
          .set({
            year: day.date.getFullYear(),
            month: day.date.getMonth() + 1,
            day: day.date.getDate(),
            hour: 11,
            minute: 59,
            second: 59,
          })
          .toUTC()
          .toISO()

        this.updateIdeaContent(idea_id, lxnDate, idea_eod)
      }
    },
    showActiveDrop(key) {
      this.$store.commit('setActiveDropZone', key)
    },
    clearTaskDate(idea_id) {
      const tasks = this.ideas.filter((item) => item.key == idea_id)
      tasks[0].dates = null
    },
    selectDay(day) {
      this.selectedDay = day
      this.getFormattedCurrentDay(this.selectedDate)
    },
    processDate(selectedDate) {
      this.setDateRange()

      let today = new Date(selectedDate)
      today.setMonth(today.getMonth() - 1)
      today.setDate(1)
      this.from = today

      today = new Date(selectedDate)
      today.setMonth(today.getMonth() + 1)
      let lastDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0
      )
      this.to = lastDayOfMonth
    },
    setDateRange() {
      let today = new Date(this.selectedDate)
      today.setMonth(today.getMonth())
      today.setDate(1)
      this.$store.commit('setDateRangeFrom', today)

      today = new Date(this.selectedDate)
      today.setMonth(today.getMonth())
      let lastDayOfMonth = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0
      )
      this.$store.commit('setDateRangeTo', lastDayOfMonth)
    },
    async selectToady() {
      // this.loaded = false;
      let today = new Date()
      today.setDate(1)
      today.setHours(23, 59, 59, 0)
      this.currentDate = today

      const calendar = this.$refs.calendar
      await calendar.move(this.currentDate)

      this.getFormattedCurrentDay(this.selectedDate)
      this.$store.commit('setDate', this.currentDate)
      this.processDate(this.selectedDate)
    },
    async datePrev() {
      this.loaded = false
      this.currentDate.setMonth(this.currentDate.getMonth() - 1)
      this.currentDate.setHours(23, 59, 59)
      const calendar = this.$refs.calendar
      await calendar.move(this.currentDate)
      this.getFormattedCurrentDay(this.selectedDate)

      this.$store.commit('setDate', this.currentDate)
      this.processDate(this.selectedDate)
    },
    async dateNext() {
      this.loaded = false
      this.currentDate.setMonth(this.currentDate.getMonth() + 1)
      this.currentDate.setHours(23, 59, 59)
      const calendar = this.$refs.calendar
      await calendar.move(this.currentDate)

      this.getFormattedCurrentDay(this.selectedDate)

      this.$store.commit('setDate', this.currentDate)
      this.processDate(this.selectedDate)
    },
    async setCurrentMonth() {
      //this.loaded = false;
      this.currentDate.setMonth(this.selectedDate.getMonth())
      const calendar = this.$refs.calendar
      await calendar.move(this.currentDate)

      this.getFormattedCurrentDay(this.selectedDate)
      this.$store.commit('setDate', this.currentDate)
      this.processDate(this.selectedDate)
    },
    toggleIdea() {
      this.showIdeas = !this.showIdeas
    },
    calendarLeave() {
      this.$store.commit('setActiveDropZone', '')
    },
  },
  watch: {
    selectedCompany() {
      this.billingOverdue = this.selectedCompany.billingOverdue
    },
    refreshContent() {
      this.$apollo.queries.companies.refetch()
    },
    globalDragStatus() {
      this.dayShowExtra = ''
    },
    companies() {
      if (this.companies.length > 0) {
        this.getContents()
        this.getSuggestions()
        this.loaded = true
      }
    },
    contentOwner() {
      this.getContents()
      // this.getSuggestions()
    },
    contentFilter() {
      this.getContents()
      // this.getSuggestions()
    },
    async selectedDate() {
      if (this.selectedCompany === null) {
        return
      }

      let today = new Date(this.selectedDate)
      today.setDate(1)
      today.setHours(23, 59, 59, 0)

      const calendar = this.$refs.calendar
      await calendar.move(today)

      this.getFormattedCurrentDay(this.selectedDate)

      this.currentDate.setMonth(this.selectedDate.getMonth())
      this.currentDate.setFullYear(this.selectedDate.getFullYear())
      this.$store.commit('setDate', this.currentDate)
      this.$store.commit('setDateRangeFrom', this.currentDate)

      this.processDate(this.selectedDate)
      this.getContents()
      // this.getSuggestions()
    },
    companyList() {
      if (
        this.companyList.length > 0 &&
        this.$route.params.company_id &&
        this.$route.params.content_id
      ) {
        this.initPage()
      }
    },
    '$route.params': 'initPage',
  },
}
</script>

<style scoped>
.counter-loader {
  display: inline-block;
  mix-blend-mode: normal;
  animation: gradient 1s ease infinite;
  border-radius: 8px;
  background: linear-gradient(
    270deg,
    #e7e9e9 0%,
    rgba(231, 233, 233, 0.5) 100%
  );
  background-size: 300% 300%;
  width: 64px;
  height: 11px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>

<style>
.custom-calendar .vc-day {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
</style>
