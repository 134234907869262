<template>
    <div 
        class="select-company-item"        
        >
        <div class="select-company-item__avatar">
            <CompanyAvatar :size="'small'" :company="company" />
        </div>
        <div class="select-company-item__name">
            {{ company.name }}
        </div>
    </div>
</template>

<script>
import CompanyAvatar from '@/components/CompanyAvatar.vue';

export default {
    components: {
        CompanyAvatar
    },
    props: {
        company: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>
    .select-company-item{
        display: flex;
        align-items: center;
        width: 100%;
        gap: 10px;
    }

    .select-company-item__name{
        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #17252A;
    }
</style>