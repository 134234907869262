<template>
  <div @click="handleOpenNotificationDetail()" class="notification-item">
    <div class="notification-item__ico">
      <Avatar v-if="item.author" :name="item.author.name" :user="item.author" />
    </div>
    <div class="notification-item__data">
      <div class="notification-item__meta">
        <span class="notification-item__meta__company">{{
          item.company.name
        }}</span>
        <span class="notification-item__meta__date">{{ showDate }}</span>
      </div>
      <div class="notification-item__title">
        {{ item.name }}
      </div>
      <div class="notification-item__desc">
        <div v-if="item.action === 'CONTENT_TODO_DONE'">
          {{ item.author.name }}
          {{ $t('8_1_3_notification_content_todo_done') }}
        </div>

        <div v-if="item.action === 'CONTENT_FEEDBACK_MENTIONED'">
          {{ item.author.name }}
          {{ $t('8_1_3_notification_content_feedback_mentoined') }}
        </div>

        <div v-if="item.action === 'CONTENT_TODO_UNASSIGNED'">
          {{ item.author.name }}
          {{ $t('8_1_3_notification_content_todo_unassigned') }}
        </div>

        <div v-if="item.action === 'CONTENT_TODO_ASSIGNED'">
          {{ item.author.name }}
          {{ $t('8_1_3_notification_content_todo_assigned') }}
        </div>

        <div v-if="item.action === 'CONTENT_COMMENT_MENTIONED'">
          {{ item.author.name }}
          {{ $t('8_1_3_notification_content_comment_mentioned') }}
        </div>
        <div v-if="item.action === 'CONTENT_STATE_CHANGED'">
          {{ item.author.name }}
          {{ $t('8_1_3_notification_content_state_changed') }}
        </div>
        <div v-if="item.action === 'CONTENT_ADDED_AS_OWNER'">
          {{ item.author.name }}
          {{ $t('8_1_3_notification_content_added_as_owner') }}
          {{ item.forUser.name }}
        </div>
        <div v-if="item.action === 'CONTENT_REMOVED_AS_OWNER'">
          {{ item.author.name }}
          {{ $t('8_1_3_notification_content_removed_as_owner') }}
          {{ item.forUser.name }}
        </div>
      </div>
      <div v-if="item.snippet" class="notification-item__on">
        {{ item.snippet }}
      </div>
    </div>
    <div class="notification-item__control">
      <button
        @click.stop="toggleUnread()"
        :class="{ 'mark-as-read': !item.read, 'mark-as-unread': item.read }"
        v-tooltip="
          !item.read ? $t('8_1_3_mark_as_read') : $t('8_1_3_mark_as_unread')
        "
      ></button>
    </div>
  </div>
</template>

<script>
import { DateService } from '@/services/DateService.js'

import ClickOutside from 'vue-click-outside'
import IcoNotification from '@/components/svg/IcoNotification.vue'
import Avatar from '@/components/Avatar.vue'
import moment from 'moment'

export default {
  components: {
    IcoNotification,
    Avatar,
  },
  directives: {
    ClickOutside,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    showDate() {
      const date1 = new Date()
      const date2 = new Date(this.item.date)
      const diffTime = Math.abs(date2 - date1)

      if (this.type === 'new') {
        return this.msToTime(diffTime)
      }

      const td = new Date(this.item.date)
      const month = DateService.getMonthName(td.getMonth())

      return moment(this.item.date).format('Do') + ' ' + this.$t(month)
    },
  },
  data() {
    return {
      unread: true,
    }
  },
  methods: {
    handleOpenNotificationDetail() {
      if (this.item.category === 'CONTENT') {
        try {
          this.$store.commit('setCompany', this.item.company)
          this.$store.commit('setRefreshToken')

          this.$router.push({
            name: 'Calendar',
            params: {
              company_id: this.item.company.id,
              content_id: this.item.itemId,
            },
          })
        } catch (error) {
          console.log('cannot router push', error)
        }
      }
    },
    toggleUnread() {
      this.$emit('toggleNotification', this.item.id, !this.item.read)
    },
    msToTime(s) {
      const ms = s % 1000
      s = (s - ms) / 1000
      const secs = s % 60
      s = (s - secs) / 60
      const mins = s % 60
      const hrs = (s - mins) / 60

      let stringHours = ''
      let stringMinutes = ''

      if (hrs === 0) {
        stringHours = ''
      } else {
        stringHours = hrs + 'h '
      }

      if (mins === 0) {
        stringMinutes = ''
      } else {
        stringMinutes = mins + 'm '
      }

      if (hrs === 0 && mins === 0) {
        return this.$t('10_1_now')
      }

      return (
        this.$t('time_before') +
        stringHours +
        stringMinutes +
        this.$t('time_after')
      )
    },
  },
}
</script>

<style scoped>
.notification-item {
  display: flex;
  position: relative;
  gap: 10px;
  cursor: pointer;
  padding: 10px 20px;
}

.notification-item:hover {
  background: #fafafc;
}

.notification-item__data {
  padding-right: 40px;
  width: 100%;
}

.notification-item__control {
  position: absolute;
  top: 0;
  right: 32px;
}

.notification-item__control button.mark-as-read,
.notification-item__control button.mark-as-unread {
  position: relative;
  cursor: pointer;
  border: none;
  border: 1px solid #fff;
  border-radius: 50%;
  background: #fff;
  padding: 0;
  width: 18px;
  height: 18px;
}

.notification-item:hover .notification-item__control button.mark-as-read,
.notification-item:hover .notification-item__control button.mark-as-unread {
  border: 1px solid #e7e9ef;
}

.notification-item__control button.mark-as-read:after {
  display: inline-block;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 50%;
  background: #2ce0c1;
  width: 8px;
  height: 8px;
  content: '';
}

.notification-item__control button.mark-as-unread:after {
  display: inline-block;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 50%;
  background: #fff;
  width: 8px;
  height: 8px;
  content: '';
}

.notification-item__meta {
  display: flex;
  align-items: center;
  gap: 10px;
}

.notification-item__meta__company {
  color: #888f91;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 15px;
  font-family: Nunito;
  letter-spacing: -0.2px;
}

.notification-item__meta__date {
  color: #888f91;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  font-family: Nunito;
  letter-spacing: -0.2px;
}

.notification-item__title {
  margin-top: 3px;
  overflow: hidden;
  color: #17252a;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 125%;
  font-family: Nunito;
  letter-spacing: -0.2px;
  text-overflow: ellipsis;
}

.notification-item__desc {
  margin-top: 3px;
  color: #17252a;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 125%;
  font-family: Nunito;
  letter-spacing: -0.2px;
}

.notification-item__desc a {
  color: #17252a;
  text-decoration: none;
}

.notification-item__desc a:hover {
  text-decoration: underline;
}

.notification-item__on {
  margin-top: 5px;
  border: 1px solid #e7e9ef;
  padding: 10px;
  width: 100%;

  color: #17252a;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  font-family: Nunito;
  letter-spacing: -0.2px;
}
</style>
