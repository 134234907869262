<template>
    <label class="checkbox" :class="{'is-checked': checked}">  
        <input v-if="!readonly" type="checkbox" v-bind:checked="checked" v-on:change="$emit('change', $event.target.checked)" />
        <span v-if="text != ''">{{ text }}</span>
        <span v-else>{{ $t("2_1_checkbox_terms_line_1") }} <br><Link @click="goToTermsAndConditions()">{{ $t("2_1_link_terms_condition") }}</Link> {{ $t("2_1_and") }} <Link @click="goToPrivacyPolicy()">{{ $t("2_1_link_privacy_policy") }}</Link></span>
    </label>
</template>

<script>
import Link from '@/components/design/Link.vue';

export default {
    components: {
        Link
    },
    model: {
        prop: 'checked',
        event: 'change'
    },
    props: {
        checked: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        text: String
    },
    methods: {
        goToTermsAndConditions() {
            switch(this.$i18n.locale) {
                case 'cs':
                window.open('https://www.contesaur.cz/vseobecne-obchodni-podminky/', '_blank')
                break

                case 'en':
                window.open('https://www.contesaur.com/general-terms-and-conditions/', '_blank')
                break
            }
        },
        goToPrivacyPolicy() {
            switch(this.$i18n.locale) {
                case 'cs':
                window.open('https://www.contesaur.cz/zpracovani-osobnich-udaju/', '_blank')
                break

                case 'en':
                window.open('https://www.contesaur.com/privacy-policy/', '_blank')
                break
            }
        }
    }
}

</script>

<style scoped>
.checkbox{
    position: relative;
    padding-left: 24px;
    display: flex;
    gap: 10px;
    align-items: flex-start;

    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 138%;
    letter-spacing: -0.2px;
    color: #17252A;
}

.checkbox input{
    margin: 0;
    display: none;
    opacity: 0;
}

.checkbox:after{
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    border: 1px solid #BFC5C5;
    border-radius: 2px;
}

.checkbox.is-checked:after{
    border: 1px solid #2CE0C1;
    background-color: #2CE0C1;
    background-image: url('../assets/svg/check.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
</style>