<template>
    <div v-if="isVisible && (infoZone !== null) && (($i18n.locale === 'cs' && infoZone.czText !== null) || ($i18n.locale === 'en' && infoZone.enText !== null))" class="global-notification">
        <div class="global-notification__data">
            <Info class="global-notification__info" />
            <div v-if="$i18n.locale === 'cs'" class="global-notification__content" v-html="infoZone.czText"></div>
            <div v-if="$i18n.locale === 'en'" class="global-notification__content" v-html="infoZone.enText"></div>
        </div>
        
        <IcoClose class="global-notification__close" @click.native="close" />
    </div>
</template>

<script>
import INFO_ZONE from "@/graphql/InfoZone.gql";
import IcoClose from '@/components/svg/IcoClose.vue';
import Info from '@/components/svg/Info.vue';

export default {
  components: {
    IcoClose,
    Info,
  },
  apollo: {
    infoZone: {
      query: INFO_ZONE,      
    },    
  },
  data() {
    return {
        infoZone: null,
        isVisible: false,
    }
  },
  computed: {
    refreshContent() {
      return this.$store.getters.getRefreshContent;
    },
  },
  methods: {
    checkIfNotificationHasBeenRead() {
      if(this.infoZone?.publishDate) {
        const closedGlobalNotification = localStorage.getItem('closedGlobalNotification')

        if(!closedGlobalNotification) {
          this.isVisible = true
          return
        }

        const publishDate = (new Date(this.infoZone.publishDate)).getTime()

        if(publishDate > closedGlobalNotification) {
          this.isVisible = true
        }

      } else {
        this.isVisible = true
      }
    },
    close() {
        this.isVisible = false

        const closedDateTime = (new Date(this.infoZone.publishDate)).getTime()
        localStorage.setItem('closedGlobalNotification', closedDateTime)
    }
  },
  watch: {
    infoZone() {
      this.checkIfNotificationHasBeenRead()
    },
    refreshContent() {
      this.$apollo.queries.infoZone.refetch();
    },
  }
}
</script>

<style>
.global-notification{
    top: 0;
    left: 0;
    right: 0;
    background: #E7E9E9;
    padding: 36px;

    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.4px;
    color: #17252A;

    width: 100%;
    justify-content: space-between;

    border-radius: 10px;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.global-notification__data{
    display: flex;
    gap: 30px;
    align-items: center;
}

.global-notification__info{
    min-width: 36px;
}

.global-notification p{
    color: #17252A !important;
}

.global-notification a{
    color: #2CE0C1;
}

.global-notification__close {
    min-width: 12px;
    cursor: pointer;
}

.global-notification__close path{
    stroke: #17252A;
}

.global-notification__content {
    display: inline-block;
}
</style>