<template>
   <IcoFeedback @click.native="openFeedback" />
</template>

<script>
import IcoFeedback from '@/components/svg/IcoFeedback.vue';

export default ({
    components: {
        IcoFeedback
    },
    
    methods: {
        openFeedback() {
            this.$store.commit("setFeedbackModal", {status: true, contentId: null});
        }
    }
})
</script>