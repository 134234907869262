<template>
    <div class="item">
        <div class="item-wrapper">
            <div @click="selectItem()" class="item-checkbox" :class="{'selected': item.selected}">
                <input type="checkbox">
            </div>

            <div class="item-company">
                <Avatar size="big" :name="item.data.company.name" />
                <div class="item-company-data">
                    <div class="item-company-data-title">{{ item.data.company.name }}</div>
                    <div class="item-company-data-invited-by">{{ $t('4_1_invited_by') }} {{ item.data.inviter.name }}</div>
                </div>
            </div>
        </div>

        <button v-if="!deleteConfirmation" @click="deleteItem" class="item-delete">
            <IcoClose />
        </button>

        <button
            v-if="deleteConfirmation" 
            
            @click.stop="confirmDeleteItem()" 
            class="item-delete confirm"
            >
            <IcoClose :color="icoCloseColorActive" />
        </button>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

import Avatar from '@/components/Avatar.vue';
import Button from '@/components/button/Button.vue';
import Link from '@/components/design/Link.vue';
import Checkbox from '@/components/Checkbox.vue';

import IcoClose from '@/components/svg/IcoClose.vue';

export default {
  components: { 
    Avatar,
    Button,
    Link,
    Checkbox,
    IcoClose,
  },
  directives: {
    ClickOutside
  },
  props: {
      item: {
          type: Object,
          required: true,
      },
      index: {
          type: Number,
          required: true,
      }
  },
  data() {
    return {
        deleteConfirmation: false,
        icoCloseColorActive: '#fff'
    }
  },
  methods: {
    deleteItem() {
        this.$notify({
            title: this.$t("toaster_info_title_confirm_delete"),
            text: this.$t("toaster_info_description_confirm_delete"),
            type: "info",
            duration: 5000,
        });

        this.deleteConfirmation = true;

        setTimeout(() => {
            this.deleteConfirmation = false;
        }, 2000);
    },
    confirmDeleteItem() {
        this.deleteConfirmation = false;
        this.$emit("deleteItem", this.index)
    },
    selectItem() {
       this.$emit("selectItem", this.index)
    }
  }
}
</script>

<style scoped>
    .item{
        padding: 15px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #F5F5F5;
    }

    .item-wrapper{
        display: flex;
        gap: 15px;
        align-items: center;
    }

    .item-checkbox{
        position: relative;
        display: inline-block;
        width: 14px;
        min-width: 14px;
        height: 14px;
        border: 1px solid #BFC5C5;
        border-radius: 2px;
        cursor: pointer;
    }

    .item-checkbox.selected{
        border: 1px solid #2CE0C1;
        background-color: #2CE0C1;
        background-image: url('../assets/svg/check.svg');
        background-repeat: no-repeat;
        background-position: 50% 50%;
    }

    .item-checkbox input{
        display: none;
    }

    .item-delete{
        display: inline-block;        
        padding: 4px 6px 1px 6px;
        margin: 0;
        border: 0;
        background: transparent;
        cursor: pointer;
        border-radius: 4px;
    }

    .item-delete.confirm{
        background-color: #F45050;        
    }

    .item-company{
        display: flex;
        gap: 8px;
        align-items: center;
    }

    .item-company-data{
        display: flex;
        flex-flow: column;
    }

    .item-company-data-title{
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.2px;
        color: #17252A;
    }

    .item-company-data-invited-by{
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: -0.2px;
        color: #979797;
    }
</style>