<template>
  <div class="join-email">
    <RadioButtons v-model="usersRole" :items="[{'name': $t('role_approver'), 'value': 'APPROVER'}, {'name': $t('role_admin'), 'value': 'ADMIN'}]" />

    <div class="join-email__wrapper" :class="{'has-focus': focusJoinEmail}">
      <div class="join-email__placeholder">
        {{ $t("6_3_label_email") }}
        <IcoInfo v-tooltip="$t('6_3_1_tooltip_email')" />
      </div>      

      <div class="join-email__input">
        <div v-if="emails.length > 0" style="padding-bottom: 5px">
          <div
            v-for="(item, index) in emails"
            :key="index"
            :class="{ 'is-invalidEmail': !validateEmail(item) }"
            class="join-email__item"
          >
            {{ item }}
            <span @click.stop="deleteItem(index)" class="join-email__delete"
              ><IcoCross
            /></span>
          </div>
        </div>

        <input
          v-model="joinEmail"
          type="email"
          :placeholder="$t('6_3_1_placeholder_email')"
          @keydown.enter="addEmail"
          @keydown.188="addEmail"
          @keydown.delete="removeLastEmail"
          @keyup.22="addEmail"
          @keyup.ctrl.76="addEmail"
          @focus="focusJoinEmail = true"
          @blur="focusJoinEmail = false"
        />
      </div>
    </div>

    <div class="join-email__button">
      <Button
        @click="clickedInvite()"
        :disabled="joinEmail.length < 1 && !(emails.length > 0)"
        style="width: 100%"
        type="primary"
        >{{ $t("6_3_1_btn_send_email_join_request") }}
      </Button>
    </div>
  </div>
</template>

<script>
import INVITE_CREATE from "@/graphql/InviteCreate.gql";
import IcoInfo from "@/components/svg/small/IcoInfo.vue";
import IcoCross from "@/components/svg/small/IcoCross.vue";


import Button from "@/components/button/Button.vue";
import Checkbox from "@/components/Checkbox.vue";
import RadioButtons from '@/components/RadioButtons.vue';

export default {
  components: {
    Button,
    IcoInfo,
    IcoCross,
    Checkbox,
    RadioButtons
  },
  computed: {
    selectedCompany() {
      return this.$store.getters.getSelectedCompany;
    },
  },
  data() {
    return {
      focusJoinEmail: false,
      isDisabledSendJoinRequest: true,
      joinEmail: "",
      emails: [],
      doneInvites: 0,
      totalInvites: 0,
      usersRole: 'APPROVER'
    }
  },
  methods: {
    deleteItem(index) {
      this.emails.splice(index, 1);

      this.isDisabledSendJoinRequest = this.emails.length <= 0;
    },
    clickedInvite() {

      if (this.joinEmail.trim().length > 0) {
        // this.emails.push(this.joinEmail.trim())

        const strings = this.joinEmail.trim().split(/[, ]+/);
        for (let i = 0; i < strings.length; i++) {
          const element = strings[i];

          if (this.emails.length >= 10) {
            this.$notify({
              title: this.$t("6_3_toaster_error_title_max_users"),
              text: this.$t("6_3_toaster_error_description_max_users"),
              type: "error",
              duration: 5000,
            });

            return;
          }

          if (element.length > 0) {
            this.emails.push(element.trim());
          }
        }
      }

      this.joinEmail = "";

      this.totalInvites = this.emails.length;

      this.emails.forEach((email) => {
        if (this.validateEmail(email)) {
          try {
            this.$apollo
              .mutate({
                mutation: INVITE_CREATE,
                variables: {
                  id: this.selectedCompany.id,
                  email: email,
                  companyRole: this.usersRole
                },
                update: (store, { data: { updateCompany } }) => {
                  this.$notify({
                    title: this.$t("6_3_1_toaster_success_title_user_invited") + " " + email,
                    text: this.$t(
                      "6_3_1_toaster_success_description_user_invited"
                    ),
                    type: "success",
                    duration: 5000,
                  });
                },
              })
              .then(() => {
                this.doneInvites++
                this.emails.splice( this.emails.indexOf(email), 1)

                this.isDisabledSendJoinRequest = this.emails.length <= 0;
              })
              .catch((error) => {
                this.$notify({
                  title: this.$t("6_3_2_toaster_error_title_user_invited") + " " + email,
                  text: this.$t("global_toaster_error_description"),
                  type: "error",
                  duration: 5000,
                });
              });
          } catch (e) {
            this.$notify({
              title: this.$t("6_3_2_toaster_error_title_user_invited") + " " + email,
              text: this.$t("global_toaster_error_description"),
              type: "error",
              duration: 5000,
            });
          }
        }

      });
    },
    validateEmail(email) {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
    },
    addEmail(event) {
      event.preventDefault();

      const val = event.target.value.trim();
      if (val.length > 0) {
        const strings = val.split(/[, ]+/);
        for (let i = 0; i < strings.length; i++) {
          const element = strings[i];

          if (this.emails.length >= 10) {
            this.$notify({
              title: this.$t("6_3_toaster_error_title_max_users"),
              text: this.$t("6_3_toaster_error_description_max_users"),
              type: "error",
              duration: 5000,
            });

            return;
          }

          if (element.length > 0) {
            this.emails.push(element.trim());
          }
        }

        this.joinEmail = "";
      }
    },
    removeLastEmail(event) {
      if (event.target.value.length === 0) {
        this.removeEmail(this.emails.length - 1);
      }
    },
    removeEmail(index) {
      this.emails.splice(index, 1);

      this.isDisabledSendJoinRequest = this.emails.length <= 0;
    },
  },
  watch: {
    joinEmail() {
      this.isDisabledSendJoinRequest = this.emails.length <= 0;
    },
    doneInvites() {
      this.$emit('done')

      /*
      if (this.doneInvites === this.totalInvites) {
        this.$emit('done')
      }
       */
    }
  },
};
</script>

<style scoped>
.join-email {
  margin-top: 20px;
}

.join-email__wrapper {
  position: relative;
  display: block;
  mix-blend-mode: normal;
  border: 1px solid #bfc5c5;
  border-radius: 8px;
  width: 100%;
  margin-top: 20px;
}

.join-email__wrapper.has-focus {
  border: 1px solid hsl(170, 74%, 53%);
}

.join-email__placeholder {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.2px;
  color: #17252a;

  position: absolute;
  top: 0;
  left: 14px;
  transform: translate(0, -50%);
  display: inline-block;
  padding: 2px 6px;
  background-color: #fff;

  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.2px;
  color: #17252a;
}

.join-email__input {
  margin-top: 6px;

  padding: 8px 20px 12px 20px;
}

.join-email__item {
  display: block;
  padding: 6px 0;
}

.join-email__item.has-focus {
  border: 1px solid hsl(170, 74%, 53%);
}

.join-email__item.is-invalidEmail {
  color: red;
}

.join-email__input input {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 1;
  letter-spacing: -0.2px;
  color: #17252a;
  padding: 0;
  margin: 0;
  width: 100%;

  border: none;
  background: transparent;
}

.join-email__input input:focus {
  outline: none;
}

.join-email__input input::placeholder {
  color: #bfc5c5;
}

.join-email__button {
  margin-top: 25px;
}

.join-email__delete {
  position: relative;
  top: 1px;
  cursor: pointer;
  display: none;
  padding-left: 5px;
}

.join-email__item:hover .join-email__delete {
  display: inline-block;
}
</style>
