<template>
  <div class="checklist" :class="{ 'show-error': showError }">
    <div class="checklist__items">
      <div v-for="(item, index) in tasksBeforePublishDate" :key="item.id">
        <ChecklistItem
          :index="index"
          :itemData="item"
          :publishDate="publishDate"
          :updatingItem="updatingItem"
          :cancelStates="cancelStates"
          @setUpdatingItem="setUpdatingItem"
          @deleteCheckbox="deleteItem(index, item)"
          @silentDeleteCheckbox="silentDeleteCheckbox(index, item)"
          @updateChecklist="updateChecklist"
        />
      </div>

      <div v-if="tasksAfterPublishDate.length > 0" class="checklist__divider">
        <span>
          {{ $t('10_1_after_publish_date') }} ({{
            publishDate | moment('D. M. YYYY')
          }})
        </span>
      </div>

      <div v-for="(item, index) in tasksAfterPublishDate" :key="item.id">
        <ChecklistItem
          :index="index"
          :itemData="item"
          :publishDate="publishDate"
          :updatingItem="updatingItem"
          :cancelStates="cancelStates"
          @setUpdatingItem="setUpdatingItem"
          @deleteCheckbox="deleteItem(index, item)"
          @silentDeleteCheckbox="silentDeleteCheckbox(index, item)"
          @updateChecklist="updateChecklist"
        />
      </div>
    </div>
    <div
      v-if="
        (!showNewInput && checklistItems.length === 0 && initFromEmpty) ||
        (!showNewInput && checklistItems.length > 0 && !initFromEmpty) ||
        (!showNewInput && checklistItems.length > 0 && initFromEmpty)
      "
      class="checklist__controll"
      :class="{ 'is-first': checklistItems.length === 0 }"
    >
      <div>
        <Button @click="addItem()" type="secondary" size="small">
          {{ $t('10_1_btn_checklist_add') }}
        </Button>
      </div>
    </div>

    <div v-if="showNewInput">
      <div class="checklist-item">
        <div class="checklist-item__edit">
          <div class="checklist-item__edit__text">
            <div
              class="checklist-item__edit__name"
              :class="{ 'has-error': invalidName }"
            >
              <div @click.stop="toggleDone()">
                <Checkbox :readonly="true" v-model="item.done" />
              </div>

              <textarea-autosize
                @input="invalidName = false"
                :placeholder="$t('10_1_checklist_placeholder_new_task')"
                ref="checklist_edit_name"
                v-model="item.name"
                :maxlength="length.short"
              ></textarea-autosize>
            </div>
          </div>

          <div class="checklist-item__edit__controll">
            <div class="checklist-item__edit__buttons">
              <Button @click="saveItem()" :size="'small'" :type="'primary'">
                {{ $t('10_1_checklist_item_btn_save') }}
              </Button>
              <Button @click="cancelItem()" :size="'small'">
                {{ $t('10_1_checklist_item_btn_cancel') }}
              </Button>
            </div>

            <div
              class="checklist-item__edit__buttons"
              :class="{ 'is-done': item.done }"
            >
              <SmallDatePicker
                :publishDate="publishDate"
                v-model="item.date"
                v-slot="slotProps"
              >
                <Button
                  :red="isChecklistAfterDueDate"
                  :active="slotProps.show"
                  :size="'slim'"
                >
                  <IcoCalendar />
                  <span v-if="item.date === null">
                    {{ $t('10_1_checklist_item_btn_select_date') }}
                  </span>
                  <span v-else>{{ date }}</span>
                </Button>
              </SmallDatePicker>

              <SmallSelectPerson
                :user="item.assignee"
                @changeUser="changeUser"
              />

              <!--
                            <Button v-if="!confirmDelete" @click="toDeleteItem()" :size="'slim'"><IcoBin /></Button>
                            <Button
                                v-if="confirmDelete"
                                v-click-outside="cancelDeleteItem"
                                @click="confirmedDeleteItem()"
                                :type="'delete'"
                                :size="'slim'"
                                class="button-confirm-delete"
                                >
                                <IcoBin />
                            </Button>
                            -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import textLength from '@/constants/TextLengthConstant.js'
import { DateService } from '@/services/DateService.js'
import gql from 'graphql-tag'

import Button from '@/components/button/Button.vue'
import ChecklistItem from '@/components/ChecklistItem.vue'
import SmallSelectPerson from '@/components/SmallSelectPerson.vue'
import SmallDatePicker from '@/components/date/SmallDatePicker.vue'
import Checkbox from '@/components/Checkbox.vue'

import IcoBin from '@/components/svg/small/IcoBin.vue'
import IcoCalendar from '@/components/svg/small/IcoCalendar.vue'
import IcoUser from '@/components/svg/small/IcoUser.vue'
import IcoChecked from '@/components/svg/checklist/IcoChecked.vue'
import IcoUnchecked from '@/components/svg/checklist/IcoUnchecked.vue'

export default {
  components: {
    Button,
    IcoBin,
    IcoCalendar,
    ChecklistItem,
    SmallSelectPerson,
    SmallDatePicker,
    Checkbox,
    IcoUser,
    IcoChecked,
    IcoUnchecked,
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      },
    },
    addChecklistItem: {
      type: Boolean,
      default: false,
    },
    contentId: {
      type: String,
      required: true,
    },
    eod: {
      type: Boolean,
      required: true,
    },
    initFromEmpty: {
      type: Boolean,
      default: false,
    },
    forCreateContent: {
      type: Boolean,
      default: false,
    },
    publishDate: {
      type: String | Date,
      required: true,
    },
    showError: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    date() {
      return moment(this.item.date).format('D MMM')
    },
    isChecklistAfterDueDate() {
      if (this.item.date === null) {
        return false
      }

      return (
        new Date(this.item.date).getTime() < new Date().getTime() &&
        !this.item.done
      )
    },
    length() {
      return textLength
    },
    tasksBeforePublishDate() {
      function compare(a, b) {
        if (new Date(a.date).getTime() < new Date(b.date).getTime()) {
          return -1
        }
        if (new Date(a.date).getTime() > new Date().getTime()) {
          return 1
        }
        return 0
      }

      return this.checklistItems
        .filter(
          (item) =>
            new Date(item.date).getTime() <=
            new Date(this.publishDate).getTime()
        )
        .sort(compare)
    },
    tasksAfterPublishDate() {
      function compare(a, b) {
        if (new Date(a.date).getTime() < new Date(b.date).getTime()) {
          return -1
        }
        if (new Date(a.date).getTime() > new Date().getTime()) {
          return 1
        }
        return 0
      }

      return this.checklistItems
        .filter(
          (item) =>
            new Date(item.date).getTime() > new Date(this.publishDate).getTime()
        )
        .sort(compare)
    },
  },
  created() {
    this.generateInternalId()
  },
  data() {
    return {
      cancelStates: new Date().getTime(),
      updatingItem: false,
      invalidName: false,
      show: false,
      list: [],
      checklistItems: [],
      isEdited: '',
      confirmDelete: false,
      showNewInput: false,
      item: {
        name: '',
        date: null,
        assignee: {},
        done: false,
      },
    }
  },
  methods: {
    setCancelStates() {
      this.cancelStates = new Date().getTime()
    },
    setUpdatingItem(state) {
      this.updatingItem = state

      if (state) {
        this.$emit('opened')
      } else {
        this.$emit('closed')
      }
    },
    changeUser(user) {
      this.item.assignee = user
    },
    toggleDone() {
      this.item.done = !this.item.done
    },
    generateInternalId() {
      const checklistItems = this.items.map((o) => ({ ...o }))
      checklistItems.forEach((item, index) => {
        item.id = 'checklist_' + index
      })

      this.checklistItems = checklistItems
    },
    editingItem(state) {
      this.isEdited = state
    },
    saveItem() {
      this.setCancelStates()

      this.updatingItem = false
      this.invalidName = false

      if (this.item.name === '') {
        this.invalidName = true

        this.$notify({
          title: this.$t('10_3_toaster_error_title_checklist_name_empty'),
          text: this.$t('10_3_toaster_error_description_checklist_name_empty'),
          type: 'error',
          duration: 5000,
        })

        return
      }

      this.checklistItems.push(this.item)

      this.item = {
        name: '',
        date: null,
        assignee: {},
        done: false,
      }

      this.showNewInput = false

      const toUpdate = []
      this.checklistItems.forEach((item) => {
        if (item.name !== '') {
          let _item = {
            assigneeId: null,
            assignee: {},
            done: item.done,
            name: item.name,
            date: null,
          }

          if (item.date !== null) {
            _item.date = DateService.dateToUTC(new Date(item.date))
          }

          if (item.hasOwnProperty('assignee') && item.assignee != null) {
            if (item.assignee.hasOwnProperty('id')) {
              _item.assigneeId = item.assignee.id
              _item.assignee = item.assignee
            }
          } else if (item.hasOwnProperty('assigneeId')) {
            _item.assigneeId = item.assigneeId
            _item.assignee = {
              id: item.assigneeId,
            }
          }

          toUpdate.push(_item)
        }
      })

      if (this.forCreateContent) {
        this.$emit('update_data', toUpdate)
        this.updatingItem = false
        return
      }

      let checklist = []
      toUpdate.forEach((element) => {
        checklist.push({
          assigneeId: element.assigneeId,
          done: element.done,
          name: element.name,
          date: element.date,
        })
      })

      try {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!, $checklist: [ToDoInput!], $eod: Boolean!) {
                updateContent(id: $id, checklist: $checklist, eod: $eod) {
                  id
                  checklist {
                    assignee {
                      id
                      name
                    }
                    name
                    date
                    done
                  }
                }
              }
            `,
            variables: {
              id: this.contentId,
              checklist: checklist,
              eod: this.eod,
            },
            update: (store, { data: { updateContent } }) => {
              /*
                        this.$notify({
                            title: this.$t('10_1_toaster_success_checklist_item_update'),
                            text: this.$t('10_1_toaster_success_description_checklist_item_update'),
                            type: 'success',
                            duration: 5000
                        });
                        */
            },
          })
          .then((data) => {
            this.$store.commit('setRefreshToken')
            this.$emit('tasksUpdated')
            this.$emit('closed')
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_checklist_item_update'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_checklist_item_update'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }

      this.$emit('closed')
    },
    updateChecklist(index, item) {
      this.setCancelStates()

      let updatedItem = this.checklistItems.find(
        (_item) => _item.id === item.id
      )
      updatedItem = item

      const toUpdate = []
      this.checklistItems.forEach((item) => {
        if (item.name !== '') {
          let _item = {
            assigneeId: null,
            assignee: {},
            done: item.done,
            name: item.name,
            date: null,
          }

          if (item.date !== null) {
            _item.date = DateService.dateToUTC(new Date(item.date))
          }

          if (item.hasOwnProperty('assignee') && item.assignee != null) {
            if (item.assignee.hasOwnProperty('id')) {
              _item.assigneeId = item.assignee.id
              _item.assignee = item.assignee
            }
          } else if (item.hasOwnProperty('assigneeId')) {
            _item.assigneeId = item.assigneeId
            _item.assignee = {
              id: item.assigneeId,
            }
          }

          toUpdate.push(_item)
        }
      })

      if (this.forCreateContent) {
        this.$emit('update_data', toUpdate)
        return
      }

      let checklist = []
      toUpdate.forEach((element) => {
        checklist.push({
          assigneeId: element.assigneeId,
          done: element.done,
          name: element.name,
          date: element.date,
        })
      })

      try {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!, $checklist: [ToDoInput!], $eod: Boolean!) {
                updateContent(id: $id, checklist: $checklist, eod: $eod) {
                  id
                  checklist {
                    assignee {
                      id
                      name
                    }
                    name
                    date
                    done
                  }
                }
              }
            `,
            variables: {
              id: this.contentId,
              checklist: checklist,
              eod: this.eod,
            },
            update: (store, { data: { updateContent } }) => {
              /*
                        this.$notify({
                            title: this.$t('10_1_toaster_success_checklist_item_update'),
                            text: this.$t('10_1_toaster_success_description_checklist_item_update'),
                            type: 'success',
                            duration: 5000
                        });
                        */
            },
          })
          .then((data) => {
            this.$store.commit('setRefreshToken')
            this.$emit('tasksUpdated')
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_checklist_item_update'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_checklist_item_update'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }

      this.$emit('closed')
    },
    addItem() {
      if (this.updatingItem) {
        this.$notify({
          title: this.$t('10_3_toaster_error_title_checklist_already_editing'),
          text: this.$t(
            '10_3_toaster_error_description_checklist_already_editing'
          ),
          type: 'error',
          duration: 5000,
        })

        return
      }

      this.updatingItem = true
      this.showNewInput = true

      this.$nextTick(() => {
        this.$refs.checklist_edit_name.$el.focus()
      })

      this.$emit('opened')
    },
    silentDeleteCheckbox(index) {
      let indexToDelete = this.checklistItems.findIndex(
        (_item) => _item.id === item.id
      )
      this.checklistItems.splice(indexToDelete, 1)
      this.$emit('closed')
    },
    deleteItem(index, item) {
      this.setCancelStates()

      let indexToDelete = this.checklistItems.findIndex(
        (_item) => _item.id === item.id
      )
      this.checklistItems.splice(indexToDelete, 1)

      const toUpdate = []
      this.checklistItems.forEach((item) => {
        let _item = {
          assigneeId: null,
          date: null,
          done: item.done,
          name: item.name,
        }

        if (item.date !== null) {
          _item.date = DateService.dateToUTC(new Date(item.date))
        }

        if (item.assignee !== null) {
          if (
            item.hasOwnProperty('assignee') &&
            item.assignee.hasOwnProperty('id')
          ) {
            _item.assigneeId = item.assignee.id
          }
        }

        toUpdate.push(_item)
      })

      if (this.forCreateContent) {
        this.$emit('update_data', toUpdate)
        return
      }

      try {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!, $checklist: [ToDoInput!], $eod: Boolean!) {
                updateContent(id: $id, checklist: $checklist, eod: $eod) {
                  id
                  checklist {
                    assignee {
                      id
                      name
                    }
                    name
                    date
                    done
                  }
                }
              }
            `,
            variables: {
              id: this.contentId,
              checklist: toUpdate,
              eod: this.eod,
            },
            update: (store, { data: { updateContent } }) => {
              this.checklistItems = updateContent.checklist

              this.$notify({
                title: this.$t('10_1_toaster_success_checklist_item_delete'),
                text: this.$t(
                  '10_1_toaster_success_description_checklist_item_delete'
                ),
                type: 'success',
                duration: 5000,
              })
            },
          })
          .then((data) => {
            this.$store.commit('setRefreshToken')
            this.$emit('closed')
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_checklist_item_delete'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_checklist_item_delete'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    toDeleteItem() {
      this.confirmDelete = true

      this.$notify({
        title: this.$t('toaster_info_title_confirm_delete'),
        text: this.$t('toaster_info_description_confirm_delete'),
        type: 'info',
        duration: 5000,
      })

      setTimeout(() => {
        this.confirmDelete = false
      }, 2000)
    },
    confirmedDeleteItem() {
      this.confirmDelete = false
      this.item = {
        name: '',
        date: null,
        assignee: {},
        done: false,
      }
      this.showNewInput = false
      this.updatingItem = false

      // this.$emit('closed')
    },
    cancelItem() {
      this.confirmDelete = false
      this.item = {
        name: '',
        date: null,
        assignee: {},
        done: false,
      }
      this.showNewInput = false
      this.updatingItem = false

      this.$emit('closed')
    },
  },
  watch: {
    items() {
      this.generateInternalId()
    },
    addChecklistItem() {
      if (this.addChecklistItem) {
        this.showNewInput = true
      }
    },
  },
}
</script>

<style scoped>
.checklist {
  padding: 16px;
  width: 100%;
}

.checklist__items {
  display: block;
  width: 100%;
}

.checklist__controll {
  display: flex;
  gap: 20px;
  margin-top: 16px;
  padding-left: 24px;
}

.checklist__controll.is-first {
  margin-top: 5px;
}

.checklist__item {
  display: flex;
  position: relative;
  justify-content: space-between;
  margin-top: 16px;
  width: 100%;
}

.checklist__item__data {
  display: flex;
  gap: 20px;
  padding-right: 150px;
}

.checklist__item__no,
.checklist__item__title {
  color: #17252a;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  font-family: Nunito;
  letter-spacing: -0.2px;
}

.checklist__item__controll {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  align-items: center;
  gap: 6px;
}

.checklist__divider {
  position: relative;
  margin: 20px 0;
}

.checklist__divider span {
  display: inline-block;
  position: relative;
  z-index: 2;
  background: #fff;
  padding-right: 15px;

  color: #9795ff;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  font-family: 'Nunito';
  letter-spacing: -0.2px;
}

.checklist__divider:after {
  position: absolute;
  top: 11px;
  right: 0;
  left: 0;
  z-index: 1;
  background-image: url('../assets/svg/line-divider.svg');
  background-repeat: repeat-x;
  height: 3px;
  content: '';
}
</style>

<style scoped>
.checklist-item {
  display: flex;
  position: relative;
  justify-content: space-between;
  margin-top: 16px;
  width: 100%;
}

.checklist-item.is-done {
  opacity: 0.45;
}

.is-done {
  opacity: 0.45 !important;
}

.checklist-item__read.is-done {
  opacity: 0.45;
}

.checklist-item__data {
  display: block;
  padding-right: 180px;
  width: 100%;
}

.checklist-item__wrapper {
  display: flex;
  gap: 20px;
  width: 100%;
}

.checklist-item__no,
.checklist-item__title {
  display: flex;
  gap: 8px;
  color: #17252a;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  font-family: Nunito;
  letter-spacing: -0.2px;
}

.checklist-item__title__edit {
}

.checklist-item__title__read {
  max-width: 350px;
  overflow-wrap: break-word;
}

.checklist-item__title__read.is-done {
  text-decoration: line-through;
  /* color: #888F91; */
}

.checklist-item__title__read--placeholder {
  color: #888f91;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  font-family: Nunito;
}

.checklist-item__title__edit textarea {
  box-sizing: border-box;
  border: 1px solid #2ce0c1;
  border-radius: 4px;
  width: 100%;
}

.checklist-item__controll {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  align-items: center;
  gap: 6px;
}

.checklist-item__controll__avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
}

.checklist-item__edit {
  width: 100%;
}

.checklist-item__edit__text {
  display: flex;
}

.checklist-item__edit__no {
  width: 20px;
  color: #17252a;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  font-family: Nunito;
  letter-spacing: -0.2px;
}

.checklist-item__edit__name {
  display: flex;
  gap: 8px;
  width: 100%;
}

.checklist-item__edit__name textarea {
  box-sizing: border-box;
  border: 1px solid #2ce0c1;
  border-radius: 4px;
  padding: 7px 10px;
  width: 100%;

  color: #17252a;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  font-family: Nunito;
  letter-spacing: -0.2px;
}

.checklist-item__edit__name.has-error textarea {
  border: 1px solid #f45050;
}

.checklist-item__edit__controll {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  padding-left: 24px;
}

.checklist-item__edit__buttons {
  display: flex;
  gap: 8px;
}
</style>

<style>
.checklist.show-error .checklist-item__edit__name textarea {
  border: 1px solid #f45050;
}
</style>
