<template>
  <Modal @closeModal="close" :type="'slim'">
    <div v-show="showModalContent" class="modal-content modal-content--slim">
      <div class="modal-content__header">
        <h2 class="modal-content__title">
          {{ $t('9_1_title') }}
        </h2>

        <div class="modal-content__settings">
          <SelectColor
            v-model="content.state"
            @save_changed_value="stateChanged"
          />
          <button @click="handleOpenModalFeedback" class="modal-open-feedback">
            <IcoFeedback />
          </button>
          <button @click="close" class="modal-content__close">
            <IcoClose />
          </button>
        </div>
      </div>

      <vuescroll :ops="ops">
        <div class="modal-content__body">
          <div class="modal-content__wrapper">
            <div
              class="
                modal-content__row modal-content__row--first
                hide-error-ico
              "
            >
              <FormGroup
                :label="$t('9_1_label_name')"
                :focused="focusContentName"
                :required="true"
                :validationError="validationError.name"
              >
                <input
                  class="form-group__input"
                  type="text"
                  :placeholder="$t('9_1_placeholder_name')"
                  :maxlength="length.short"
                  v-model="content.name"
                  @input="validationError.name = false"
                  @focus="focusContentName = true"
                  @blur="focusContentName = false"
                  ref="contentName"
                />
              </FormGroup>
            </div>

            <div class="modal-content__row">
              <div style="width: 50%; min-width: 0">
                <FormGroup
                  :label="$t('9_1_label_owner')"
                  :required="true"
                  :validationError="validationError.owner"
                  :focused="focusContentOwner"
                >
                  <SelectPerson
                    v-model="content.owner"
                    :users="
                      codelist.users.filter((user) => user.role !== 'APPROVER')
                    "
                    @updateUser="updateUser"
                    @focus="focusContentOwner = true"
                    @blur="focusContentOwner = false"
                  />
                </FormGroup>
              </div>

              <div style="width: 50%; min-width: 0">
                <FormGroup
                  :focused="focusContentChannel"
                  :label="$t('9_1_label_channel')"
                >
                  <ChannelInput
                    :multiple="false"
                    :name="$t('9_1_label_channel')"
                    :items="codelist.channels"
                    v-model="content.channel"
                    @save_changed_value="updateChannel"
                    :placeholder="$t('9_1_placeholder_channel')"
                    type="create"
                    @focus="focusContentChannel = true"
                    @blur="focusContentChannel = false"
                  />
                </FormGroup>
              </div>
            </div>

            <div class="modal-content__row">
              <FormGroup
                :label="$t('9_1_label_description')"
                :focused="focusContentDescription"
                :required="false"
                :border="false"
              >
                <WYSIWYGEditor
                  :content="content.description"
                  :isValid="true"
                  title=""
                  tinymceRef=""
                  :buttonTitle="$t('10_1_title_add_description')"
                  :maxLength="length.long"
                  :externalEditState="true"
                  :showControl="false"
                  :placeholder="$t('9_1_placeholder_description')"
                  :showError="false"
                  format="description"
                  @updateContent="updateDescription"
                />
              </FormGroup>
            </div>

            <div class="modal-content__row">
              <FormGroup :label="$t('9_1_resources')" :required="false">
                <Resources
                  :items="content.resources"
                  :show-error="false"
                  @updateResources="updateResources"
                />
              </FormGroup>
            </div>

            <div class="modal-content__row">
              <div style="width: 33.32%">
                <DatePicker
                  :state="content.state"
                  :allowEmpty="allowEmptyDatepicker"
                  v-model="content.date"
                  @updatedDate="updatedDate"
                  @updatedDateClear="updatedDateClear"
                />
              </div>
              <div style="width: 33.32%">
                <TimePicker
                  :eod="content.eod"
                  :state="content.state"
                  :content="content"
                  v-model="content.date"
                  @updatedTime="updatedTime"
                  @updatedTimeClear="updatedTimeClear"
                />
              </div>
            </div>
          </div>

          <Accordion>
            <AccordionItem
              :open="accordionDetailsOpen"
              :title="$t('9_1_details')"
            >
              <div class="modal-content__row">
                <FormGroup
                  :focused="focusContentStdc"
                  :label="$t('9_1_label_stdc')"
                  :required="false"
                >
                  <STDCInput
                    :multiple="true"
                    :name="$t('9_1_name_stdc')"
                    :items="codelist.stdcs"
                    v-model="content.stdc"
                    :placeholder="$t('9_1_placeholder_stdc')"
                    boxSize="popper-big-edit-codelist"
                    @save_changed_value="updateSTDC"
                    type="create"
                    @focus="focusContentStdc = true"
                    @blur="focusContentStdc = false"
                  />
                </FormGroup>
              </div>

              <div class="modal-content__row">
                <FormGroup
                  :focused="focusContentPersona"
                  :label="$t('9_1_label_persona')"
                  :required="false"
                >
                  <PersonaInput
                    :multiple="true"
                    :name="$t('9_1_name_persona')"
                    :max="5"
                    :items="codelist.personas"
                    v-model="content.persona"
                    :placeholder="$t('9_1_placeholder_persona')"
                    boxSize="popper-big-edit-codelist"
                    @save_changed_value="updatePersona"
                    type="create"
                    @focus="focusContentPersona = true"
                    @blur="focusContentPersona = false"
                  />
                </FormGroup>
              </div>

              <div class="modal-content__row">
                <FormGroup
                  :focused="focusContentProduct"
                  :label="$t('9_1_label_product')"
                  :required="false"
                >
                  <ProductInput
                    :multiple="true"
                    :name="$t('9_1_name_product')"
                    :max="5"
                    :items="codelist.products"
                    v-model="content.product"
                    :placeholder="$t('9_1_placeholder_product')"
                    boxSize="popper-big-edit-codelist"
                    @save_changed_value="updateProduct"
                    type="create"
                    @focus="focusContentProduct = true"
                    @blur="focusContentProduct = false"
                  />
                </FormGroup>
              </div>

              <div class="modal-content__row">
                <FormGroup
                  :focused="focusContentTag"
                  :label="$t('9_1_label_tags')"
                  :required="false"
                >
                  <TagInput
                    :multiple="true"
                    :name="$t('9_1_name_tag')"
                    :max="5"
                    :items="codelist.tags"
                    :colorize="true"
                    v-model="content.tag"
                    :placeholder="$t('9_1_placeholder_tags')"
                    boxSize="popper-big-edit-codelist"
                    @save_changed_value="updateTags"
                    type="create"
                    @focus="focusContentTag = true"
                    @blur="focusContentTag = false"
                  />
                </FormGroup>
              </div>

              <div class="modal-content__row">
                <FormGroup
                  :focused="focusContentKeyword"
                  :label="$t('9_1_label_keywords')"
                  :required="false"
                >
                  <Keywords
                    v-model="content.keyword"
                    :placeholder="$t('9_1_placeholder_keywords')"
                    @save_changed_value="updateKeywords"
                    @focus="focusContentKeyword = true"
                    @blur="focusContentKeyword = false"
                  />
                </FormGroup>
              </div>
            </AccordionItem>
            <AccordionItem :title="$t('9_1_content')">
              <div class="modal-content__row">
                <FormGroup
                  :focused="focusContentFinalText"
                  :label="$t('9_1_label_final_text')"
                  :required="false"
                  :border="false"
                >
                  <WYSIWYGEditor
                    :content="content.finalText"
                    :isValid="true"
                    title=""
                    tinymceRef="create_content_final_text"
                    :buttonTitle="$t('10_1_title_add_description')"
                    :maxLength="length.long"
                    :externalEditState="true"
                    :showControl="false"
                    :placeholder="$t('9_1_placeholder_final_text')"
                    :showError="false"
                    format="description"
                    @updateContent="updateFinalText"
                  />
                </FormGroup>
              </div>

              <div class="modal-content__row">
                <FormGroup :label="$t('9_1_content_links')" :required="false">
                  <Resources
                    :items="content.contentLinks"
                    :show-error="false"
                    transCreateNew="10_1_link_add"
                    @updateResources="updateContentLinks"
                  />
                </FormGroup>
              </div>
            </AccordionItem>

            <AccordionItem
              v-if="content.state === 'PUBLISHED'"
              :title="$t('9_1_content_review')"
            >
              <div>
                <div class="modal-content__rating">
                  <star-rating
                    v-model="content.review_value"
                    :star-size="30"
                    :increment="0.5"
                    :clearable="true"
                    :max-rating="5"
                  ></star-rating>
                </div>

                <FormGroup
                  :focused="focusContentReviewText"
                  :label="$t('9_1_label_review_text')"
                  :required="false"
                  :border="false"
                >
                  <WYSIWYGEditor
                    style="padding-top: 1px"
                    :content="content.review_text"
                    :isValid="true"
                    title=""
                    tinymceRef="create_content_review_text"
                    :buttonTitle="$t('9_1_placeholder_review_text')"
                    :maxLength="length.long"
                    :externalEditState="true"
                    :showControl="false"
                    :placeholder="$t('9_1_placeholder_description')"
                    :showError="false"
                    format="description"
                    @updateContent="updateReviewText"
                  />
                </FormGroup>
              </div>
            </AccordionItem>

            <AccordionItem :title="$t('10_1_title_checklist')">
              <div class="modal-content__row">
                <FormGroup
                  :label="$t('10_1_subtitle_checklist')"
                  :required="false"
                >
                  <Checklist
                    :publishDate="content.date"
                    :items="content.checklist"
                    :initFromEmpty="true"
                    :forCreateContent="true"
                    :contentId="'newContent'"
                    :eod="content.eod"
                    @update_data="updateChecklist"
                  />
                </FormGroup>
              </div>
            </AccordionItem>
          </Accordion>
        </div>
      </vuescroll>

      <div class="modal-content__footer">
        <div class="modal-content__footer__terms">
          <Checkbox
            v-model="content.duplicate"
            :text="$t('9_1_check_duplicate')"
          />
        </div>
        <div class="modal-content__footer__buttons">
          <Button
            @click="createContent()"
            :disabled="disabledCreateButton"
            :type="'primary'"
          >
            {{ $t('9_1_btn_save') }}
          </Button>
          <Button @click.native="close">{{ $t('9_1_btn_cancel') }}</Button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import gql from 'graphql-tag'
import textLength from '@/constants/TextLengthConstant.js'
import SINGLE_CONTENT from '@/graphql/SingleContent.gql'
import vuescroll from 'vuescroll'
import StarRating from 'vue-star-rating'
import { DateTime } from 'luxon'

import Modal from '@/components/Modal.vue'
import FormGroup from '@/components/FormGroup.vue'
import TagInput from '@/components/TagInput.vue'
import ProductInput from '@/components/ProductInput.vue'
import PersonaInput from '@/components/PersonaInput.vue'
import STDCInput from '@/components/STDCInput.vue'
import ChannelInput from '@/components/ChannelInput.vue'

import Keywords from '@/components/Keywords.vue'
import SelectPerson from '@/components/SelectPerson.vue'
import SelectColor from '@/components/SelectColor.vue'
import SelectCompany from '@/components/SelectCompany.vue'
import Button from '@/components/button/Button.vue'
import WYSIWYGEditor from '@/components/WYSIWYGEditor.vue'

import TimePicker from '@/components/TimePicker.vue'
import DatePicker from '@/components/DatePicker.vue'

import Checklist from '@/components/Checklist.vue'

import Accordion from '@/components/design/Accordion.vue'
import AccordionItem from '@/components/design/AccordionItem.vue'

import Resources from '@/components/design/Resources.vue'
import ResourceItem from '@/components/design/ResourceItem.vue'

import Checkbox from '@/components/Checkbox.vue'
import IcoClose from '@/components/svg/IcoClose.vue'
import IcoFeedback from '@/components/svg/IcoFeedback.vue'
import { DateService } from '../services/DateService'
import { ResourceService } from '../services/ResourceService'
import { obj_clone } from '../services/helpers/clone'

export default {
  components: {
    Modal,
    FormGroup,
    TagInput,
    ProductInput,
    PersonaInput,
    STDCInput,
    ChannelInput,
    Keywords,
    SelectPerson,
    SelectColor,
    Checkbox,
    IcoClose,
    IcoFeedback,
    SelectCompany,
    Button,
    WYSIWYGEditor,
    TimePicker,
    DatePicker,
    Checklist,
    Accordion,
    AccordionItem,
    Resources,
    ResourceItem,
    vuescroll,
    StarRating,
  },
  apollo: {
    companies: {
      query: SINGLE_CONTENT,
      variables() {
        return {
          from: new Date(-8640000000000000),
          to: new Date(8640000000000000),
          id: this.duplicateContentModalId,
          companyId: this.selectedCompany.id,
        }
      },
      skip() {
        return this.duplicateContentModalId === ''
      },
      error(error) {
        this.$notify({
          title: this.$t('trs_cannot_load_data'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })

        this.close()
      },
    },
  },
  computed: {
    length() {
      return textLength
    },
    me() {
      return this.$store.getters.getMe
    },
    duplicateContentModalId() {
      return this.$store.getters.getDuplicateContentModalId
    },
    defaultValues() {
      return this.$store.getters.getCreateContentDefaultVale
    },
    codelist() {
      return this.$store.getters.getCodelist
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
    computedChannel() {
      if (
        this.content.channel &&
        this.content.channel.length &&
        this.content.channel[0].id
      ) {
        return this.content.channel[0].id
      }

      return null
    },
  },
  mounted() {
    document.addEventListener('keydown', this.escapeEvent)
  },
  destroyed() {
    document.removeEventListener('keydown', this.escapeEvent)
  },
  data() {
    const today = new Date()

    return {
      showModalContent: true,
      enableLoadContentToDuplicate: true,
      focusContentName: false,
      focusContentDescription: false,
      focusContentOwner: false,
      focusContentKeyword: false,
      focusContentFinalText: false,
      focusContentChannel: false,
      focusContentTag: false,
      focusContentStdc: false,
      focusContentPersona: false,
      focusContentProduct: false,
      focusContentReviewText: false,

      disabledCreateButton: true,
      allowEmptyDatepicker: true,
      companies: [],
      validationError: {
        name: false,
        owner: false,
      },
      accordionDetailsOpen: false,
      content_tmp: {
        id: '',
        state: 'IDEA',
        company: '',
        name: '',
        date: null,
        time: today,
        description: '',
        owner: {},
        channel: [],
        resources: [],
        contentLinks: [],
        keyword: [],
        finalText: '',
        tag: [],
        product: [],
        persona: [],
        stdc: [],
        duplicate: false,
        review_text: '',
        review_value: 0,
        checklist: [],
        eod: true,
      },
      content: {},
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {},
      },
    }
  },
  created() {
    this.$nextTick(() => {
      this.$refs.contentName.focus()
    })

    if (this.duplicateContentModalId > 0) {
    } else {
      this.content = obj_clone(this.content_tmp)

      this.content.owner = {
        id: this.me.id,
        name: this.me.name,
      }

      if (this.defaultValues.date != null) {
        this.content.date = new Date(this.defaultValues.date)

        this.content.state = 'TODO'
        this.stateChanged()
      }

      if (this.defaultValues.name != null) {
        this.content.name = this.defaultValues.name
        this.stateChanged()
      }

      if (this.defaultValues.state != null) {
        this.content.state = this.defaultValues.state
        this.stateChanged()
      }

      if (this.defaultValues.channel != null) {
        const channel = this.codelist.channels.find(
          (item) => item.id === this.defaultValues.ids[0]
        )
        this.content.channel = [
          {
            id: channel.id,
            name: channel.label,
          },
        ]
      }

      // Suggestions create data from object
      if (this.defaultValues.tagObjects != null) {
        this.content.tag = []

        this.defaultValues.tagObjects.forEach((tag) => {
          // const tag = this.codelist.tags.find((item) => item.id === id)
          this.content.tag.push({
            id: tag.id,
            name: tag.name,
          })
        })

        this.accordionDetailsOpen = true
      }

      if (this.defaultValues.tags != null) {
        this.content.tag = []

        this.defaultValues.ids.forEach((id) => {
          const tag = this.codelist.tags.find((item) => item.id === id)
          this.content.tag.push({
            id: tag.id,
            name: tag.label,
          })
        })

        this.accordionDetailsOpen = true
      }

      if (this.defaultValues.personas != null) {
        this.content.persona = []

        this.defaultValues.ids.forEach((id) => {
          const persona = this.codelist.personas.find((item) => item.id === id)
          this.content.persona.push({
            id: persona.id,
            name: persona.label,
          })
        })

        this.accordionDetailsOpen = true
      }

      if (this.defaultValues.products != null) {
        this.content.product = []

        this.defaultValues.ids.forEach((id) => {
          const product = this.codelist.products.find((item) => item.id === id)
          this.content.product.push({
            id: product.id,
            name: product.label,
          })
        })

        this.accordionDetailsOpen = true
      }

      if (this.defaultValues.stdcs != null) {
        this.content.stdc = []

        this.defaultValues.ids.forEach((item) => {
          this.content.stdc.push({
            id: item,
            name: item,
          })
        })

        this.accordionDetailsOpen = true
      }

      if (this.defaultValues.owner != null) {
        this.content.owner = {
          id: this.defaultValues.ids[0],
          name: this.defaultValues.owner,
        }
      }

      if (this.defaultValues.eod != null) {
        this.content.eod = this.defaultValues.eod
      }

      this.content.company = this.selectedCompany
    }
  },
  methods: {
    handleOpenModalFeedback() {
      this.$store.commit('setFeedbackModal', { status: true, contentId: null })
    },
    stateChanged() {
      this.allowEmptyDatepicker = this.content.state === 'IDEA'
    },
    updatedDateClear() {
      this.content.date = new Date()
      this.content.date.setHours(23, 59, 59, 0)
      this.content.eod = true
      this.content.state = 'IDEA'
    },
    loadContentToDuplicate() {
      if (!this.enableLoadContentToDuplicate) {
        return
      }

      const content = this.companies[0].contents[0]

      this.content = obj_clone(this.content_tmp)
      this.content.state = content.state
      this.content.company = this.selectedCompany

      this.allowEmptyDatepicker = false

      // this.content.date = content.date
      /*
      this.content.date = new Date(
        DateService.getDateToTimezone(content.date, this.selectedCompany.zoneId)
      )
       */
      this.content.date = content.date

      this.content.eod = content.eod

      this.content.name = this.$t('9_1_is_copy') + ' ' + content.name
      this.content.description = content.description

      if (content.owner) {
        this.content.owner = content.owner
      }

      if (content.channel) {
        this.content.channel.push(content.channel)
      }

      if (content.resources) {
        content.resources.forEach((element) => {
          this.content.resources.push({
            link: element.link,
            name: element.name,
          })
        })
      }

      if (content.keywords) {
        this.content.keyword = content.keywords
      }

      if (content.tags) {
        this.content.tag = content.tags
      }

      if (content.products) {
        this.content.product = content.products
      }

      if (content.personas) {
        this.content.persona = content.personas
      }

      if (content.stdcs) {
        content.stdcs.forEach((item) => {
          if (item !== '') {
            this.content.stdc.push({
              id: item,
              name: item,
            })
          }
        })
      }

      this.content.finalText = content.finalText

      if (content.contentLinks) {
        content.contentLinks.forEach((element) => {
          this.content.contentLinks.push({
            link: element.link,
            name: element.name,
          })
        })
      }

      if (content.review !== null) {
        if (content.review.hasOwnProperty('text')) {
          this.content.review_text = content.review.text
        }

        if (content.review.hasOwnProperty('value')) {
          this.content.review_value = content.review.value / 2
        }
      }

      if (content.checklist) {
        content.checklist.forEach((item) => {
          this.content.checklist.push({
            assigneeId: item.assignee.hasOwnProperty('id')
              ? item.assignee.id
              : null,
            assignee: item.assignee,
            date: item.date,
            done: item.done,
            name: item.name,
          })
        })
      }

      this.enableLoadContentToDuplicate = false
    },
    createContent() {
      this.validationError.name = false
      this.validationError.owner = false

      if (this.content.name === '') {
        this.validationError.name = true
      }

      if (!this.content.owner.id) {
        this.validationError.owner = true
      }

      if (this.content.owner.id === 'Unknown') {
        this.$notify({
          title: this.$t('9_1_toaster_error_title_owner_undefined'),
          text: this.$t('9_1_toaster_error_description_owner_undefined'),
          type: 'error',
          duration: 5000,
        })

        return
      }

      if (this.content.name === '' || !this.content.owner.id) {
        this.$notify({
          title: this.$t('9_1_toaster_error_title_create'),
          text: this.$t('9_1_toaster_error_description_create'),
          type: 'error',
          duration: 5000,
        })

        return
      }

      let tags = []
      let products = []
      let personas = []
      let stdcs = []

      if (this.getDuplicateContentModalId !== '') {
        if (
          this.content.tag.length &&
          this.content.tag[0].hasOwnProperty('id')
        ) {
          tags = this.content.tag.map((item) => item.id)
        }

        if (
          this.content.product.length &&
          this.content.product[0].hasOwnProperty('id')
        ) {
          products = this.content.product.map((item) => item.id)
        }

        if (
          this.content.persona.length &&
          this.content.persona[0].hasOwnProperty('id')
        ) {
          personas = this.content.persona.map((item) => item.id)
        }

        if (
          this.content.stdc.length &&
          this.content.stdc[0].hasOwnProperty('id')
        ) {
          stdcs = this.content.stdc.map((item) => item.id)
        }
      } else {
        tags = this.content.tag
        products = this.content.product
        personas = this.content.persona
        stdcs = this.content.stdc
      }

      let checklist = []
      this.content.checklist.forEach((element) => {
        checklist.push({
          assigneeId: element.assigneeId,
          done: element.done,
          name: element.name,
          date: element.date,
        })
      })

      this.disabledCreateButton = true

      let review = null
      if (this.content.state === 'PUBLISHED') {
        const updateReviewValue = this.content.review_value * 2

        review = {
          text: this.content.review_text,
          value: updateReviewValue === 0 ? null : updateReviewValue,
        }
      }

      try {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($content: ContentInput) {
                createContent(content: $content) {
                  id
                  name
                }
              }
            `,
            variables: {
              content: {
                companyId: this.selectedCompany.id,
                name: this.content.name,
                ownerId: this.content.owner.id,
              },
            },

            update: (store, { data: { createContent } }) => {
              this.content.id = createContent.id

              if (this.content.state === 'IDEA') {
                this.content.date = DateTime.now().toUTC().toISO()
              }

              let date

              if (typeof this.content.date === 'string') {
                this.content.date = new Date(this.content.date)
              }

              if (this.content.eod) {
                const _date = DateTime.fromISO(
                  this.content.date.toISOString()
                ).setZone(this.selectedCompany.zoneId)

                date = DateTime.fromISO(this.content.date.toISOString())
                  .setZone(this.selectedCompany.zoneId)
                  .set({
                    year: _date.year,
                    month: _date.month,
                    day: _date.day,
                    hour: 11,
                    minute: 59,
                    second: 59,
                    millisecond: 0,
                  })
                  .toUTC()
                  .set({
                    hour: 11,
                    minute: 59,
                    second: 59,
                  })
                  .toISO()
              } else {
                date = DateTime.fromISO(this.content.date.toISOString())
                  .setZone(this.selectedCompany.zoneId)
                  .toUTC()
                  .toISO()
              }

              try {
                this.$apollo
                  .mutate({
                    mutation: gql`
                      mutation (
                        $id: ID!
                        $name: String
                        $state: State!
                        $description: String
                        $date: Date
                        $channelId: ID
                        $ownerId: ID
                        $finalText: String
                        $resources: [LinkInput!]
                        $contentLinks: [LinkInput!]
                        $tagIds: [ID!]
                        $productIds: [ID!]
                        $personaIds: [ID!]
                        $stdcs: [STDC!]
                        $keywords: [String!]
                        $review: ReviewInput
                        $checklist: [ToDoInput!]
                        $eod: Boolean!
                      ) {
                        updateContent(
                          id: $id
                          name: $name
                          state: $state
                          description: $description
                          date: $date
                          channelId: $channelId
                          ownerId: $ownerId
                          finalText: $finalText
                          resources: $resources
                          contentLinks: $contentLinks
                          tagIds: $tagIds
                          productIds: $productIds
                          personaIds: $personaIds
                          stdcs: $stdcs
                          keywords: $keywords
                          review: $review
                          checklist: $checklist
                          eod: $eod
                        ) {
                          id
                          state
                          description
                          eod
                          date
                        }
                      }
                    `,
                    variables: {
                      id: this.content.id,
                      name: this.content.name,
                      state: this.content.state,
                      description: this.content.description,
                      date: date,
                      channelId: this.computedChannel,
                      ownerId: this.content.owner.id,
                      finalText: this.content.finalText,
                      resources: this.content.resources,
                      contentLinks: this.content.contentLinks,
                      tagIds: tags,
                      productIds: products,
                      personaIds: personas,
                      stdcs: stdcs,
                      checklist: checklist,
                      keywords: this.content.keyword,
                      review: review,
                      eod: this.content.eod,
                    },
                    update: (store, { data: { updateContent } }) => {
                      this.$store.commit('setRefreshToken')

                      if (this.content.duplicate) {
                        this.content.name =
                          this.$t('9_1_is_copy') + ' ' + this.content.name
                        this.content.duplicate = false

                        this.$notify({
                          title: this.$t(
                            '9_1_toaster_success_title_content_created_and_duplicated'
                          ),
                          text: this.$t(
                            '9_1_toaster_success_description_content_created_and_duplicated'
                          ),
                          type: 'success',
                          duration: 5000,
                        })

                        this.showModalContent = false
                        setTimeout(() => {
                          this.showModalContent = true
                        }, 1000)
                      } else {
                        this.$notify({
                          title: this.$t(
                            '9_1_toaster_success_title_content_created'
                          ),
                          text: this.$t(
                            '9_1_toaster_success_description_content_created'
                          ),
                          type: 'success',
                          duration: 5000,
                        })

                        // Close modal
                        this.$store.commit('setCreateContentModalStatus', false)
                        this.$store.commit('setDuplicateContentModalId', '')
                        this.$store.commit(
                          'setModalConfirmCloseCreateContent',
                          false
                        )
                      }
                    },
                  })
                  .then((data) => {
                    this.disabledCreateButton = false
                  })
                  .catch((error) => {
                    this.$notify({
                      title: this.$t('9_1_toaster_error_title_content_created'),
                      text: this.$t('global_toaster_error_description'),
                      type: 'error',
                      duration: 5000,
                    })

                    this.disabledCreateButton = false
                  })
              } catch (e) {
                this.$notify({
                  title: this.$t('9_1_toaster_error_title_content_created'),
                  text: this.$t('global_toaster_error_description'),
                  type: 'error',
                  duration: 5000,
                })

                this.disabledCreateButton = false
              }
            },
          })
          .catch((error) => {
            this.disabledCreateButton = false

            this.$notify({
              title: this.$t('9_1_toaster_error_title_content_created'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.disabledCreateButton = false

        this.$notify({
          title: this.$t('9_1_toaster_error_title_content_created'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    updateChecklist(items) {
      this.content.checklist = items
    },
    updateSTDC(items) {
      this.content.stdc = items
    },
    updatePersona(items) {
      this.content.persona = items
    },
    updateProduct(items) {
      this.content.product = items
    },
    updateTags(items) {
      this.content.tag = items
    },
    updateKeywords(items) {
      this.content.keyword = items
    },
    updateResources(list) {
      this.content.resources = ResourceService.prepareLinksForUpdate(list)
    },
    updateContentLinks(list) {
      this.content.contentLinks = ResourceService.prepareLinksForUpdate(list)
    },
    updateChannel(items) {
      this.content.channel = items
    },
    updatedDate(date) {
      if (this.content.state === 'IDEA') {
        this.content.state = 'TODO'
      }

      this.content.eod = true
      this.content.date = date
    },
    updatedTime(time) {
      const timeToSet = new Date(time)
      let contentDate = new Date(this.content.date)

      contentDate.setHours(timeToSet.getHours())
      contentDate.setMinutes(timeToSet.getMinutes())
      contentDate.setSeconds(timeToSet.getSeconds())

      this.content.date = contentDate
      this.content.eod = false
    },
    updatedTimeClear() {
      this.content.eod = true
      this.content.date = new Date(
        new Date(this.content.date).setHours(23, 59, 59, 0)
      )
    },
    updateUser(user) {
      this.validationError.owner = false
      this.content.owner = user
    },
    escapeEvent(event) {
      const self = this
      if (event.key === 'Escape') {
        self.close()
      }
    },
    close() {
      this.$store.commit('setModalConfirmCloseCreateContent', true)
    },
    toggleAccordion(item) {
      item = !item
    },
    validateSubmitButton() {
      this.disabledCreateButton = this.content.name.length <= 0

      if (!this.content.owner.id) {
        this.disabledCreateButton = true
      }
    },
    validateContentName() {
      if (this.content.name.length === 0) {
        this.validationError.name = true

        this.$notify({
          title: this.$t('9_1_toaster_error_title_create'),
          text: this.$t('9_1_toaster_error_description_create'),
          type: 'error',
          duration: 5000,
        })
        return
      }

      this.validationError.name = false
    },
    updateDescription(value) {
      this.content.description = value
    },
    updateReviewText(value) {
      this.content.review_text = value
    },
    updateFinalText(value) {
      this.content.finalText = value
    },
  },
  watch: {
    content: {
      deep: true,

      handler() {
        this.validateSubmitButton()

        if (this.content.date === null) {
          let date = new Date()
          date.setHours(23, 59, 59, 0)
          this.content.date = date
          this.content.eod = true
        }
      },
    },
    companies() {
      this.loadContentToDuplicate()
    },
  },
}
</script>
