<template>
  <div>
    <div class="sticky-header">
      <Menu />

      <div v-if="!billingOverdue" class="wrapper wrapper--calendar-fixed">
        <CalendarHeader
          :selectedDate="formattedRange"
          :showGroupBy="true"
          :type="'listView'"
          :nameList="nameList"
          :disableIdeas="true"
          @selectToday="selectToday"
          @datePrev="datePrev"
          @dateNext="dateNext"
          @dropToContainer="dropToContainer"
        />
      </div>
    </div>

    <div
      v-if="!billingOverdue"
      class="wrapper wrapper--calendar wrapper--top wrapper--list"
      :class="{
        'days-to-expiration':
          this.selectedCompany && this.selectedCompany.showExpirationWarning,
      }"
    >
      <GlobalNotification />
      <div class="list">
        <div class="list__controll">
          <button
            v-if="collapseAll"
            class="list__controll__item list__controll__item--active"
            @click="actionExpandAll"
          >
            {{ $t('8_4_control_expand') }}
          </button>
          <button
            v-if="!collapseAll"
            class="list__controll__item"
            @click="actionCollapseAll"
          >
            {{ $t('8_4_control_collapse') }}
          </button>
        </div>

        <div v-if="loaded" class="list__items">
          <StatusCard
            v-show="showStatusCard"
            v-for="(group, index) in data"
            :key="index"
            :item="group.name"
            :ids="group.ids"
            :type="groupBy"
            :contents="group.items"
            :collapse="cardsStatus[group.name]"
            @update_content="updateContent"
            @deleteContent="deleteContent"
            @toggleCard="toggleCard"
          />
        </div>

        <div v-else class="list__items">
          <StatusCardPreloader />
        </div>
      </div>
    </div>

    <ExpiredCompany v-else :company="selectedCompany" />
  </div>
</template>

<script>
import CONTENTS from '@/graphql/Contents.gql'
import CONTENT_UPDATE from '@/graphql/ContentUpdate.gql'
import CONTENT_STATE_UPDATE from '@/graphql/ContentStateUpdate.gql'
import CONTENT_DELETE from '@/graphql/ContentDelete.gql'

import { obj_clone } from '@/services/helpers/clone.js'
import { DateTime } from 'luxon'
import { DateService } from '@/services/DateService.js'
import { FilterService } from '@/services/FilterService.js'
import { ContentService } from '@/services/ContentService.js'
import moment from 'moment'

import Menu from '@/components/Menu.vue'
import CalendarHeader from '@/components/CalendarHeader.vue'
import StatusCard from '@/components/StatusCard.vue'
import StatusCardPreloader from '@/components/StatusCardPreloader.vue'
import GlobalNotification from '@/components/GlobalNotification.vue'
import ExpiredCompany from '@/components/ExpiredCompany.vue'

export default {
  components: {
    Menu,
    CalendarHeader,
    StatusCard,
    StatusCardPreloader,
    GlobalNotification,
    ExpiredCompany,
  },
  apollo: {
    companies: {
      query: CONTENTS,
      variables() {
        return {
          from: DateService.dateToUTCStart(this.dateRangeFrom),
          to: DateService.dateToUTCEnd(this.dateRangeTo),
          id: this.selectedCompany.id,
        }
      },
      skip() {
        return !this.selectedCompany
      },
      error(error) {
        this.$notify({
          title: this.$t('8_4_error_title_load_contents'),
          text: this.$t('8_4_error_description_load_contents'),
          type: 'error',
          duration: 5000,
        })
      },
    },
  },
  created() {
    this.generateContents()
    this.prepareContentGroup()
  },
  computed: {
    collapseAll() {
      return this.$store.getters.getListCollapsedAll
    },
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    refreshContent() {
      return this.$store.getters.getRefreshContent
    },
    groupBy() {
      return this.$store.getters.getSelectedGroupBy
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
    selectedDate() {
      return this.$store.getters.getSelectedDate
    },
    dateRangeFrom() {
      return this.$store.getters.getDateRangeFrom
    },
    dateRangeTo() {
      return this.$store.getters.getDateRangeTo
    },
    formattedRange() {
      return this.$store.getters.getFormattedRange
    },
    diffDays() {
      return this.$store.getters.getDateRangeDiff
    },
    contentOwner() {
      return this.$store.getters.getContentOwner
    },
    codelist() {
      return this.$store.getters.getCodelist
    },
    contentFilter() {
      let result = []
      const _contentFilter = this.$store.getters.getContentFilter
      _contentFilter.forEach((item) => {
        let values = []

        if (item.name.id === 'name') {
          if (item.value.length > 0) {
            values.push(item.value)
          }
        } else {
          if (item.value !== '') {
            item.value.forEach((value) => {
              values.push(value.value)
            })
          }
        }

        result.push({
          type: item.name.id,
          values: values,
        })
      })

      return result
    },
  },
  data() {
    return {
      billingOverdue: false,
      showStatusCard: true,
      loaded: false,
      refresh: 0,
      from: '',
      to: '',
      companies: [],
      formattedCurrentDate: '',
      currentDate: new Date(),
      // collapseAll: false,
      statusList: [],
      groupByList: [],
      nameList: [],
      contentGroup: [],
      data: {},
      contentReady: false,
      contents: [],
      dateFrom: new Date(new Date().setTime(0, 0, 0, 0)),
      dateTo: new Date(new Date().setTime(23, 59, 59, 0)),
      cardsStatus: {},
    }
  },
  methods: {
    checkCollapseAll() {
      if (this.collapseAll) {
        this.actionCollapseAll()
      } else {
        this.actionExpandAll()
      }
    },
    toggleCard(status, name) {
      this.cardsStatus[name] = status

      if (
        Object.keys(this.cardsStatus).length === Object.keys(this.data).length
      ) {
        let result = false

        for (let key in this.cardsStatus) {
          let item = this.cardsStatus[key]

          if (item) {
            result = true
          }
        }

        this.collapseAll = !result
      }

      this.prepareContentGroup()
    },
    generateContents() {
      this.contents = []
      this.groupByList = []
      let contents = []

      if (this.companies.length === 0) {
        return contents
      }

      const _contents = this.companies[0].contents

      for (let i = 0; i < _contents.length; i++) {
        let content = obj_clone(_contents[i])

        if (content.state === 'IDEA') {
          continue
        }

        content.key = 'key-' + content.id + '-' + new Date().getTime()
        content.date_original = content.date

        if (content.eod === true) {
          const dateObject = DateService.dateFromString(content.date)
          dateObject.setHours(23, 59, 59, 0)

          content.luxonDate = DateTime.fromISO(dateObject.toISOString())
          content.luxonDate.set({ hour: 23, minutes: 59, seconds: 59 })

          content.date = DateTime.fromISO(content.date)
            .setZone(this.selectedCompany.zoneId)
            .set({
              hour: 23,
              minutes: 59,
              second: 50,
            })
            .toUTC()
            .toISO()
        } else {
          const overrideZone = DateTime.fromISO(
            new Date(content.date).toISOString()
          ).setZone(this.selectedCompany.zoneId)

          content.luxonDate = overrideZone
        }

        if (content.channel === null) {
          content.channel = { id: 0, name: '' }
        }

        if (content.personas === null || content.personas.length === 0) {
          content.personas = [{ id: 0, name: '' }]
        }

        if (content.products === null || content.products.length === 0) {
          content.products = [{ id: 0, name: '' }]
        }

        if (content.tags === null || content.tags.length === 0) {
          content.tags = [{ id: 0, name: '' }]
        }

        if (content.stdcs === null || content.stdcs.length === 0) {
          content.stdcs = ['']
        }

        contents.push(content)

        this.nameList = FilterService.addNameList(content.name, this.nameList)
      }

      const filteredService = FilterService.filterContent(
        contents,
        this.contentFilter,
        this.contentOwner
      )
      this.contentReady = true
      this.contents = filteredService
    },
    updateContentState(content, state) {
      if (this.role === 'READ_ONLY') {
        return
      }

      try {
        this.$apollo
          .mutate({
            mutation: CONTENT_STATE_UPDATE,
            variables: {
              id: content.id,
              state: state,
              eod: content.eod,
            },
            update: (store, { data: { updateContent } }) => {},
          })
          .then((data) => {
            this.$store.commit('setRefreshToken')

            /* this.$notify({
                        title: this.$t('8_4_toaster_success_title_content_updated'),
                        text: this.$t('8_4_toaster_success_description_content_updated'),
                        type: 'success',
                        duration: 5000
                    }); */
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('8_4_toaster_error_content_state_update'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('8_4_toaster_error_content_state_update'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    updateContent(data) {
      if (this.role === 'READ_ONLY') {
        return
      }

      const tasks = this.contents.filter((item) => item.id === data.id)
      let content = obj_clone(tasks[0])

      const updated_content = ContentService.prepareContentForUpdate(
        content,
        data,
        this.selectedCompany.zoneId
      )

      try {
        this.$apollo
          .mutate({
            mutation: CONTENT_UPDATE,
            variables: {
              ...updated_content,
            },
          })
          .then((data) => {
            /* this.$notify({
                        title: this.$t('8_4_toaster_success_title_content_updated'),
                        text: this.$t('8_4_toaster_success_description_content_updated'),
                        type: 'success',
                        duration: 5000
                    }); */

            this.$store.commit('setRefreshToken')
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('8_4_toaster_error_content_update'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('8_4_toaster_error_content_update'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    deleteContent(content_id) {
      try {
        this.$apollo
          .mutate({
            mutation: CONTENT_DELETE,
            variables: {
              id: content_id,
            },
            update: (store, { data: { deleteContent } }) => {
              if (!deleteContent) {
                this.$notify({
                  title: this.$t('8_4_toaster_error_content_delete'),
                  text: this.$t('global_toaster_error_description'),
                  type: 'error',
                  duration: 5000,
                })
                return
              }

              const cached = store.readQuery({
                query: CONTENTS,
                variables: {
                  from: DateService.dateToUTC(this.dateRangeFrom),
                  to: DateService.dateToUTC(this.dateRangeTo),
                  id: this.selectedCompany.id,
                },
              })

              cached.companies[0].contents =
                cached.companies[0].contents.filter(
                  (item) => item.id !== content_id
                )

              store.writeQuery({
                query: CONTENTS,
                data: cached,
                variables: {
                  from: DateService.dateToUTC(this.dateRangeFrom),
                  to: DateService.dateToUTC(this.dateRangeTo),
                  id: this.selectedCompany.id,
                },
              })

              this.$notify({
                title: this.$t('8_4_toaster_success_title_deleted_content'),
                text: this.$t(
                  '8_4_toaster_success_description_deleted_content'
                ),
                type: 'success',
                duration: 5000,
              })

              this.generateContents()
              this.prepareContentGroup()
            },
          })
          .then((data) => {
            // console.log("data", data);
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('8_4_toaster_error_content_delete'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('8_4_toaster_error_content_delete'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    addGroupByList(value) {
      const result = this.groupByList.filter((item) => item.name === value.name)
      if (result.length < 1) {
        this.groupByList.push(value)
      }
    },
    reorderItems(items) {
      let arrayOrder = []
      for (let i = 0; i < items.length; i++) {
        switch (items[i]) {
          case 'SEE':
            arrayOrder[1] = items[i]
            break
          case 'THINK':
            arrayOrder[2] = items[i]
            break
          case 'DO':
            arrayOrder[3] = items[i]
            break
          case 'CARE':
            arrayOrder[4] = items[i]
            break
        }
      }

      return arrayOrder.filter((val) => val)
    },
    prepareContentGroup() {
      let data = {}

      this.contents.forEach((item) => {
        switch (this.groupBy) {
          case 'state':
            const state_key = ContentService.getStateOrder(item['state'])

            if (!data.hasOwnProperty(state_key)) {
              data[state_key] = {
                items: [],
                ids: [item['state']],
                name: item['state'],
              }
            }

            data[state_key].items.push(item)
            break

          case 'owner':
            const owner_key = item['owner']

            if (!data.hasOwnProperty(owner_key.id)) {
              data[owner_key.id] = {
                items: [],
                ids: [owner_key.id],
                name: owner_key.name,
              }
            }

            data[owner_key.id].items.push(item)
            break

          case 'date':
            let lxnDate = DateTime.fromISO(item['date_original'])
              .setZone(this.selectedCompany.zoneId)
              .set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              })

            const date_key = lxnDate.toMillis()
            const displayDate = lxnDate.toLocaleString({
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            })

            if (!data.hasOwnProperty(date_key)) {
              data[date_key] = {
                items: [],
                ids: [item['date_original']],
                name: displayDate,
              }
            }

            data[date_key].items.push(item)
            break

          case 'channel':
            const channel_key = item[this.groupBy]

            if (!data.hasOwnProperty(channel_key.id)) {
              data[channel_key.id] = {
                items: [],
                ids: [channel_key.id],
                name: channel_key.name,
              }
            }

            data[channel_key.id].items.push(item)
            break

          case 'products':
          case 'personas':
          case 'tags':
            const items_data = item[this.groupBy].sort((a, b) => {
              const fa = a.name.toLowerCase(),
                fb = b.name.toLowerCase()

              if (fa < fb) {
                return -1
              }
              if (fa > fb) {
                return 1
              }
              return 0
            })

            const key = items_data.map((e) => e.name).join(', ')

            const ids = items_data.map((e) => e.id)

            if (!data.hasOwnProperty(key)) {
              data[key] = { items: [], ids: [], name: key }
            }

            data[key].items.push(item)
            data[key].ids = ids
            break

          case 'stdcs':
            const stdcs = this.reorderItems(item['stdcs'])
            const stdc_key = stdcs.join(', ')

            if (!data.hasOwnProperty(stdc_key)) {
              data[stdc_key] = { items: [], ids: item['stdcs'], name: stdc_key }
            }

            data[stdc_key].items.push(item)
            break
        }
      })

      if (this.codelist.hasOwnProperty(this.groupBy)) {
        this.codelist[this.groupBy].forEach((item) => {
          if (this.groupBy === 'state') {
            if (item.id !== 'IDEA') {
              const key = ContentService.getStateOrder(item.id)
              if (!data.hasOwnProperty(key)) {
                data[key] = { items: [], ids: [item.id], name: item.value }
              }
            }
          }
        })
      }

      if (this.groupBy === 'channel') {
        this.codelist.channels.forEach((channel) => {
          if (!data.hasOwnProperty(channel.id)) {
            data[channel.id] = {
              items: [],
              ids: [channel.id],
              name: channel.label,
            }
          }
        })
      }

      // Add options from codelist
      if (this.groupBy === 'tags') {
        this.codelist.tags.forEach((tag) => {
          if (!data.hasOwnProperty(tag.value)) {
            data[tag.value] = { items: [], ids: [tag.id], name: tag.label }
          }
        })
      }

      if (this.groupBy === 'products') {
        this.codelist.products.forEach((product) => {
          if (!data.hasOwnProperty(product.value)) {
            data[product.value] = {
              items: [],
              ids: [product.id],
              name: product.label,
            }
          }
        })
      }

      if (this.groupBy === 'personas') {
        this.codelist.personas.forEach((persona) => {
          if (!data.hasOwnProperty(persona.value)) {
            data[persona.value] = {
              items: [],
              ids: [persona.id],
              name: persona.label,
            }
          }
        })
      }

      if (this.groupBy === 'owner') {
        this.codelist.users.forEach((user) => {
          if (!data.hasOwnProperty(user.id)) {
            data[user.id] = { items: [], ids: [user.id], name: user.label }
          }
        })
      }

      if (
        this.groupBy === 'products' ||
        this.groupBy === 'personas' ||
        this.groupBy === 'tags' ||
        this.groupBy === 'date' ||
        this.groupBy === 'channel' ||
        this.groupBy === 'owner' ||
        this.groupBy === 'stdcs'
      ) {
        let index = 0
        let orderedData = {}

        for (const key in data) {
          index++
          const item = data[key]
          const order_key = ContentService.getItemsOrder(
            item.ids.length,
            item.items.length,
            index,
            item.name
          )
          orderedData[order_key] = item
        }

        this.data = orderedData
      } else {
        this.data = data
      }

      function compare(a, b) {
        if (new Date(a.date).getTime() < new Date(b.date).getTime()) {
          return -1
        }
        if (new Date(a.date).getTime() > new Date().getTime()) {
          return 1
        }
        return 0
      }

      for (const key in this.data) {
        this.data[key].items.sort(compare)
      }

      if (
        this.groupBy === 'products' ||
        this.groupBy === 'personas' ||
        this.groupBy === 'tags' ||
        this.groupBy === 'channel' ||
        this.groupBy === 'owner'
        )
      {
        const result = [];
        for (const key in this.data) {
          result.push(this.data[key]);
        }

        result.sort(function(a, b) {
          return a.name.localeCompare(b.name);
        });

        this.data = result;
      }

    },
    filterContents(value) {
      switch (this.groupBy) {
        case 'state':
          return this.contents.filter((item) => item[this.groupBy] === value)

        case 'channel':
          return this.contents.filter(
            (item) => item[this.groupBy].name === value
          )

        case 'date':
          return this.contents.filter(
            (item) => moment(item[this.groupBy]).format('D.M.YYYY') === value
          )

        case 'owner':
          return this.contents.filter((item) => item.owner.name === value)

        case 'stdcs':
          return this.contents.filter((x) =>
            x[this.groupBy].some((g) => g.includes(value))
          )

        case 'tags':
        case 'personas':
        case 'products':
          return this.contents.filter((content) => {
            return content[this.groupBy].filter((obj) => {
              return obj.name === value
            })
          })
      }
    },
    selectToday() {
      let _dateFrom = new Date(new Date().setHours(0, 0, 0, 0))
      this.$store.commit('setDateRangeFrom', _dateFrom)
      this.$store.commit('setDate', _dateFrom)

      const _dateTo = new Date(new Date().setHours(23, 59, 59, 0))
      this.$store.commit('setDateRangeTo', _dateTo)
    },
    datePrev() {
      this.loaded = false
      let days = this.diffDays * -1
      if (days === 0) {
        days = -1
      }
      this.changeDate(days)
    },
    dateNext() {
      this.loaded = false
      let days = this.diffDays
      if (days === 0) {
        days = 1
      }
      this.changeDate(days)
    },
    changeDate(day) {
      if (
        DateService.checkIfDateRangeIsMonth(
          this.dateRangeFrom,
          this.dateRangeTo
        )
      ) {
        let duration = DateService.getMonthsDuration(
          this.dateRangeFrom,
          this.dateRangeTo
        )

        if (day <= 0) {
          duration *= -1
        }

        const _dateFrom = DateService.getMonthShiftStartDate(
          this.dateRangeFrom,
          duration
        )
        const _dateTo = DateService.getMonthShiftEndDate(
          this.dateRangeTo,
          duration
        )

        this.$store.commit('setDateRangeFrom', _dateFrom)
        this.$store.commit('setDate', _dateFrom)
        this.$store.commit('setDateRangeTo', _dateTo)

        return
      }

      this.loaded = false
      const _dateFrom = new Date(this.dateRangeFrom)
      _dateFrom.setDate(_dateFrom.getDate() + day)
      this.$store.commit('setDateRangeFrom', _dateFrom)
      this.$store.commit('setDate', _dateFrom)

      const _dateTo = new Date(this.dateRangeTo)
      _dateTo.setDate(_dateTo.getDate() + day)
      _dateTo.setHours(23, 59, 59, 0)
      this.$store.commit('setDateRangeTo', _dateTo)
    },
    actionCollapseAll() {
      this.$store.commit('setListCollapsedAll', true)
      this.cardsStatus = {}

      for (let key in this.data) {
        let item = this.data[key]

        this.cardsStatus[item.name] = false
      }

      this.prepareContentGroup()
    },
    actionExpandAll() {
      this.$store.commit('setListCollapsedAll', false)
      this.cardsStatus = {}

      for (let key in this.data) {
        let item = this.data[key]

        this.cardsStatus[item.name] = true
      }

      this.prepareContentGroup()
    },
    dropToContainer(idea_id) {
      const contents = this.contents.filter((item) => item.id === idea_id)
      this.updateContentState(contents[0], 'IDEA')
    },
  },
  watch: {
    selectedCompany() {
      if (this.selectedCompany) {
        this.billingOverdue = this.selectedCompany.billingOverdue
      }
    },
    refreshContent() {
      this.$apollo.queries.companies.refetch()

      this.prepareContentGroup()
      this.generateContents()
    },
    companies() {
      if (this.companies.length > 0) {
        this.loaded = true
      }

      this.generateContents()
      this.prepareContentGroup()
    },
    groupBy() {
      this.showStatusCard = false
      this.prepareContentGroup()

      setTimeout(() => {
        this.checkCollapseAll()
        this.showStatusCard = true
      }, 100)
    },
    codelist() {
      this.prepareContentGroup()
    },
    contentFilter() {
      this.generateContents()
      this.prepareContentGroup()
    },
    contentOwner() {
      this.generateContents()
      this.prepareContentGroup()
    },
  },
}
</script>
