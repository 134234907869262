<template>
    <div 
        class="company-avatar"
        :class="{'company-avatar--small': size === 'small'}"
        :style="{backgroundColor: color}"
        >
        <img v-if="imageUrl !== '' && imageUrl !== 'empty'" :src="imageUrl" />
        <span v-if="imageUrl === '' || imageUrl === 'empty'">        
            {{ name }}
        </span>
    </div>
</template>

<script>
import { ColorService } from "@/services/ColorService.js";
import { URL } from "@/constants/URLConstant.js"
import api from "@/services/rest-api/restapi"

export default {
    props: {
        company: {
            type: Object
        },
        size: {
            type: String,
            default: 'normal'
        }
    },
    computed: {
        name() {
            if(this.company != null) {

                let name = this.company.name.split(" ");
                if( name.length === 1 ) {
                    return name[0].charAt(0);
                } else {
                    return name[0].charAt(0) + name[1].charAt(0);
                }
            }
        },
        color() {
            if(this.imageUrl !== '' && this.imageUrl !== 'empty') {
                return 'transparent';
            }

            if(this.company != null) {
                return ColorService.computedColor(this.company.name);
            }            
        },
        mainUrl() {
            return URL;
        },
        accessToken() {
            return localStorage.getItem('access_token')
        },
        refreshCompanyLogo() {
            return this.$store.getters.getRefreshCompanyLogo;
        },
    },
    created() {
        this.loadImage()
    },
    data() {
        return {
            imageUrl: ''
        }
    },
    methods: {
        async loadImage() {

            if (!this.company.id) {
                this.imageUrl = ''
                return
            }

            const storedCompanyLogo = this.$store.getters.getCompanyLogo( this.company.id )
            if (storedCompanyLogo) {
                this.imageUrl = storedCompanyLogo.logo
                return
            }
            
            try {
                console.log("company avatar rest api request")
                const response = await api.get(`/api/images/company/${this.company.id}`, {
                    responseType: 'blob'
                })            

                const data = await response.data

                if(data.size > 0) {
                    const urlCreator = window.URL || window.webkitURL
                    this.imageUrl = urlCreator.createObjectURL(data)
                    this.$store.commit("setCompanyLogo", { companyId: this.company.id, logo: this.imageUrl })
                } else {
                    this.imageUrl = ''
                }

            } catch(e) {
                this.imageUrl = ''
            }
        }
    },
    watch: {
        company() {
            this.loadImage()
        },
        refreshCompanyLogo() {
            this.$store.commit("clearCompanyLogos")
            this.imageUrl = ''
            this.loadImage()
        }
    }
}
</script>
