<template>
    <Modal @closeModal="closeModal">
        <div class="logout" style="width: 320px;">
            <h2 class="logout__title">{{ $t("4_3_1_title") }}</h2>

            <div class="modal-description">
                <ol>
                    <li>{{ $t("4_3_1_description_step_1") }}</li>
                    <li>{{ $t("4_3_1_description_step_2") }}</li>
                    <li>{{ $t("4_3_1_description_step_3") }}</li>
                </ol>
            </div>        

            <div class="modal-options">
                <Button style="width: 100%;" @click="closeModal()" :type="'secondary'">{{ $t('4_3_1_btn_confirm') }}</Button>
            </div>

        </div>
    </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import Button from "@/components/button/Button.vue";

export default {
    components: {
        Modal,
        Button,
    },
    methods: {
        closeModal() {
            this.$emit("closeModal");
        }
    }
}
</script>
