<template>
  <div>
    <div
      v-if="companies.length === 0 && !selectedCompany"
      class="button-add-company"
    >
      <button @click="addCompany()" class="button-add-company__btn">
        <span>
          <IcoPlus />
        </span>
        {{ $t('5_2_new_company') }}
      </button>
    </div>

    <div
      v-else
      class="company-select"
      :class="{ 'is-open': isOpen }"
      v-click-outside="hide"
    >
      <button class="company-select__btn" @click="toggle">
        <CompanyAvatar :company="selected" />

        <span class="company-select__btn__content">
          {{ selected.name ? selected.name : $t('5_2_select_company') }}
        </span>

        <span class="company-select__controll">
          <IcoDown />
        </span>
      </button>
      <div v-if="isOpen" class="company-select__data">
        <div class="company-select__wrapper">
          <div class="company-select__total">
            {{ $t('5_2_companies_count') }} ({{ companies.length }})
          </div>
          <form
            class="company-select__search"
            autocomplete="off"
            @submit.prevent
          >
            <input
              id="company-search"
              type="text"
              name="company-search"
              ref="company_search"
              :placeholder="$t('5_2_placeholder_search')"
              v-model="search"
              autocomplete="off"
            />
            <button @click.prevent>
              <IcoSearch />
            </button>
          </form>
        </div>

        <div v-if="searchCompany.length" class="company-select__list">
          <div
            class="company-select__item clearfix"
            v-for="(item, index) in searchCompany"
            :key="index"
            @click="select(item)"
          >
            <div class="company-select__item__logo">
              <CompanyAvatar :company="item" />
            </div>
            <span class="company-select__item__name">
              {{ item.name }}
              <IcoOverdue
                v-if="item.billingOverdue"
                class="company-select__item__name__ico"
              />
            </span>
          </div>
        </div>

        <div
          class="company-select__wrapper"
          v-if="searchCompany.length === 0 && search !== ''"
        >
          <div class="company-select__no-result">
            <p>{{ $t('5_2_no_result_p1') }}</p>
            <p>{{ $t('5_2_no_result_p2') }}</p>
          </div>
        </div>

        <div @click="showAllCompanies()" class="company-select__all">
          <IcoMore />
          {{ $t('5_2_btn_show_all') }}
        </div>

        <div class="company-select__create">
          <div>
            <button @click="addCompany()" class="company-select__create__btn">
              <span>
                <IcoPlus />
              </span>
              {{ $t('5_2_btn_new_company') }}
              <div
                v-if="me.invitesCount > 0"
                class="company-select__notification"
              >
                <div v-if="me.invitesCount < 10">{{ me.invitesCount }}</div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import ClickOutside from 'vue-click-outside'

import CompanyAvatar from '@/components/CompanyAvatar.vue'
import IcoDown from '@/components/svg/IcoDown.vue'
import IcoPlus from '@/components/svg/IcoPlus.vue'
import IcoMore from '@/components/svg/IcoMore.vue'
import IcoSearch from '@/components/svg/IcoSearch.vue'
import IcoOverdue from '@/components/svg/IcoOverdue.vue'

export default {
  components: {
    IcoDown,
    IcoPlus,
    IcoMore,
    IcoSearch,
    CompanyAvatar,
    IcoOverdue,
  },
  directives: {
    ClickOutside,
  },
  props: {
    size: {
      type: String,
      default: 'normal',
    },
  },
  computed: {
    searchCompany() {
      if (this.search === '') {
        return this.companies.slice(0, 5)
      }

      let filteredCompanies = this.companies.filter((item) => {
        return (
          item.name
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036F]/g, '')
            .indexOf(
              this.search
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036F]/g, '')
            ) !== -1
        )
      })

      return filteredCompanies.slice(0, 5)
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
    me() {
      return this.$store.getters.getMe
    },
    role() {
      return this.$store.getters.getMeCompanyRole
    },
  },
  created() {
    if (this.selectedCompany && this.selectedCompany.id !== this.selected.id) {
      this.selected = this.selectedCompany
    }
  },
  apollo: {
    companies: {
      query: gql`
        query getCompanies {
          companies {
            name
            id
            zoneId
            billingDate
            billingOverdue
            aiTokenAvailable
          }
        }
      `,
      error(error) {
        // this.$store.commit("setCompany", {});
      },
    },
  },
  data() {
    return {
      company: [],
      companies: [],
      isOpen: false,
      selected: '',
      search: '',
      filteredCompanies: [],
    }
  },
  methods: {
    addCompany() {
      this.$router.push({ name: 'AddCompany' })
    },
    showAllCompanies() {
      if (this.$route.name === 'Companies') {
        this.isOpen = false
      } else {
        this.$router.push({ name: 'Companies' })
      }
    },
    toggle() {
      this.isOpen = !this.isOpen

      if (this.isOpen) {
        this.$nextTick(() => {
          this.search = ''
          this.$refs.company_search.focus()
        })
      }
    },
    hide() {
      this.isOpen = false
    },
    select(item) {
      this.selected = item
      this.isOpen = false
      this.$store.commit('setCompany', item)
      this.$store.commit('setRefreshToken')

      if (this.$route.name === 'Company') {
        this.$router.push({ name: 'Company', params: { id: item.id } })
      }
    },
  },
  watch: {
    companies() {
      this.$store.commit('setCompanies', this.companies)
    },
    selectedCompany() {
      if (this.selected.id !== this.selectedCompany.id) {
        this.selected = this.selectedCompany
      }
    },
  },
}
</script>
