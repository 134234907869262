<template>
  <Modal @closeModal="closeModal">
    <div class="logout" style="width: 460px">
      <h2 class="logout__title">{{ $t("7_1_1_title") }}</h2>

      <div class="modal-description">
        <p>{{ $t("7_1_1_description") }}</p>
        <p class="modal-description__paragraph"><strong>{{ $t("7_1_1_company_no") }}:</strong> <span class="qty-secondary">{{ companiesJoinedCount }}</span></p>
        <p class="modal-description__paragraph"><strong>{{ $t("7_1_1_cards_no") }}:</strong> <span class="qty-secondary">{{ contentsPlannedCount }}</span></p>
      </div>

      <div class="line">
        <FormGroup
          :label="$t('7_1_1_label_password')"
          :required="true"
          :validationError="invalidCurrentPassword"
        >
          <input
            class="form-group__input"
            type="password"
            placeholder=""
            v-model="password"
            @input="invalidCurrentPassword = false"
            :maxlength="length.short"
          />
        </FormGroup>
      </div>

      <div style="margin-top: 10px">
        <Link @click="forgotPassword()">{{
          $t("7_1_1_link_forgot_password")
        }}</Link>
      </div>

      <div class="modal-options">
        <ul>          
          <li>
            <Button
              style="width: 100%"
              @click="closeModal()"
              :type="'secondary'"
              >{{ $t("7_1_1_btn_back") }}</Button
            >
          </li>
          <li>
            <Button
              :disabled="disabledDeleteAction"
              style="width: 100%"
              @click="deleteUser()"
              :type="'delete'"
              >{{ $t("7_1_1_btn_delete") }}</Button
            >
          </li>
        </ul>
      </div>
    </div>
  </Modal>
</template>

<script>
import textLength from "@/constants/TextLengthConstant.js";
import ME_DELETE from "@/graphql/MeDelete.gql";
import Modal from "@/components/Modal.vue";
import FormGroup from "@/components/FormGroup.vue";
import Link from "@/components/design/Link.vue";

import Button from "@/components/button/Button.vue";

export default {
  components: {
    Modal,
    FormGroup,
    Button,
    Link,
  },
  props: {
    companiesJoinedCount: {
      type: Number,
      default: 0,
    },
    contentsPlannedCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    length() {
      return textLength;
    },
  },
  data() {
    return {
      disabledDeleteAction: true,
      error_form: [],
      password: "",
      invalidCurrentPassword: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    deleteUser() {
      this.invalidCurrentPassword = false;

      if (this.password === "") {
        this.invalidCurrentPassword = true;

        this.$notify({
          title: this.$t("7_1_toaster_error_title_current_password_required"),
          text: this.$t(
            "7_1_toaster_error_description_current_password_required"
          ),
          type: "error",
          duration: 5000,
        });

        return;
      }

      try {
        this.$apollo
          .mutate({
            mutation: ME_DELETE,
            variables: {
              password: this.password,
            },
            update: (store, { data: { updateContent } }) => {},
          })
          .then((data) => {
            this.$notify({
              title: this.$t("7_1_toaster_success_title_user_deleted"),
              text: this.$t("7_1_toaster_success_description_user_deleted"),
              type: "success",
              duration: 5000,
            });

            this.password = "";
            this.$router.push({ name: "Login" });
            this.$emit("closeModal");
          })
          .catch((error) => {
            this.invalidCurrentPassword = true;

            this.$notify({
              title: this.$t("7_1_toaster_error_title_user_deleted"),
              text: this.$t("global_toaster_error_description"),
              type: "error",
              duration: 5000,
            });
          });
      } catch (e) {
        this.invalidCurrentPassword = true;

        this.$notify({
          title: this.$t("7_1_toaster_error_title_user_deleted"),
          text: this.$t("global_toaster_error_description"),
          type: "error",
          duration: 5000,
        });
      }
    },
    forgotPassword() {
      this.$emit("openForgotPasswordAndCloseModal");
    },
  },
  watch: {
    password() {
      if (this.password == "") {
        this.disabledDeleteAction = true;
      } else {
        this.disabledDeleteAction = false;
      }
    },
  },
};
</script>
