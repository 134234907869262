<template>
    <div class="linked-accounts">
        <div class="linked-accounts__item">
            <div class="linked-accounts__company">
                <div class="linked-accounts__logo">
                    <img src="@/assets/images/google.png" alt="">
                </div>
                <div class="linked-accounts__name">
                    Google
                </div>    
            </div>

            <div class="linked-accounts__controll">
                <button class="active">{{ $t("7_1_connect_linked_account") }}</button>
            </div>
        </div>

        <div class="linked-accounts__item">
            <div class="linked-accounts__company">
                <div class="linked-accounts__logo">
                    <img src="@/assets/images/facebook.png" alt="">
                </div>
                <div class="linked-accounts__name">
                    Facebook
                </div>    
            </div>

            <div class="linked-accounts__controll">
                <button class="inactive">{{ $t("7_1_disconnect_linked_account") }} <IcoCross /></button>
            </div>
        </div>    
    </div>
</template>

<script>
    import IcoCross from '@/components/svg/small/IcoCross.vue';

    export default ({
        components: {
            IcoCross,
        },        
        props: {
            
        },
    });
</script>

<style>
.linked-accounts__controll .inactive svg path{
    stroke: #C5C5C5;
}
</style>

<style scoped>
.linked-accounts__item{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #C5C5C5;
    padding: 11px 0;
}

.linked-accounts__company{
    display: flex;
    gap: 15px;
}

.linked-accounts__logo{
    width: 18px;
    height: 18px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.linked-accounts__name{
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.2px;
    color: #17252A;
}
.linked-accounts__controll{

}

.linked-accounts__controll button{
    border: none;
    background: transparent;
    cursor: pointer;
}

.linked-accounts__controll button.inactive {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.2px;
    color: #C5C5C5;
}

.linked-accounts__controll button.active {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.2px;
    color: #2CE0C1;
}
</style>