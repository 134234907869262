<template>
    <div v-if="items.length > 0" class="errors">
        <ul>
            <li v-for="(item, index) in items" :key="index">{{ item }}</li>
        </ul>
    </div>
</template>

<script>

export default {
    props: {
        items: {
            type: Array,
            default() {
                return []
            }
        }
    }
}
</script>

<style scoped>
    ul li{
        color: #F45050;
    }
</style>