<template>
  <div
    @click="selectCompany()"
    class="company-item"
    :class="{ 'is-active': isActive }"
  >
    <div class="company-item__data">
      <div class="company-item__logo">
        <CompanyAvatar :company="company" />
      </div>
      <div class="company-item__name">
        <div class="company-item__name__title">
          {{ company.name }}
          <span
            v-if="company.billingOverdue"
            class="comapny-item__name__title__overdue"
            ><IcoOverdue /> {{ $t('6_2_select_subscription') }}</span
          >
        </div>
        <div class="company-item__name__description">
          <span v-if="company.description">{{ company.description }}</span>
        </div>
      </div>
    </div>
    <div v-if="role !== 'READ_ONLY'" class="company-item__users">
      <div class="company-item__users__list">
        <div
          v-if="user1"
          class="company-item__users__item company-item__users__item--1"
        >
          <Avatar :user="user1" :name="user1.name" :size="'extrasmall'" />
        </div>
        <div
          v-if="user2"
          class="company-item__users__item company-item__users__item--2"
        >
          <Avatar :user="user2" :name="user2.name" :size="'extrasmall'" />
        </div>
        <div
          v-if="user3"
          class="company-item__users__item company-item__users__item--3"
        >
          <Avatar :user="user3" :name="user3.name" :size="'extrasmall'" />
        </div>
        <div
          v-if="user4"
          class="company-item__users__item company-item__users__item--4"
        >
          <Avatar :user="user4" :name="user4.name" :size="'extrasmall'" />
        </div>
      </div>

      <div v-if="showMore > 0" class="company-item__users__next">
        +{{ showMore }}
      </div>
    </div>
  </div>
</template>

<script>
import CompanyAvatar from '@/components/CompanyAvatar.vue'
import Avatar from '@/components/Avatar.vue'
import IcoOverdue from '@/components/svg/IcoOverdue.vue'

export default {
  props: {
    company: {
      type: Object,
      require: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    showMore() {
      if (this.company.users) {
        if (this.company.users.length - 4 > 0) {
          return this.company.users.length - 4
        }
      }

      return 0
    },
    user1() {
      if (this.company.users) {
        if (this.company.users[0]) {
          return this.company.users[0]
        }
      }
      return false
    },
    user2() {
      if (this.company.users) {
        if (this.company.users[1]) {
          return this.company.users[1]
        }
      }
      return false
    },
    user3() {
      if (this.company.users) {
        if (this.company.users[2]) {
          return this.company.users[2]
        }
      }
      return false
    },
    user4() {
      if (this.company.users) {
        if (this.company.users[3]) {
          return this.company.users[3]
        }
      }
      return false
    },
  },
  components: {
    CompanyAvatar,
    Avatar,
    IcoOverdue,
  },
  methods: {
    selectCompany() {
      this.$store.commit('setCompany', this.company)
      this.$router.push({ name: 'Calendar' })
    },
  },
}
</script>

<style scoped>
.company-item {
  display: flex;
  justify-content: space-between;
  transition: all 0.2s linear;
  cursor: pointer;
  margin-top: 15px;
  box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  background: #ffffff;
  padding: 10px 15px;
  color: #17252a;
  text-decoration: none;
}

.company-item:hover {
  box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.5);
}

.company-item__data {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-right: 60px;
}

.company-item__name {
}

.company-item__name__title {
  display: flex;
  align-items: center;
  gap: 15px;
  transition: all 0.2s linear;
  color: #17252a;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 1;
  font-family: Nunito;
}

.comapny-item__name__title__overdue {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #f45050;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  font-family: 'Nunito';
}

.company-item__name__description {
  margin-top: 2px;
  color: #888f91;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 143%;
  font-family: Nunito;
  letter-spacing: -0.4px;
}

/*
.company-item:hover .company-item__name__title{
    color: #2CE0C1;
}
*/

.company-item.is-active .company-item__name__title {
  color: #2ce0c1;
}

.company-item__users {
  display: flex;
  position: relative;
  align-items: center;
}

.company-item__users__list {
  display: flex;
  position: absolute;
  left: -52px;
  align-items: center;
}

.company-item__users__item {
  display: inline-block;
  position: relative;
  top: 0;
  border-radius: 50%;
  width: 22px;
  height: 22px;
}

.company-item__users__item--1 {
  left: 0px;
  z-index: 14;
}

.company-item__users__item--2 {
  left: -12px;
  z-index: 13;
}

.company-item__users__item--3 {
  left: -24px;
  z-index: 12;
}

.company-item__users__item--4 {
  left: -36px;
  z-index: 11;
}

.company-item__users__next {
  color: #aeb3b5;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  font-family: Nunito;
}
</style>
