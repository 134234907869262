<template>
  <div class="language-select">
    <button @click="toggle" class="language-select__active">
      <div class="language-select__active__wrapper">
        <div>
          <span
            v-if="$i18n.locale === 'en'"
            class="
              language-select__active__flag language-select__active__flag--en
            "
          ></span>
          <span
            v-if="$i18n.locale === 'cs'"
            class="
              language-select__active__flag language-select__active__flag--cs
            "
          ></span>

          <span v-if="$i18n.locale === 'en'">
            {{ $t('5_3_2_lang_english') }}
          </span>
          <span v-if="$i18n.locale === 'cs'">
            {{ $t('5_3_2_lang_czech') }}
          </span>
        </div>

        <span
          class="language-select__active__ico"
          :class="{
            'is-open': openSubmenu,
          }"
        >
          <IcoNext />
        </span>
      </div>
    </button>
    <div v-if="openSubmenu" class="language-select__data">
      <ul>
        <li>
          <button
            :class="{ 'is-active': $i18n.locale === 'en' }"
            @click="setLanguage('en')"
          >
            <span class="language-select__ico language-select__ico--en"></span>
            {{ $t('5_3_2_lang_english') }}
          </button>
        </li>
        <li>
          <button
            :class="{ 'is-active': $i18n.locale === 'cs' }"
            @click="setLanguage('cs')"
          >
            <span class="language-select__ico language-select__ico--cs"></span>
            {{ $t('5_3_2_lang_czech') }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import IcoNext from '@/components/svg/IcoNext.vue'

export default {
  components: {
    IcoNext,
  },
  data() {
    return {
      openSubmenu: false,
    }
  },
  methods: {
    toggle() {
      this.openSubmenu = !this.openSubmenu
    },
    setLanguage(lang) {
      this.$i18n.locale = lang
      if (lang === 'en') {
        this.$moment.locale('en-gb')
      } else {
        this.$moment.locale(lang)
      }

      localStorage.setItem('selectedLanguage', lang)
    },
  },
}
</script>
