<template>
    <Modal>
        this is modal window
    </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';

export default {
    components: {
        Modal
    },
    data() {
        return {
            title: '',
            message: '',
            resolvePromise: undefined,
            rejectPromise: undefined
        }
    }
}
</script>
