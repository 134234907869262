var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"sticky-header"},[_c('Menu'),_c('div',{staticClass:"wrapper wrapper--calendar-fixed"},[_c('DashboardHeader',{attrs:{"selectedDate":_vm.formattedRange,"type":'listView',"nameList":[]},on:{"selectToday":_vm.selectToday,"datePrev":_vm.datePrev,"dateNext":_vm.dateNext}})],1)],1),_c('div',{staticClass:"wrapper wrapper--calendar wrapper--top wrapper--list",class:{
      'days-to-expiration': this.selectedCompany && this.selectedCompany.showExpirationWarning,
    }},[_c('GlobalNotification'),_c('div',{staticClass:"print-only"},[_c('div',{staticClass:"c-flex"},[_c('LogoContesaurBlack'),_c('div',[_c('div',[(_vm.selectedCompany)?_c('strong',[_vm._v(_vm._s(_vm.selectedCompany.name))]):_vm._e()]),_c('strong',[_vm._v(_vm._s(_vm.formattedFullRange))])])],1)]),_c('div',{staticClass:"grid grid--full"},[_c('div',{staticClass:"grid-col grid-col--3"},[_c('div',{staticClass:"dashboard-card",class:{ 'dashboard-card--loading': _vm.loadingStatistics }},[_c('div',{staticClass:"dashboard-card__data"},[_c('h2',[_vm._v(_vm._s(_vm.$t('13_title_state')))]),_c('p',[_vm._v(_vm._s(_vm.$t('13_description_state')))]),(_vm.statesData)?_c('div',{staticClass:"dashboard-card__legend"},[_c('ul',_vm._l((_vm.statesData.legend),function(legendItem,index){return _c('li',{key:index},[_c('span',{staticClass:"percentage",style:({
                      'background-color':
                        _vm.statesData.datasets[0].backgroundColor[index],
                    })},[_vm._v(" "+_vm._s(legendItem.percentage)+" ")]),_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(legendItem.label)+" ")]),_c('span',{staticClass:"count"},[_vm._v(" "+_vm._s(legendItem.count)+" ")])])}),0)]):_vm._e()]),(_vm.statesData)?_c('div',{staticClass:"dashboard-card__graph"},[_c('DoughnutChart',{ref:"state_chart",attrs:{"width":200,"height":200,"chart-data":_vm.statesData,"options":_vm.options}}),(_vm.statesData)?_c('div',{staticClass:"dashboard-card__graph-center"},[_c('strong',[_vm._v(_vm._s(_vm.statesSum))]),_vm._v(_vm._s(_vm.$t('13_title_state'))+" ")]):_vm._e()],1):_vm._e()])]),_c('div',{staticClass:"grid-col grid-col--3"},[_c('div',{staticClass:"dashboard-card",class:{ 'dashboard-card--loading': _vm.loadingStatistics }},[_c('div',{staticClass:"dashboard-card__data"},[_c('h2',[_vm._v(_vm._s(_vm.$t('13_title_channels')))]),_c('p',[_vm._v(_vm._s(_vm.$t('13_description_channels')))]),(_vm.channelsData)?_c('div',{staticClass:"dashboard-card__legend"},[_c('ul',_vm._l((_vm.channelsData.legend),function(legendItem,index){return _c('li',{key:index},[_c('span',{staticClass:"percentage",style:({
                      'background-color':
                        _vm.channelsData.datasets[0].backgroundColor[index],
                    })},[_vm._v(" "+_vm._s(legendItem.percentage)+" ")]),_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(legendItem.label)+" ")]),_c('span',{staticClass:"count"},[_vm._v(" "+_vm._s(legendItem.count)+" ")])])}),0)]):_vm._e()]),(_vm.channelsData)?_c('div',{staticClass:"dashboard-card__graph"},[_c('DoughnutChart',{ref:"channel_chart",attrs:{"width":200,"height":200,"chart-data":_vm.channelsData,"options":_vm.options}}),(_vm.channelsData)?_c('div',{staticClass:"dashboard-card__graph-center"},[_c('strong',[_vm._v(_vm._s(_vm.channelsCount))]),_vm._v(_vm._s(_vm.$t('13_title_channels'))+" ")]):_vm._e()],1):_vm._e()])]),_c('div',{staticClass:"grid-col grid-col--3"},[_c('div',{staticClass:"dashboard-card",class:{ 'dashboard-card--loading': _vm.loadingStatistics }},[_c('div',{staticClass:"dashboard-card__data"},[_c('h2',[_vm._v(_vm._s(_vm.$t('13_title_products')))]),_c('p',[_vm._v(_vm._s(_vm.$t('13_description_products')))]),(_vm.productsData)?_c('div',{staticClass:"dashboard-card__legend"},[_c('ul',_vm._l((_vm.productsData.legend),function(legendItem,index){return _c('li',{key:index},[_c('span',{staticClass:"percentage",style:({
                      'background-color':
                        _vm.productsData.datasets[0].backgroundColor[index],
                    })},[_vm._v(" "+_vm._s(legendItem.percentage)+" ")]),_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(legendItem.label)+" ")]),_c('span',{staticClass:"count"},[_vm._v(" "+_vm._s(legendItem.count)+" ")])])}),0)]):_vm._e()]),(_vm.productsData)?_c('div',{staticClass:"dashboard-card__graph"},[_c('DoughnutChart',{ref:"skills_chart",attrs:{"width":200,"height":200,"chart-data":_vm.productsData,"options":_vm.options}}),_c('div',{staticClass:"dashboard-card__graph-center"},[_c('strong',[_vm._v(_vm._s(_vm.productsCount))]),_vm._v(_vm._s(_vm.$t('13_title_products'))+" ")])],1):_vm._e()])]),_c('div',{staticClass:"grid-col grid-col--3"},[_c('div',{staticClass:"dashboard-card",class:{ 'dashboard-card--loading': _vm.loadingStatistics }},[_c('div',{staticClass:"dashboard-card__data"},[_c('h2',[_vm._v(_vm._s(_vm.$t('13_title_personas')))]),_c('p',[_vm._v(_vm._s(_vm.$t('13_description_personas')))]),(_vm.personasData)?_c('div',{staticClass:"dashboard-card__legend"},[_c('ul',_vm._l((_vm.personasData.legend),function(legendItem,index){return _c('li',{key:index},[_c('span',{staticClass:"percentage",style:({
                      'background-color':
                        _vm.personasData.datasets[0].backgroundColor[index],
                    })},[_vm._v(" "+_vm._s(legendItem.percentage)+" ")]),_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(legendItem.label)+" ")]),_c('span',{staticClass:"count"},[_vm._v(" "+_vm._s(legendItem.count)+" ")])])}),0)]):_vm._e()]),(_vm.personasData)?_c('div',{staticClass:"dashboard-card__graph"},[_c('DoughnutChart',{ref:"skills_chart",attrs:{"width":200,"height":200,"chart-data":_vm.personasData,"options":_vm.options}}),(_vm.personasData)?_c('div',{staticClass:"dashboard-card__graph-center"},[_c('strong',[_vm._v(_vm._s(_vm.personsCount))]),_vm._v(_vm._s(_vm.$t('13_title_personas'))+" ")]):_vm._e()],1):_vm._e()])]),_c('div',{staticClass:"grid-col grid-col--3"},[_c('div',{staticClass:"dashboard-card",class:{ 'dashboard-card--loading': _vm.loadingStatistics }},[_c('div',{staticClass:"dashboard-card__data"},[_c('h2',[_vm._v(_vm._s(_vm.$t('13_title_stdc')))]),_c('p',[_vm._v(_vm._s(_vm.$t('13_description_stdc')))]),(_vm.stdcData)?_c('div',{staticClass:"\n                dashboard-card__legend dashboard-card__legend--capitalize\n              "},[_c('ul',_vm._l((_vm.stdcData.legend),function(legendItem,index){return _c('li',{key:index},[_c('span',{staticClass:"percentage",style:({
                      'background-color':
                        _vm.stdcData.datasets[0].backgroundColor[index],
                    })},[_vm._v(" "+_vm._s(legendItem.percentage)+" ")]),_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(legendItem.label)+" ")]),_c('span',{staticClass:"count"},[_vm._v(" "+_vm._s(legendItem.count)+" ")])])}),0)]):_vm._e()]),_c('div',{staticClass:"dashboard-card__graph"},[_c('DoughnutChart',{ref:"skills_chart",attrs:{"width":200,"height":200,"chart-data":_vm.stdcData,"options":_vm.options}}),(_vm.stdcData)?_c('div',{staticClass:"dashboard-card__graph-center"},[_c('strong',[_vm._v(_vm._s(_vm.stdcCount))]),_vm._v(_vm._s(_vm.$t('13_title_stdc'))+" ")]):_vm._e()],1)])]),_c('div',{staticClass:"grid-col grid-col--3"},[_c('div',{staticClass:"dashboard-card",class:{ 'dashboard-card--loading': _vm.loadingStatistics }},[_c('div',{staticClass:"dashboard-card__data"},[_c('h2',[_vm._v(_vm._s(_vm.$t('13_title_tags')))]),_c('p',[_vm._v(_vm._s(_vm.$t('13_description_tags')))]),(_vm.tagsData)?_c('div',{staticClass:"dashboard-card__legend"},[_c('ul',_vm._l((_vm.tagsData.legend),function(legendItem,index){return _c('li',{key:index},[_c('span',{staticClass:"percentage",style:({
                      'background-color':
                        _vm.tagsData.datasets[0].backgroundColor[index],
                    })},[_vm._v(" "+_vm._s(legendItem.percentage)+" ")]),_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(legendItem.label)+" ")]),_c('span',{staticClass:"count"},[_vm._v(" "+_vm._s(legendItem.count)+" ")])])}),0)]):_vm._e()]),_c('div',{staticClass:"dashboard-card__graph"},[_c('DoughnutChart',{ref:"tags_chart",attrs:{"width":200,"height":200,"chart-data":_vm.tagsData,"options":_vm.options}}),(_vm.tagsData)?_c('div',{staticClass:"dashboard-card__graph-center"},[_c('strong',[_vm._v(_vm._s(_vm.tagsCount))]),_vm._v(_vm._s(_vm.$t('13_title_tags'))+" ")]):_vm._e()],1)])]),(
          this.statistic &&
          this.statistic.tasksPerPerson[0] &&
          this.statistic.tasksPerPerson[0]._id !== 'Disabled'
        )?_c('div',{staticClass:"grid-col grid-col--3"},[_c('div',{staticClass:"dashboard-card",class:{ 'dashboard-card--loading': _vm.loadingStatistics }},[_c('div',{staticClass:"dashboard-card__data"},[_c('h2',[_vm._v(_vm._s(_vm.$t('13_title_tasks')))]),_c('p',[_vm._v(_vm._s(_vm.$t('13_description_tasks')))]),(_vm.usersData)?_c('div',{staticClass:"dashboard-card__legend"},[_c('ul',_vm._l((_vm.usersData.legend),function(legendItem,index){return _c('li',{key:index},[_c('span',{staticClass:"percentage",style:({
                      'background-color':
                        _vm.usersData.datasets[0].backgroundColor[index],
                    })},[_vm._v(" "+_vm._s(legendItem.percentage)+" ")]),_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(legendItem.label)+" ")]),_c('span',{staticClass:"count"},[_vm._v(" "+_vm._s(legendItem.count)+" ")])])}),0)]):_vm._e()]),_c('div',{staticClass:"dashboard-card__graph"},[_c('DoughnutChart',{ref:"skills_chart",attrs:{"width":200,"height":200,"chart-data":_vm.usersData,"options":_vm.options}}),(_vm.usersData)?_c('div',{staticClass:"dashboard-card__graph-center"},[_c('strong',[_vm._v(_vm._s(_vm.tasksSum))]),_vm._v(" "+_vm._s(_vm.$t('13_title_tasks'))+" ")]):_vm._e()],1)])]):_vm._e(),_c('div',{staticClass:"grid-col grid-col--3"},[_c('div',{staticClass:"dashboard-card",class:{ 'dashboard-card--loading': _vm.loadingStatistics }},[_c('div',{staticClass:"dashboard-card__data"},[_c('h2',[_vm._v(_vm._s(_vm.$t('13_title_review')))]),_c('p',[_vm._v(_vm._s(_vm.$t('13_description_review')))]),(_vm.reviewData)?_c('div',{staticClass:"\n                dashboard-card__legend dashboard-card__legend--capitalize\n              "},[_c('ul',_vm._l((_vm.reviewData.legend),function(legendItem,index){return _c('li',{key:index},[_c('span',{staticClass:"percentage",style:({
                      'background-color':
                        _vm.reviewData.datasets[0].backgroundColor[index],
                    })},[_vm._v(" "+_vm._s(legendItem.percentage)+" ")]),_c('span',{staticClass:"name"},[(legendItem.value)?_c('star-rating',{attrs:{"star-size":20,"increment":0.5,"read-only":true},model:{value:(legendItem.value),callback:function ($$v) {_vm.$set(legendItem, "value", $$v)},expression:"legendItem.value"}}):_c('span',[_vm._v(_vm._s(legendItem.label))])],1),_c('span',{staticClass:"count"},[_vm._v(" "+_vm._s(legendItem.count)+" ")])])}),0)]):_vm._e()]),_c('div',{staticClass:"dashboard-card__graph"},[_c('DoughnutChart',{ref:"skills_chart",attrs:{"width":200,"height":200,"chart-data":_vm.reviewData,"options":_vm.options}}),(_vm.reviewData)?_c('div',{staticClass:"dashboard-card__graph-center"},[_c('strong',[_vm._v(_vm._s(_vm.reviewsCount))]),_vm._v(_vm._s(_vm.$t('13_title_review'))+" ")]):_vm._e()],1)])]),(_vm.role !== 'READ_ONLY')?_c('div',{staticClass:"grid-col grid-col--3"},[_c('div',{staticClass:"dashboard-tips"},[_c('div',{staticClass:"dashboard-tips__background"},[_c('DashboardTips')],1),_c('div',{staticClass:"dashboard-tips__wrapper"},[_c('div',{staticClass:"dashboard-tips__data"},[_c('div',{staticClass:"dashboard-tips__title"},[_c('h2',[_vm._v(_vm._s(_vm.$t('13_title_tips')))])]),_c('div',{staticClass:"dashboard-tips__description"},[_c('p',[_vm._v(_vm._s(_vm.$t('13_description_tips')))])]),_c('div',{staticClass:"dashboard-tips__control"},[_c('button',{staticClass:"menu__create__btn",on:{"click":_vm.addContent}},[_c('IcoPlus'),_vm._v(" "+_vm._s(_vm.$t('5_1_menu_add_content'))+" ")],1)])])])])]):_vm._e(),_c('div',{staticClass:"grid-col grid-col--3"})])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }