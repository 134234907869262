<template>
    <div class="idea-week-placeholder"></div>
</template>

<script>
export default ({
    
})
</script>

<style scoped>
    .idea-week-placeholder{
        background: linear-gradient(270deg, #E7E9E9 0%, rgba(231, 233, 233, 0.5) 100%);
        background-size: 300% 300%;
        mix-blend-mode: normal;
        border-radius: 8px;
        width: 100%;
        height: 113px;
        animation: gradient 1s ease infinite;
        margin-top: 10px;
    }

    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }   
</style>