<template>
  <div
    class="status-card"
    @drop.prevent="drop($event)"
    @dragover.prevent="showActiveDrop('state-' + item)"
  >
    <div
      v-if="globalDragStatus"
      class="status-card__drop-area"
      :class="{
        'is-dropped': activeDropzone === 'state-' + item,
      }"
    >
      <span>{{ $t('8_4_drop_area') }}</span>
    </div>

    <div class="status-card__header" @click.stop="toggleCard">
      <div class="status-card__title">
        <div
          class="status-card__title__ico"
          :style="{
            backgroundColor: color,
          }"
        ></div>
        <div class="status-card__title__name">
          <span v-if="title">{{ title }}</span
          ><span v-else>{{ $t('8_4_empty_title_none') }}</span>
        </div>
      </div>
      <div class="status-card__controll">
        <div
          v-if="contents.length > 0"
          class="
            status-card__counter
            content-counter content-counter--status-card
          "
        >
          <IcoContent /> {{ contents.length }}
        </div>
        <ButtonAddContent
          v-if="role !== 'READ_ONLY'"
          :item="item"
          :type="type"
          :ids="ids"
        />
        <button class="status-card__toggle" :class="{ rotate: showCard }">
          <IcoToggle />
        </button>
      </div>
    </div>
    <div v-if="showCard && contents.length > 0" class="status-card__body">
      <IdeaStatusCard
        v-for="(content, index) in contents"
        :key="index"
        :id="content.key"
        :idea="content"
        :draggable="true"
        :showDate="showIdeaDate"
        @deleteContent="deleteContent"
      />
    </div>
  </div>
</template>

<script>
import { ColorService } from '@/services/ColorService.js'
import { StateService } from '@/services/StateService.js'

import IcoToggle from '@/components/svg/IcoToggle.vue'
import IcoContent from '@/components/svg/IcoContent.vue'

import IdeaStatusCard from '@/components/IdeaStatusCard.vue'
import IdeaStatusCardPreloader from '@/components/IdeaStatusCardPreloader.vue'
import Idea from '@/components/Idea.vue'
import ButtonAddContent from '@/components/button/ButtonAddContent.vue'

export default {
  components: {
    ButtonAddContent,
    IdeaStatusCard,
    IdeaStatusCardPreloader,
    Idea,
    IcoToggle,
    IcoContent,
  },
  props: {
    collapse: {
      type: Boolean,
      default: true,
    },
    contents: {
      type: Array,
      default: [],
    },
    item: {
      type: String,
      default: 'No name',
    },
    type: {
      type: String,
      required: true,
    },
    ids: {
      type: Array,
      default: [],
    },
    showIdeaDate: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    globalDragStatus() {
      return this.$store.getters.getGlobalDragStatus
    },
    activeDropzone() {
      return this.$store.getters.getActiveDropZone
    },
    color() {
      if (this.item === 'IDEA') {
        return '#D0D0D0'
      }

      if (this.type === 'state') {
        const state = StateService.getState(this.item)
        return state.color
      } else {
        return ColorService.computedColor(this.item)
      }
    },
    title() {
      if (this.type === 'state') {
        // const state = StateService.getState( this.item );
        return this.$t(this.item)
      } else {
        return this.item
      }
    },
  },
  data() {
    const month = new Date().getMonth()
    const year = new Date().getFullYear()

    return {
      showCard: true,
    }
  },
  methods: {
    deleteContent(content_id) {
      this.$emit('deleteContent', content_id)
    },
    toggleCard() {
      this.showCard = !this.showCard
      this.$emit('toggleCard', this.showCard, this.item)
    },
    showActiveDrop(key) {
      this.$store.commit('setActiveDropZone', key)
    },
    drop(e, status) {
      const idea_id = e.dataTransfer.getData('idea_id')

      const update = {
        id: idea_id,
        type: this.type,
        item: this.item,
        ids: this.ids,
      }

      this.$emit('update_content', update)
    },
  },
  watch: {
    collapse() {
      this.showCard = this.collapse
    },
  },
}
</script>
