import { DateService } from '@/services/DateService.js'
import { DateTime } from 'luxon'

function prepareContentForUpdate(content, data, zoneId) {
  let updated_content = {
    __typename: 'Content',
    id: content.id,
    name: content.name,
    state: content.state,
    date: content.date_original,
    eod: content.eod,
  }

  switch (data.type) {
    case 'owner':
      updated_content.ownerId = data.ids[0]
      break

    case 'state':
      updated_content.state = data.item
      break

    case 'date':
      const newDate = DateTime.fromISO(data.ids[0]).setZone(zoneId)
      let lxnDate = undefined

      if (content.eod) {
        lxnDate = DateTime.fromISO(content.date_original)
          .setZone(zoneId)
          .set({
            year: newDate.year,
            month: newDate.month,
            day: newDate.day,
            hour: 11,
            minute: 59,
            second: 59,
          })
          .toUTC()
          .set({
            hour: 11,
            minute: 59,
            second: 59,
          })
          .toISO()
      } else {
        lxnDate = DateTime.fromISO(content.date)
          .setZone(zoneId)
          .set({
            year: newDate.year,
            month: newDate.month,
            day: newDate.day,
          })
          .toUTC()
          .toISO()
      }

      updated_content.date = lxnDate
      break

    case 'channel':
      if (data.ids[0] === 0) {
        updated_content.channelId = null
      } else {
        updated_content.channelId = data.ids[0]
      }
      break

    case 'products':
      if (data.ids[0] === 0) {
        updated_content.productIds = null
      } else {
        updated_content.productIds = data.ids
      }

      break

    case 'personas':
      if (data.ids[0] === 0) {
        updated_content.personaIds = null
      } else {
        updated_content.personaIds = data.ids
      }

      break

    case 'stdcs':
      if (data.ids[0] === 0) {
        updated_content.stdcs = null
      } else {
        updated_content.stdcs = data.ids
      }

      break

    case 'tags':
      if (data.ids[0] === 0) {
        updated_content.tagIds = null
      } else {
        updated_content.tagIds = data.ids
      }

      break
  }

  return updated_content
}

function getStateOrder(state) {
  switch (state) {
    case 'IDEA':
      return 1
    case 'TODO':
      return 2
    case 'IN_PROGRESS':
      return 3
    case 'IN_REVIEW':
      return 4
    case 'APPROVED':
      return 5
    case 'PUBLISHED':
      return 6
  }
}

function getSTDCOrder(state) {
  switch (state) {
    case 'SEE':
      return 10
    case 'THINK':
      return 20
    case 'DO':
      return 30
    case 'CARE':
      return 40
  }
}

function getItemsOrder(group_count, items_count, index, name) {
  if (name === '') {
    return 999999900
  }

  let no = '1'
  for (let i = 0; i <= group_count; i++) {
    no += '0'
  }

  let ino = parseInt(no)

  return 999999999 - (ino * items_count + index)
}

function getItemsOrderByIds(content_no, items_no, index, name) {
  let multiplier = 0

  if (content_no > 1) {
    multiplier = 10
  } else if (content_no === 1) {
    if (items_no === 0) {
      multiplier = 1000
    } else if (name === '') {
      multiplier = 10000
    } else {
      multiplier = 1000 - items_no
    }
  } else {
    multiplier = 1000 + items_no
  }

  return multiplier - index
}

function updateContentState(content, state) {}

function stringCapitalize(string) {}

export const ContentService = {
  prepareContentForUpdate,
  getStateOrder,
  getSTDCOrder,
  updateContentState,
  getItemsOrderByIds,
  getItemsOrder,
}
