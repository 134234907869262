<template>
    <div 
        class="select-color-item"
        :style="{
            backgroundColor: state.color
        }"
        >

        <span
            :style="{
                color: state.textColor
            }"
        >{{ $t(state.id) }}</span>
    </div>
</template>


<script>

export default {
    props: {
        state: {
            type: Object,
            require: true
        }
    }    
}
</script>


<style scoped>
    .select-color-item{
        height: 30px;
        padding: 0 12px;

        display: flex;
        align-items: center;

        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        color: #888F91;
        mix-blend-mode: normal;

        background: #E7E9E9;
        border-radius: 4px;

        cursor: pointer;
    }

    .select-color-item span{
        position: relative;
        top: 1px;
        color: #fff;
        line-height: 1;
    }
</style>