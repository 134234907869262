<template>
  <div class="person">
    <div class="person__inner">
      <div class="person__data">
        <div class="person__avatar">
          <Avatar :size="'medium'" :name="person.name" :user="person" />
        </div>
        <div class="person__text">
          <div title="" v-tooltip="person.name" class="person__title">{{ person.name }}</div>
          <div title="" v-tooltip="person.position" class="person__position"> {{ person.position }}</div>
        </div>
      </div>
      <div class="person__controll">
        <div class="person__role">
          <PersonRole :role="person.role" @updateRole="updateRole" />
        </div>
        <div>
          <button
            v-if="!toDelete"
            @click="deletePerson()"
            class="person__delete"
          >
            <IcoClose />
          </button>
          <button
            v-if="toDelete"
            @click="confirmDeletePerson()"
            class="person__delete person__delete--confirm"
          >
            <IcoClose />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IcoClose from "@/components/svg/IcoClose.vue";
import Avatar from "@/components/Avatar.vue";
import PersonRole from "@/components/PersonRole.vue";

export default {
  components: {
    Avatar,
    IcoClose,
    PersonRole,
  },
  props: {
    person: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      toDelete: false,
    };
  },
  methods: {
    updateRole(role) {
      this.$emit("updateRole", role, this.person);
    },
    deletePerson() {
      this.toDelete = true;

      this.$notify({
        title: this.$t("toaster_info_title_confirm_delete"),
        text: this.$t("toaster_info_description_confirm_delete"),
        type: "info",
        duration: 5000,
      });

      setTimeout(() => {
        this.toDelete = false;
      }, 2000);
    },
    confirmDeletePerson() {
      this.$emit("delete", this.person);
      this.toDelete = false;
    },
  },
};
</script>

<style scoped>
.person {
  border-bottom: 1px solid #F5F5F5;
  box-sizing: border-box;
  border-radius: 0;
  padding: 10px 0;
  margin-bottom: 10px;
}

.person__inner {
  position: relative;
}

.person__data {
  max-width: 207px;
  overflow: hidden;
  display: flex;
  gap: 15px;
  align-items: center;
}

.person__avatar {
  width: 36px;
}

.person__text {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-top: -2px;
  align-items: flex-start;
}

.person__title {
  max-width: 157px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.2px;
  color: #17252a;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.person__position {
  max-width: 156px;
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.2px;
  color: #979797;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.person__controll {
  position: absolute;
  right: 0;
  top: 50%;
  display: flex;
  align-items: center;
  gap: 8px;
  transform: translate(0, -50%);
}

.person__delete {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  border-radius: 4px;
  cursor: pointer;
}

.person__delete svg {
  position: relative;
  top: 1px;
}

.person__delete--confirm {
  background: #f45050;
}
</style>

<style>
.person__delete svg path {
  stroke: #AEB3B5;
}

.person__delete--confirm svg path {
  stroke: #fff;
}
</style>
