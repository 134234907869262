<template>
  <div
    @click="editContent()"
    class="idea"
    :class="{
      'is-invisible': item.id === 0,
      'is-completed':
        item.state === 'PUBLISHED' &&
        item.luxonDate.toMillis() < todayTimeStamp,
      'is-overdue':
        item.state != 'PUBLISHED' && item.luxonDate.toMillis() < todayTimeStamp,
      'read-only': role === 'READ_ONLY',
    }"
    :draggable="draggable"
    @dragstart="dragStart"
    @dragend="dragEnd"
    @dragover.prevent
    v-bind:data-state="item.state"
    v-bind:data-date="item.dates"
  >
    <div v-if="item.id !== 0" class="idea__inner">
      <div
        class="idea__label"
        :style="{ color: getColor(computedChannelName) }"
      >
        {{ computedChannelName }}
      </div>

      <div class="idea__controll" v-click-outside="hideControll">
        <VDropdown
          :triggers="[]"
          @hide="closeData"
          :eagerMount="autoHide"
          :auto-hide="autoHide"
          :shown="showControll"
        >
          <button class="idea__controll__button" @click.stop="toggleControll()">
            <IcoKebab />
          </button>
          <template #popper>
            <div class="idea__controll__items">
              <ul>
                <li>
                  <button @click.stop="shareContent()">
                    <IcoShare /> {{ $t('8_1_idea_btn_share') }}
                  </button>
                </li>
                <li v-if="role !== 'READ_ONLY'">
                  <button @click.stop="duplicateContent()">
                    <IcoDuplicate /> {{ $t('8_1_idea_btn_duplicate') }}
                  </button>
                </li>
                <li v-if="role !== 'READ_ONLY'">
                  <button @click.stop="deleteContent()">
                    <IcoBin /> {{ $t('8_1_idea_btn_delete') }}
                  </button>
                </li>
              </ul>
            </div>
          </template>
        </VDropdown>
      </div>

      <div class="idea__title">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { ColorService } from '@/services/ColorService.js'
import { DateTime } from 'luxon'

import IcoKebab from '@/components/svg/IcoKebab.vue'
import IcoBin from '@/components/svg/IcoBin.vue'
import IcoDuplicate from '@/components/svg/IcoDuplicate.vue'
import IcoShare from '@/components/svg/IcoShare.vue'
import ClickOutside from 'vue-click-outside'

export default {
  components: {
    IcoKebab,
    IcoBin,
    IcoDuplicate,
    IcoShare,
  },
  directives: {
    ClickOutside,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    draggable: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    me() {
      return this.$store.getters.getMe
    },
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
    computedChannelName() {
      if (this.item.channel && this.item.channel.name) {
        return this.item.channel.name
      }

      return ''
    },
    slickUpdate() {
      return this.$store.getters.getSlickUpdate
    },
    todayTimeStamp() {
      const date = DateTime.now().setZone(this.selectedCompany.zoneId)
      return date.toMillis()
    },
  },
  data() {
    return {
      showControll: false,
      canBeClosed: false,
      autoHide: true,
    }
  },
  methods: {
    async shareContent() {
      this.showControll = false
      const link =
        '' +
        window.location.protocol +
        '//' +
        window.location.host +
        '/calendar/' +
        this.selectedCompany.id +
        '/' +
        this.item.id

      await navigator.clipboard.writeText(link)

      this.$notify({
        title: this.$t('share_content_title_copied_to_clipboard'),
        text: this.$t('share_content_description_copied_to_clipboard'),
        type: 'info',
        duration: 5000,
      })
    },
    duplicateContent() {
      this.showControll = false
      this.$store.commit('setDuplicateContentModalId', this.item.id)
    },
    deleteContent() {
      this.showControll = false
      this.$store.commit('setContentToDelete', this.item)
    },
    editContent() {
      this.$store.commit('setEditContentModalStatus', this.item)
    },
    getColor(name) {
      return ColorService.computedColor(name)
    },
    dragStart(e) {
      this.$store.commit('setGlobalDrag', true)
      const target = e.target

      e.dataTransfer.setData('idea_id', this.item.id)
      e.dataTransfer.setData('idea_date', this.item.date)
    },
    dragEnd(e) {
      this.$store.commit('setGlobalDrag', false)
      this.$store.commit('setActiveDropZone', '')
    },
    toggleControll() {
      this.canBeClosed = false
      this.showControll = !this.showControll

      if (this.type === 'idea') {
        if (this.showControll) {
          this.autoHide = false
          setTimeout(() => (this.autoHide = true), 300)
        }
      }
    },
    hideControll() {
      if (this.type === 'idea') {
        if (this.slickUpdate === this.item.id) {
          if (this.canBeClosed) {
            this.showControll = false
            this.$store.commit('setSlickUpdate', '')
          }
        }
      } else {
        this.showControll = false
      }
    },
    closeData() {
      this.showControl = false
    },
  },
}
</script>
