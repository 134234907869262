
<template>
    <div class="company-item-preloader"></div>
</template>

<script>

export default {
  
}
</script>

<style scoped>
.company-item-preloader{
    width: 100%;
    height: 50px;
    border-radius: 6px;
    background-color: #EDEEEF;
    margin-top: 15px;
    background: linear-gradient(-45deg, #EFF0F1, #E7E9E9, #DEDEDE);
	background-size: 300% 300%;
	animation: gradient 1s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

</style>