<template>
    <div
        class="tag-input-item"
        :class="{'noedit': !editable}"
        >
        <div v-if="edit === 'disabled'" class="tag-input-item__content">
            <div @click="selectItem()" class="tag-input-item__name" :style="{'color': color}">
                {{ item.label }}
            </div>
            <div v-if="editable" class="tag-input-item__controll">
                <button @click.stop="editItem"><IcoEdit /></button>
                <button @click.stop="shouldDeleteItem"><IcoBin /></button>
            </div>
        </div>
        <div v-if="edit === 'edit'" class="tag-input-item__edit">
            <input
                type="text"
                ref="item_edit"
                v-model="item.label"
                :maxlength="length.short"
                >
            <button :disabled="disabled" @click.stop="confirmItem"><IcoCheck /></button>
        </div>
        <div v-if="edit === 'delete'" class="tag-input-item__delete">
            <div class="tag-input-item__name">
                {{ item.label }}
            </div>
            <button :disabled="disabled" v-click-outside="cancelDelete" @click.stop="deleteItem"><IcoBin /></button>
        </div>
    </div>
</template>

<script>
import textLength from "@/constants/TextLengthConstant.js";
import ClickOutside from 'vue-click-outside';

import IcoBin from '@/components/svg/medium/IcoBin.vue';
import IcoEdit from '@/components/svg/IcoEdit.vue';
import IcoCheck from '@/components/svg/IcoCheck.vue';

export default {
    components: {
        IcoBin,
        IcoEdit,
        IcoCheck
    },
    directives: {
        ClickOutside
    },
    props: {
        editable: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            require: true,
        },
        color: {
            type: String,
            default: '#17252A',
        }
    },
    computed: {
        deletingItemId() {
            return this.$store.getters.getDeletingCodelistId;
        },
        length() {
            return textLength;
        },
    },
    data() {
        return {
            disabled: false,
            edit: "disabled",
        }
    },
    methods: {
        cancelDelete() {
            if(this.edit === 'delete') {
                this.edit = 'disabled'
            }
        },
        selectItem() {
            this.$emit('selectItem', this.item);
        },
        shouldDeleteItem() {
            this.disabled = false;
            this.edit = 'delete';

            this.$notify({
                title: this.$t("toaster_info_title_confirm_delete"),
                text: this.$t("toaster_info_description_confirm_delete"),
                type: "info",
                duration: 5000,
            });

            setTimeout(() => {
                this.edit = 'disabled';
            }, 2000);
        },
        editItem() {
            this.disabled = false;
            this.edit = 'edit';
        },
        confirmItem() {
            this.disabled = true;
            this.edit = 'disabled';
            this.$emit("updateItem", this.item);
        },
        deleteItem() {
            this.disabled = true;
            this.edit = 'disabled';
            this.$emit("deleteItem", this.item);
        }
    },
    watch: {
        editable() {
            this.edit = "disabled";
        }
    }
}
</script>

<style scoped>
.tag-input-item{
    display: block;
    padding: 4px 15px;
    min-height: 30px;
    line-height: 30px;
    cursor: pointer;
}

.tag-input-item.noedit:hover{
    background-color: #F6F6F7;
}

.tag-input-item__content{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.tag-input-item__name{
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #17252A;

    min-height: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 100%;
    min-width: 0;
    word-break: break-all;
}

.tag-input-item__edit{
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

.tag-input-item__delete{
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

.tag-input-item__edit input{
    width: 100%;
    background: #F6F6F7;
    border-radius: 4px;
    border: none;
    padding: 0 10px;
    height: 30px;
    line-height: 30px;

    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #17252A;
}

.tag-input-item__edit button{
    width: 30px;
    height: 30px;
    background: #2CE0C1;
    border-radius: 4px;
    border: none;
    color: #fff;
    line-height: 1;
    cursor: pointer;
}

.tag-input-item__delete button{
    width: 30px;
    height: 30px;
    background: #F45050;
    border-radius: 4px;
    border: none;
    color: #fff;
    line-height: 1;
    cursor: pointer;
}

.tag-input-item__controll{
    display: flex;
    align-items: center;
    min-width: 0;
    padding-top: 8px;
}

.tag-input-item__controll button{
    border: none;
    background: none;
    cursor: pointer;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 1;
    min-width: 0;
}

/*
.tag-input-item__controll button svg,
.tag-input-item__controll button svg *,
.tag-input-item__controll button svg path{
    stroke: #AEB3B5 !important;

}
*/

.tag-input-item__controll svg,
.tag-input-item__controll path{
    stroke: #AEB3B5;
}
</style>

<style>
.tag-input-item__controll svg,
.tag-input-item__controll path{
    stroke: #AEB3B5;
}

.tag-input-item__edit button svg path{
    stroke: #fff !important;
}

.tag-input-item__delete button svg path{
    stroke: #fff !important;
}
</style>
