<template>
  <div class="menu-renew-subscription">
    <div class="menu-renew-subscription__data">
      <IcoOverdue />
      <p>
        {{ $t('5_1_company_subscription_will_expire_in') }}
        <strong>{{ days }}</strong>
        <strong v-if="days === 1">{{ $t('5_1_company_expire_day_1') }}</strong>
        <strong v-else-if="days === 2">
          {{ $t('5_1_company_expire_day_2') }}
        </strong>
        <strong v-else-if="days === 3">
          {{ $t('5_1_company_expire_day_3') }}
        </strong>
        <strong v-else-if="days === 4">
          {{ $t('5_1_company_expire_day_4') }}
        </strong>
        <strong v-else>
          {{ $t('5_1_company_expire_days') }}
        </strong>
      </p>
    </div>
    <div class="menu-renew-subscription__control">
      <button
        @click="handleRenewSubscription()"
        class="menu-renew-subscription__btn"
      >
        {{ $t('5_1_renew_subscription') }}
      </button>
      <a style="display: none" target="_blank" ref="mailto" :href="mailToLink">
        {{ $t('5_1_renew_subscription') }}
      </a>
    </div>
  </div>
</template>

<script>
import IcoOverdue from '@/components/svg/IcoOverdue.vue'

export default {
  components: { IcoOverdue },
  props: {
    days: {
      type: Number,
      require: true,
    },
  },
  computed: {
    company() {
      return this.$store.getters.getSelectedCompany
    },
    mailToBody() {
      let _content = ''
      const content_cs =
        'Společnost: {companyName}, {companyId} \nZašlete mi fakturu na předplatné: Sólo Dino / Triasový Tým / Jurská Smečka / Obsahový T-Rex \nMám zájem o období: ROČNÍ / MĚSÍČNÍ \n\nSlevový kód:\nFakturační údaje: \n- Název firmy \n- Sídlo (ulice, město, psč, země) \n- IČO \n- DIČ'
      const content_en = `Company: {companyName}, {companyId} \nSend me an invoice for subscription: Solo Dino / Triassic Team / Jurassic Crew / Content T-Rex \nI am interested in this period: YEARLY / MONTHLY \n\nPromo code: \nBilling info: \n- Company name \n- Address (street, city, postal code, country) \n- ID \n- TID (VAT no.)`

      _content = content_cs

      if (this.$i18n.locale === 'en') {
        _content = content_en
      }

      _content = _content
        .replace('{companyName}', this.company.name)
        .replace('{companyId}', this.company.id)

      return encodeURIComponent(_content)
    },
    mailToLink() {
      const subject = this.$t('6_5_email_subject_request_subscription')

      return (
        'mailto:support@contesaur.com?subject=' +
        subject +
        '&body=' +
        this.mailToBody
      )
    },
  },
  methods: {
    handleRenewSubscription() {
      this.$refs.mailto.click()
    },
  },
}
</script>

<style scoped>
.menu-renew-subscription {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fdeded;
  padding: 12px 24px;
}

.menu-renew-subscription__data {
  display: flex;
  gap: 12px;
}

.menu-renew-subscription__data p {
  color: #f45050;
}

.menu-renew-subscription__btn {
  gap: 4px;
  cursor: pointer;
  border: 1px solid #17252a;
  border-radius: 6px;
  background-color: transparent;
  padding: 7px 19px 7px 19px;
  color: #17252a;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  font-family: Nunito;
  letter-spacing: 0;
  text-align: left;
}

.menu-renew-subscription__btn:hover {
  background-color: #17252a;
  color: #fff;
}
</style>
