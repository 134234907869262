<template>
    <div class="error-page">
      <div class="error-page__row">
        <div class="error-page__title">
          <SGV505 />
          <div class="error-page__title-content">500</div>
        </div>

        <div>
          <template v-if="$i18n.locale === 'cs'">
            <h2 style="margin-top: 40px;">Vypadá to, že se server rozhodl trucovat a proto neodpovídá. Chyba není na vašem přijímači – je na našem vysílači a už na tom pracujeme.</h2>
            <p>
              Dejte nám chvilku a mezitím si uvařte si kávu, prohoďte pár slov s kolegy, nebo si představte T-Rexe, jak se tlapinkama snaží něco opravit. A pak se vraťte se na <Link @click="$router.push({path: '/'})">hlavní stránku</Link>, nebo nám o problému napište <MailToSupport :subject="$t('11_1_2_support_mail_subject')" />.
            </p>
          </template>

          <template v-else>
            <h2>It looks like the server got grumpy and stopped responding. The problem is not on your end, it’s on ours, and we're working on it.</h2>
            <p>
              Give us a moment, and in the meantime make yourself a coffee, have a little chat with your colleagues, or imagine a T-Rex trying to fix hardware with those cute little paws. And then return to the <Link @click="$router.push({path: '/'})">main page</Link> or write us about the problem <MailToSupport :subject="$t('11_1_2_support_mail_subject')" />.
            </p>
          </template>
        </div>

      </div>
    </div>
</template>

<script>
import Link from '@/components/design/Link.vue';
import SGV505 from '@/components/svg/505.vue';
import MailToSupport from '@/components/MailToSupport.vue';

export default {
  components: {
    Link,
    SGV505,
    MailToSupport
  },
}
</script>
