<template>
  <div>
    <div class="controll">
      <div class="controll__item controll__item--left">
        <div class="selected-date selected-date--left">
          {{ $t('8_5_all_ideas') }}
        </div>
      </div>

      <div class="controll__item controll__item--right">
        <GroupByFilter
          v-if="showGroupBy"
          :disableOptions="['state', 'date']"
          type="ideas"
        />

        <div class="button-group">
          <ul class="button-group__items">
            <li
              class="button-group__item"
              :class="{ 'is-selected': contentOwner !== 'all' }"
            >
              <button
                @click="setContentOwner('me')"
                v-tooltip="$t('8_5_tooltip_me')"
                class="button-group__button button-group__button--ico"
              >
                <IcoUser /> {{ $t('8_5_btn_me') }}
              </button>
            </li>
            <li
              class="button-group__item"
              :class="{ 'is-selected': contentOwner === 'all' }"
            >
              <button
                @click="setContentOwner('all')"
                v-tooltip="$t('8_5_tooltip_all')"
                class="button-group__button button-group__button--ico"
              >
                <IcoUsers /> {{ $t('8_5_btn_all') }}
              </button>
            </li>
          </ul>
        </div>

        <CalendarFilter :nameList="nameList" />
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import moment from 'moment'
import ClickOutside from 'vue-click-outside'

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import vuescroll from 'vuescroll'

import IcoCalendar from '@/components/svg/IcoCalendar.vue'
import IcoIdea from '@/components/svg/IcoIdea.vue'
import IcoUser from '@/components/svg/IcoUser.vue'
import IcoUsers from '@/components/svg/IcoUsers.vue'
import IcoPrev from '@/components/svg/IcoPrev.vue'
import IcoNext from '@/components/svg/IcoNext.vue'
import IcoGroupBy from '@/components/svg/IcoGroupBy.vue'

import Idea from '@/components/Idea.vue'
import ButtonCalendar from '@/components/ButtonCalendar.vue'
import CalendarFilter from '@/components/CalendarFilter.vue'
import GroupByFilter from '@/components/GroupByFilter.vue'

export default {
  components: {
    VueSlickCarousel,
    IcoCalendar,
    IcoIdea,
    IcoUser,
    IcoUsers,
    IcoPrev,
    IcoNext,
    IcoGroupBy,
    ButtonCalendar,
    CalendarFilter,
    GroupByFilter,
    Idea,
    vuescroll,
  },
  directives: {
    ClickOutside,
  },
  created() {
    this._selectedDate = this.selectedDate
  },
  props: {
    selectedDate: {
      type: String,
    },
    showGroupBy: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'calendarMonthView',
    },
    nameList: {
      type: Array,
      default: [],
    },
  },
  computed: {
    globalDragStatus() {
      return this.$store.getters.getGlobalDragStatus
    },
    activeDropzone() {
      return this.$store.getters.getActiveDropZone
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
    contentOwner() {
      return this.$store.getters.getContentOwner
    },
  },
  data() {
    return {
      range: {
        start: new Date().setHours(0, 0, 0, 0),
        end: new Date().setHours(23, 59, 59, 0),
      },
      masks: {
        input: 'YYYY-MM-DD h:mm A',
      },
      _selectedDate: '',
      showCalendarData: false,
      currentDate: new Date(),
      scrollPanel: {
        initialScrollY: false,
        initialScrollX: false,
        scrollingX: true,
        scrollingY: true,
        speed: 300,
        easing: undefined,
        verticalNativeBarPos: 'right',
      },
    }
  },
  methods: {
    setContentOwner(type) {
      this.$store.commit('setContentOwner', type)
    },
    showActiveDrop(key) {
      this.$store.commit('setActiveDropZone', key)
    },
    toggleCalendarData() {
      this.showCalendarData = !this.showCalendarData
    },
    hideCalendarData() {
      if (this.showCalendarData == true) {
        this.showCalendarData = false
      }
    },
    drop(e) {
      this.$refs.slickone.destroy()

      const idea_id = e.dataTransfer.getData('idea_id')
      this.$emit('dropToContainer', idea_id)

      Vue.nextTick().then(this.$refs.slickone.create)
    },
  },
}
</script>
