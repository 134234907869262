<template>
    <div class="idea-preloader"></div>
</template>

<script>

export default ({
    data() {
        return {
                    
        }
    }
})
</script>

<style scoped>
.idea-preloader{
    width: 100%;
    height: 41px;
    background: linear-gradient(270deg, #E7E9E9 0%, rgba(231, 233, 233, 0.5) 100%);
    mix-blend-mode: normal;
    border-radius: 8px;
    background-size: 300% 300%;
    animation: gradient 1s ease infinite;
    margin-top: 4px;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
</style>
