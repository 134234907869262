<template>
  <div>
    <Menu />

    <div v-if="!billingOverdue" class="wrapper wrapper--calendar">
      <CalendarHeader
        :selectedDate="formattedCurrentDate"
        :type="'calendarDayView'"
        :nameList="nameList"
        @selectToday="selectToday"
        @datePrev="datePrev"
        @dateNext="dateNext"
        @dropToContainer="dropToContainer"
      />

      <GlobalNotification />

      <vuescroll :ops="scrollPanel">
        <div class="calendar-day">
          <CalendarDayColumn
            v-for="item in columns"
            :key="item.id"
            :column="item"
            :data="getTypeData(item.id)"
            :suggestion="getTypeSuggestion(item.id)"
            :loaded="loaded"
            @changeIdeaStatusById="changeIdeaStatusById"
            @deleteContent="deleteContent"
          />
        </div>
      </vuescroll>
    </div>

    <ExpiredCompany v-else :company="selectedCompany" />
  </div>
</template>

<script>
import CONTENTS from '@/graphql/Contents.gql'
import CONTENT_STATE_UPDATE from '@/graphql/ContentStateUpdate.gql'
import CONTENT_IDEA_DATE_UPDATE from '@/graphql/ContentIdeaDateUpdate.gql'
import CONTENT_DELETE from '@/graphql/ContentDelete.gql'
import { DateTime } from 'luxon'
import { DateService } from '@/services/DateService.js'
import { FilterService } from '@/services/FilterService.js'
import { obj_clone } from '@/services/helpers/clone.js'

import vuescroll from 'vuescroll'
import moment from 'moment'

import Menu from '@/components/Menu.vue'
import CalendarHeader from '@/components/CalendarHeader.vue'
import CalendarDayColumn from '@/components/CalendarDayColumn.vue'
import GlobalNotification from '@/components/GlobalNotification.vue'
import ExpiredCompany from '@/components/ExpiredCompany.vue'

export default {
  components: {
    Menu,
    CalendarHeader,
    vuescroll,
    CalendarDayColumn,
    GlobalNotification,
    ExpiredCompany,
  },
  created() {
    this.$store.commit('setDate', this.dateRangeFrom)
    this.processDate()
  },
  apollo: {
    companies: {
      query: CONTENTS,
      variables() {
        return {
          from: DateService.dateToUTC(this.from),
          to: DateService.dateToUTC(this.to),
          id: this.selectedCompany.id,
        }
      },
      skip() {
        if (!this.selectedCompany) {
          return true
        }

        return !(this.from !== '' && this.to !== '')
      },
      error(error) {
        console.log('error', error)
        this.$notify({
          title: this.$t('8_3_error_title_load_contents'),
          text: this.$t('8_3_error_description_load_contents'),
          type: 'error',
          duration: 5000,
        })
      },
    },
  },
  data() {
    return {
      billingOverdue: false,
      loaded: false,
      nameList: [],
      companies: [],
      from: '',
      to: '',
      content: [],
      suggestions: [],
      currentDate: new Date(),
      columns: [
        {
          id: 'TODO',
          title: this.$t('TODO'),
          color: '#888F91',
        },
        {
          id: 'IN_PROGRESS',
          title: this.$t('IN_PROGRESS'),
          color: '#17252A',
        },
        {
          id: 'IN_REVIEW',
          title: this.$t('IN_REVIEW'),
          color: '#F7DC6F',
        },
        {
          id: 'APPROVED',
          title: this.$t('APPROVED'),
          color: '#2CE0C1',
        },
        {
          id: 'PUBLISHED',
          title: this.$t('PUBLISHED'),
          color: '#B6F5EA',
        },
      ],
      scrollPanel: {
        initialScrollY: false,
        initialScrollX: false,
        scrollingX: true,
        scrollingY: true,
        speed: 300,
        easing: undefined,
        verticalNativeBarPos: 'right',
      },
      date: '',
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD', // Uses 'iso' if missing
      },
      showIdeas: false,
      selectedDay: '',
      masks: {
        weekdays: 'WWW',
      },
    }
  },
  computed: {
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    dateRangeFrom() {
      return this.$store.getters.getDateRangeFrom
    },
    refreshContent() {
      return this.$store.getters.getRefreshContent
    },
    contents() {
      let contents = []

      if (this.companies.length === 0) {
        return contents
      }

      const _contents = this.companies[0].contents
      for (let i = 0; i < _contents.length; i++) {
        let content = obj_clone(_contents[i])

        if (content.state === 'IDEA') {
          continue
        }

        content['key'] = 'key-' + content.id

        const overrideZone = DateTime.fromISO(
          new Date(content.date).toISOString()
        ).setZone(this.selectedCompany.zoneId)
        content.luxonDate = overrideZone
        content.date = `${overrideZone.toFormat('yyyy-MM-dd')}T${overrideZone
          .toUTC()
          .toFormat('HH:mm:ss')}`

        if (content.eod === true) {
          const dateObject = new Date(content.date)
          dateObject.setHours(23, 59, 59, 0)

          content.luxonDate.set({ hour: 23, minutes: 59, seconds: 59 })
          content.date = dateObject
        }

        contents.push(content)

        this.nameList = FilterService.addNameList(content.name, this.nameList)
      }

      const filteredService = FilterService.filterContent(
        contents,
        this.contentFilter,
        this.contentOwner
      )
      return filteredService
    },
    formattedCurrentDate() {
      return moment(this.selectedDate).format('dddd, Do MMM YYYY')
    },
    selectedDate() {
      return this.$store.getters.getSelectedDate
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
    contentOwner() {
      return this.$store.getters.getContentOwner
    },
    contentFilter() {
      let result = []
      const _contentFilter = this.$store.getters.getContentFilter
      _contentFilter.forEach((item) => {
        let values = []

        if (item.name.id === 'name') {
          if (item.value.length > 0) {
            values.push(item.value)
          }
        } else {
          if (item.value !== '') {
            item.value.forEach((value) => {
              values.push(value.value)
            })
          }
        }

        result.push({
          type: item.name.id,
          values: values,
        })
      })

      return result
    },
  },
  methods: {
    deleteContent(content_id) {
      try {
        this.$apollo
          .mutate({
            mutation: CONTENT_DELETE,
            variables: {
              id: content_id,
            },
            update: (store, { data: { deleteContent } }) => {
              if (!deleteContent) {
                this.$notify({
                  title: this.$t('8_3_toaster_error_title_content_delete'),
                  text: this.$t('8_3_toaster_error_description_content_delete'),
                  type: 'error',
                  duration: 5000,
                })
                return
              }

              const cached = store.readQuery({
                query: CONTENTS,
                variables: {
                  from: DateService.dateToUTC(this.from),
                  to: DateService.dateToUTC(this.to),
                  id: this.selectedCompany.id,
                },
              })

              cached.companies[0].contents =
                cached.companies[0].contents.filter(
                  (item) => item.id !== content_id
                )

              store.writeQuery({
                query: CONTENTS,
                data: cached,
                variables: {
                  from: DateService.dateToUTC(this.from),
                  to: DateService.dateToUTC(this.to),
                  id: this.selectedCompany.id,
                },
              })

              this.$notify({
                title: this.$t('8_3_toaster_success_title_deleted_content'),
                text: this.$t(
                  '8_3_toaster_success_description_deleted_content'
                ),
                type: 'success',
                duration: 5000,
              })
            },
            onQueryUpdated(observableQuery) {
              // console.log("observableQuery", observableQuery);
              // Define any custom logic for determining whether to refetch
              // console.log("refetch after deleted");
              return observableQuery.refetch()
            },
          })
          .then((data) => {
            // console.log("data", data);
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('8_3_toaster_error_content_delete'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('8_3_toaster_error_content_delete'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    updateContent(content, state) {
      if (this.role === 'READ_ONLY') {
        return
      }

      try {
        this.$apollo
          .mutate({
            mutation: CONTENT_STATE_UPDATE,
            variables: {
              id: content.id,
              state: state,
              eod: content.eod,
            },
            update: (store, { data: { updateContent } }) => {
              this.$store.commit('setRefreshToken')

              setTimeout(() => {
                this.$store.commit('setRefreshToken')
              }, 500)
            },
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('8_3_toaster_error_content_update'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('8_3_toaster_error_content_update'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    updateIdeaContent(idea_id, date, state) {
      if (this.role === 'READ_ONLY') {
        return
      }

      let _date = new Date(date)
      _date.setHours(23, 59, 59, 0)

      try {
        this.$apollo
          .mutate({
            mutation: CONTENT_IDEA_DATE_UPDATE,
            variables: {
              id: idea_id,
              date: DateService.timeZoneToUTC(
                _date,
                this.selectedCompany.zoneId
              ),
              state: state,
            },
            update: (store, { data: { updateContent } }) => {
              console.log('updateIdeaContent calendarDay - refetch')
              this.$apollo.queries.companies.refetch()
              this.$store.commit('setRefreshToken')
            },
          })
          .then((data) => {
            // console.log("data", data);
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('8_3_toaster_error_content_state_update'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('8_3_toaster_error_content_state_update'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    selectToday() {
      // this.loaded = false;
      const currentDate = new Date()
      this.$store.commit('setDate', currentDate)
      this.$store.commit('setDateRangeFrom', currentDate)
    },
    datePrev() {
      this.loaded = false
      const currentDate = this.changeDate(-1)
      this.$store.commit('setDate', currentDate)
      this.$store.commit('setDateRangeFrom', currentDate)
    },
    dateNext() {
      this.loaded = false
      const currentDate = this.changeDate(1)
      this.$store.commit('setDate', currentDate)
      this.$store.commit('setDateRangeFrom', currentDate)
    },
    changeDate(day) {
      let currentDate = new Date(this.selectedDate)
      currentDate.setDate(currentDate.getDate() + day)
      return currentDate
    },
    changeIdeaStatusById(idea_id, status) {
      const ideas = this.contents.filter((item) => item.id == idea_id)

      if (ideas.length > 0) {
        this.updateContent(ideas[0], status)
      } else {
        const ideaDate = new Date(this.selectedDate)
        ideaDate.setHours(0, 0, 0, 0)

        this.updateIdeaContent(idea_id, ideaDate, status)
      }
    },
    getTypeData(state) {
      function compare(a, b) {
        if (new Date(a.date).getTime() < new Date(b.date).getTime()) {
          return -1
        }
        if (new Date(a.date).getTime() > new Date().getTime()) {
          return 1
        }
        return 0
      }

      const data = this.contents.filter(
        (item) =>
          item.state === state &&
          item.date != null &&
          new Date(item.date).getDate() ===
            new Date(this.selectedDate).getDate()
      )
      data.sort(compare)
      return data
    },
    getTypeSuggestion(state) {
      const data = this.suggestions.filter(
        (item) =>
          item.state === state &&
          item.date != null &&
          new Date(item.date).getDate() ===
            new Date(this.selectedDate).getDate()
      )
      return data
    },
    dropToContainer(idea_id) {
      const contents = this.contents.filter((item) => item.id == idea_id)
      this.updateContent(contents[0], 'IDEA')
    },
    processDate() {
      this.from = new Date(this.selectedDate)
      this.from.setDate(new Date(this.selectedDate).getDate() - 1)

      this.to = new Date(this.selectedDate)
      this.to.setDate(new Date(this.selectedDate).getDate() + 1)
    },
    getSuggestions() {
      let suggestions = []

      if (this.companies.length === 0) {
        return suggestions
      }

      const _suggestions = this.companies[0].suggestedContents

      for (let i = 0; i < _suggestions.length; i++) {
        let content = obj_clone(_suggestions[i])

        content.key = 'key-' + content.id
        content.state = 'TODO'

        const day = DateService.getDateFromString(content.date)
        content.date = DateTime.fromISO(content.date)
          .setZone(this.selectedCompany.zoneId)
          .set({
            year: day[0],
            month: day[1],
            day: day[2],
            hour: 11,
            minute: 59,
            second: 59,
          })
          .toUTC()
          .set({
            hour: 11,
            minute: 59,
            second: 59,
          })
          .toISO()

        suggestions.push(content)
      }

      this.suggestions = suggestions
    },
  },
  watch: {
    selectedCompany() {
      this.billingOverdue = this.selectedCompany.billingOverdue
    },
    selectedDate() {
      this.processDate()
    },
    refreshContent() {
      this.$apollo.queries.companies.refetch()
    },
    companies() {
      if (this.companies.length > 0) {
        this.loaded = true
        this.getSuggestions()
      }
    },
  },
}
</script>
