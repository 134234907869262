<template>
  <div class="error-page">
    <div class="error-page__row">
      <div class="error-page__title">
        <SGV404 />
        <div>404</div>
      </div>

      <div>
        <template v-if="$i18n.locale === 'cs'">
          <h2>
            Omlouváme se, ale tato stránka buď nikdy neexistovala, nebo ji ze
            světa sprovodil gigantický meteorit. Dinosauři to nikdy neměli lehké.
          </h2>
          <p class="large-content">
            Zkontrolujte prosím, jestli jste zadali správnou URL, vraťte se na
            <Link @click="$router.push({ path: '/' })">hlavní stránku</Link>, nebo
            nám o problému napište na
            <MailToSupport :subject="$t('11_1_1_support_mail_subject')" />.
          </p>
        </template>

        <template v-else>
          <h2>
            Sorry, but this site either never existed or a gigantic meteorite took
            it down. Dinosaurs never had it easy.
          </h2>
          <p class="large-content">
            Please check that you have entered the correct URL, return to the
            <Link @click="$router.push({ path: '/' })">main page</Link>, or e-mail
            us about the problem at
            <MailToSupport :subject="$t('11_1_1_support_mail_subject')" />.
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Link from '@/components/design/Link.vue'
import SGV404 from '@/components/svg/404.vue'
import MailToSupport from '@/components/MailToSupport.vue'

export default {
  components: {
    Link,
    SGV404,
    MailToSupport,
  },
}
</script>
