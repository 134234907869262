
function prepareLinksForUpdate(items) {
    let updated_items = [];

    items.forEach((item) => {
        let updated_item = {
            link: item.link,
            name: item.name
        };

        updated_items.push( updated_item );
    });

    return updated_items;
}

export const ResourceService = {
    prepareLinksForUpdate
}