<template>
  <VDropdown
    :triggers="[]"
    :shown="showData"
    :handle-resize="true"
    :arrow-padding="distanceNo"
    :popperClass="boxSize"
    :auto-hide="autoHide"
    :eagerMount="autoHide"
    @hide="closeData"
  >
    <div
      class="tag-input"
      :class="{
        slim: slim,
        'show-data': showData && type != 'create',
        compact: !showData,
        editable: editable,
      }"
    >
      <div @click="openData" class="tag-input__selected clearfix">
        <div
          class="tag-input__tag"
          v-for="(tag, index) in data_items"
          :key="index"
        >
          <span
            v-if="colorize"
            class="tag-input__bg"
            :style="{ 'background-color': getBackgroundColor(tag.name) }"
          ></span>

          <span class="tag-input__selected-text">{{
            tag.name.toLowerCase()
          }}</span>
          <span v-if="slim && !showData && index + 1 < data_items.length"
            >,
          </span>
          <span
            v-if="!slim || (slim && showData)"
            class="tag-input__remove"
            @click.stop.prevent="removeTag(index)"
            ><IcoCross
          /></span>
        </div>

        <div
          @click.prevent="showData = true"
          class="tag-input__empty-placeholder"
          v-if="!showData && slim && data_items.length === 0"
        >
          {{ $t('9_2_none') }}
        </div>

        <input
          v-if="showInput"
          readonly="readonly"
          type="text"
          :placeholder="placeholder"
          :maxlength="length.attributes"
          class="tag-input__text"
          v-model="content"
          @keydown.enter="addTag"
          @keydown.188="addTag"
          @keydown.delete="removeLastTag"
          v-autowidth="{ maxWidth: '578px', minWidth: '120px', comfortZone: 0 }"
        />
      </div>
    </div>

    <template #popper>
      <div class="tag-input__top">
        <div class="tag-input__data__header">
          <div>{{ name }} ({{ searchItems.length }})</div>
          <div>
            <button
              v-if="editable"
              @click.stop.prevent="closeEdit"
              class="back"
            >
              <IcoBack /> {{ $t('9_2_back') }}
            </button>

            <button class="simple-button" @click="closeData()">
              <IcoClose />
            </button>
          </div>
        </div>
        <div class="tag-input__data__search">
          <div style="width: 100%">
            <input
              type="text"
              ref="search_items"
              :placeholder="$t('9_2_search')"
              :maxlength="length.attributes"
              v-model="search"
            />
            <IcoSearch />
          </div>
        </div>
      </div>

      <div
        class="tag-input__data___wrapper"
        :class="{ edit: editable }"
        v-prevent-parent-scroll
      >
        <vuescroll :ops="ops">
          <div class="tag-input__data__content">
            <TagInputItem
              :editable="editable"
              v-for="(item, index) in searchItems"
              :key="index"
              :item="item"
              @selectItem="selectItem"
            />

            <div class="tag-input__no-result" v-if="searchItems.length == 0">
              <p>{{ $t('9_2_stdc_search_no_result_title') }}.</p>
            </div>
          </div>
        </vuescroll>
      </div>

      <div v-if="editable" class="tag-input__data__add">
        <input
          v-model="newTag"
          type="text"
          :placeholder="$t('9_2_placeholder_new_stdc')"
          :maxlength="length.short"
        />
        <button :disabled="isCreateDisabled" @click="createItem()">
          <IcoPlus />
        </button>
      </div>
    </template>
  </VDropdown>
</template>

<script>
import { ColorService } from '@/services/ColorService.js'
import textLength from '@/constants/TextLengthConstant.js'

import vuescroll from 'vuescroll'

import TagInputItem from '@/components/TagInputItem.vue'
import IcoSettings from '@/components/svg/IcoSettings.vue'

import ClickOutside from 'vue-click-outside'

import IcoBack from '@/components/svg/IcoBack.vue'
import IcoClose from '@/components/svg/IcoClose.vue'
import IcoSearch from '@/components/svg/IcoSearch.vue'
import IcoPlus from '@/components/svg/IcoPlus.vue'
import IcoCross from '@/components/svg/small/IcoCross.vue'

export default {
  components: {
    vuescroll,
    TagInputItem,
    IcoBack,
    IcoSearch,
    IcoPlus,
    IcoSettings,
    IcoCross,
    IcoClose,
  },
  directives: {
    ClickOutside,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    boxSize: {
      type: String,
      default: 'popper-edit-codelist',
    },
    value: {
      type: Array,
      default() {
        return []
      },
    },
    placeholder: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default() {
        return []
      },
    },
    name: {
      type: String,
      default: '',
    },
    max: {
      type: Number,
      default: 0,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'casual',
    },
    slim: {
      type: Boolean,
      default: false,
    },
    colorize: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.data_items = this.value
    this.reorderItems()
  },
  computed: {
    length() {
      return textLength
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
    searchItems() {
      let filteredItems = this.itemsList

      filteredItems = filteredItems.filter((item) => {
        return (
          item.label.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
        )
      })

      if (this.editable) {
        return filteredItems
      }

      if (this.data_items) {
        filteredItems = this.getDifference(filteredItems, this.data_items)
      }

      return filteredItems
    },
    activeUID() {
      return this.$store.getters.getActiveUID
    },
  },
  created() {
    if (!this.slim) {
      this.showInput = true
    }

    this.itemsList = this.items
    this.reorderItems()
  },
  data() {
    return {
      distanceNo: 12,
      autoHide: true,
      disableClose: false,
      isCreateDisabled: false,
      textColor: '#888F91',
      backgroundColor: '#E7E9E9',
      search: '',
      showData: false,
      editable: false,
      content: '',
      data_items: [],
      canClose: true,
      showInput: false,
      uid: '',
      newTag: '',
      itemsList: [],
      ops: {
        vuescroll: {},
        scrollPanel: {
          scrollingX: false,
          scrollingY: true,
          initialScrollY: false,
          initialScrollX: false,
        },
        rail: {},
        bar: {
          onlyShowBarOnScroll: true,
          keepShow: false,
          minSize: 0,
        },
      },
    }
  },
  methods: {
    getDifference(array1, array2) {
      return array1.filter((object1) => {
        return !array2.some((object2) => {
          return object1.id === object2.id
        })
      })
    },

    reorderItems() {
      let arrayOrder = []
      for (let i = 0; i < this.data_items.length; i++) {
        switch (this.data_items[i].id) {
          case 'SEE':
            arrayOrder[1] = this.data_items[i]
            break
          case 'THINK':
            arrayOrder[2] = this.data_items[i]
            break
          case 'DO':
            arrayOrder[3] = this.data_items[i]
            break
          case 'CARE':
            arrayOrder[4] = this.data_items[i]
            break
        }
      }

      arrayOrder = arrayOrder.filter((val) => val)

      this.data_items = [...arrayOrder]
    },

    processDataFromServer(items) {
      const result = []
      if (items) {
        items.forEach((item) => {
          result.push({
            id: item.id,
            label: item.name,
            value: item.name,
          })
        })
      }

      return result
    },
    getBackgroundColor(string) {
      if (this.colorize) {
        return ColorService.computedColor(string)
      } else {
        return this.backgroundColor
      }
    },
    getTextColor(string) {
      if (this.colorize) {
        return ColorService.computedColor(string)
      } else {
        return this.textColor
      }
    },
    getColor(name) {
      return ColorService.computedColor(name)
    },
    closeData() {
      this.showData = false
    },
    openData() {
      if (this.disabled) {
        this.showData = false
        return
      }

      this.itemsList = this.items
      this.autoHide = false
      setTimeout(() => (this.autoHide = true), 300)

      this.showData = true
    },
    openEdit() {
      this.canClose = false
      this.editable = true

      setTimeout(() => (this.canClose = true), 100)
    },
    selectItem(item) {
      setTimeout(() => {
        this.distanceNo = this.getRandomInt(15)
      }, 100)

      if (!this.multiple) {
        this.canClose = false
        this.data_items = [
          {
            name: item.label,
            id: item.id,
          },
        ]

        this.$emit('save_changed_value', this.data_items)

        setTimeout(() => (this.canClose = true), 100)
        return
      }

      if (this.max > 0) {
        if (this.data_items.length < this.max) {
          this.canClose = false
          this.data_items.push({
            name: item.label,
            id: item.id,
          })

          this.$emit('save_changed_value', this.data_items)

          setTimeout(() => (this.canClose = true), 100)
        } else {
          alert('Maximum ' + this.name + 's is ' + this.max)
        }
      } else {
        this.canClose = false
        this.data_items.push({ name: item.label, id: item.id })
        this.$emit('save_changed_value', this.data_items)

        setTimeout(() => (this.canClose = true), 100)
      }

      this.reorderItems()
    },
    closeEdit() {
      this.editable = false
    },
    getRandomInt(max) {
      return Math.floor(Math.random() * max)
    },
    addTag(event) {
      event.preventDefault()

      var val = event.target.value.trim()
      if (val.length > 0) {
        this.data_items.push(val)
        this.content = ''
      }

      this.$emit('input', this.data_items)
    },
    removeTag(index) {
      setTimeout(() => {
        this.distanceNo = this.getRandomInt(15)
      }, 100)

      this.autoHide = false
      setTimeout(() => (this.autoHide = true), 300)

      this.data_items.splice(index, 1)
      // Update model
      this.$emit('input', this.data_items)
      this.$emit('save_changed_value', this.data_items)
    },
    removeLastTag(event) {
      if (event.target.value.length === 0) {
        this.removeTag(this.data_items.length - 1)
      }

      // Update model
      this.$emit('input', this.data_items)
      this.$emit('save_changed_value', this.data_items)
    },
  },
  watch: {
    showData() {
      if (this.showData) {
        this.$emit('focus')
        this.search = ''

        setTimeout(() => {
          this.$refs.search_items.focus()
        }, 200)
      } else {
        this.$emit('blur')
      }
    },
    editable() {
      if (this.editable) {
        setTimeout(() => {
          this.$refs.search_items.focus()
        }, 200)
      }
    },
  },
}
</script>

<style scoped>
.tag-input__top {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  background: #fff;
}

.v-popper {
  width: 100%;
}
.clearfix {
  overflow: auto;
}

.clearfix::after {
  display: table;
  clear: both;
  content: '';
}
.tag-input {
  position: relative;
  box-sizing: border-box;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 0 10px;
  width: 100%;
  font-size: 0.9em;
  font-size: 14px;
}

.tag-input.slim {
  border-color: #fff;
  padding: 0;
}

.tag-input.show-data {
  border-color: #2ce0c1;
}

.tag-input__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.3;
  z-index: 1;
  border-radius: 3px;
}

.tag-input__selected {
  position: relative;
  padding-right: 3px;
  padding-bottom: 3px;
  min-height: 20px;
  overflow: hidden;
}

.tag-input__selected .tag-input__empty-placeholder {
  position: relative;
  float: left;
  margin-top: 0;
  margin-top: 3px;
  margin-right: 6px;
  background-color: #eee;
  background: transparent;
  padding: 0;
  padding-left: 4px;
  height: 22px;
  color: #888f91;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  font-family: Nunito;
}

.tag-input.slim.show-data .tag-input__empty-placeholder {
  margin-left: 6px;
  font-size: 12px;
}

.tag-input__selected-text {
  position: relative;
  z-index: 2;
  color: #888f91;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  font-family: 'Nunito';
  text-transform: capitalize !important;
  word-break: break-all;
}

.tag-input.slim .tag-input__selected-text {
  color: #17252a;
}

.tag-input .tag-input__selected-text {
  /* text-transform: uppercase; */
}

.tag-input.slim .tag-input__selected-text {
  text-transform: none;
}

.tag-input.show-data .tag-input__selected-text {
  /* text-transform: uppercase; */
  color: #888f91;
}

.tag-input.slim.show-data .tag-input__selected-text {
  font-size: 12px;
}

.tag-input__no-result {
  padding: 15px 15px 15px 15px;
}

.tag-input__no-result p {
  margin-bottom: 6px;
  color: #aeb3b5;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  font-family: Nunito;
}

.tag-input__no-result p a {
  color: #2ce0c1;
  text-decoration: underline;
}

.tag-input__tag {
  position: relative;
  float: left;
  margin-top: 10px;
  margin-right: 6px;
  border-radius: 3px;
  background-color: #eee;
  background: #e7e9e9;
  padding: 0 6px;
  padding-right: 16px;
  color: #888f91;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  font-family: Nunito;
}

.tag-input.slim.compact .tag-input__tag {
  background: transparent;
  padding-right: 0;
  padding-left: 0;
  color: #17252a;
}

.tag-input.slim .tag-input__tag {
  margin-top: 3px;
  margin-right: 0;
  margin-left: 3px;
}

.tag-input__tag span.tag-input__remove {
  position: absolute;
  top: 0;
  right: 6px;
  z-index: 92;
  cursor: pointer;
}

.tag-input__tag span.tag-input__remove svg {
  width: 7px;
  height: 7px;
}

.tag-input__text {
  display: inline-block;
  margin-top: 6px;
  outline: none;
  border: none;
  background: none;
  width: auto;
  height: 30px;
  color: #17252a;
  font-style: normal;

  font-weight: bold;
  font-size: 0.9em;
  font-size: 12px;
  line-height: 30px;
  font-family: Nunito;
}

.tag-input.slim .tag-input__text {
  margin-top: 1px;
}

.tag-input__data {
  position: absolute;
  top: 100%;
  right: 14px;
  left: 14px;
  z-index: 9993;
  box-shadow: 15px 15px 60px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background: #ffffff;
  min-width: 240px;
}

.tag-input__data___wrapper {
  padding-top: 84px;
  height: 300px;
  overflow: hidden;
}

.tag-input__data___wrapper.edit {
  padding-bottom: 50px;
}

.tag-input__data__header {
  display: flex;
  justify-content: space-between;
  padding: 15px;
}

.tag-input__data__header div {
  display: flex;
  align-items: center;
  mix-blend-mode: normal;
  color: #aeb3b5;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
  font-family: Nunito;
  text-transform: uppercase;
}

.tag-input__data__header button {
  cursor: pointer;
  border: none;
  border-radius: 3px;
  background: #2ce0c1;
  height: 20px;
  color: #ffffff;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 10px;
  font-family: Nunito;
}

.tag-input__data__header button.edit {
  background: #e7e9e9;
  color: #17252a;
}

.tag-input__data__header button.back {
  background: #2ce0c1;
  color: #ffffff;
}

.tag-input__data__search {
  padding: 0 15px 5px 15px;
}

.tag-input__data__search div {
  position: relative;
  mix-blend-mode: normal;
  border-radius: 5px;
  background: #f6f6f7;
}

.tag-input__data__search input {
  border: none;
  background: transparent;
  padding: 0 10px;
  padding-right: 30px;
  width: 100%;
  height: 30px;

  color: #17252a;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  font-family: Nunito;
}

.tag-input__data__search svg {
  position: absolute;
  top: 7px;
  right: 10px;
}

.tag-input__data__content {
}

.tag-input__data__add {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: space-between;
  gap: 10px;
  background: #fff;
  padding: 0 15px 15px 15px;
  padding-top: 5px;
}

.tag-input__data__add input {
  border: none;
  border-radius: 4px;
  background: #f6f6f7;
  padding: 0 10px;
  width: 100%;
  height: 30px;
  color: #17252a;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 30px;
  line-height: 18px;
  font-family: Nunito;
}

.tag-input__data__add button {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background: #2ce0c1;
  width: 30px;
  height: 30px;
  color: #fff;
  line-height: 1;
}

.tag-input__data__add button:disabled {
  opacity: 0.4;
}

.tag-input.editable .tag-input__data {
  padding-bottom: 30px;
}
</style>
