var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group",class:{
        'is-disabled': _vm.disabled,
        'is-invalid': _vm.validationError,
        'has-focus': _vm.focused,
        'is-rounded': _vm.rounded,
        'has-border': _vm.border,
        'is-inline': !_vm.border,
        }},[_vm._t("default"),_c('span',{staticClass:"form-group__label"},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"form-group__required"},[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"form-group__validation-error"},[_c('IcoValidationError')],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }