const getDaysToExpiration = (billingDateString) => {
  const today = new Date()
  const expirationDate = new Date(billingDateString)
  const diffTime = expirationDate - today
  return Math.round(diffTime / (1000 * 60 * 60 * 24))
}

module.exports = {
  getDaysToExpiration,
}
