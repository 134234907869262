var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"idea-week",class:{
    'old-day': _vm.today.getTime() < _vm.currentDate.getTime(),
    'is-overdue': _vm.item.state !== 'PUBLISHED' && _vm.isOverdue,
    'read-only': _vm.role === 'READ_ONLY',
  },attrs:{"draggable":_vm.role !== 'READ_ONLY'},on:{"click":function($event){return _vm.editContent()},"dragstart":_vm.dragStart,"dragend":_vm.dragEnd,"dragover":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"idea-week__inner"},[_c('div',{staticClass:"idea-week__label",style:({ color: _vm.getColor(_vm.computedChannelName) })},[_vm._v(" "+_vm._s(_vm.computedChannelName)+" ")]),_c('div',{staticClass:"idea-week__data"},[(!_vm.item.eod)?_c('div',{staticClass:"idea-week__time",class:{
          'old-day': _vm.today.getTime() < _vm.currentDate.getTime(),
          'is-overdue': _vm.item.state !== 'PUBLISHED' && _vm.isOverdue,
        }},[_c('IcoTime'),_vm._v(" "+_vm._s(_vm.contentTime)+" ")],1):_vm._e(),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideControll),expression:"hideControll"}],staticClass:"idea-week__controll"},[_c('VDropdown',{attrs:{"skidding":-42,"placements":'bottom-end',"triggers":[],"shown":_vm.showControll,"eagerMount":_vm.autoHide,"auto-hide":_vm.autoHide},on:{"hide":_vm.closeData},scopedSlots:_vm._u([{key:"popper",fn:function(){return [_c('div',{staticClass:"idea-week__controll__items"},[_c('ul',[_c('li',[_c('button',{on:{"click":function($event){$event.stopPropagation();return _vm.shareContent()}}},[_c('IcoShare'),_vm._v(" "+_vm._s(_vm.$t('8_2_content_btn_share'))+" ")],1)]),(_vm.role !== 'READ_ONLY')?_c('li',[_c('button',{on:{"click":function($event){$event.stopPropagation();return _vm.duplicateContent()}}},[_c('IcoDuplicate'),_vm._v(" "+_vm._s(_vm.$t('8_2_content_btn_duplicate'))+" ")],1)]):_vm._e(),(_vm.role !== 'READ_ONLY')?_c('li',[_c('button',{on:{"click":function($event){$event.stopPropagation();return _vm.deleteContent()}}},[_c('IcoBin'),_vm._v(" "+_vm._s(_vm.$t('8_2_content_btn_delete'))+" ")],1)]):_vm._e()])])]},proxy:true}])},[_c('button',{staticClass:"idea-week__controll__button",on:{"click":function($event){$event.stopPropagation();return _vm.toggleControll.apply(null, arguments)}}},[_c('IcoKebabWeekIdea')],1)])],1)]),_c('div',{staticClass:"idea-week__title"},[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),_c('div',{staticClass:"idea-week__footer"},[_c('div',{staticClass:"idea-week__status"},[_c('IdeaState',{attrs:{"id":_vm.item.state}})],1),_c('Avatar',{attrs:{"size":'small',"name":_vm.item.owner.name,"user":_vm.item.owner}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }