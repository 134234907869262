<template>
    <div v-if="items.length > 0" class="idea-task">
        <div v-if="items.length > 0" class="idea-task__data">            
            <div class="idea-task__progress">
                <div class="idea-task__progress-bar idea-task__progress-bar--full">
                    <div 
                        class="idea-task__progress-state"
                        :style="{width: completed + '%'}"
                    ></div>
                </div>
                <div class="idea-task__percentage">{{completed}}&nbsp;%</div>
            </div>
        </div>

        <div v-else class="idea-task__empty">
            {{ $t("8_3_idea_task_no_checklist") }}
        </div>

    </div>
</template>

<script>

export default {
    props: {
        items: {
            type: Array,
            require: true
        }
    },
    computed: {
        completed() {
            let completed_count = this.items.filter((item) => item.done).length;
            return Math.ceil(((completed_count / this.items.length) * 100));
        }
    }
}
</script>
