<template>
  <Sidebar>
    <div class="line">
        <h2>{{ $t('1_2_title_verify_email') }}</h2>
    </div>
    <div class="line line--half">
        <p>{{ $t("1_2_description_verify_email") }}</p>
    </div>    
  </Sidebar>
</template>

<script>
import { URL } from "@/constants/URLConstant.js";
import Sidebar from "@/components/layout/Sidebar.vue";

export default {
  components: {
    Sidebar,
  },
  created() {
    this.verifyToken()
  },
  data() {
    return {
      
    }
  },
  methods: {
      verifyToken() {

          /*
          if(!this.$route.params.token) {
              this.$notify({
                  title: this.$t("1_2_toaster_error_title_invalid_token"),
                  text: this.$t("1_2_toaster_error_description_invalid_token"),
                  type: "success",
                  duration: 5000,
              });

              return
          }
          */

          const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                token: this.$route.params.token,
            }),
          };

          fetch(URL + "/api/register/verify", requestOptions)
            .then((data) => {
              
              if( data.status === 200 ) {

                this.$notify({
                  title: this.$t("1_2_toaster_success_title_account_verified"),
                  text: this.$t("1_2_toaster_success_description_account_verified"),
                  type: "success",
                  duration: 5000,
                });

              } else {

                this.$notify({
                  title: this.$t("1_2_toaster_error_title_account_verified"),
                  text: this.$t("1_2_toaster_error_description_account_verified"),
                  type: "error",
                  duration: 5000,
                });

              } 
          
              this.$router.push({ name: "Login" });

            }).catch(() => {

              this.$notify({
                title: this.$t("1_2_toaster_error_title_account_verify"),
                text: this.$t("global_toaster_error_description"),
                type: "error",
                duration: 5000,
              });

              this.$router.push({ name: "Login" });

            })
    },
  },
};
</script>
