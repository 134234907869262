<template>
    <button class="feedback-selection" :class="{'is-selected': selected}">
        <span class="feedback-selection__ico"><slot name="ico">ico</slot></span>
        <span class="feedback-selection__title"><slot name="title">I want to report a bug</slot></span>
    </button>
</template>

<script>
export default {
  props: {
    selected: {
        type: Boolean,
        default: false,
    }
  },
  data() {
    return {
        selectedItem: undefined
    }
  },
  methods: {
    closeModal() {
        this.$store.commit("setFeedbackModal", false);
    },
    selectItem(item) {
        this.selectItem = item;
    }
  }
}
</script>

<style scoped>
.feedback-selection{
    display: flex;
    align-items: center;
    width: 100%;
    height: 56px;
    border: none;
    background: #F6F6F7;
    border: 1px solid #F6F6F7;
    border-radius: 10px;
    cursor: pointer;
    -webkit-transition: all 200ms ease;
    transition: all 200ms ease;
    margin-top: 10px;
}

.feedback-selection:hover{
    background: #E7E9E9;
}

.feedback-selection.is-selected{
    background: rgba(151, 149, 255, 0.2);
    border: 1px solid #9795FF;
}



.feedback-selection__ico{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    min-width: 56px;
    max-width: 56px;
    overflow: hidden;
}

.feedback-selection__title{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;    
    letter-spacing: -0.4px;    
    color: #000000;
}
</style>