<template>
  <div
    class="avatar"
    :class="{
      'avatar--small': size === 'small',
      'avatar--extrasmall': size === 'extrasmall',
      'avatar--medium': size === 'medium',
      'avatar--big': size === 'big',
    }"
    :style="{ backgroundColor: color }"
  >
    <img
      v-if="imageUrl !== '' && imageUrl !== 'empty' && !isTeam"
      :src="imageUrl"
    />
    <span v-if="(imageUrl === '' || imageUrl === 'empty') && !isTeam">
      {{ avatarName }}
    </span>
    <IcoDino v-if="isTeam" />
  </div>
</template>

<script>
import IcoDino from '@/components/svg/IcoDino.vue'
import { ColorService } from '@/services/ColorService.js'
import { URL } from '@/constants/URLConstant.js'
import api from '@/services/rest-api/restapi'

export default {
  components: {
    IcoDino,
  },
  props: {
    name: {
      type: String,
      default: 'No name',
    },
    size: {
      type: String,
      default: 'normal',
    },
    user: {
      type: Object,
      default: () => {},
    },
    isTeam: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    avatarName() {
      const name = this.name.split(' ')
      if (name.length === 1) {
        return name[0].charAt(0)
      } else {
        return name[0].charAt(0) + name[1].charAt(0)
      }
    },
    color() {
      if (this.isTeam) {
        return '#E7E9E9'
      }

      if (this.imageUrl !== '' && this.imageUrl !== 'empty') {
        return 'transparent'
      }

      return ColorService.computedColor(this.name)
    },
    mainUrl() {
      return URL
    },
    accessToken() {
      return localStorage.getItem('access_token')
    },
    refreshUserAvatar() {
      return this.$store.getters.getRefreshUserAvatar
    },
  },
  created() {
    console.log('created')
    this.loadImage()
  },
  data() {
    return {
      imageUrl: '',
    }
  },
  methods: {
    async loadImage() {
      console.log('laod image')
      if (!this.user.id) {
        this.imageUrl = ''
        console.log('image neni')
        return
      }

      const storedUserAvatar = this.$store.getters.getUserAvatar(this.user.id)
      if (storedUserAvatar) {
        this.imageUrl = storedUserAvatar.avatar
        return
      }

      try {
        const response = await api.get(`/api/images/user/${this.user.id}`, {
          responseType: 'blob',
        })

        const data = response.data

        if (data.size > 0) {
          const urlCreator = window.URL || window.webkitURL
          this.imageUrl = urlCreator.createObjectURL(data)
          this.$store.commit('setUserAvatar', {
            userId: this.user.id,
            avatar: this.imageUrl,
          })
        } else {
          this.imageUrl = ''
        }
      } catch (e) {
        this.imageUrl = ''
      }
    },
  },
  watch: {
    user() {
      this.loadImage()
    },
    refreshUserAvatar() {
      this.$store.commit('clearUserAvatars')
      this.imageUrl = ''
      this.loadImage()
    },
  },
}
</script>
