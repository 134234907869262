<template>
    <Modal @closeModal="closeModal" :showClose="true">
        <div v-if="isSubmitted" class="logout" style="width: 460px;">
            <h2 class="logout__title">{{ $t("7_1_3_title") }}</h2>

            <div class="modal-description">
                <p>{{ $t("7_1_3_description") }}</p>
            </div>
            
            <Info />

            <div class="line">
                <div class="modal-description">
                    <p>{{ $t("7_1_3_info_trouble") }} <MailToSupport :subject="$t('7_1_2_support_mail_subject')" /></p>
                </div>
            </div>
        </div>
        <div v-else class="logout" style="width: 460px;">
            <h2 class="logout__title">{{ $t("7_1_2_title") }}</h2>

            <div class="modal-description">
                <p>{{ $t("7_1_2_description") }}</p>
            </div>
            
            <div class="line">
                <FormGroup :label="$t('7_1_2_email')" :required="true">
                    <input                   
                        class="form-group__input"                     
                        type="text"
                        placeholder=""
                        v-model="email"
                    >
                </FormGroup>
            </div>

            <div class="modal-options">
                <ul>
                    <li style="width: 100%;">
                        <Button :disabled="disabledRecover" style="width: 100%;" @click="recoverPassword()" :type="'primary'">{{ $t('7_1_2_btn_recover_email') }}</Button>
                    </li>
                </ul>
            </div>

            <ShowError :items="error_form" />

            <div class="line">
                <div class="modal-description">
                    <p>{{ $t('7_1_2_info_trouble') }} <MailToSupport :subject="$t('7_1_2_support_mail_subject')" /></p>
                </div>
            </div>

        </div>
    </Modal>
</template>

<script>
import { URL } from "@/constants/URLConstant.js";
import Modal from '@/components/Modal.vue';
import FormGroup from '@/components/FormGroup.vue';
import Link from '@/components/design/Link.vue';

import Button from '@/components/button/Button.vue';
import Info from '@/components/Info.vue';
import ShowError from '@/components/ShowError.vue';
import MailToSupport from '@/components/MailToSupport.vue';

export default {
    components: {
        Modal,
        FormGroup,
        Button,
        ShowError,
        Link,
        Info,
        MailToSupport,
    },
    data() {
        return {
            disabledRecover: true,
            error_form: [],
            email: '',
            isSubmitted: false,
        }
    },
    methods: {
        closeModal() {
            this.$emit("closeModal");
        },
       recoverPassword() {

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            email: this.email,
          }),
        };

        fetch(URL + "/api/login/stuck", requestOptions)
          .then((data) => {

            console.log("data", data)

            if(data.status === 409) {
              this.$notify({
                  title: this.$t("3_1_toaster_error_title_invalid_reset_password"),
                  text: this.$t("3_1_toaster_error_description_invalid_reset_password"),
                  type: "error",
                  duration: 5000,
              });

              return
            }

            this.email = '';
            this.isSubmitted = true;

          }).catch(error => {

              this.$notify({
                  title: this.$t("3_1_toaster_error_title_invalid_reset_password"),
                  text: this.$t("global_toaster_error_description"),
                  type: "error",
                  duration: 5000,
              });
        })
      },
    },
    watch: {
        email() {
            if(this.email == "") {
                this.disabledRecover = true
            } else {
                this.disabledRecover = false;
            }
        }
    }
}
</script>
