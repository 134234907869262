<template>
    <div>
        <div v-if="invites.length > 0 && !$apollo.queries.invites.loading && showDescription">
            <h2>{{ $t("6_1_subtitle_join") }}</h2>
            <p class="mt12">{{ $t("6_1_description_join") }}</p>
        </div>

        <div v-if="invites.length > 0 && !$apollo.queries.invites.loading" class="join-company line">
            <div class="join-company__header">
                <Link v-if="!selectedAll" @click="selectAll">{{ $t('4_1_link_select_all') }}</Link>
                <Link v-if="selectedAll" @click="deselectAll">{{ $t('4_1_link_deselect_all') }}</Link>
            </div>
            <div class="join-company__body">
                <JoinCompanyItem
                    v-for="(item, index) in items"
                    :key="index" 
                    :index="index"
                    :item="item"
                    @deleteItem="deleteItem" 
                    @selectItem="selectItem" 
                />
            </div>

            <div class="join-company__footer">
                <Button :disabled="selectedItems.length === 0" style="width:100%" @click="joinCompanies()" :size="'max'" :type="'secondary'">{{ $t('4_1_btn_join_selected_companies') }}</Button>
                <Link @click="openInfoModal()">{{ $t('4_1_link_how_users_work_with_companies') }}</Link>
            </div>
        </div>

        <div v-if="invites.length === 0 && !$apollo.queries.invites.loading && showDescription">
            <h2>{{ $t("6_1_subtitle_join_no_results") }}</h2>
            <p class="mt12">{{ $t("6_1_description_join_no_results_paragraph_1") }}</p>
            <p class="mt12">{{ $t("6_1_description_join_no_results_paragraph_2") }}</p>
            <br>
            <Link @click="openInfoModal()">{{ $t('4_1_link_how_users_work_with_companies') }}</Link>
        </div>
    </div>
</template>

<script>
import Button from '@/components/button/Button.vue';
import Link from '@/components/design/Link.vue';
import Checkbox from '@/components/Checkbox.vue';
import JoinCompanyItem from '@/components/JoinCompanyItem.vue';
import INVITE_DELETE from "@/graphql/InviteDelete.gql";
import INVITE_ACCEPT from "@/graphql/InviteAccept.gql";
import INVITES from "@/graphql/Invites.gql";

export default {
  components: { 
    Button,
    Link,
    Checkbox,
    JoinCompanyItem
  },
  apollo: {
    invites: {
      query: INVITES
    },    
  },
  props: {
      showDescription: {
          type: Boolean,
          default: false,
      },
  },
  created() {
    
  },
  computed: {
      selectedItems() {
          return this.items.filter((_item) => _item.selected === true)
      }
  },
  data() {
      return {
          selectedAll: false,
          disabledSubmit: true,
          invites: [],
          items: []
      }
  },
  methods: {
      selectAll() {
          this.items.map(item => item.selected =  true)
          this.selectedAll = true;
      },
      deselectAll() {
          this.items.map(item => item.selected =  false)
          this.selectedAll = false;
      },
      selectItem(index) {
          this.items[index].selected = !this.items[index].selected
      },
      deleteItem(index) {          
          
          try {
            this.$apollo
            .mutate({
                mutation: INVITE_DELETE,
                variables: {
                    id: this.items[index].data.id,
                },
                update: (store, { data: { data } }) => {

                    this.$notify({
                        title: this.$t("6_3_2_toaster_success_title_invite_deleted"),
                        text: this.$t("6_3_2_toaster_success_description_invite_deleted"),
                        type: "success",
                        duration: 5000,
                    });

                    this.$apollo.queries.invites.refetch();
                },
            })          
            .catch((error) => {
                this.$notify({
                    title: this.$t("6_3_2_toaster_error_title_invite_delete"),
                    text: this.$t("global_toaster_error_description"),
                    type: "error",
                    duration: 5000,
                });
            });
        } catch (e) {
            this.$notify({
                title: this.$t("6_3_2_toaster_error_title_invite_delete"),
                text: this.$t("global_toaster_error_description"),
                type: "error",
                duration: 5000,
            });
        }
      },
      joinCompanies(){
          this.items.forEach((item) => {
              try {
                this.$apollo
                    .mutate({
                        mutation: INVITE_ACCEPT,
                        variables: {
                            id: item.data.id,
                        },
                        update: (store, { data: { updateCompany } }) => {
                            this.$notify({
                            title: this.$t("6_1_toaster_success_title_user_accept"),
                            text: this.$t(
                                "6_1_toaster_success_description_user_accept"
                            ),
                            type: "success",
                            duration: 5000,
                            });  
                        },
                    })
                    .then(() => {

                        this.items.splice( this.items.findIndex((_item) => _item.id === item.id), 1)

                        if(this.items.length === 0) {
                            this.$router.push({name: 'Companies'})
                        }

                    })
                    .catch((error) => {
                        this.$notify({
                            title: this.$t("6_1_toaster_error_title_user_accept"),
                            text: this.$t("global_toaster_error_description"),
                            type: "error",
                            duration: 5000,
                        });
                    });
                } catch (e) {
                    this.$notify({
                        title: this.$t("6_1_toaster_error_title_user_accept"),
                        text: this.$t("global_toaster_error_description"),
                        type: "error",
                        duration: 5000,
                    });
                }
          })
      },
      openInfoModal() {
          this.$store.commit("setModalUserRolesInfo", true)
      }
  },
  watch: {
      invites() {
        this.items = [];
        this.invites.forEach((item) => {
            this.items.push({
                selected: false,
                data: item
            });
        })
      }
  }
}
</script>

<style scoped>
    .join-company__body{
        margin-top: 5px;
        border-top: 1px solid #F5F5F5;
    }

    .join-company__item{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .join-company__footer{
        margin-top: 25px;
        display: flex;
        flex-flow: column;
        gap: 20px;
    }
</style>