<template>
    <div class="calendar-filter" v-click-outside="hide">
        <button @click="toggle" class="calendar-filter__toggle" :class="{'is-active': activeFilters.length > 0, 'is-open': isOpen }">
            <IcoFilter />
            <span v-if="activeFilters.length < 1"> {{ $t("8_5_name_filer") }}</span>
            <span v-else> {{ activeFilters.length }}</span>
        </button>

        <div v-if="isOpen" class="calendar-filter__data">
            <div class="calendar-filter__header">
                <div>{{ $t("8_5_active_filters") }} <span v-if="activeFilters.length > 0">({{ activeFilters.length }})</span></div>
                <button
                    class="calendar-filter__button-clear"
                    v-if="activeFilters.length > 0"
                    @click.stop="clearAllFilters()">
                        <IcoBinSmall /> {{ $t("8_5_clear_filters") }}
                </button>
            </div>

            <div
                v-for="(item, index) in activeFilters"
                :key="index"
                class="calendar-filter__item"
                >

                <v-select
                    :options="filterTypes"
                    :clearable="false"
                    :searchable="false"
                    v-model="item.name"
                    @input="updateFilterType(index)"
                    >
                    <template #open-indicator="{ attributes }">
                        <span v-bind="attributes"><IcoCarretDownBlack /></span>
                    </template>
                    <span slot="no-options" @click="$refs.select.open = false">
                        {{ $t('8_5_select_no_result') }}
                    </span>
                </v-select>

                <SearchInput
                    v-if="item.name.id === 'name'"
                    v-model="item.value"
                    :nameList="nameList"
                    @setItemNameValue="setItemNameValue"
                    />

                <ReviewFilter v-else-if="item.name.id === 'rating'" :values="item.value" :index="index" @updated="setRating" />

                <v-select
                    v-else
                    multiple
                    :options="getFilterValues(item, index)"
                    :clearable="false"
                    :searchable="false"
                    v-model="item.value"
                    >
                    <template #open-indicator="{ attributes }">
                        <span v-bind="attributes"><IcoCarretDownBlack /></span>
                    </template>

                    <span slot="no-options" @click="$refs.select.open = false">
                        {{ $t('8_5_select_no_result') }}
                    </span>
                </v-select>

                <button @click.stop="deleteFilterType(index)" class="calendar-filter__item__delete">
                    <IcoClose />
                </button>
            </div>

           <div>
                <div
                    class="calendar-filter__controll"
                    v-click-outside="hideFilterItems"
                    >
                    <button
                        @click.stop="addFilterItem()"
                        class="calendar-filter__add"
                        :class="{
                            'is-opened': showFilterItems
                        }"
                        >
                        <IcoPlus /> {{ $t("8_5_add_filter") }}
                    </button>

                    <div
                        v-if="showFilterItems || (isOpen && activeFilters.length == 0)"
                        class="filter-items">
                        <div class="filter-items__wrapper">
                            <div class="filter-items__title">{{ $t("8_5_add_filter") }}</div>
                            <!--
                            <form
                                class="filter-items__search"
                                @submit.prevent
                                autocomplete="off"
                                >
                                <input
                                    id="filter_items_search"
                                    name="filter_items_search"
                                    ref="filter_items_search"
                                    type="text"
                                    :placeholder="$t('8_5_placeholder_search')"
                                    v-model="search"
                                    >
                                <button @submit.prevent @click.prevent>
                                    <IcoSearch />
                                </button>
                            </form>
                            -->
                        </div>
                        <div class="filter-items__items">
                            <div
                                class="filter-items__item"
                                v-for="(item, index) in searchItems"
                                :key="index"
                                @click.stop="setFilterItem(item)"
                                >
                                    {{ item.label }}
                            </div>
                        </div>

                    </div>
                </div>
           </div>
        </div>
    </div>
</template>

<script>
import { states } from '@/constants/StateConstant.js';
import { stdcs } from '@/constants/STDCSConstant.js';
import gql from 'graphql-tag';

import ClickOutside from 'vue-click-outside';

import SearchInput from '@/components/SearchInput.vue';
import IcoSearch from '@/components/svg/IcoSearch.vue';
import IcoFilter from '@/components/svg/IcoFilter.vue';
import IcoPlus from '@/components/svg/IcoPlus.vue';
import IcoBinSmall from '@/components/svg/IcoBinSmall.vue';
import IcoClose from '@/components/svg/IcoClose.vue';
import IcoCarretDownBlack from '@/components/svg/IcoCarretDownBlack.vue';
import StarRating from "vue-star-rating";
import ReviewFilter from '@/components/ReviewFilter.vue';


export default ({
    components: {
        SearchInput,
        IcoSearch,
        IcoFilter,
        IcoPlus,
        IcoBinSmall,
        IcoClose,
        IcoCarretDownBlack,
        StarRating,
        ReviewFilter
    },
    directives: {
        ClickOutside
    },
    props: {
        nameList: {
            type: Array,
            default: []
        }
    },
    apollo: {
        company: {
            query: gql` query getCompany($id: String!) {
                    companies(filter: [{ name: id, operator: EQ, value: $id}]) {
                        name
                        tags {
                            name
                            id
                        }
                        users {
                            name
                            id
                            position
                            role
                        }
                        channels {
                            name
                            id
                        }
                        products {
                            name
                            id
                        },
                        personas {
                            name
                            id
                        },
                    }
                }
            `,
            update: data => data.companies,
            variables() {
                return {
                    id: this.selectedCompany.id
                }
            },
            skip() {
                return !this.selectedCompany
            }
        },
    },
    mounted() {
        this.activeFilters = this.$store.getters.getContentFilter;
    },
    computed: {
        role() {
            return this.$store.getters.getMeCompanyRole;
        },
        filterTypes() {
            if( this.role === 'READ_ONLY' ) {
                return this.filterTypesReadOnly
            }

            return this.filterTypesFull
        },
        refreshContent() {
            return this.$store.getters.getRefreshContent;
        },
        selectedCompany() {
            return this.$store.getters.getSelectedCompany;
        },
        searchItems() {
            if(this.search === "") {
                return this.filterTypes;
            }

            let filteredItems = this.filterTypes.filter(item => {
                return (
                    item.label
                    .toLowerCase()
                    .indexOf(this.search.toLowerCase()) !== -1
                );
            });

            console.log("filteredItems", filteredItems);

          filteredItems.sort(function(a, b) {
            return a.label.localeCompare(b.label);
          });

            return filteredItems;
        },
        stateCodelist() {
            let arr = [];

            states.forEach(element => {
                arr.push({
                    label: this.$t(element.id),
                    value: element.id,
                    id: element.id
                })
            });

            return arr;
        },
        stdcsCodelist() {
            let arr = [];

            stdcs.forEach(element => {
                arr.push({
                    label: element.charAt(0).toUpperCase() + element.slice(1).toLowerCase(),
                    value: element,
                    id: element
                })
            });

            return arr;
        },
        tagsCodelist() {
            let arr = [];
            if(!this.company.length) return [];

            const tags = this.company[0].tags;

            tags.forEach(element => {
                arr.push({
                    label: element.name,
                    value: element.name,
                    id: element.id
                })
            });
console.log("tady");
            arr.sort(function(a, b) {
              return a.label.localeCompare(b.label);
            });


            return arr;
        },
        productsCodelist() {
            let arr = [];
            if(!this.company.length) return [];

            const products = this.company[0].products;

            products.forEach(element => {
                arr.push({
                    label: element.name,
                    value: element.name,
                    id: element.id
                })
            });

            arr.sort(function(a, b) {
              return a.label.localeCompare(b.label);
            });

            return arr;
        },
        personasCodelist() {
            let arr = [];
            if(!this.company.length) return [];

            const personas = this.company[0].personas;

            personas.forEach(element => {
                arr.push({
                    label: element.name,
                    value: element.name,
                    id: element.id
                })
            });

          arr.sort(function(a, b) {
            return a.label.localeCompare(b.label);
          });

            return arr;
        },
        usersCodelist() {
            let arr = [];
            if(!this.company.length) return [];

            const users = this.company[0].users;

            users.forEach(element => {
                arr.push({
                    label: element.name,
                    value: element.name,
                    id: element.id,
                    position: element.position,
                    role: element.role
                })
            });

            arr.sort(function(a, b) {
              return a.label.localeCompare(b.label);
            });

            return arr;
        },
        channelsCodelist() {
            let arr = [];
            if(!this.company.length) return [];

            const channels = this.company[0].channels;

            channels.forEach(element => {
                arr.push({
                    label: element.name,
                    value: element.name,
                    id: element.id
                })
            });

            arr.sort(function(a, b) {
              return a.label.localeCompare(b.label);
            });

            return arr;
        },
        filterUsers() {
            const users = this.codelist['users']
            users.push({
                label: "Unknown",
                value: "Unknown",
                id: "Unknown",
                position: null,
                role: null
            })

            return users
        }
    },
    data() {
        return {
            currentRating: 0,
            newRating: 0,
            company: [],
            companies: [],
            filterTypesReadOnly: [
                {
                    label: this.$t("8_5_filter_item_name"),
                    id: "name"
                },
                {
                    label: this.$t("8_5_filter_item_state"),
                    id: "state"
                },
                {
                    label: this.$t("8_5_filter_item_channels"),
                    id: "channels"
                },
                {
                    label: this.$t("8_5_filter_item_stdcs"),
                    id: "stdcs"
                },
                {
                    label: this.$t("8_5_filter_item_personas"),
                    id: "personas"
                },
                {
                    label: this.$t("8_5_filter_item_products"),
                    id: "products"
                },
                {
                    label: this.$t("8_5_filter_item_tags"),
                    id: "tags"
                },
                {
                    label: this.$t("8_5_filter_item_rating"),
                    id: "rating"
                },
            ],
            filterTypesFull: [
                {
                    label: this.$t("8_5_filter_item_name"),
                    id: "name"
                },
                {
                    label: this.$t("8_5_filter_item_state"),
                    id: "state"
                },
                {
                    label: this.$t("8_5_filter_item_channels"),
                    id: "channels"
                },
                {
                    label: this.$t("8_5_filter_item_users"),
                    id: "users"
                },
                {
                    label: this.$t("8_5_filter_item_stdcs"),
                    id: "stdcs"
                },
                {
                    label: this.$t("8_5_filter_item_personas"),
                    id: "personas"
                },
                {
                    label: this.$t("8_5_filter_item_products"),
                    id: "products"
                },
                {
                    label: this.$t("8_5_filter_item_tags"),
                    id: "tags"
                },
                {
                    label: this.$t("8_5_filter_item_rating"),
                    id: "rating"
                },
            ],
            activeFilters: [],
            selectedFilterType: '',
            isOpen: false,
            canClose: true,
            showFilterItems: false,
            search: "",
            codelist: {}
        }
    },
    created() {
        this.codelist['name'] = this.nameList;
        this.codelist['state'] = this.stateCodelist;
        this.codelist['stdcs'] = this.stdcsCodelist;
        this.codelist['users'] = this.usersCodelist;
        this.codelist['channels'] = this.channelsCodelist;
        this.codelist['tags'] = this.tagsCodelist;
        this.codelist['products'] = this.productsCodelist;
        this.codelist['personas'] = this.personasCodelist;
    },
    methods: {
        setRating(index, values) {
            if (!Array.isArray(this.activeFilters[ index ].value)) {
                this.activeFilters[ index ].value = [];
            }

            if(values.length) {
                this.activeFilters[ index ].value = values
            }
        },
        checkActiveFilters() {
            let newActiveFilters = [];

            this.activeFilters.forEach((element) => {
                if(element.name.id !== 'rating') {
                    let availableItems = this.codelist[element.name.id];
                    let newValues = [];

                    if(Array.isArray(element.value)) {

                        element.value.forEach((value) => {
                            if( availableItems.find((item) => item.id === value.id) ) {
                                newValues.push(value);
                            }
                        });

                        if(newValues.length > 0) {
                            element.value = newValues;
                            newActiveFilters.push(element);
                        }
                    } else {
                        if(element.value !== "") {
                            newActiveFilters.push(element);
                        }
                    }
                } else {
                    newActiveFilters.push(element);
                }
            });

            this.activeFilters = newActiveFilters;
            this.$store.commit("setContentFilter", newActiveFilters);
        },
        setItemNameValue(item) {
            setTimeout(() => {
                item.showSearch = false;
            }, 1000)
        },
        getFilterValues(item, index) {
            if (item.name.id === 'users') {
              this.filterUsers.sort(function(a, b) {
                return a.label.localeCompare(b.label);
              });
              return this.filterUsers
            }
            return this.codelist[item.name.id].sort(function(a, b) {
              return a.label.localeCompare(b.label);
            });
        },
        updateFilterType(index) {
            this.activeFilters[index].value = [];
        },
        updateFilterValue(index) {
            this.$store.commit("setContentFilter", this.activeFilters);
        },
        addFilterItem() {
            this.showFilterItems = true;
            this.$nextTick(() => {
                this.$refs.filter_items_search.focus();
            });
        },
        setFilterItem(item) {
            this.canClose = false;

            const self = this;
            setTimeout(function(){
                self.activeFilters.push( {
                    name: self.cleanSource(item),
                    value: ''
                } );
                self.showFilterItems = false;
                self.canClose = true;
            }, 1);
        },
        cleanSource (source) {
            return JSON.parse(JSON.stringify(source));
        },
        deleteFilterType(index) {
            this.canClose = false;

            const self = this;
            setTimeout(function(){
                self.activeFilters.splice(index, 1);
                self.canClose = true;
            }, 1);
        },
        clearAllFilters() {
            this.activeFilters = [];
        },
        toggle() {
            this.isOpen = !this.isOpen;
            this.search = '';

            if(this.isOpen) {
                this.checkActiveFilters();
            }

            if(this.isOpen && this.activeFilters.length == 0) {
                this.addFilterItem();
            }
        },
        hide() {
            this.checkActiveFilters();

            if(this.isOpen && this.canClose) {
                this.isOpen = false;
                this.search = '';
            }
        },
        hideFilterItems() {
            if(this.canClose) {
                this.showFilterItems = false;
            }
        }
    },
    watch: {
        newRating() {

        },
        activeFilters() {
            this.$store.commit("setContentFilter", this.activeFilters);
        },
        company() {
            if(!this.company.length) {
                return;
            };

            let tags = [];
            if(this.company[0].tags && this.company[0].tags.length > 0) {
                this.company[0].tags.forEach(element => {
                    tags.push({
                        label: element.name,
                        value: element.name,
                        id: element.id
                    })
                });
            }

            let products = [];
            if(this.company[0].products && this.company[0].products.length > 0) {
                this.company[0].products.forEach(element => {
                    products.push({
                        label: element.name,
                        value: element.name,
                        id: element.id
                    })
                });
            }


            let personas = [];
            if(this.company[0].personas && this.company[0].personas.length > 0) {
                this.company[0].personas.forEach(element => {
                    personas.push({
                        label: element.name,
                        value: element.name,
                        id: element.id
                    })
                });
            }


            let users = [];
            if(this.company[0].users && this.company[0].users.length > 0) {
                this.company[0].users.forEach(element => {
                    users.push({
                        label: element.name,
                        value: element.name,
                        id: element.id,
                        position: element.position,
                        role: element.role
                    })
                });

            }


            let channels = [];
            if(this.company[0].channels && this.company[0].channels.length > 0) {
                this.company[0].channels.forEach(element => {
                    channels.push({
                        label: element.name,
                        value: element.name,
                        id: element.id
                    })
                });
            }


            this.codelist['products'] = products;
            this.codelist['personas'] = personas;
            this.codelist['users'] = users;
            this.codelist['channels'] = channels;
            this.codelist['tags'] = tags;

            this.$store.commit("setCodelist", this.codelist);

            this.checkActiveFilters();
        },
        refreshContent() {
            this.$apollo.queries.company.refetch();

            if(!this.company.length) {
                return;
            };

            let tags = [];
            if(this.company[0].tags && this.company[0].tags.length > 0) {
                this.company[0].tags.forEach(element => {
                    tags.push({
                        label: element.name,
                        value: element.name,
                        id: element.id
                    })
                });
            }

            let products = [];
            if(this.company[0].products && this.company[0].products.length > 0) {
                this.company[0].products.forEach(element => {
                    products.push({
                        label: element.name,
                        value: element.name,
                        id: element.id
                    })
                });
            }


            let personas = [];
            if(this.company[0].personas && this.company[0].personas.length > 0) {
                this.company[0].personas.forEach(element => {
                    personas.push({
                        label: element.name,
                        value: element.name,
                        id: element.id
                    })
                });
            }


            let users = [];
            if(this.company[0].users && this.company[0].users.length > 0) {
                this.company[0].users.forEach(element => {
                    users.push({
                        label: element.name,
                        value: element.name,
                        id: element.id,
                        role: element.role,
                        position: element.position
                    })
                });
            }


            let channels = [];
            if(this.company[0].channels && this.company[0].channels.length > 0) {
                this.company[0].channels.forEach(element => {
                    channels.push({
                        label: element.name,
                        value: element.name,
                        id: element.id
                    })
                });
            }


            this.codelist['products'] = products;
            this.codelist['personas'] = personas;
            this.codelist['users'] = users;
            this.codelist['channels'] = channels;
            this.codelist['tags'] = tags;

            this.$store.commit("setCodelist", this.codelist);

            this.checkActiveFilters();
        },
    }
})
</script>

<style>
.calendar-filter .vs--searchable .vs__search,
.calendar-filter .vs__search{
    display: none !important;
}

.calendar-filter .vs--open.vs--searchable .vs__search{
    display: none !important;
}

.calendar-filter,
.calendar-filter *,
.v-select,
.v-select * {
    white-space: normal;
}
</style>
