<template>
  <div>
    <div
      v-if="slim"
      class="time-picker time-picker--slim"
      :class="{
        slim: slim,
        'is-disabled': content.state === 'IDEA',
      }"
      v-click-outside="hide"
    >
      <div
        :class="{
          'time-picker__selected': isSelected,
          'time-picker__default': !isSelected,
        }"
        @click="toggle"
      >
        <IcoTime /> <span>{{ display }}</span>
      </div>

      <div v-if="show" class="time-picker__time time-picker__time--right">
        <div class="ctimepicker">
          <div class="ctimepicker__col">
            <button class="ctimepicker__button" @click="addHour()">
              <IcoCarretUp />
            </button>
            <input
              type="number"
              v-mask="'##'"
              class="ctimepicker__input"
              @input="checkHours()"
              @change="changeHours()"
              v-model="hours"
              max="23"
            />
            <button class="ctimepicker__button" @click="decreaseHour()">
              <IcoCarretDown />
            </button>
          </div>
          <div>:</div>
          <div class="ctimepicker__col">
            <button class="ctimepicker__button" @click="addMinute()">
              <IcoCarretUp />
            </button>
            <input
              class="ctimepicker__input"
              @change="changeMinutes()"
              v-model="minutes"
              @input="checkMinutes()"
              type="number"
              v-mask="'##'"
            />
            <button class="ctimepicker__button" @click="decreaseMinute()">
              <IcoCarretDown />
            </button>
          </div>
        </div>

        <div class="clear-timer">
          <button @click.stop="clearDate()">
            <IcoBin /> <span>{{ $t('9_2_clear') }}</span>
          </button>
        </div>
      </div>
    </div>

    <FormGroup
      v-else
      :label="$t('9_1_publish_time')"
      :required="false"
      :class="{ 'is-active': show }"
      :disabled="disabled"
      :focused="show"
    >
      <div
        class="time-picker"
        :class="{
          'is-disabled': content.state === 'IDEA',
        }"
        v-click-outside="hide"
      >
        <div
          :class="{
            'time-picker__selected': isSelected,
            'time-picker__default': !isSelected,
          }"
          @click="toggle"
        >
          <IcoTime /> <span>{{ display }}</span>
        </div>
        <div v-if="show" class="time-picker__time">
          <div class="ctimepicker">
            <div class="ctimepicker__col">
              <button class="ctimepicker__button" @click="addHour()">
                <IcoCarretUp />
              </button>
              <input
                type="number"
                v-mask="'##'"
                class="ctimepicker__input"
                @input="checkHours()"
                @change="changeHours()"
                v-model="hours"
              />
              <button class="ctimepicker__button" @click="decreaseHour()">
                <IcoCarretDown />
              </button>
            </div>
            <div>:</div>
            <div class="ctimepicker__col">
              <button class="ctimepicker__button" @click="addMinute()">
                <IcoCarretUp />
              </button>
              <input
                class="ctimepicker__input"
                @change="changeMinutes()"
                v-model="minutes"
                @input="checkMinutes()"
                type="number"
                v-mask="'##'"
              />
              <button class="ctimepicker__button" @click="decreaseMinute()">
                <IcoCarretDown />
              </button>
            </div>
          </div>

          <div class="clear-timer">
            <button @click.stop="clearDate()">
              <IcoBin /> <span>{{ $t('9_2_clear') }}</span>
            </button>
          </div>
        </div>
      </div>
    </FormGroup>
  </div>
</template>

<script>
import { DateService } from '@/services/DateService'
import ClickOutside from 'vue-click-outside'
import FormGroup from '@/components/FormGroup.vue'

import IcoTime from '@/components/svg/IcoTime.vue'
import IcoCarretDown from '@/components/svg/IcoCarretDown.vue'
import IcoCarretUp from '@/components/svg/IcoCarretUp.vue'
import IcoBin from '@/components/svg/IcoBin.vue'

export default {
  components: {
    FormGroup,
    IcoTime,
    IcoCarretDown,
    IcoCarretUp,
    IcoBin,
  },
  directives: {
    ClickOutside,
  },
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    slim: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      required: true,
    },
  },
  computed: {
    display() {
      if (this.content.state === 'IDEA') {
        return this.$t('10_1_time_picker_not_set')
      }

      if (this.eod === true) {
        return this.$t('10_1_time_picker_not_set')
      }

      if (!this.content.date) {
        return this.$t('10_1_time_picker_not_set')
      }

      return this.chours + ':' + this.cminutes
    },
    isSelected() {
      if (this.show) {
        return true
      }

      if (this.eod) {
        return false
      }

      if (this.content.state === 'IDEA') {
        return false
      }

      if (!this.content.date) {
        return false
      }

      return true
    },
    isDisabled() {
      if (this.content.state === 'IDEA') {
        return true
      }

      if (!this.content.date) {
        return true
      }

      return false
    },

    chours() {
      if (this.hours < 10) {
        return this.hours
      } else {
        return this.hours
      }
    },
    cminutes() {
      this.minutes = this.minutes.toString()
      if (this.minutes.length < 2) {
        return '0' + this.minutes
      } else {
        return this.minutes
      }
    },
    disabled() {
      if (this.state === 'IDEA') {
        return true
      }

      return false
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
    state() {
      return this.content.state
    },
  },
  created() {
    if (this.content.date) {
      this.date = new Date(
        DateService.getDateToTimezone(
          this.content.date,
          this.selectedCompany.zoneId
        )
      )
    } else {
      this.date = new Date()
    }

    this.hours = this.date.getHours()
    this.minutes = this.processTime(this.date.getMinutes())
    this.eod = this.content.eod

    this.updateHours()
    this.updateMinutes()
  },
  data() {
    return {
      eod: true,
      time: '00:00',
      hours: 0,
      minutes: '00',
      date: null,
      show: false,
      timezone: '',
      edited: false,
      selected: false,
    }
  },
  methods: {
    checkHours() {
      if (this.hours === '') {
        this.hours = 0
      }

      if (this.hours > 23) {
        this.hours = 0
      }

      if (this.hours < 0) {
        this.hours = 0
      }

      this.eod = false
      this.updateHours()
    },
    checkMinutes() {
      if (this.minutes === '') {
        this.minutes = '00'
      }

      if (this.minutes > 59) {
        this.minutes = 0
      }

      if (this.minutes < 0) {
        this.minutes = 0
      }

      this.eod = false
      this.updateMinutes()
    },
    clearDate() {
      this.date = new Date(this.content.date)
      this.date.setHours(23, 59, 59, 0)
      this.hours = 23
      this.minutes = 59

      this.show = false
      this.eod = true

      this.$nextTick(() => {
        this.$emit('updatedTimeClear', this.date)
      })
    },
    changeHours() {
      this.updateHours()
    },
    changeMinutes() {
      this.updateMinutes()
    },
    addHour() {
      let hours = parseInt(this.hours)
      if (hours + 1 > 23) {
        this.hours = 0
        return
      }
      hours += 1
      this.hours = hours
      this.updateHours()
    },
    decreaseHour() {
      let hours = parseInt(this.hours)
      if (hours - 1 < 0) {
        this.hours = 23
        return
      }
      hours -= 1
      this.hours = hours
      this.updateHours()
    },
    updateHours() {
      let hours = parseInt(this.hours)
      if (hours > 23) {
        this.hours = 23
      }
      if (hours < 0) {
        this.hours = 0
      }
      this.hours = hours
      this.date.setHours(hours)
      this.time = this.chours + ':' + this.cminutes
    },
    addMinute() {
      let minutes = parseInt(this.minutes)
      if (minutes + 1 > 55) {
        this.minutes = '00'
        return
      }

      if (minutes % 5 === 0) {
        minutes += 5
      } else {
        minutes = this.roundTo(minutes, 5)
      }

      this.minutes = this.processTime(minutes)
      this.updateMinutes()
    },
    /*
        addMinute() {
            let minutes = parseInt(this.minutes);
            if(minutes + 1 > 59) {
                return;
            }
            minutes += 1;
            this.minutes = this.processTime(minutes);
            this.updateMinutes();
        },
        */
    /*
        decreaseMinute() {
            let minutes = parseInt(this.minutes);
            if(minutes - 1 < 0) {
                return;
            }
            minutes -= 1;
            this.minutes = this.processTime(minutes);
            this.updateMinutes();
        },
        */
    decreaseMinute() {
      let minutes = parseInt(this.minutes)
      if (minutes - 1 < 0) {
        this.minutes = '55'
        return
      }

      if (minutes % 5 === 0) {
        minutes -= 5
      } else {
        minutes = this.roundDownTo(minutes, 5)
      }

      this.minutes = this.processTime(minutes)
      this.updateMinutes()
    },
    roundTo(number, roundto) {
      return roundto * Math.ceil(number / roundto)
    },
    roundDownTo(number, roundto) {
      return roundto * Math.floor(number / roundto)
      // return roundto * Math.ceil(number/roundto);
    },
    updateMinutes() {
      let minutes = parseInt(this.minutes)
      if (minutes > 59) {
        this.minutes = 59
      }
      if (minutes < 0) {
        this.minutes = '00'
      }
      this.date.setMinutes(minutes)
      this.minutes = this.processTime(minutes)
      this.time = this.chours + ':' + this.cminutes
    },
    processTime(item) {
      if (item < 10) {
        return '0' + item
      } else {
        return item
      }
    },
    hide() {
      if (this.show) {
        if (isNaN(this.hours)) {
          this.hours = '0'
          this.updateHours()
        }

        if (isNaN(parseInt(this.minutes))) {
          this.minutes = '00'
          this.updateMinutes()
        }

        this.$emit('updatedTime', this.hours, this.minutes)
        this.show = false
      }
    },
    toggle() {
      if (this.readonly) {
        this.show = false
        return
      }

      if (this.isDisabled) {
        this.show = false
        return
      }

      this.show = !this.show

      if (this.show) {
        if (this.eod) {
          this.date = new Date()
          this.hours = this.date.getHours()
          this.minutes = this.processTime(this.date.getMinutes())
        }

        this.eod = false

        this.updateHours()
        this.updateMinutes()
      }
    },
  },
  watch: {
    content: {
      deep: true,
      handler() {
        this.eod = this.content.eod

        if (this.content.date) {
          this.date = new Date(this.content.date)

          this.hours = this.date.getHours()
          this.minutes = this.processTime(this.date.getMinutes())
          this.eod = this.content.eod

          this.updateHours()
          this.updateMinutes()
        }
      },
    },
  },
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.time-picker {
  position: relative;
}

.time-picker.slim {
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 4px;
}

.time-picker.slim.is-open {
  border: 1px solid #2ce0c1;
}

.time-picker__selected {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: text;
  padding: 0 15px;
  min-width: 80px;
  height: 45px;

  color: #17252a;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 45px;
  line-height: 19px;
  font-family: Nunito;
}

.time-picker--slim .time-picker__selected {
  padding: 0;
  height: auto;
  line-height: 1;
}

.time-picker__selected svg {
  position: relative;
  width: 22px;
}

.time-picker__default {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: text;
  padding: 0 15px;

  min-width: 80px;
  height: 45px;

  color: #c5c5c5;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 45px;
  line-height: 19px;
  font-family: Nunito;
}

.time-picker .is-disabled {
  color: #c5c5c5 !important;
}

.time-picker.is-disabled .time-picker__default {
  cursor: not-allowed;
}

.time-picker--slim .time-picker__default {
  padding: 0;
  height: auto;
  line-height: 1;
}

.time-picker__default svg {
  position: relative;
  min-width: 13px;
}

.time-picker__default span {
  width: 100%;
}

.time-picker__time {
  position: absolute;
  top: 100%;
  right: auto;
  left: 0;
  z-index: 9992;
  box-shadow: 15px 15px 60px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background-color: #fff;
  background: #ffffff;
}

.time-picker__time--right {
}

.vc-date {
}

.ctimepicker {
  display: flex;
  align-items: center;
  vertical-align: middle;
  padding: 10px;
  padding-bottom: 0;
  width: 100%;
}

.ctimepicker__col {
  padding: 5px;
  text-align: center;
}

.ctimepicker__button {
  display: block;
  align-items: center;
  cursor: pointer;
  margin: 0;
  border: none;
  background: transparent;
  padding: 4px;
  width: 100%;
  text-align: center;
}

.ctimepicker__input {
  border: none;
  border-radius: 4px;
  background: #f6f6f7;
  width: 47px;
  height: 38px;
  color: #17252a;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 38px;
  line-height: 14px;
  font-family: Nunito;
  text-align: center;
}

.clear-timer {
  padding-bottom: 10px;
  text-align: center;
}

.clear-timer button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  margin: 0;
  border: none;
  background: none;
  padding: 0;
  width: 100%;
  text-align: center;
}

.clear-timer span {
  color: #aeb3b5;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  font-family: Nunito;
  letter-spacing: -0.2px;
  text-align: center;
}
</style>

<style>
.clear-timer svg path {
  stroke: #aeb3b5;
}

.time-picker__default svg path {
  fill: #c5c5c5 !important;
}

.time-picker .is-disabled svg path {
  fill: #c5c5c5 !important;
}

.time-picker__selected svg path {
  fill: #17252a !important;
}

.time-picker .vc-time-picker .vc-date {
  display: none !important;
}

.time-picker .vc-container.vc-blue {
  border: none;
  padding: 10px;
}

.time-picker .vc-time-picker > div:first-child {
  display: none !important;
}

.time-picker .vc-am-pm button.active {
  border: none;
  background-color: #2ce0c1 !important;
}

.time-picker .vc-select select {
  border-radius: 4px;
}
</style>
