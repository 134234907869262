<template>
  <div>
        <template v-for="item in items" >
            <button
              :class="{'selected': value === item.value, 'has-invitation': (item.value === 'invited' && item.count > 0)}"
              @click="$emit('input', item.value)"
            >
              {{ item.name }}

              <IcoOverdue
                v-if="item.value === 'invited' && item.count > 0"
                class="overdue"
              />
            </button>
        </template>
    </div>
</template>

<script>
import IcoRadio from "@/components/svg/IcoRadio.vue";
import IcoRadioSelected from "@/components/svg/IcoRadioSelected.vue";
import IcoOverdue from "@/components/svg/IcoOverdue.vue";

export default {
    components: {
      IcoOverdue,
        IcoRadio,
        IcoRadioSelected
    },
    props: ['items', 'value'],
    data() {
        return {
            selectedItem: 'members'
        }
    },
    methods: {
        setItem(item) {
            this.selectedItem = item
            this.$emit('input', item)
        }
    }
}
</script>

<style scoped>
div {
    display: inline-block;
    background: #E7E9E9;
    border-radius: 6px;
    padding: 4px;
    margin-top: 10px;
}

button {
    position: relative;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #888F91;
    padding: 8px;
    background: none;
    border: none;
    cursor: pointer;
    transition: all 200ms ease;
}

button .overdue {
  position: absolute;
  z-index: 2;
  top: -8px;
  right: -8px;
}

button:hover {
    color: #17252A;
}

button.selected {
    color: #17252A;
    background: #FFFFFF;
    border-radius: 4px;
}

</style>
