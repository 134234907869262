<template>
    <Modal @closeModal="closeModal">
        <div class="logout" style="width: 320px;">
            <h2 class="logout__title">{{ $t("6_3_2_title") }} {{ company.name }}?</h2>

            <div class="modal-description">
                <p>{{ $t("6_3_2_description") }}</p>
                <p class="modal-description__paragraph"><strong>{{ $t('6_3_2_content_no') }}:</strong> <span class="qty-secondary">{{ company.plannedContentCount }}</span></p>
                <p class="modal-description__paragraph"><strong>{{ $t('6_3_2_users_no') }}:</strong> <span class="qty-secondary">{{ company.userCount }}</span></p>
            </div>     

            <div class="line">
                <FormGroup
                :label="$t('7_1_1_label_company')"
                :required="true"
                >
                <input
                    class="form-group__input"
                    type="text"
                    :placeholder="$t('7_1_1_placeholder_company')"
                    v-model="companyName"
                    :maxlength="length.short"
                />
                </FormGroup>
            </div>   

            <div class="modal-options">
                <ul>                    
                    <li>
                        <Button style="width: 100%;" @click="closeModal()" :type="'secondary'">{{ $t('6_3_2_btn_cancel') }}</Button>
                    </li>
                    <li>
                        <Button :disabled="!enabledDelete" style="width: 100%;" @click="deleteCompany()" :type="'delete'">{{ $t("6_3_2_btn_delete") }}</Button>
                    </li>
                </ul>
            </div>

        </div>
    </Modal>
</template>

<script>
import COMPANY_DELETE from "@/graphql/CompanyDelete.gql";
import textLength from "@/constants/TextLengthConstant.js";

import Modal from '@/components/Modal.vue';
import FormGroup from '@/components/FormGroup.vue';
import Link from '@/components/design/Link.vue';

import Button from '@/components/button/Button.vue';
import ShowError from '@/components/ShowError.vue';


export default {
    components: {
        Modal,
        FormGroup,
        Button,
        Link,
        ShowError
    },
    props: {
        company: {
            type: Object,
            required: true
        }
    },
    computed: {
        length() {
            return textLength;
        },
        selectedCompany() {
            return this.$store.getters.getSelectedCompany;
        },
    },
    data() {
        return {
            disabledDeleteAction: true,
            error_form: [],
            password: '',
            companyName: '',
            invalidCompanyName: false,
            enabledDelete: false,
        }
    },
    methods: {
        closeModal() {
            this.$emit("closeModal");
        },
        deleteCompany() {
            // this.$emit("closeModal");
            try {
                this.$apollo.mutate({
                    mutation: COMPANY_DELETE,
                    variables: {
                        id: this.company.id,
                    },                
                    update: (store, { data: { createCompany } }) => {
                        
                        this.$notify({
                            title: this.$t('6_3_2_toaster_success_title_company_deleted'), 
                            text: this.$t('6_3_2_toaster_success_description_company_deleted'),
                            type: 'success',
                            duration: 5000
                        });

                        this.$store.commit("setCompany", {});
                        this.$router.push({name: 'Companies'});
                    
                    },
                });

            } catch(e) {
                console.error(e);

                this.$notify({
                    title: this.$t('6_3_2_toaster_error_title_company_delete'), 
                    text: this.$t('6_3_2_toaster_error_description_company_delete'),
                    type: 'error',
                    duration: 5000
                });
            }
        },
    },
    watch: {
        companyName() {
            if(this.companyName === this.company.name) {
                this.enabledDelete = true;
            } else {
                this.enabledDelete = false;
            }
        }
    }
}
</script>
