<template>
  <a
    target="_blank"
    :href="`mailto:support@contesaur.com?subject=${subject}&body=${body}`"
  >
    {{ title }}
  </a>
</template>

<script>
export default {
  props: {
    subject: {
      type: String,
      default: 'Contact us',
    },
    title: {
      type: String,
      default: 'support@contesaur.com',
    },
    body: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped>
a {
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  cursor: pointer;
  color: #2ce0c1;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 138%;
  font-family: Nunito;
  letter-spacing: -0.4px;
  text-decoration: none;
}

a:hover {
  color: #2ce0c1;
}

a:active {
  color: #23c2a7;
}
</style>
