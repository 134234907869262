<template>
    <div class="info">
        <div class="info__ico"><IcoInfo /></div>
        <div class="info__title">{{ $t("3_1_info_title") }}</div>
        <div class="info__desc">{{ $t("3_1_info_description") }}</div>
    </div>
</template>

<script>
import IcoInfo from '@/components/svg/IcoInfo.vue';

export default {
    components: {
        IcoInfo
    }
}
</script>

<style scoped>
.info{
    margin-top: 20px;
    padding: 15px;
    position: relative;
    background: #FBFBFB;
    border-radius: 8px;
}

.info__ico{
    position: absolute;
    top: -6px;
    right: -6px;
}

.info__title{
    color: #AEB3B5;
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: -0.4px;
    text-transform: uppercase;
}

.info__desc{
    margin-top: 6px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: -0.4px;
    color: #17252A;
}
</style>