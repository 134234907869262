<template>
    <div class="select-color" v-click-outside="hide">
        <div 
            class="select-color__selected"
            @click="toggle"
            >
            <SelectColorItem
                :state="state"
                />

            <IcoDown v-if="!disabled || (disabled && ( state.id === 'IN_REVIEW' || state.id === 'APPROVED' || state.id === 'IN_PROGRESS'))" :class="{'is-opened': showData}" :textColor="state.textColor" />
        </div>
        <div v-if="showData" class="select-color__data">
            <div 
                class="select-color__item"
                v-for="(item, index) in items"
                :key="index"
                @click="selectItem(index)"
                >

                <SelectColorItem :state="item" />
            </div>
        </div>
        
    </div>
</template>

<script>
import { statesFull, statesReadonly } from "@/constants/StateConstant.js";
import SelectColorItem from '@/components/SelectColorItem.vue';
import ClickOutside from 'vue-click-outside';
import IcoDown from '@/components/svg/IcoDown.vue';

export default {
    components: {
        SelectColorItem,
        IcoDown
    },
    directives: {
        ClickOutside
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
            default: ""
        }
    },
    created() {
        if( this.value != "") {
            this.selected = this.allItems.find(item => item.id == this.value);
        } else {
            this.selected = this.allItems[0];
        }
    },
    computed: {        
        items() {
            if( this.disabled ) {
                return statesReadonly
            }

            return statesFull;
        },
        allItems() {
            return statesFull;
        },
        state() {
            if( this.selected.id != "" ) {
                return this.selected;
            }

            return this.items[0];
        }
    },
    data() {
        return {
            showData: false,
            selected: {},
        }
    },
    methods: {
        hide() {
            this.showData = false;
        },
        toggle() { 
            if(this.disabled && !( this.state.id === 'IN_REVIEW' || this.state.id === 'APPROVED' || this.state.id === 'IN_PROGRESS' ) ) {
                this.showData = false;
                return
            }

            this.showData = !this.showData;
        },
        selectItem(index) {
            if(this.disabled && !( this.state.id === 'IN_REVIEW' || this.state.id === 'APPROVED' || this.state.id === 'IN_PROGRESS' ) ) {
                return
            }

            this.selected = this.items[index];
            this.$emit('input', this.state.id);
            this.$emit('save_changed_value', this.state.id);
            this.hide();
        }
    },
    watch: {
        value() {
            this.selected = this.allItems.find(item => item.id == this.value);            
        }
    }
}
</script>


<style scoped>
    .select-color{
        width: 121px;
        position: relative;
    }

    .select-color__selected{
        position: relative;
    }

    .select-color__selected svg{
        position: absolute;
        top: 11px;
        right: 7px;
        transform: rotate(0deg);    
        transition-duration: 0.2s;
        transition-property: transform;
    }

    .select-color__selected svg.is-opened{
        transform: rotate(180deg);
    }

    .select-color__data{
        position: absolute;
        z-index: 9992;
        top: 100%;
        right: -20px;
        width: 130px;

        background: #FFFFFF;
        border-radius: 8px;
        box-shadow: 15px 15px 60px rgba(0, 0, 0, 0.05);

        padding-bottom: 4px;
        padding-top: 10px;
    }

    .select-color__item{
        cursor: pointer;
        padding: 4px 7px;
    }
</style>