<template>
  <Sidebar>
    <h2>{{ $t("4_3_title") }}</h2>
    <p>{{ $t("4_3_description") }}</p>

    <div class="line">
      <FormGroup
        :focused="focusCompanyId"
        :label="$t('4_3_label_company_id')"
        :required="false"
      >
        <input
          class="form-group__input"
          type="text"
          placeholder="035dDvx881xzlwq"
          v-model="company_id"
          @focus="focusCompanyId = true"
          @blur="focusCompanyId = true"
          :maxlength="length.short"
        />
      </FormGroup>
    </div>

    <div class="line line--half">
      <p>
        <Link @click="showModalHowJoinCompany = true">{{
          $t("4_3_link_get_company_id")
        }}</Link>
      </p>
    </div>

    <div class="line">
      <Button
        style="width: 100%"
        @click="joinCompany()"
        :size="'max'"
        :type="'primary'"
        >{{ $t("4_3_link_join_company") }}</Button
      >
    </div>

    <div class="line">
      <p>
        {{ $t("4_3_no_company") }}
        <Link @click="createCompany()">{{
          $t("4_3_link_create_company")
        }}</Link>
      </p>
    </div>

    <ModalHowJoinCompany
      v-if="showModalHowJoinCompany"
      @closeModal="showModalHowJoinCompany = false"
    />
  </Sidebar>
</template>

<script>
import textLength from "@/constants/TextLengthConstant.js";
import Sidebar from "@/components/layout/Sidebar.vue";
import Button from "@/components/button/Button.vue";
import Link from "@/components/design/Link.vue";
import FormGroup from "@/components/FormGroup.vue";
import ModalHowJoinCompany from "@/components/ModalHowJoinCompany.vue";

export default {
  components: {
    Sidebar,
    Button,
    Link,
    FormGroup,
    ModalHowJoinCompany,
  },
  computed: {
    length() {
      return textLength;
    },
  },
  data() {
    return {
      focusCompanyId: false,

      showModalHowJoinCompany: false,
      openLightBox: false,
      company_id: "",
      invalidForm: false,
      media: [
        {
          thumb: require("@/assets/images/screenshot.png"),
          src: require("@/assets/images/screenshot.png"),
          caption: "test",
        },
      ],
    };
  },
  methods: {
    joinCompany() {
      // console.log("process to join company");
    },
    createCompany() {
      this.$router.push({ name: "CreateCompany" });
    },
  },
};
</script>

<style>
.vue-lb-thumbnail-wrapper {
  display: none !important;
}
</style>
