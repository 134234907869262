<template>
    <div         
        class="select-person-item"
        :class="{'is-active': isActive}"
        @click="selectPerson()"
        >

        <div class="select-person-item__data">
            <div>
                <div class="select-person-item__avatar">
                    <Avatar :size="'small'" :name="user.name" :user="user" />
                </div>
                <div class="select-person-item__name">
                    {{ user.name }}            
                </div>
            </div>            
        </div>
        
        <button v-if="isActive" class="select-person-item__delete">
            <IcoCross />
        </button>
    </div>
</template>

<script>
import Avatar from '@/components/Avatar.vue';

import IcoCross from '@/components/svg/small/IcoCross.vue';

export default {
    components: {
        Avatar,
        IcoCross
    },
    props: {
        user: {
            type: Object,
            required: true
        },
        selected: {
            type: Object,
            default() {
                return {};
            }            
        }
    },
    computed: {
        isActive() {

            // console.log("this.selected", this.selected);

            if( !this.isEmpty(this.selected) ) {
                if( this.selected.id == this.user.id ) {
                    return true;
                }
            }

            return false;
        }
    },
    methods: {
        selectPerson() {
            if(this.isActive) {
                this.unsetUser();
                return;
            }
            this.$emit("selectPerson", this.user);
        },
        isEmpty(obj) {
            return Object.keys(obj).length === 0;
        },
        unsetUser() {
            this.$emit("unsetUser");
        }
    }
}
</script>

<style scoped>
    .select-person-item{
        position: relative;
        display: block;
        width: 100%;
        padding-right: 12px;
    }

    .select-person-item__data{
        display: block;
        width: 100%;
        max-width: 220px;
    }

    .select-person-item__data > div{
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .select-person-item__name{
        
        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        color: #17252A;

        word-wrap: break-word;
        overflow: hidden;
        height: 18px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .select-person-item__delete{
        position: absolute;
        top: 2px;
        right: -5px;
        border: 0;
        background: transparent;
        padding: 0;        
        cursor: pointer;
    }
</style>