<template>
  <div
    @click="editContent()"
    class="idea-week"
    :class="{
      'old-day': today.getTime() < currentDate.getTime(),
      'is-overdue': item.state !== 'PUBLISHED' && isOverdue,
      'read-only': role === 'READ_ONLY',
    }"
    :draggable="role !== 'READ_ONLY'"
    @dragstart="dragStart"
    @dragend="dragEnd"
    @dragover.prevent
  >
    <div class="idea-week__inner">
      <div
        class="idea-week__label"
        :style="{ color: getColor(computedChannelName) }"
      >
        {{ computedChannelName }}
      </div>
      <div class="idea-week__data">
        <div
          v-if="!item.eod"
          class="idea-week__time"
          :class="{
            'old-day': today.getTime() < currentDate.getTime(),
            'is-overdue': item.state !== 'PUBLISHED' && isOverdue,
          }"
        >
          <IcoTime /> {{ contentTime }}
        </div>
        <div class="idea-week__controll" v-click-outside="hideControll">
          <VDropdown
            :skidding="-42"
            :placements="'bottom-end'"
            :triggers="[]"
            :shown="showControll"
            :eagerMount="autoHide"
            :auto-hide="autoHide"
            @hide="closeData"
          >
            <button
              class="idea-week__controll__button"
              @click.stop="toggleControll"
            >
              <IcoKebabWeekIdea />
            </button>
            <template #popper>
              <div class="idea-week__controll__items">
                <ul>
                  <li>
                    <button @click.stop="shareContent()">
                      <IcoShare /> {{ $t('8_2_content_btn_share') }}
                    </button>
                  </li>
                  <li v-if="role !== 'READ_ONLY'">
                    <button @click.stop="duplicateContent()">
                      <IcoDuplicate /> {{ $t('8_2_content_btn_duplicate') }}
                    </button>
                  </li>
                  <li v-if="role !== 'READ_ONLY'">
                    <button @click.stop="deleteContent()">
                      <IcoBin /> {{ $t('8_2_content_btn_delete') }}
                    </button>
                  </li>
                </ul>
              </div>
            </template>
          </VDropdown>
        </div>
      </div>

      <div class="idea-week__title">
        {{ item.name }}
      </div>

      <div class="idea-week__footer">
        <div class="idea-week__status">
          <IdeaState :id="item.state" />
        </div>

        <Avatar :size="'small'" :name="item.owner.name" :user="item.owner" />
      </div>
    </div>
  </div>
</template>

<script>
import { DateService } from '@/services/DateService'
import { ColorService } from '@/services/ColorService.js'
import { DateTime } from 'luxon'

import moment from 'moment'
import IdeaState from '@/components/IdeaState.vue'
import Avatar from '@/components/Avatar.vue'

import IcoKebabWeekIdea from '@/components/svg/IcoKebabWeekIdea.vue'
import IcoTime from '@/components/svg/IcoTime.vue'
import IcoKebab from '@/components/svg/IcoKebab.vue'
import IcoBin from '@/components/svg/IcoBin.vue'
import IcoDuplicate from '@/components/svg/IcoDuplicate.vue'
import IcoShare from '@/components/svg/IcoShare.vue'
import ClickOutside from 'vue-click-outside'

export default {
  components: {
    Avatar,
    IcoKebabWeekIdea,
    IdeaState,
    IcoTime,
    IcoKebab,
    IcoBin,
    IcoDuplicate,
    IcoShare,
  },
  directives: {
    ClickOutside,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    draggable: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    computedDate() {
      return DateTime.fromISO(this.item.date).setZone(
        this.selectedCompany.zoneId
      )
    },
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
    computedChannelName() {
      if (this.item.channel && this.item.channel.name) {
        return this.item.channel.name
      }

      return ''
    },
    isOverdue() {
      return (
        DateTime.fromISO(this.item.date)
          .setZone(this.selectedCompany.zoneId)
          .toMillis() <
        DateTime.now().setZone(this.selectedCompany.zoneId).toMillis()
      )
    },
    contentTime() {
      return DateTime.fromISO(this.item.date)
        .setZone(this.selectedCompany.zoneId)
        .toLocaleString({ hour: '2-digit', minute: '2-digit' })
    },
  },
  data() {
    const d = new Date()
    d.setHours(0, 0, 0, 0)

    return {
      autoHide: true,
      today: new Date(),
      currentDate: d,
      showControll: false,
      dateFrom: '',
      dateTo: '',
    }
  },
  filters: {
    time: function (time) {
      return moment(time).format('H:mm')
    },
  },
  methods: {
    async shareContent() {
      this.showControll = false
      const link =
        '' +
        window.location.protocol +
        '//' +
        window.location.host +
        '/calendar/' +
        this.selectedCompany.id +
        '/' +
        this.item.id

      await navigator.clipboard.writeText(link)

      this.$notify({
        title: this.$t('share_content_title_copied_to_clipboard'),
        text: this.$t('share_content_description_copied_to_clipboard'),
        type: 'info',
        duration: 5000,
      })
    },
    duplicateContent() {
      this.showControll = false
      this.$store.commit('setDuplicateContentModalId', this.item.id)
    },
    deleteContent() {
      this.showControll = false
      this.$store.commit('setContentToDelete', this.item)
    },
    editContent() {
      this.$store.commit('setEditContentModalStatus', this.item)
    },
    getColor(name) {
      return ColorService.computedColor(name)
    },
    dragStart(e) {
      this.$store.commit('setGlobalDrag', true)
      const target = e.target
      e.dataTransfer.setData('idea_id', this.item.id)
      e.dataTransfer.setData('idea_date', this.item.date)
      e.dataTransfer.setData('idea_eod', this.item.eod ? 'yes' : 'no')
    },
    dragEnd(e) {
      this.$store.commit('setGlobalDrag', false)
      this.$store.commit('setActiveDropZone', '')
    },
    toggleControll() {
      this.showControll = !this.showControll
      this.autoHide = false
      setTimeout(() => (this.autoHide = true), 300)
    },
    hideControll() {
      this.showControll = false
    },
    closeData() {
      this.showControl = false
    },
  },
}
</script>
