<template>
  <div class="comments" :class="{ 'show-error': showError }">
    <div class="comments-create" :class="{ 'has-error': invalidComment }">
      <div style="flex: 0 0 25px">
        <Avatar :name="loggedUser.name" :user="loggedUser" :size="'small'" />
      </div>

      <div class="position-relative">
        <div
          ref="textareaCover"
          class="textarea-cover"
          style="z-index: 10"
          v-html="commentMention"
        />

        <button
          v-if="!addComment"
          class="comments__create"
          @click="startComment()"
        >
          {{ $t('10_1_btn_create_comment') }}
        </button>

        <mentionable
          v-if="addComment"
          :keys="['@', '＠']"
          :items="users"
          :insertSpace="true"
          omit-key
        >
          <textarea-autosize
            :min-height="30"
            v-model="newComment"
            :placeholder="$t('10_1_btn_create_comment')"
            ref="comment"
            @keyup="commentScroll"
            @scroll="commentScroll"
            @input="invalidComment = false"
            :maxlength="length.comment"
          ></textarea-autosize>

          <template #item="{ item }">
            <div @click="selectUser()" class="user">
              <span class="font-weight-bold">
                {{ item.value }}
              </span>
            </div>
          </template>
        </mentionable>
      </div>
    </div>

    <div v-if="addComment" class="comments-create__controll">
      <Button @click="createComment()" :type="'primary'" :size="'small'">{{
        $t('10_1_btn_comment_save')
      }}</Button>
      <Button @click="clearComment()" :type="'secondary'" :size="'small'">{{
        $t('10_1_btn_comment_cancel')
      }}</Button>
    </div>

    <div class="comments__list" :class="{ 'show-more': !showMore }">
      <Comment
        v-for="(comment, index) in comments_data.slice().reverse()"
        :key="comment.id"
        :index="index"
        :comment="comment"
        @deleteComment="deleteComment"
        @updateComment="updateComment"
        @editComment="editComment"
        @closeComment="closeComment"
      />
    </div>

    <div class="comments__footer">
      <div v-if="showMore" class="comments__show-all">
        <Button @click="showMoreComments" style="width: 100%" :size="'small'">{{
          $t('10_1_view_more_comments')
        }}</Button>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import COMMENT_CREATE from '@/graphql/CommentCreate.gql'
import COMMENT_UPDATE from '@/graphql/CommentUpdate.gql'
import { CommentService } from '@/services/CommentService.js'

import textLength from '@/constants/TextLengthConstant.js'

import Avatar from '@/components/Avatar.vue'
import Button from '@/components/button/Button.vue'
import Comment from '@/components/comments/Comment.vue'
import { Mentionable } from 'vue-mention'

Mentionable.methods.onKeyDown = function (e) {
  if (this.key) {
    if (e.key === 'ArrowDown' || e.keyCode === 40) {
      this.selectedIndex++
      if (this.selectedIndex >= this.displayedItems.length) {
        this.selectedIndex = 0
      }
      this.cancelEvent(e)
    }
    if (e.key === 'ArrowUp' || e.keyCode === 38) {
      this.selectedIndex--
      if (this.selectedIndex < 0) {
        this.selectedIndex = this.displayedItems.length - 1
      }
      this.cancelEvent(e)
    }
    if (
      (e.keyCode === 13 || e.keyCode === 9) &&
      this.displayedItems.length > 0
    ) {
      this.applyMention(this.selectedIndex)
      this.cancelEvent(e)
    }
    if (e.key === 'Escape' || e.keyCode === 27) {
      this.closeMenu()
      this.cancelEvent(e)
    }
  }
}

export default {
  components: {
    Avatar,
    Button,
    Comment,
    Mentionable,
  },
  props: {
    comments: {
      type: Array,
      default() {
        return []
      },
    },
    contentId: {
      type: String,
      required: true,
    },
    showError: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    length() {
      return textLength
    },
    codelist() {
      return this.$store.getters.getCodelist
    },
    loggedUser() {
      return this.$store.getters.getMe
    },
    commentMention() {
      function escapeRegExp(text) {
        return text.replaceAll(/[-[\]{}()*+?.,\\^$#\s]/g, '\\$&')
      }

      if (typeof this.newComment?.replaceAll !== 'function') {
        return this.newComment
      }

      return CommentService.highlightUsers(this.newComment, this.users)
    },
  },
  mounted() {
    this.initComments()
  },
  data() {
    return {
      invalidComment: false,
      addComment: false,
      newComment: '',
      comment: '',
      comments_data: [],
      comments_data_init: [],
      users: [],
      showMore: true,
      editingItems: 0,
    }
  },
  methods: {
    editComment() {
      this.editingItems += 1
      this.$emit('opened')
    },
    closeComment() {
      if (this.editingItems > 0) {
        this.editingItems -= 1
      }

      if (this.editingItems < 1) {
        this.$emit('closed')
      }
    },
    initComments() {
      this.generateUserList()
      this.comments_data = this.comments

      if (this.comments_data.length < 4) {
        this.showMore = false
      }
    },
    startComment() {
      this.addComment = true

      setTimeout(() => {
        this.$refs.comment.$el.focus()
      }, 100)

      this.editComment()
    },
    selectUser() {
      setTimeout(() => {
        this.$refs.comment.$el.focus()
      }, 100)
    },
    showMoreComments() {
      this.showMore = false
      this.comments_data_init = this.comments_data
    },
    generateUserList() {
      let users = []
      if (this.codelist.users) {
        this.codelist.users
          .filter((user) => user.role !== 'APPROVER')
          .forEach((user) => {
            users.push({
              value: '@' + user.value,
            })
          })
      }
      this.users = users
    },
    mounted() {
      setTimeout(() => {
        this.resize()
        window.addEventListener('resize', this.resize)
        this.$once('hook:beforeDestroy', () => {
          window.removeEventListener('resize', this.resize)
        })
      })
    },
    resize() {
      const barWidth =
        this.$refs.comment.getBoundingClientRect().width -
        this.$refs.comment.clientWidth -
        2 // border
      this.$refs.textareaCover.style.paddingRight = `calc(12px + ${barWidth}px)`
    },
    commentScroll() {
      this.$refs.textareaCover.scrollTop = this.$refs.comment.scrollTop
      this.resize()
    },
    createComment() {
      this.invalidComment = false

      if (this.newComment === '') {
        this.invalidComment = true

        this.$notify({
          title: this.$t('10_1_toaster_error_title_comment_name_empty'),
          text: this.$t('10_1_toaster_error_description_comment_name_empty'),
          type: 'error',
          duration: 5000,
        })

        return
      }

      try {
        this.$apollo
          .mutate({
            mutation: COMMENT_CREATE,
            variables: {
              contentId: this.contentId,
              text: this.newComment,
            },
            update: (store, { data: { createComment } }) => {
              this.$notify({
                title: this.$t('10_1_toaster_success_title_comment_create'),
                text: this.$t(
                  '10_1_toaster_success_description_comment_create'
                ),
                type: 'success',
                duration: 5000,
              })

              this.newComment = ''
              this.addComment = false
            },
          })
          .then((data) => {
            this.$emit('commentsUpdated')

            this.closeComment()
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_title_comment_create'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_title_comment_create'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },

    updateComment(comment, text) {
      try {
        this.$apollo
          .mutate({
            mutation: COMMENT_UPDATE,
            variables: {
              id: comment.id,
              text: text,
            },
            update: (store, { data: { updateComment } }) => {
              /* this.$notify({
                    title: this.$t('10_1_toaster_success_title_comment_updated'),
                    text: this.$t('10_1_toaster_success_description_comment_updated'),
                    type: 'success',
                    duration: 5000
                }); */

              let editedComment = this.comments_data.find(
                (item) => item.id == comment.id
              )
              // console.log("editedComment", editedComment);
              editedComment.text = text
            },
          })
          .then((data) => {
            // console.log("data", data);
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_title_comment_update'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_title_comment_update'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },

    deleteComment(comment) {
      try {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation ($id: ID!) {
                deleteComment(id: $id)
              }
            `,
            variables: {
              id: comment.id,
            },
            update: (store, { data: { deleteComment } }) => {
              this.$notify({
                title: this.$t('10_1_toaster_success_title_comment_deleted'),
                text: this.$t(
                  '10_1_toaster_success_description_comment_deleted'
                ),
                type: 'success',
                duration: 5000,
              })

              this.comments_data = this.comments_data.filter(
                (item) => item.id != comment.id
              )
              this.clearComment()
            },
          })
          .then((data) => {
            // console.log("data", data);
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('10_1_toaster_error_title_comment_delete'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('10_1_toaster_error_title_comment_delete'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },

    clearComment() {
      this.newComment = ''
      this.addComment = false

      this.closeComment()
    },
  },
  watch: {
    codelist() {
      this.generateUserList()
    },
    comments() {
      this.initComments()
    },
  },
}
</script>

<style scoped>
.comments {
  margin-top: 30px;
}
.comments-create {
  display: flex;
  gap: 8px;
}

.comments-create__controll {
  display: flex;
  gap: 5px;
  padding-top: 10px;
  padding-left: 30px;
}

/*
.comments-create textarea{
    width: 100%;
    border: 1px solid #C5C5C5;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 7px 10px;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 129%;
    letter-spacing: -0.2px;
    color: #17252A;
}
*/

.comments__create {
  cursor: text;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  background: transparent;
  padding: 0 10px;
  width: 100%;
  height: 33px;
  color: #bfc5c5;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  line-height: 33px;
  font-family: Nunito;
  letter-spacing: -0.2px;
  text-align: left;
}

.comments__footer {
  margin-top: 25px;
}
</style>

<style>
[id^='popover_'].tooltip {
  display: block !important;
  z-index: 10000;
}
[id^='popover_'].tooltip .tooltip-inner {
  border-radius: 16px;
  background: transparent;
  padding: 5px 10px 4px;
  color: white;
}
[id^='popover_'].tooltip .tooltip-arrow {
  position: absolute;
  z-index: 1;
  margin: 5px;
  border-style: solid;
  width: 0;
  height: 0;
}
[id^='popover_'].tooltip[x-placement^='top'] {
  margin-bottom: 5px;
}
[id^='popover_'].tooltip[x-placement^='top'] .tooltip-arrow {
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
  border-width: 5px 5px 0 5px;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  border-left-color: transparent !important;
}
[id^='popover_'].tooltip[x-placement^='bottom'] {
  margin-top: 5px;
}
[id^='popover_'].tooltip[x-placement^='bottom'] .tooltip-arrow {
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
  border-width: 0 5px 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-left-color: transparent !important;
}
[id^='popover_'].tooltip[x-placement^='right'] {
  margin-left: 5px;
}
[id^='popover_'].tooltip[x-placement^='right'] .tooltip-arrow {
  top: calc(50% - 5px);
  left: -5px;
  margin-right: 0;
  margin-left: 0;
  border-width: 5px 5px 5px 0;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  border-left-color: transparent !important;
}
[id^='popover_'].tooltip[x-placement^='left'] {
  margin-right: 5px;
}
[id^='popover_'].tooltip[x-placement^='left'] .tooltip-arrow {
  top: calc(50% - 5px);
  right: -5px;
  margin-right: 0;
  margin-left: 0;
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
}
[id^='popover_'].tooltip.popover .popover-inner {
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background: #f9f9f9;
  padding: 10px;
  color: black;
}
[id^='popover_'].tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}
[id^='popover_'].tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}
[id^='popover_'].tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}
.mention-item {
  border-radius: 4px;
  padding: 8px 10px;
  color: #23c2a7;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  font-family: Nunito;
  text-align: left;
}
.mention-selected {
  background: rgba(44, 224, 193, 0.2);
  color: #23c2a7;
}
.textarea-cover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  border: 1px solid transparent;
  padding: 6px 12px;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  pointer-events: none;
  color: transparent;
  color: #17252a;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 129%;
  font-family: Nunito;
  letter-spacing: -0.2px;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
}

.comments-create textarea,
.comment__edit textarea {
  -webkit-text-fill-color: transparent;
  box-sizing: border-box;
  border: 1px solid #17252a;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  padding: 6px 12px;

  width: 100%;
  color: #17252a;
  color: #000;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 129%;
  font-family: Nunito;
  letter-spacing: -0.2px;
}

.comments-create.has-error textarea,
.comment__edit.has-error textarea {
  border: 1px solid #f45050 !important;
}

.comments-create textarea,
.comment__edit textarea {
  border: 1px solid #c5c5c5;
}

.comments-create textarea:focus,
.comment__edit textarea:focus {
  border: 1px solid hsl(170, 74%, 53%);
}

.comments-create textarea:focus,
.comment__edit textarea:focus {
  outline: none;
}

.comments-create textarea::placeholder,
.comment__edit textarea::placeholder {
  color: #bfc5c5;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 129%;
  font-family: Nunito;
  letter-spacing: -0.2px;
}

.textarea-under {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  border: 1px solid transparent;
  padding: 6px 12px;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  pointer-events: none;
  color: transparent;
  white-space: pre-wrap;
  overflow-wrap: anywhere;
}
.comment {
  width: 100%;
}
.mention-str {
  position: relative;
  border-radius: 3px;
  background: rgba(44, 224, 193, 0.2);
  color: #23c2a7;
}

.position-relative {
  position: relative;
  width: 100%;
}

.tooltip.popover {
  background: transparent !important;
}
/*
    .mention-str{
        display: inline-block;
        position: relative;
        color: #23C2A7;
    }

    .textarea-under .mention-str{
        display: inline-block;
        background: rgba(44, 224, 193, 0.2);
        border-radius: 3px;
        padding: 2px;
        position: relative;
        left: -2px;
        color: transparent;
    }
    */

.comments__list .comment:nth-child(n + 4) {
  display: none !important;
}

.comments__list.show-more .comment:nth-child(n + 4) {
  display: block !important;
}

.comments.show-error .comments-create textarea,
.comments.show-error .comment__edit textarea {
  border: 1px solid #f45050;
}
</style>
