<template>
    <Modal @closeModal="closeModal">
        <div class="logout" style="width: 320px;">
            <h2 class="logout__title">{{ $t('9_1_modal_close_content_title') }}</h2>

            <div class="modal-description">
                <p>{{ $t('9_1_modal_close_content_description') }}</p>
            </div>        

            <div class="modal-options">
                <ul>                    
                    <li>
                        <Button style="width: 100%;" @click="closeModal()" :type="'secondary'">{{ $t('9_1_modal_close_content_btn_cancel') }}</Button>
                    </li>
                    <li>
                        <Button style="width: 100%;" @click="deleteContent()" :type="'delete'">{{ $t("9_1_modal_close_content_btn_close") }}</Button>
                    </li>
                </ul>
            </div>

        </div>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import Button from '@/components/button/Button.vue';

export default {
    components: {
        Modal,
        Button
    },
    methods: {
        closeModal() {
            this.$store.commit("setModalConfirmCloseCreateContent", false)
        },
        deleteContent() {
            this.$store.commit("setCreateContentModalStatus", false);
            this.$store.commit("setDuplicateContentModalId", "");
            this.$store.commit("setModalConfirmCloseCreateContent", false)
        }
    }
}
</script>
