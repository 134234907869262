<template>
  <div v-click-outside="hide" class="button-group-by">
    <button
      v-tooltip="{ content: $t('8_5_group_by_tooltip') }"
      class="button-group-by__button button-group-by__button--title"
      :class="{ 'is-opened': showGroupBy }"
      @click="toggleGroupBy"
    >
      <IcoGroupBy />&nbsp; {{ $t('8_5_group_by_title') }}: {{ selectedGroupBy }}
    </button>

    <div v-if="showGroupBy" class="button-group-by__data">
      <div class="button-group-by__wrapper">
        <div class="button-group-by__header">
          {{ $t('8_5_group_by_title') }}
        </div>

        <div class="button-group-by__items">
          <ul class="button-group-by__list">
            <li
              class="button-group-by__item"
              v-for="(item, index) in optionList"
              :key="index"
            >
              <button class="button-group-by__button" @click="setGroupBy(item)">
                {{ item.name }}
                <span v-if="item.default">
                  ({{ $t('8_5_group_by_default') }})
                </span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import vuescroll from 'vuescroll'
import IcoGroupBy from '@/components/svg/IcoGroupBy.vue'

export default {
  components: {
    IcoGroupBy,
    vuescroll,
  },
  directives: {
    ClickOutside,
  },
  props: {
    disableOptions: {
      type: Array,
      default() {
        return []
      },
    },
    type: {
      type: String,
      default: 'content',
    },
  },
  computed: {
    optionList() {
      if (this.$route.name === 'Ideas') {
        return this.groupByListIdea
      } else {
        return this.groupByList
      }
    },
    selectedGroupByState() {
      return this.$store.getters.getSelectedGroupBy
    },
    selectedGroupByStateIdea() {
      return this.$store.getters.getSelectedIdeaGroupBy
    },
  },
  mounted() {
    if (this.$route.name === 'Ideas') {
      this.selectedGroupBy = this.groupByListIdea.find(
        (item) => item.id === this.selectedGroupByStateIdea
      ).name
    } else {
      this.selectedGroupBy = this.groupByList.find(
        (item) => item.id === this.selectedGroupByState
      ).name
    }
  },
  data() {
    return {
      showGroupBy: false,
      list: [],
      groupByList: [
        {
          name: this.$t('8_5_group_by_item_state'),
          default: true,
          id: 'state',
        },
        {
          name: this.$t('8_5_group_by_item_owner'),
          default: false,
          id: 'owner',
        },
        {
          name: this.$t('8_5_group_by_item_channel'),
          default: false,
          id: 'channel',
        },
        {
          name: this.$t('8_5_group_by_item_date'),
          default: false,
          id: 'date',
        },
        {
          name: this.$t('8_5_group_by_item_stdc'),
          default: false,
          id: 'stdcs',
        },
        {
          name: this.$t('8_5_group_by_item_personas'),
          default: false,
          id: 'personas',
        },
        {
          name: this.$t('8_5_group_by_item_products'),
          default: false,
          id: 'products',
        },
        {
          name: this.$t('8_5_group_by_item_tags'),
          default: false,
          id: 'tags',
        },
      ],
      groupByListIdea: [
        {
          name: this.$t('8_5_group_by_item_channel'),
          default: true,
          id: 'channel',
        },
        /*
        {
          name: this.$t('8_5_group_by_item_ideas_date'),
          default: false,
          id: 'date',
        },
         */
        {
          name: this.$t('8_5_group_by_item_owner'),
          default: false,
          id: 'owner',
        },
        {
          name: this.$t('8_5_group_by_item_tags'),
          default: false,
          id: 'tags',
        },
        {
          name: this.$t('8_5_group_by_item_products'),
          default: false,
          id: 'products',
        },
        {
          name: this.$t('8_5_group_by_item_personas'),
          default: false,
          id: 'personas',
        },
        {
          name: this.$t('8_5_group_by_item_stdc'),
          default: false,
          id: 'stdcs',
        },
      ],
      selectedGroupBy: this.$t('8_5_group_by_item_state'),
      scrollPanelGroupBy: {
        initialScrollY: false,
        initialScrollX: false,
        scrollingX: true,
        scrollingY: true,
        speed: 300,
        easing: undefined,
        verticalNativeBarPos: 'right',
      },
    }
  },
  methods: {
    toggleGroupBy() {
      this.showGroupBy = !this.showGroupBy
    },
    setGroupBy(item) {
      if (this.$route.name === 'Ideas') {
        this.$store.commit('setGroupByIdea', item.id)
      } else {
        this.$store.commit('setGroupBy', item.id)
      }

      this.selectedGroupBy = item.name
      this.showGroupBy = false
    },
    hide() {
      this.showGroupBy = false
    },
  },
}
</script>
