<template>
  <div>
    <div class="sticky-header">
      <Menu />
    </div>

    <div
      class="container container--top"
      :class="{
        'days-to-expiration': this.selectedCompany && this.selectedCompany.showExpirationWarning,
      }"
    >
      <h1 class="main-title">{{ $t('6_2_title') }}</h1>

      <div class="company-list">
        <div class="company-list__header">
          <div class="company-list__count">
            {{ $t('6_2_company_no') }}: <span>{{ companies.length }}</span>
          </div>
          <div class="company-list__controll">
            <div class="company-list__search">
              <Search v-model="search" />
            </div>
            <div v-if="role !== 'READ_ONLY'" class="company-list__create">
              <button @click="createCompany()" class="menu__create__btn">
                {{ $t('6_1_btn_add_company') }}
              </button>
            </div>
          </div>
        </div>

        <div v-if="searchItems.length > 0" class="company-list__data">
          <CompanyItem
            v-for="(item, index) in searchItems"
            :key="index"
            :company="item"
            :isActive="selectedCompany.id === item.id"
          />
        </div>

        <div v-if="companies.length === 0" class="company-list__data">
          <CompanyItemPreloader />
          <CompanyItemPreloader />
          <CompanyItemPreloader />
        </div>

        <div
          v-if="companies.length > 0 && searchItems.length === 0"
          class="company-list__data"
        >
          <p>{{ $t('5_2_no_result_p1') }}</p>
          <p>{{ $t('5_2_no_result_p2') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'

import Menu from '@/components/Menu.vue'
import CompanyItem from '@/components/CompanyItem.vue'
import CompanyItemPreloader from '@/components/CompanyItemPreloader.vue'
import Search from '@/components/Search.vue'
import IcoSearch from '@/components/svg/IcoSearch.vue'
import SearchInput from '../components/SearchInput.vue'

export default {
  components: {
    Menu,
    CompanyItem,
    CompanyItemPreloader,
    Search,
    IcoSearch,
    SearchInput,
  },
  apollo: {
    companies: {
      query: gql`
        query getCompanies {
          companies {
            name
            id
            description
            users {
              name
              id
            }
            userCount
            plannedContentCount
            billingDate
            billingOverdue
            aiTokenAvailable
          }
        }
      `,
    },
  },
  computed: {
    me() {
      return this.$store.getters.getMe
    },
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    searchItems() {
      if (this.search === '') {
        return this.companies
      }

      return this.companies.filter((item) => {
        return (
          item.name
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036F]/g, '')
            .indexOf(
              this.search
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036F]/g, '')
            ) !== -1
        )
      })
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
  },
  data() {
    return {
      companies: [],
      search: '',
    }
  },
  methods: {
    createCompany() {
      this.$router.push({ name: 'AddCompany' })
    },
  },
}
</script>

<style scoped>
.company-list {
  margin-top: 57px;
  padding-bottom: 57px;
}

.company-list__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #eaeaea;
  padding-bottom: 15px;
}

.company-list__count {
  color: #aeb3b5;
  font-style: normal;
  font-weight: 800;
  font-size: 21px;
  line-height: 21px;
  font-family: Nunito;
  text-align: center;
}

.company-list__controll {
  display: flex;
  gap: 8px;
}

.company-list__search {
  width: 263px;
}

.company-list__count span {
  color: #17252a;
}

.company-list__data {
  margin-top: 30px;
}
</style>

