<template>
  <div>
    <Menu />
    <div v-if="!billingOverdue" class="wrapper wrapper--calendar wrapper--full">
      <CalendarHeader
        :selectedDate="formattedCurrentDate"
        :type="'calendarWeekView'"
        :nameList="nameList"
        @selectToday="selectToday"
        @datePrev="datePrev"
        @dateNext="dateNext"
        @dropToContainer="dropToContainer"
      />

      <GlobalNotification />

      <div class="calendar-week">
        <div
          class="calendar-week__weekday"
          v-for="(day, index) in days"
          :key="'day-name-' + index"
          :class="{
            'current-day': day.date.toMillis() === currentDate.toMillis(),
            'old-day': day.date.toMillis() < currentDate.toMillis(),
            'future-day': day.date.toMillis() > currentDate.toMillis(),
          }"
        >
          <div class="calendar-week__weekday__name">
            {{ getDayNameFromDate(day.date) }}
          </div>
        </div>

        <div
          v-if="!loaded"
          class="calendar-week__day"
          :style="{
            height: `${availableSpace}px`,
          }"
        >
          <div class="calendar-week__day__wrapper">
            <div class="calendar-week__day__header">
              <span> </span>
            </div>
            <div class="calendar-week__day__body">
              <div>
                <IdeaWeekPlaceholder v-for="item in randomNumber" :key="item" />
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="!loaded"
          class="calendar-week__day"
          :style="{
            height: `${availableSpace}px`,
          }"
        >
          <div class="calendar-week__day__wrapper">
            <div class="calendar-week__day__header">
              <span> </span>
            </div>
            <div class="calendar-week__day__body">
              <div>
                <IdeaWeekPlaceholder />
                <IdeaWeekPlaceholder />
                <IdeaWeekPlaceholder />
                <IdeaWeekPlaceholder />
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="!loaded"
          class="calendar-week__day"
          :style="{
            height: `${availableSpace}px`,
          }"
        >
          <div class="calendar-week__day__wrapper">
            <div class="calendar-week__day__header">
              <span> </span>
            </div>
            <div class="calendar-week__day__body">
              <div>
                <IdeaWeekPlaceholder />
                <IdeaWeekPlaceholder />
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="!loaded"
          class="calendar-week__day"
          :style="{
            height: `${availableSpace}px`,
          }"
        >
          <div class="calendar-week__day__wrapper">
            <div class="calendar-week__day__header">
              <span> </span>
            </div>
            <div class="calendar-week__day__body">
              <div>
                <IdeaWeekPlaceholder />
                <IdeaWeekPlaceholder />
                <IdeaWeekPlaceholder />
                <IdeaWeekPlaceholder />
                <IdeaWeekPlaceholder />
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="!loaded"
          class="calendar-week__day"
          :style="{
            height: `${availableSpace}px`,
          }"
        >
          <div class="calendar-week__day__wrapper">
            <div class="calendar-week__day__header">
              <span> </span>
            </div>
            <div class="calendar-week__day__body">
              <div>
                <IdeaWeekPlaceholder />
                <IdeaWeekPlaceholder />
                <IdeaWeekPlaceholder />
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="!loaded"
          class="calendar-week__day"
          :style="{
            height: `${availableSpace}px`,
          }"
        >
          <div class="calendar-week__day__wrapper">
            <div class="calendar-week__day__header">
              <span> </span>
            </div>
            <div class="calendar-week__day__body">
              <div>
                <IdeaWeekPlaceholder />
                <IdeaWeekPlaceholder />
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="!loaded"
          class="calendar-week__day"
          :style="{
            height: `${availableSpace}px`,
          }"
        >
          <div class="calendar-week__day__wrapper">
            <div class="calendar-week__day__header">
              <span> </span>
            </div>
            <div class="calendar-week__day__body">
              <div>
                <IdeaWeekPlaceholder />
                <IdeaWeekPlaceholder />
                <IdeaWeekPlaceholder />
                <IdeaWeekPlaceholder />
                <IdeaWeekPlaceholder />
                <IdeaWeekPlaceholder />
              </div>
            </div>
          </div>
        </div>

        <template v-if="loaded">
          <div
            class="calendar-week__day"
            v-for="(day, index) in days"
            v-bind:data-date="day.date"
            :class="{
              'current-day': day.date.toMillis() === currentDate.toMillis(),
              'old-day': day.date.toMillis() < currentDate.toMillis(),
              'future-day': day.date.toMillis() > currentDate.toMillis(),
            }"
            :key="'day-data-' + index"
            :style="{
              height: `${availableSpace}px`,
            }"
          >
            <div
              v-if="globalDragStatus"
              class="calendar-week__drop-area"
              :class="{
                'is-dropped': activeDropzone === 'day-' + day.date.toMillis(),
              }"
            >
              <span>{{ $t('8_2_drop_area') }}</span>
            </div>

            <div
              class="calendar-week__day__wrapper"
              @drop.prevent="drop($event, day.date)"
              @mouseleave="hideExtra"
              @mouseover="showExtra(day.date)"
              @dragover.prevent="showActiveDrop('day-' + day.date.toMillis())"
            >
              <vuescroll :ops="scrollPanelIdea">
                <div class="calendar-week__day__header">
                  <span
                    :class="{
                      'current-day':
                        day.date.toMillis() === currentDate.toMillis(),
                      'old-day': day.date.toMillis() < currentDate.toMillis(),
                      'future-day':
                        day.date.toMillis() > currentDate.toMillis(),
                    }"
                  >
                    <div class="calendar-week__day__no">
                      {{ getDayFromDate(day.date) }}
                    </div>
                    <span class="calendar-wweek__day-labels">
                      <span
                        v-if="day.suggestions.length > 0"
                        class="content-suggestion"
                      >
                        <IcoSuggestion />
                      </span>
                      <span v-if="day.ideas.length > 0" class="content-counter">
                        <IcoContent /> {{ day.ideas.length }}
                      </span>
                    </span>
                  </span>
                </div>
                <div class="calendar-week__day__body">
                  <Suggestion
                    v-if="ideasContainerStatus"
                    v-for="item in day.suggestions"
                    :key="item.id"
                    size="large"
                    :content="item"
                  />
                  <IdeaWeek
                    v-for="item in day.ideas"
                    :key="item.id"
                    :id="item.key"
                    :item="item"
                    @deleteContent="deleteContent"
                  />
                  <div
                    v-if="dayShowExtra === day.date && role !== 'READ_ONLY'"
                    class="calendar-week__add-content"
                  >
                    <button
                      class="calendar-week__add-content__button"
                      @click="addContent(day.date)"
                    >
                      <IcoPlus /> {{ $t('8_2_add_content') }}
                    </button>
                  </div>
                </div>
              </vuescroll>
            </div>
          </div>
        </template>
      </div>
    </div>

    <ExpiredCompany v-else :company="selectedCompany" />
  </div>
</template>

<script>
import CONTENTS from '@/graphql/Contents.gql'
import CONTENT_DATE_UPDATE from '@/graphql/ContentDateUpdate.gql'
import CONTENT_IDEA_DATE_UPDATE from '@/graphql/ContentIdeaDateUpdate.gql'
import CONTENT_STATE_UPDATE from '@/graphql/ContentStateUpdate.gql'
import CONTENT_DELETE from '@/graphql/ContentDelete.gql'
import { DateTime } from 'luxon'
import { DateService } from '@/services/DateService.js'
import { FilterService } from '@/services/FilterService.js'
import Moment from 'moment'
import moment from 'moment'

import IcoPlus from '@/components/svg/IcoPlus.vue'
import IcoContent from '@/components/svg/IcoContent.vue'

import Menu from '@/components/Menu.vue'
import CalendarHeader from '@/components/CalendarHeader.vue'
import IdeaWeek from '@/components/IdeaWeek.vue'
import IdeaWeekPlaceholder from '@/components/IdeaWeekPlaceholder.vue'
import vuescroll from 'vuescroll'
import GlobalNotification from '@/components/GlobalNotification.vue'
import ExpiredCompany from '@/components/ExpiredCompany.vue'
import Suggestion from '@/components/Suggestion.vue'
import { obj_clone } from '@/services/helpers/clone'
import IcoSuggestion from '@/components/svg/IcoSuggestion.vue'

export default {
  components: {
    IcoSuggestion,
    IcoPlus,
    IcoContent,
    Menu,
    Moment,
    vuescroll,
    CalendarHeader,
    IdeaWeek,
    IdeaWeekPlaceholder,
    moment,
    GlobalNotification,
    ExpiredCompany,
    Suggestion,
  },
  created() {
    this.countAvailableSpace()
    window.addEventListener('resize', this.countAvailableSpace)

    this.$store.commit('setDate', this.dateRangeFrom)
    this.setWeek()
  },
  apollo: {
    companies: {
      query: CONTENTS,
      variables() {
        return {
          from: this.localDateFrom,
          to: this.localDateTo,
          id: this.selectedCompany.id,
        }
      },
      skip() {
        if (!this.selectedCompany) {
          return true
        }

        return !(this.from !== '' && this.to !== '')
      },
      error(error) {
        this.$notify({
          title: this.$t('8_2_error_title_load_contents'),
          text: this.$t('8_2_error_description_load_contents'),
          type: 'error',
          duration: 5000,
        })
      },
    },
  },
  computed: {
    currentDate() {
      if (this.selectedCompany) {
        return DateTime.now()
          .setZone(this.selectedCompany.zoneId)
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      }

      return DateTime.now().set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      })
    },
    localDateTo() {
      if (this.to && this.selectedCompany) {
        return DateService.lxnGetEODFromDate(
          this.to,
          this.selectedCompany.zoneId
        )
      }
    },
    localDateFrom() {
      if (this.from && this.selectedCompany) {
        return DateTime.fromISO(this.from.toISOString())
          .setZone(this.selectedCompany.zoneId)
          .set({
            year: this.from.getFullYear(),
            month: this.from.getMonth() + 1,
            day: this.from.getDate(),
            hour: 0,
            minute: 0,
            seconds: 0,
          })
          .toUTC()
          .toISO()
      }
    },
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    profile() {
      return this.$store.getters.getMe
    },
    dateRangeFrom() {
      return this.$store.getters.getDateRangeFrom
    },
    randomNumber() {
      return Math.floor(Math.random() * 4) + 3
    },
    refreshContent() {
      return this.$store.getters.getRefreshContent
    },
    selectedDate() {
      return this.$store.getters.getSelectedDate
    },
    globalDragStatus() {
      return this.$store.getters.getGlobalDragStatus
    },
    activeDropzone() {
      return this.$store.getters.getActiveDropZone
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
    contentOwner() {
      return this.$store.getters.getContentOwner
    },
    contentFilter() {
      let result = []
      const _contentFilter = this.$store.getters.getContentFilter
      _contentFilter.forEach((item) => {
        let values = []

        if (item.name.id === 'name') {
          if (item.value.length > 0) {
            values.push(item.value)
          }
        } else {
          if (item.value !== '') {
            item.value.forEach((value) => {
              values.push(value.value)
            })
          }
        }

        result.push({
          type: item.name.id,
          values: values,
        })
      })

      return result
    },
    ideasContainerStatus() {
      return this.$store.getters.getIdeasContainerStatus
    },
  },
  data() {
    return {
      billingOverdue: false,
      loaded: false,
      companies: [],
      dayShowExtra: '',
      formattedCurrentDate: '',
      scrollPanel: {
        initialScrollY: false,
        initialScrollX: false,
        scrollingX: false,
        scrollingY: true,
        speed: 300,
        easing: undefined,
        verticalNativeBarPos: 'right',
      },
      scrollPanelIdea: {
        initialScrollY: false,
        initialScrollX: false,
        scrollingX: true,
        scrollingY: false,
        speed: 300,
        easing: undefined,
        verticalNativeBarPos: 'right',
      },
      selectedDay: '',
      from: '',
      to: '',
      dateTo: '',
      dateFrom: '',
      lastClick: '',
      counter: 0,
      days: [],
      nameList: [],
      contents: [],
      suggestions: [],
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.countAvailableSpace)
  },
  methods: {
    getContents() {
      let contents = []

      if (this.companies.length === 0) {
        return contents
      }

      const _contents = this.companies[0].contents
      for (let i = 0; i < _contents.length; i++) {
        let content = _contents[i]

        if (content.state === 'IDEA') {
          continue
        }

        content.key = 'key-' + content.id

        if (content.eod === true) {
          content.date = DateTime.fromISO(content.date)
            .setZone(this.selectedCompany.zoneId)
            .set({
              hour: 23,
              minutes: 59,
              second: 50,
            })
            .toUTC()
            .toISO()
        }

        contents.push(content)

        this.nameList = FilterService.addNameList(content.name, this.nameList)
      }

      this.contents = FilterService.filterContent(
        contents,
        this.contentFilter,
        this.contentOwner
      )
    },
    getSuggestions() {
      let suggestions = []

      if (this.companies.length === 0) {
        return suggestions
      }

      const _suggestions = this.companies[0].suggestedContents

      for (let i = 0; i < _suggestions.length; i++) {
        let content = obj_clone(_suggestions[i])

        content.key = 'key-' + content.id

        const day = DateService.getDateFromString(content.date)
        content.date = DateTime.fromISO(content.date)
          .setZone(this.selectedCompany.zoneId)
          .set({
            year: day[0],
            month: day[1],
            day: day[2],
            hour: 11,
            minute: 59,
            second: 59,
          })
          .toUTC()
          .set({
            hour: 11,
            minute: 59,
            second: 59,
          })
          .toISO()

        suggestions.push(content)
      }

      this.suggestions = suggestions
    },
    getDayNameFromDate(date) {
      return DateTime.fromISO(date)
        .setZone(this.selectedCompany.zoneId)
        .setLocale(this.$i18n.locale)
        .toLocaleString({ weekday: 'short' })
    },
    getDayFromDate(date) {
      return DateTime.fromISO(date)
        .setZone(this.selectedCompany.zoneId)
        .toLocaleString({ day: '2-digit' })
    },
    deleteContent(content_id) {
      try {
        this.$apollo
          .mutate({
            mutation: CONTENT_DELETE,
            variables: {
              id: content_id,
            },
            update: (store, { data: { deleteContent } }) => {
              if (!deleteContent) {
                this.$notify({
                  title: this.$t('8_2_toaster_error_title_content_delete'),
                  text: this.$t('8_2_toaster_error_description_content_delete'),
                  type: 'error',
                  duration: 5000,
                })

                return
              }

              const cached = store.readQuery({
                query: CONTENTS,
                variables: {
                  from: DateService.dateToUTC(this.from),
                  to: DateService.dateToUTC(this.to),
                  id: this.selectedCompany.id,
                },
              })

              cached.companies[0].contents =
                cached.companies[0].contents.filter(
                  (item) => item.id !== content_id
                )

              store.writeQuery({
                query: CONTENTS,
                data: cached,
                variables: {
                  from: DateService.dateToUTC(this.from),
                  to: DateService.dateToUTC(this.to),
                  id: this.selectedCompany.id,
                },
              })

              // this.getContents();

              this.$notify({
                title: $t('8_2_toaster_success_title_deleted_content'),
                text: $t('8_2_toaster_success_description_deleted_content'),
                type: 'success',
                duration: 5000,
              })
            },
            onQueryUpdated(observableQuery) {
              return observableQuery.refetch()
            },
          })
          .then((data) => {})
          .catch((error) => {
            this.$notify({
              title: this.$t('8_2_toaster_error_content_delete'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('8_2_toaster_error_content_delete'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    updateContentState(content_id, state) {
      if (this.role === 'READ_ONLY') {
        return
      }

      try {
        this.$apollo
          .mutate({
            mutation: CONTENT_STATE_UPDATE,
            variables: {
              id: content_id,
              state: state,
              eod: false,
            },
            update: (store, { data: { updateContent } }) => {
              this.$store.commit('setRefreshToken')
            },
          })
          .then((data) => {
            // console.log("data", data);
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('8_2_toaster_error_content_state_update'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('8_2_toaster_error_content_state_update'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    updateContent(content, date) {
      if (this.role === 'READ_ONLY') {
        return
      }

      try {
        this.$apollo
          .mutate({
            mutation: CONTENT_DATE_UPDATE,
            variables: {
              id: content.id,
              date: date,
              eod: content.eod,
            },
            update: (store, { data: { updateContent } }) => {},
          })
          .then((data) => {
            this.$store.commit('setRefreshToken')
          })
          .catch((error) => {
            this.$notify({
              title: this.$t('8_2_toaster_error_content_update'),
              text: this.$t('global_toaster_error_description'),
              type: 'error',
              duration: 5000,
            })
          })
      } catch (e) {
        this.$notify({
          title: this.$t('8_2_toaster_error_content_update'),
          text: this.$t('global_toaster_error_description'),
          type: 'error',
          duration: 5000,
        })
      }
    },
    updateIdeaContent(idea_id, date, eod) {
      if (this.role === 'READ_ONLY') {
        return
      }

      try {
        this.$apollo.mutate({
          mutation: CONTENT_IDEA_DATE_UPDATE,
          variables: {
            id: idea_id,
            date: date,
            state: 'TODO',
          },
          update: (store, { data: { updateContent } }) => {
            this.$apollo.queries.companies.refetch()
            this.$store.commit('setRefreshToken')
          },
        })
      } catch (e) {
        console.error(e)
      }
    },
    showActiveDrop(key) {
      this.$store.commit('setActiveDropZone', key)
    },
    showExtra(day) {
      this.dayShowExtra = day
    },
    hideExtra() {
      this.dayShowExtra = ''
    },
    getFormattedCurrentDate() {
      this.formattedCurrentDate =
        moment(this.dateFrom).format('D MMM') +
        ' - ' +
        moment(this.dateTo).format('D MMM, YYYY')
    },
    countAvailableSpace() {
      if (this.ideasContainerStatus) {
        this.availableSpace = window.innerHeight - 322
      } else {
        this.availableSpace = window.innerHeight - 220
      }

      if (this.selectedCompany && this.selectedCompany.showExpirationWarning) {
        this.availableSpace = this.availableSpace - 38
      }
    },
    drop(e, date) {
      const idea_id = e.dataTransfer.getData('idea_id')
      const idea_date = e.dataTransfer.getData('idea_date')
      const idea_eod = e.dataTransfer.getData('idea_eod')
      const tasks = this.contents.filter((item) => item.id === idea_id)
      const task = tasks[0]

      let lxnDate = undefined

      if (idea_eod === 'yes') {
        lxnDate = DateTime.fromISO(idea_date)
          .setZone(this.selectedCompany.zoneId)
          .set({
            year: date.year,
            month: date.month,
            day: date.day,
            hour: 11,
            minute: 59,
            second: 59,
          })
          .toUTC()
          .set({
            hour: 11,
            minute: 59,
            second: 59,
          })
          .toISO()
      } else {
        lxnDate = DateTime.fromISO(idea_date)
          .setZone(this.selectedCompany.zoneId)
          .set({
            year: date.year,
            month: date.month,
            day: date.day,
          })
          .toUTC()
          .toISO()
      }

      if (tasks.length > 0) {
        this.updateContent(task, lxnDate)
      } else {
        lxnDate = DateTime.fromISO(new Date(idea_date).toISOString())
          .setZone(this.selectedCompany.zoneId)
          .set({
            year: date.year,
            month: date.month,
            day: date.day,
            hour: 11,
            minute: 59,
            second: 59,
          })
          .toUTC()
          .toISO()

        this.updateIdeaContent(idea_id, lxnDate, idea_eod)
      }
    },
    generateWeek() {
      if (!this.selectedCompany) {
        return
      }

      this.days = []

      function compare(a, b) {
        if (a.date < b.date) {
          return -1
        }
        if (a.date > DateTime.now()) {
          return 1
        }
        return 0
      }

      if (this.dateFrom) {
        for (let i = 0; i < 7; i++) {
          const lxnDate = DateTime.now()
            .setZone(this.selectedCompany.zoneId)
            .set({
              year: this.dateFrom.getFullYear(),
              month: this.dateFrom.getMonth() + 1,
              day: this.dateFrom.getDate(),
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .plus({ days: i })

          this.days[i] = {}
          this.days[i].date = lxnDate
          this.days[i].ideas = this.contents.filter(
            (item) =>
              DateTime.fromISO(item.date)
                .setZone(this.selectedCompany.zoneId)
                .set({ hour: 0, minute: 0, seconds: 0, millisecond: 0 })
                .toMillis() === lxnDate.toMillis()
          )
          this.days[i].suggestions = this.suggestions.filter(
            (item) =>
              DateTime.fromISO(item.date)
                .setZone(this.selectedCompany.zoneId)
                .set({ hour: 0, minute: 0, seconds: 0, millisecond: 0 })
                .toMillis() === lxnDate.toMillis()
          )

          this.days[i].ideas.sort(compare)
        }
      }
    },
    processDate() {
      this.from = new Date(this.dateFrom)
      this.from.setHours(0, 0, 0, 0)

      this.to = new Date(this.dateTo)
      this.to.setHours(23, 59, 59, 0)
    },
    setWeek() {
      const _date = this.firstDayOfWeek(this.selectedDate, 1)

      this.dateFrom = _date

      let dateTo = new Date(this.dateFrom)
      dateTo.setDate(_date.getDate() + 6)
      this.dateTo = dateTo
    },
    selectToday() {
      const date = this.firstDayOfWeek(new Date(), 1)

      let dateTo = new Date()
      dateTo.setDate(date.getDate() + 6)

      this.dateFrom = date
      this.dateTo = dateTo

      this.$store.commit('setDate', this.dateFrom)
      this.$store.commit('setDateRangeFrom', this.dateFrom)
      this.$store.commit('setDateRangeTo', this.dateTo)

      this.processDate()
    },
    datePrev() {
      let dateFrom = this.dateFrom
      let dateTo = this.dateTo

      dateFrom.setDate(dateFrom.getDate() - 7)
      dateTo.setDate(dateTo.getDate() - 7)

      this.dateFrom = dateFrom
      this.dateTo = dateTo

      this.loaded = false
      this.$store.commit('setDate', this.dateFrom)
      this.$store.commit('setDateRangeFrom', this.dateFrom)
      this.$store.commit('setDateRangeTo', this.dateTo)

      this.processDate()
    },
    dateNext() {
      let dateFrom = this.dateFrom
      let dateTo = this.dateTo

      dateFrom.setDate(dateFrom.getDate() + 7)
      dateTo.setDate(dateTo.getDate() + 7)

      this.dateFrom = dateFrom
      this.dateTo = dateTo

      this.loaded = false
      this.$store.commit('setDate', this.dateFrom)
      this.$store.commit('setDateRangeFrom', this.dateFrom)
      this.$store.commit('setDateRangeTo', this.dateTo)

      this.processDate()

      this.getFormattedCurrentDate()
    },
    addContent(date) {
      this.$store.commit('setCreateContentDefaultValue', {
        date: date,
      })
      this.$store.commit('setCreateContentModalStatus', true)
    },
    dropToContainer(idea_id) {
      this.updateContentState(idea_id, 'IDEA')
    },
    firstDayOfWeek(dateObject, firstDayOfWeekIndex) {
      const dayOfWeek = dateObject.getDay(),
        firstDayOfWeek = new Date(dateObject),
        diff =
          dayOfWeek >= firstDayOfWeekIndex
            ? dayOfWeek - firstDayOfWeekIndex
            : 6 - dayOfWeek

      firstDayOfWeek.setDate(dateObject.getDate() - diff)
      firstDayOfWeek.setHours(0, 0, 0, 0)

      return firstDayOfWeek
    },
  },
  watch: {
    selectedCompany() {
      this.billingOverdue = this.selectedCompany.billingOverdue
    },
    refreshContent() {
      this.$apollo.queries.companies.refetch()
    },
    dateFrom() {
      this.processDate()
    },
    contents() {
      this.generateWeek()
      this.getFormattedCurrentDate()
    },
    selectedDate() {
      this.setWeek()
      this.generateWeek()
      this.getFormattedCurrentDate()
    },
    companies() {
      if (this.companies.length > 0) {
        this.loaded = true
        this.getContents()
        this.getSuggestions()
      }
    },
    ideasContainerStatus() {
      this.countAvailableSpace()
    },
  },
}
</script>
