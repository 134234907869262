<template>
  <Sidebar>
    <div class="line">
        <h2>{{ $t('1_2_title_invite_company') }}</h2>
    </div>
    <div class="line line--half">
        <p>{{ $t("1_2_description_invite_company") }}</p>
    </div>
  </Sidebar>
</template>

<script>
import INVITE_ACCEPT from "@/graphql/InviteAccept.gql";
import Sidebar from "@/components/layout/Sidebar.vue";

export default {
  components: {
    Sidebar,
  },
  mounted() {
    this.verifyToken()
  },
  data() {
    return {}
  },
  methods: {
    verifyToken() {

      try {
        this.$apollo
          .mutate({
            mutation: INVITE_ACCEPT,
            variables: {
              id: this.$route.params.token
            },
            update: (store, { data: { acceptInvite } }) => {

              if(!acceptInvite.id) {
                this.$notify({
                  title: this.$t("1_2_toaster_error_title_company_join"),
                  text: this.$t("1_2_toaster_error_description_company_join"),
                  type: "error",
                  duration: 5000,
                })

                this.$router.push({ name: "AddCompany" });
                return
              }

              this.$notify({
                title: this.$t("1_2_toaster_success_title_company_joined"),
                text: this.$t("1_2_toaster_success_description_company_joined"),
                type: "success",
                duration: 5000,
              });

              this.$store.commit("setCompany", {id: acceptInvite.id, name: acceptInvite.name, zoneId: acceptInvite.zoneId});
              this.$store.commit("setRefreshToken");
              this.$router.push({ name: "Calendar" });
            },
          })
          .catch((e) => {
            /*
            this.$notify({
              title: this.$t("1_2_toaster_error_title_company_join"),
              text: this.$t("1_2_toaster_error_description_company_join"),
              type: "error",
              duration: 5000,
            }))
             */

            // this.$router.push({ name: "AddCompany" });
            // this.$router.push({ name: "Login" });
            this.$router.push({ name: "Login", params: { redirect_url: "https://contesaur.app/invite/" + this.$route.params.token } });
          })
      } catch (e) {
        /*
        this.$notify({
          title: this.$t("1_2_toaster_error_title_company_join"),
          text: this.$t("1_2_toaster_error_description_company_join"),
          type: "error",
          duration: 5000,
        })
         */

        this.$router.push({ name: "Login", params: { redirect_url: "https://contesaur.app/invite/" + this.$route.params.token } });
      }
    },
  },
};
</script>
