<template>
  <div
    class="calendar-day-column"
    @mouseleave="hideExtra"
    @mouseover="showExtra(column.id)"
  >
    <div
      v-if="globalDragStatus"
      class="calendar-day__drop-area"
      :class="{
        'is-dropped': activeDropzone == 'column-' + column.id,
      }"
    >
      <span>{{ $t('8_3_drop_area') }}</span>
    </div>

    <div class="calendar-day-column__header">
      <div class="calendar-day-column__header__status">
        <div
          class="calendar-day-column__header__ico"
          :style="{ backgroundColor: column.color }"
        ></div>
        <div class="calendar-day-column__header__name">
          {{ column.title }}
        </div>
      </div>

      <div class="calendar-day-column__header__counter">
        <div
          class="content-suggestion content-suggestion--transparent"
          v-if="suggestion.length > 0"
        >
          <IcoSuggestion />
        </div>

        <div v-if="data.length > 0" class="content-counter">
          <IcoContent /> {{ data.length }}
        </div>
      </div>
    </div>

    <div
      class="calendar-day-column__data"
      :style="{
        height: `${availableSpace}px`,
      }"
      @dragover.prevent="showActiveDrop('column-' + column.id)"
      @drop.prevent="drop($event, column.id)"
    >
      <vuescroll :ops="ops">
        <div v-if="!loaded">
          <IdeaDayPreloader v-for="item in randomNumber" :key="item" />
        </div>

        <Suggestion
          v-if="loaded && ideasContainerStatus"
          v-for="item in suggestion"
          :key="item.key"
          color="dark"
          size="large"
          :content="item"
        />

        <IdeaDay
          v-if="loaded"
          v-for="day in data"
          :key="day.id"
          :id="day.key"
          :item="day"
          @deleteContent="deleteContent"
        />

        <div
          v-if="role !== 'READ_ONLY'"
          class="calendar-day-column__add-content"
        >
          <button
            v-if="getDayShowExtra === column.id"
            class="calendar-day-column__add-content__button"
            @click="addContent(column.id)"
          >
            <IcoPlus /> {{ $t('8_3_add_content') }}
          </button>
        </div>
      </vuescroll>
    </div>
  </div>
</template>

<script>
import vuescroll from 'vuescroll'
import IdeaDay from '@/components/IdeaDay.vue'
import IdeaDayPreloader from '@/components/IdeaDayPreloader.vue'
import IcoPlus from '@/components/svg/IcoPlus.vue'
import IcoContent from '@/components/svg/IcoContent.vue'
import Suggestion from '@/components/Suggestion.vue'
import IcoSuggestion from '@/components/svg/IcoSuggestion.vue'

export default {
  components: {
    IcoSuggestion,
    vuescroll,
    IdeaDay,
    IdeaDayPreloader,
    IcoPlus,
    IcoContent,
    Suggestion,
  },
  props: {
    data: {
      type: Array,
    },
    suggestion: {
      type: Array,
    },
    column: {
      type: Object,
      required: true,
    },
    loaded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    role() {
      return this.$store.getters.getMeCompanyRole
    },
    getDayShowExtra() {
      return this.$store.getters.getDayShowExtra
    },
    randomNumber() {
      return Math.floor(Math.random() * 4) + 1
    },
    selectedDate() {
      return this.$store.getters.getSelectedDate
    },
    globalDragStatus() {
      return this.$store.getters.getGlobalDragStatus
    },
    activeDropzone() {
      return this.$store.getters.getActiveDropZone
    },
    ideasContainerStatus() {
      return this.$store.getters.getIdeasContainerStatus
    },
    selectedCompany() {
      return this.$store.getters.getSelectedCompany
    },
  },
  data() {
    return {
      dayShowExtra: '',
      availableSpace: 500,
      days: [
        {
          id: 1,
          order: 10,
        },
        {
          id: 2,
          order: 20,
        },
        {
          id: 3,
          order: 30,
        },
        {
          id: 4,
          order: 40,
        },
      ],
      ops: {
        scrollPanel: {
          initialScrollY: true,
          initialScrollX: false,
          scrollingX: false,
          scrollingY: true,
          speed: 300,
          easing: undefined,
          verticalNativeBarPos: 'right',
        },
      },
    }
  },
  created() {
    this.countAvailableSpace()
    window.addEventListener('resize', this.countAvailableSpace)
  },
  destroyed() {
    window.removeEventListener('resize', this.countAvailableSpace)
  },
  methods: {
    deleteContent(content_id) {
      this.$emit('deleteContent', content_id)
    },
    showExtra(columnId) {
      // this.dayShowExtra = column;
      this.$store.commit('setDayShowExtra', columnId)
    },
    hideExtra() {
      this.$store.commit('setDayShowExtra', null)
    },
    addContent(state) {
      this.$store.commit('setCreateContentDefaultValue', {
        state: state,
        date: this.selectedDate,
      })
      this.$store.commit('setCreateContentModalStatus', true)
    },
    countAvailableSpace() {
      if (this.ideasContainerStatus) {
        this.availableSpace = window.innerHeight - 330
      } else {
        this.availableSpace = window.innerHeight - 220
      }

      if (this.selectedCompany.showExpirationWarning) {
        this.availableSpace = this.availableSpace - 50
      }
    },
    showActiveDrop(key) {
      this.$store.commit('setActiveDropZone', key)
    },
    drop(e, status) {
      this.$store.commit('setDayShowExtra', null)
      const idea_id = e.dataTransfer.getData('idea_id')
      this.$emit('changeIdeaStatusById', idea_id, status)
    },
  },
  watch: {
    ideasContainerStatus() {
      this.countAvailableSpace()
    },
  },
}
</script>
